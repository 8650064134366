import { LoadingButton } from "@mui/lab";
import { FormControl, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getEmailTemplatesList,
  putEmailTemplatesList,
  sendTestEmail,
} from "src/features/returnConfiguration/emailTemplatesApi";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import typography from "src/theme/typography";
import {
  htmlReturnTemplateTextList,
  fetchTemplateByName,
} from "src/utils/convertHtmlToText";
import PreviewTemplate from "./PreviewTemplate";
import ModalComp from "./ModalComp";
import ModalComponent from "src/components/modal/ModalComponent";
import { retrieveLogoUrl } from "src/features/returnConfiguration/emailTemplatesApi";
import { useForm } from "react-hook-form";
import "./styles.css";

export const filterImgs = (htmlContent) => {
  const temporalComp = document.createElement("div");
  temporalComp.innerHTML = htmlContent;

  const unwantedImgs = temporalComp.getElementsByTagName("img");
  for (let i = 0; i < unwantedImgs.length; i++) {
    unwantedImgs[i].parentNode.removeChild(unwantedImgs[i]);
  }

  // const paragraphs = temporalComp.getElementsByTagName("p");
  // const ul = temporalComp.getElementsByTagName("ul");
  // const ol = temporalComp.getElementsByTagName("ol");
  // for (let i = 0; i < paragraphs.length; i++) {
  //   const br = document.createElement("br");
  //   paragraphs[i].parentNode.insertBefore(br, paragraphs[i].nextSibling);
  // }
  // for (let i = 0; i < ul.length; i++) {
  //   const br = document.createElement("br");
  //   ul[i].parentNode.insertBefore(br, ul[i].nextSibling);
  // }
  // for (let i = 0; i < ol.length; i++) {
  //   const br = document.createElement("br");
  //   ol[i].parentNode.insertBefore(br, ol[i].nextSibling);
  // }

  return temporalComp.innerHTML;
};

export const filterTable = (htmlContent) => {
  const temporalComp = document.createElement("div");
  temporalComp.innerHTML = htmlContent;
  const unwantedTable = temporalComp.getElementsByTagName("table");
  for (let i = 0; i < unwantedTable.length; i++) {
    unwantedTable[i].parentNode.removeChild(unwantedImgs[i]);
  }

  return temporalComp.innerHTML;
};

const EmailTemplateEdition = ({
  template_name,
  setOpenEditTemplateUI,
  emailTemplateItemData,
  logoFile,
  setRefresh,
}) => {
  const [emailTemplateLayout, setEmailTemplateLayout] = useState("");
  const [emailTemplateContent, setEmailTemplateContent] = useState("");
  const [emailTemplateSubject, setEmailTemplateSubject] = useState("");
  const [emailTemplateBackendReponse, setEmailTemplateBackendReponse] =
    useState("");

  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [openSuccesfullyPublish, setOpenSuccesfullyPublish] = useState(false);
  const [openSuccesfullySentTestMail, setOpenSuccesfullySentTestMail] =
    useState(false);

  const [isSubmited, setIsSubmited] = useState(false);
  const [sendEmailErrorMessage, setSendEmailErrorMessage] = useState("");

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  const handleCancelBtn = () => {
    setOpenEditTemplateUI("");
    setRefresh((prev) => !prev);
  };

  const handlePublishBtn = () => {
    setOpenPublishModal(true);
  };
  const debouncedHandleChange = useCallback(
    debounce((value) => {
      setEmailTemplateContent(value);
    }, 500),
    []
  );

  const mergeHtmlLayoutAndNewContent = () => {
    let emailTemplateFormatted = emailTemplateContent.replaceAll(
      "<p",
      '<p style="margin: 0px; line-height: 20px;"'
    );

    // if includes the track return items btn
    if (emailTemplateFormatted.includes('class="ql-align-center')) {
      emailTemplateFormatted = emailTemplateFormatted.replaceAll(
        '<p style="margin: 0px; line-height: 20px;" class="ql-align-center"',
        '<p style="margin: 0px; line-height: 20px; text-align: center;" class="ql-align-center"'
      );
    }

    const src = retrieveLogoUrl + logoFile;

    return emailTemplateLayout.replace(
      "[replace_this_by_content]",
      `<div style="display: flex; justify-content: center; padding-bottom: 20px">
        <img
        alt="logo"
        src="${src}"
        style="display: block; max-width: 150px"
      /></div>` + emailTemplateFormatted
    );
  };

  useEffect(() => {
    const populateEmailTemplate = async () => {
      dispatch(incrementAwaitingApiCounter());

      try {
        const response = await getEmailTemplatesList(userInfo);
        const templateData = await fetchTemplateByName(template_name);

        if (response.statusCode === 200) {
          if (response.data.length > 0) {
            const emailTemplateData = response.data.find(
              (el) => el.template_name === template_name
            );
            if (emailTemplateData) {
              setEmailTemplateContent(
                filterImgs(emailTemplateData?.html_content)
              );
              setEmailTemplateLayout(templateData?.html_layout);
              setEmailTemplateBackendReponse(
                filterImgs(emailTemplateData?.html_content)
              );
              setEmailTemplateSubject(emailTemplateData?.subject);
              dispatch(decrementAwaitingApiCounter());
              return;
            }
          }

          const defaultTemplate = (await htmlReturnTemplateTextList()).find(
            (el) => el.template_name === template_name
          );
          setEmailTemplateContent(
            defaultTemplate?.html_content.replace(
              "{{tracking_link}}",
              // TODO: when deploying to product, change this url to the prod url

              `https://app-staging.beyondcarts.com/returns/${userInfo.tenant_name}/tracking`
            )
          );
          setEmailTemplateLayout(defaultTemplate?.html_layout);
          setEmailTemplateSubject(defaultTemplate?.subject);
          setEmailTemplateBackendReponse(
            defaultTemplate?.html_layout.replace(
              "[replace_this_by_content]",
              filterImgs(defaultTemplate?.html_content)
            )
          );
          dispatch(decrementAwaitingApiCounter());
        }
        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        dispatch(decrementAwaitingApiCounter());

        console.log("error", error);
      }
    };

    if (template_name) {
      populateEmailTemplate();
    }
  }, [template_name]);

  const handleAcceptPublishBtn = async () => {
    const body = {
      template_name,
      html_content: mergeHtmlLayoutAndNewContent(),
      subject: emailTemplateSubject,
    };

    dispatch(incrementAwaitingApiCounter());

    try {
      const response = await putEmailTemplatesList(body, userInfo);

      if (response.statusCode === 200) {
        setOpenPublishModal(false);
        setOpenSuccesfullyPublish(true);
      }
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
    }
  };

  const handleSendTestMail = async (data) => {
    const body = {
      to_address: [data.email],
      subject: emailTemplateSubject,
      content: mergeHtmlLayoutAndNewContent(),
      source: "no-reply@beyondcarts.com",
    };

    try {
      dispatch(incrementAwaitingApiCounter());

      const response = await sendTestEmail(body, userInfo);
      if (response.statusCode === 200) {
        setIsSubmited(true);
        setOpenSuccesfullySentTestMail(true);
      } else {
        setSendEmailErrorMessage("❌ There was an error sending the email");
        setIsSubmited(false);
      }

      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      console.log("error", error);
      setSendEmailErrorMessage(
        "❌ You have exceeded the number of attempts or your email is not registered"
      );
      dispatch(decrementAwaitingApiCounter());
    }
  };

  useEffect(() => {
    const transformContent = async () => {
      if (emailTemplateContent) {
        if (document.querySelector("#email_template_content")) {
          let response;
          if (template_name === "request_initiated") {
            response = await fetch(
              "/returnEmailTemplates/contents/tableTemplateContentWithoutPricing.txt"
            );
          } else if (template_name === "free_label") {
            response = await fetch(
              "/returnEmailTemplates/contents/tableTemplateFreeLabel.txt"
            );
          } else if (template_name === "paid_label") {
            response = await fetch(
              "/returnEmailTemplates/contents/tableTemplatePaidLabel.txt"
            );
          } else if (template_name === "own_label") {
            response = await fetch(
              "/returnEmailTemplates/contents/tableTemplateOwnLabel.txt"
            );
          } else {
            response = await fetch(
              "/returnEmailTemplates/contents/tableTemplateContent.txt"
            );
          }
          const htmlTable = await response.text();
          document.querySelector("#email_template_content").innerHTML =
            emailTemplateContent.replace("{{product_items}}", htmlTable);
        }
      }
    };

    transformContent();
  }, [emailTemplateContent, emailTemplateBackendReponse]);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingTop={3}
      >
        <Typography fontSize={20} fontFamily={typography.fontFamilySemiBold}>
          Template Name:{" "}
          {
            emailTemplateItemData?.find(
              (el) => el?.templateName === template_name
            )?.title
          }
        </Typography>
        <Stack direction={"row"} gap={2}>
          <LoadingButton
            variant="outlined"
            onClick={handleCancelBtn}
            sx={{
              width: { lg: 140, sm: 120, xs: 120, md: 140 },
              height: { xs: 36, sm: 36, md: 36, lg: 40 },
              border: "1px rgba(128, 128, 128, 0.32) solid",
              color: "secondary.main",
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handlePublishBtn}
            sx={{
              width: { lg: 140, sm: 120, xs: 120, md: 140 },
              height: { xs: 36, sm: 36, md: 36, lg: 40 },
              backgroundColor: "secondary.main",
              boxShadow: "none",
            }}
          >
            Publish
          </LoadingButton>
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={3} paddingY={3}>
        <Stack
          height={"100%"}
          flex={1}
          bgcolor={"#fff"}
          borderRadius={"12px"}
          border="1px solid #AAAAAA"
          padding={2}
          gap={1.5}
        >
          <Typography fontFamily={typography.fontFamilySemiBold} fontSize={14}>
            Edit template
          </Typography>
          <Typography fontFamily={typography.fontFamilySemiBold} fontSize={18}>
            Subject
          </Typography>
          <TextField
            size="small"
            value={emailTemplateSubject}
            onChange={(event) => setEmailTemplateSubject(event.target.value)}
          />
          <Typography fontFamily={typography.fontFamilySemiBold} fontSize={18}>
            Message body
          </Typography>

          <ReactQuill
            modules={module}
            theme="snow"
            value={emailTemplateContent}
            onChange={debouncedHandleChange}
          />
        </Stack>
        <Stack
          // height={"200%"}
          flex={1}
          bgcolor={"#fff"}
          borderRadius={"12px"}
          border="1px solid #AAAAAA"
          padding={2}
          gap={1.5}
        >
          <Typography fontFamily={typography.fontFamilySemiBold} fontSize={14}>
            Preview template
          </Typography>

          <PreviewTemplate
            template_name={template_name}
            logoFile={logoFile}
            emailTemplateContent={emailTemplateBackendReponse}
            emailTemplateSubject={emailTemplateSubject}
          />
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography fontSize={20}>Test your template</Typography>
        <FormControl
          component="form"
          className="form"
          onSubmit={handleSubmit(handleSendTestMail)}
        >
          <Stack direction={"row"} gap={2}>
            <TextField
              size="small"
              placeholder="Enter an email to test your changes"
              sx={{
                width: "30%",
              }}
              autoComplete="email"
              {...register("email", {
                required: "Email address is required.",
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: "Invalid email address.",
                },
              })}
              error={!!errors.email}
              aria-invalid={!!errors.email}
              helperText={
                errors.email
                  ? errors.email.message
                  : isSubmited
                  ? ""
                  : sendEmailErrorMessage
              }
            />
            <LoadingButton
              variant="contained"
              type="submit"
              sx={{
                width: { lg: 140, sm: 120, xs: 120, md: 140 },
                height: { xs: 36, sm: 36, md: 36, lg: 40 },
                backgroundColor: "secondary.main",
                boxShadow: "none",
              }}
            >
              Send test mail
            </LoadingButton>
          </Stack>
        </FormControl>
      </Stack>

      {openPublishModal && (
        <ModalComp
          openModal={openPublishModal}
          handleCloseModal={() => setOpenPublishModal(false)}
          handlePublishBtn={handleAcceptPublishBtn}
          handleCancelBtn={() => setOpenPublishModal(false)}
          title={"Email Template"}
          description={
            "Are you sure you want to publish the changes you have made?"
          }
        />
      )}

      {openSuccesfullyPublish && (
        <ModalComponent
          openModal={openSuccesfullyPublish}
          handleCloseModal={() => setOpenSuccesfullyPublish(false)}
          handleBtnClick={() => {
            setOpenSuccesfullyPublish(false);
            setOpenEditTemplateUI("");
          }}
          title={"Email Template"}
          description={"Your Email Template has been updated successfully"}
          btnText={"Close"}
        />
      )}

      {openSuccesfullySentTestMail && (
        <ModalComponent
          openModal={openSuccesfullySentTestMail}
          handleCloseModal={() => setOpenSuccesfullySentTestMail(false)}
          handleBtnClick={() => setOpenSuccesfullySentTestMail(false)}
          title={"Test Email Template"}
          description={"Your Email Template has been sent to your email."}
          btnText={"Close"}
        />
      )}
    </>
  );
};

export default EmailTemplateEdition;
