import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import postData from "src/dataFetcher/postDataFetcher";
import postFormData from "src/dataFetcher/postFormDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";

import { backendTenantUrl, backendBaseUrl } from "src/utils/backendUrls";

const logoUrl = process.env.REACT_APP_BACKEND_LOGO_URL;

export const retrieveLogoUrl = process.env.REACT_APP_BACKEND_RETRIEVE_LOGO_URL;

const extractData = (result) => {
	if (encConfig.encryption.decrypt) {
	   return decryptData(result);
	}
	return result;
  };

export async function updateTenantInfo(data, userInfo) {
  const token = userInfo?.accessToken;
  const tenant_id = userInfo?.tenant_id;

  const header = {
    tenant_id,
    action: "upgradePlan",
    user_id: userInfo.user_id,
  };

  const res = await postData(`${backendBaseUrl}order`, header, data, token);
  return JSON.parse(extractData(res));
}

export async function updateLogo(file, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;

  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo.user_id,
  };

  return await postFormData(
    `${logoUrl}${tenant}/tenant/logo_update`,
    headers,
    file,
    token
  );
}

export async function updateEmailPreferences(body, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const tenant_id = userInfo?.tenant_id;

  const headers = {
    tenant_id,
    user_id: userInfo.user_id,
  };

  return await putData(
    `${backendTenantUrl}${tenant}/tenant/config/email_preference/${tenant_id}`,
    headers,
    body,
    token
  );
}

export async function updateCarrierManagerInfo(body, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const tenant_id = userInfo?.tenant_id;

  const headers = {
    tenant_id,
    user_id: userInfo.user_id,
  };

  return await putData(
    `${backendTenantUrl}${tenant}/tenant/company_config/${tenant_id}`,
    headers,
    body,
    token
  );
}
