import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import typography from "src/theme/typography";
import SearchIcon from "@mui/icons-material/Search";
import {
  addBlockedCustomer,
  getBlockedCustomersList,
  getCustomersByEmail,
} from "src/features/returnConfiguration/blockedCustomersApi";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { unixToDate } from "src/utils/core";
import { getUserNameById } from "src/utils/getUserNameById";

const popUpModalStyle = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "auto", lg: "580px", md: "auto" },
  borderRadius: "20px",
  background: "#FFF",
  overflow: "auto",
  overflow: "auto-y",
  overflowX: "hidden",
  outline: "none",
};

const AddBlockedCustomer = ({
  openAddBlockedCustomerModal,
  handleCloseAddBlockedCustomerModal,
}) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const [searchValue, setSearchValue] = useState("");
  const [currentCustomerList, setCurrentCustomerList] = useState([]);
  const [specificCustomerInfo, setSpecificCustomerInfo] = useState(null);
  const [reasonToBlock, setReasonToBlock] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [blockedBy, setBlockedBy] = useState("");
  const [blockedDate, setBlockedDate] = useState("");

  const handleSearch = async () => {
    const trimmedSearchValue = searchValue?.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!trimmedSearchValue) {
      setErrorMessage("Please provide an email address.");
    } else if (!emailRegex?.test(trimmedSearchValue)) {
      setErrorMessage("Please enter a valid email address.");
    } else {
      try {
        dispatch(incrementAwaitingApiCounter());

        const response = await getCustomersByEmail(userInfo, {
          email_id: trimmedSearchValue,
        });
        if (response.statusCode === 200) {
          setSpecificCustomerInfo(response?.data);
        }

        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        if(error =='The email ID does not exist.')
        {
          setErrorMessage('The Email does not exist.');
        }
        else{
          setErrorMessage(error);
        }
        console.log("inside handlesearch",error);
        
        dispatch(decrementAwaitingApiCounter());
      }
    }
  };

  const handleOnChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
    errorMessage && setErrorMessage("");
  };

  const handleBlockCustomer = async () => {
    dispatch(incrementAwaitingApiCounter());

    const body = {
      ...specificCustomerInfo,
      reason: reasonToBlock,
    };

    try {
      const response = await addBlockedCustomer(userInfo, body);
      if (response.statusCode === 200) {
        const customerList = await getBlockedCustomersList(userInfo, {});
        if (customerList.statusCode === 200) {
          const data = customerList.data.items.filter(
            (el) => el.email_id === specificCustomerInfo.email
          )[0];
          const blockedByName = await getUserNameById(
            userInfo,
            data?.created_by
          );
          setBlockedBy(blockedByName || "");
          setBlockedDate(unixToDate(data?.created_at) || "");
        }

        setSuccess(true);
      }

      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      if(error.message === 'Email ID Already Block List'){
        setErrorMessage("Email is already blocked")
      }
      dispatch(decrementAwaitingApiCounter());
    }
  };

  return (
    <Modal
      open={openAddBlockedCustomerModal}
      onClose={handleCloseAddBlockedCustomerModal}
    >
      <Stack
        direction="column"
        alignItems={"center"}
        gap={2.5}
        sx={{
          ...popUpModalStyle,
          color: "#1C272A",
          backgroundColor: "white",
          fontFamily: typography.fontFamilyRegular,
        }}
      >
        <Typography
          fontSize={24}
          paddingTop={2}
          fontFamily={typography.fontFamilySemiBold}
        >
          {!success ? "Customer Details" : "Blocked Customer details"}
        </Typography>

        <Stack gap={1.2} paddingX={2.5} width={"100%"}>
          {!success && (
            <>
              <Typography fontSize={16}>Customer Email</Typography>
              <OutlinedInput
                size="small"
                value={searchValue}
                placeholder={"Email"}
                sx={{
                  color: "#000",
                  overflow: "hidden",
                }}
                onChange={handleOnChangeSearchValue}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleSearch}
                      sx={{
                        backgroundColor: "secondary.main",
                        color: "#fff",
                        borderRadius: 0,
                        right: "-2px",
                        "&:hover": {
                          backgroundColor: "secondary.main",
                        },
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errorMessage && (
                <Typography paddingLeft={1} fontSize={14} color={"error.main"}>
                  {errorMessage}
                </Typography>
              )}
            </>
          )}
          <Stack>
            {specificCustomerInfo && (
              success ? (
                <Stack paddingX={0.5}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    borderBottom={"1px solid lightgrey"}
                    paddingY={1.5}
                  >
                    <Typography>First Name</Typography>
                    <Typography>{specificCustomerInfo.first_name}</Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    borderBottom={"1px solid lightgrey"}
                    paddingY={1.5}
                  >
                    <Typography>Last Name</Typography>
                    <Typography>{specificCustomerInfo.last_name}</Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    borderBottom={"1px solid lightgrey"}
                    paddingY={1.5}
                  >
                    <Typography>Email</Typography>
                    <Typography>{specificCustomerInfo.email}</Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    borderBottom={"1px solid lightgrey"}
                    paddingY={1.5}
                  >
                    <Typography>Blocked date</Typography>
                    <Typography>{blockedDate}</Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    borderBottom={"1px solid lightgrey"}
                    paddingY={1.5}
                  >
                    <Typography>Blocked by</Typography>
                    <Typography>{blockedBy}</Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    paddingY={1.5}
                  >
                    <Typography>Order Number</Typography>
                    <Typography>{specificCustomerInfo.order_number}</Typography>
                  </Stack>
                  <Stack gap={0.8} paddingTop={0.5}>
                    <Typography>Reason for blocking</Typography>
                    <Typography fontSize={14} paddingLeft={0.5}>
                      {reasonToBlock}
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Stack gap={1.5} paddingTop={1} paddingX={0.5}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography>First Name</Typography>
                    <Typography>{specificCustomerInfo.first_name}</Typography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography>Last Name</Typography>
                    <Typography>{specificCustomerInfo.last_name}</Typography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography>Email</Typography>
                    <Typography>{specificCustomerInfo.email}</Typography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography>Order Number</Typography>
                    <Typography>{specificCustomerInfo.order_number}</Typography>
                  </Stack>
                  <Typography>Reason for blocking</Typography>
                  <TextField
                    multiline
                    rows={3}
                    placeholder="Add a comment"
                    value={reasonToBlock}
                    onChange={(event) => setReasonToBlock(event.target.value)}
                  />
                </Stack>
              )
            ) 
           }
          </Stack>
        </Stack>

        {!success ? (
          <Stack direction={"row"} gap={2} marginTop={1.5} marginBottom={2.5}>
            <LoadingButton
              variant="outlined"
              disableElevation
              sx={{
                width: 150,
                borderRadius: "10px",
                boxShadow: "none",
                paddingY: 1,
                fontSize: {
                  md: 15,
                  xs: 13,
                },
                border: "1px rgba(128, 128, 128, 0.32) solid",
                color: "secondary.main",
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={handleCloseAddBlockedCustomerModal}
            >
              Cancel
            </LoadingButton>
            {specificCustomerInfo && (
              <LoadingButton
                variant="contained"
                disableElevation
                sx={{
                  width: 150,
                  borderRadius: "10px",
                  boxShadow: "none",
                  paddingY: 1,
                  fontSize: {
                    md: 15,
                    xs: 13,
                  },
                  border: "1px rgba(128, 128, 128, 0.32) solid",
                  backgroundColor: "secondary.main",
                  fontFamily: typography.fontFamilySemiBold,
                }}
                onClick={handleBlockCustomer}
              >
                Block Customer
              </LoadingButton>
            )}
          </Stack>
        ) : (
          <LoadingButton
            variant="outlined"
            disableElevation
            sx={{
              width: 150,
              borderRadius: "10px",
              boxShadow: "none",
              paddingY: 1,
              fontSize: {
                md: 15,
                xs: 13,
              },
              border: "1px rgba(128, 128, 128, 0.32) solid",
              color: "secondary.main",
              fontFamily: typography.fontFamilySemiBold,
              marginBottom: 2.5,
            }}
            onClick={handleCloseAddBlockedCustomerModal}
          >
            Close
          </LoadingButton>
        )}
      </Stack>
    </Modal>
  );
};

export default AddBlockedCustomer;
