// authSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  return_flow: null,
  tenant_name: null,
  user_info: null
};

const authSlice = createSlice({
  name: 'customerAuthInfo',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      console.log('action.payload ',action.payload);
      const {user_info, return_flow, tenant_name} = action.payload;
      state.isLoggedIn = true;
      state.user_info = user_info;
      state.return_flow = return_flow;
      state.tenant_name = tenant_name;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user_info = null;
      state.user_info = null;
      state.return_flow = null;
    }
  }
});

export const {loginSuccess, logout} = authSlice.actions;
export default authSlice.reducer;
