import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTooltip } from "./NavItems";

const Item = ({
  item,
  open,
  setOpenDeliveryManagementMenu,
  setOpenReturnsManagementMenu,
  setOpenConfigManagementMenu,
}) => {
  const navigate = useNavigate();
  const handleMenu = (path) => {
    navigate(path);
    setOpenDeliveryManagementMenu(false);
    setOpenReturnsManagementMenu(false);
    setOpenConfigManagementMenu(false);
  };
  return (
    <CustomTooltip title={open ? "" : item.title} placement="right">
      <ListItemButton
        component="li"
        onClick={() => handleMenu(item.path)}
        className={item.path === location.pathname ? "active" : ""}
        sx={{
          marginX: 1,
          borderRadius: "12px",
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 1.5,
          "&.active": {
            color: "text.active",
            bgcolor: "action.selected",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            width: 24,
            height: 24,
            mr: open ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          {item.icon}
        </ListItemIcon>
        {open && <ListItemText primary={item.title} />}
      </ListItemButton>
    </CustomTooltip>
  );
};

export default Item;
