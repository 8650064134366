import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Stack,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { updateLabel } from "src/features/returns/stepperFormSlice";
import { labelSizeOptions, getLabelByValue } from 'src/utils/labelUtils';
import OutlinedInput from "@mui/material/OutlinedInput";
import 'src/pages/returns/style/styles.css'

const LabelConfigStepper = ({ handleNext, handleBack }) => {
  const [labelSize, setLabelSize] = useState("4*5 label");
  const [labelType, setLabelType] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const { labelType: labelTypeStore, labelSize: labelSizeStore } = useSelector(
    (state) => state.form.label
  );

  const labelTypeOptions = ["PDF", "JPG"];

  const handleNextClick = () => {
    const selectedLabel = labelSizeOptions?.find(option => option?.label === labelSize);
    if (labelType.length > 0) {
      setError(false);
      dispatch(updateLabel({ labelSize: selectedLabel?.value, labelType }));
      handleNext();
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (labelTypeStore.length > 0) setLabelType(labelTypeStore);
    if (labelSizeStore.length > 0) setLabelSize(labelSizeStore);
  }, []);

  return (
    <Stack gap={5} padding={5}>
      <Stack gap={1}>
        <Typography variant="h5" textAlign={"center"}>
          Label configuration
        </Typography>
        <Typography variant="body2" textAlign={"center"} color={"#AAAAAA"}>
          Pick the dimensions and format required for label generation.
        </Typography>
      </Stack>
      <Stack alignItems={"center"} gap={2} sx={{flexDirection:'row',justifyContent:'center'}}>
          <FormControl  sx={{ mb: 2 }}>
            <InputLabel>Label size</InputLabel>
            <Select
              label="Label size"
              value={labelSize}
              onChange={(e) => setLabelSize(e.target.value)}
            input={<OutlinedInput label="Label size" />}
            className="textBoxsize"
            >
              {labelSizeOptions.map((option) => (
                <MenuItem key={option?.label} value={option?.label}>
                  <ListItemText primary={option?.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl  error={error} sx={{ mb: 2}}>
            <InputLabel id="label-type-label">Select label type</InputLabel>
            <Select
              labelId="label-type-label"
              label="Select label type"
              id="label-type-select"
              value={labelType}
              onChange={(e) => {
                setLabelType(e.target.value);
                setError(false);
              }}
            className="textBoxsize"
            >
              {labelTypeOptions.map((labelTypeOption) => (
                <MenuItem key={labelTypeOption} value={labelTypeOption}>
                  <ListItemText primary={labelTypeOption} />
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText error>
                Please select at least one label type
              </FormHelperText>
            )}
          </FormControl>

          {/* <TextField
            fullWidth
            label="Select label type"
            name="Select label type"
            value={labelType}
            onChange={(e) => {
              setLabelType(e.target.value);
              setError(false);
            }}
            error={error && labelType.length === 0} // Show error if label type is empty
            helperText={
              error && labelType.length === 0
                ? "Please select at least one label type"
                : ""
            }
            // displayEmpty
            select
          >
            {labelTypeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField> */}
        {/* </Grid> */}
      {/* </Grid> */}
</Stack>
      <Stack gap={1} direction={"row"} justifyContent={"flex-end"}>
        <ArrowCircleLeftIcon
          onClick={handleBack}
          sx={{
            color: "#002F43",
            cursor: "pointer",
            width: "50px !important",
            height: "50px !important",
            transition: "transform 0.3s ease-in-out",
            "@keyframes pulse": {
              "0%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.1)" },
              "100%": { transform: "scale(1)" },
            },
          }}
        />
        <ArrowCircleRightIcon
          onClick={handleNextClick}
          sx={{
            color: error || labelType?.length == 0 ? "#CFDEE9" : "#002F43",
            cursor: "pointer",
            width: "50px !important",
            height: "50px !important",
            transition: "transform 0.3s ease-in-out",
            "@keyframes pulse": {
              "0%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.1)" },
              "100%": { transform: "scale(1)" },
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default LabelConfigStepper;
