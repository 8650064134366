import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { postEnableDisableEmailTemplate } from "src/features/returnConfiguration/emailTemplatesApi";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import typography from "src/theme/typography";

const EmailTemplateItem = ({
  data,
  setOpenEditTemplateUI,
  setTemplateNameSelected,
  setOpenPreview,
  lastUpdatedInfo,
  emailTemplateList,
  hideCheckbox,
  showBtns,
  hideDivider,
}) => {
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const isInitialMount = useRef(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const postEnableDisableTemplate = async (isEnabled) => {
    const body = {
      template_name: data.templateName,
      is_active: isEnabled,
    };

    try {
      dispatch(incrementAwaitingApiCounter());

      const response = await postEnableDisableEmailTemplate(body, userInfo);
      if (response.statusCode === 200) {
        setIsCheckboxChecked(isEnabled);
      }
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      console.log("error", error);
      dispatch(decrementAwaitingApiCounter());
    }
  };

  const handleCheckboxClicked = (event) => {
    if (data.templateName === "otp_triggered") return;
    const isChecked = event.target.checked;
    if (isCheckboxChecked !== isChecked) {
      postEnableDisableTemplate(isChecked);
    }
  };

  const handleEditTemplateBtn = () => {
    setOpenEditTemplateUI(data.templateName);
  };

  const handlePreviewBtn = () => {
    setTemplateNameSelected(data.templateName);
    setOpenPreview(true);
  };

  useEffect(() => {
    if (emailTemplateList.length === 0 || hideCheckbox) return;

    if (data.templateName === "otp_triggered") {
      setIsCheckboxChecked(true);
    } else {
      const template = emailTemplateList.find(
        (el) => el.template_name === data.templateName
      );
      setIsCheckboxChecked(template?.is_active || false);
    }
  }, [emailTemplateList, data?.templateName]);

  return (
    <>
      <Grid container paddingY={3}>
        <Grid
          item
          xs={12}
          lg={8}
          display={"flex"}
          alignItems={"center"}
          gap={2}
        >
          {hideCheckbox ? null : data.title !== "OTP Mail" ? (
            <Checkbox
              checked={isCheckboxChecked}
              onChange={handleCheckboxClicked}
            />
          ) : (
            <Box />
          )}
          <Stack gap={0.7}>
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Typography
                sx={{
                  color: "common.black",
                  fontFamily: typography.fontFamilySemiBold,
                  fontSize: { xs: 18, md: 20 },
                }}
              >
                {data?.title}
              </Typography>
              <Typography
                sx={{
                  color: "#256FA5",
                  fontSize: 12,
                }}
              >
                {lastUpdatedInfo !== "No updated yet"
                  ? `Published by ${lastUpdatedInfo}`
                  : ``}
              </Typography>
            </Stack>
            <Typography
              sx={{
                color: "#AAAAAA",
                fontSize: { xs: 14, md: 16 },
              }}
            >
              {data?.subtitle}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={1}
        >
          {(showBtns || isCheckboxChecked) && (
            <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
              <LoadingButton
                variant="contained"
                onClick={handleEditTemplateBtn}
                sx={{
                  width: { lg: 140, sm: 120, xs: 120, md: 140 },
                  height: { xs: 36, sm: 36, md: 36, lg: 40 },
                  backgroundColor: "secondary.main",
                  boxShadow: "none",
                }}
              >
                {data?.button1Text}
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                onClick={handlePreviewBtn}
                sx={{
                  width: { lg: 140, sm: 120, xs: 120, md: 140 },
                  height: { xs: 36, sm: 36, md: 36, lg: 40 },
                  border: "1px rgba(128, 128, 128, 0.32) solid",
                  color: "secondary.main",
                  border: "1.5px solid #002F43",
                }}
              >
                {data?.button2Text}
              </LoadingButton>
            </Stack>
          )}
        </Grid>
      </Grid>
      {!hideDivider && <Divider />}
    </>
  );
};

export default EmailTemplateItem;
