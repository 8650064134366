const appBaseUrls = {
  outboundManagement: "/delivery/",
  inboundManagement: "/return-refund/",
  configurationManagement: "/configurations/",
};

const appUrls = {
  outboundManagement: {
    baseUrl: "/delivery/",
    dashboard: {
      relativeUrl: "dashboard/",
      absoluteUrl: appBaseUrls.outboundManagement + "dashboard/",
    },
    tracking: {
      relativeUrl: "tracking/",
      absoluteUrl: appBaseUrls.outboundManagement + "tracking/",
    },
    carrierOriginScan: {
      relativeUrl: "carrier-origin-scan/",
      absoluteUrl: appBaseUrls.outboundManagement + "carrier-origin-scan/",
    },
    reports: {
      baseUrl: "reports/",
      preShipment: {
        relativeUrl: "preshipment/",
        absoluteUrl: appBaseUrls.outboundManagement + "reports/preshipment/",
      },
      inTransit: {
        relativeUrl: "intransit/",
        absoluteUrl: appBaseUrls.outboundManagement + "reports/intransit/",
      },
      averageTimeInTransit: {
        relativeUrl: "average-time-in-transit/",
        absoluteUrl:
          appBaseUrls.outboundManagement + "reports/average-time-in-transit/",
      },
    },
  },
  inboundManagement: {
    baseUrl: "/return-refund/",
    manageReturns: {
      relativeUrl: "manage-returns/",
      absoluteUrl: appBaseUrls.inboundManagement + "manage-returns/",
    },
    brandedReturnPortal: {
      relativeUrl: "branded-tracking-portal/",
      absoluteUrl: appBaseUrls.inboundManagement + "branded-tracking-portal/",
    },
    brandedReturnsPortal: {
      relativeUrl: "branded-returns-portal/",
      absoluteUrl: appBaseUrls.inboundManagement + "branded-returns-portal/",
    },
    returnConfiguration: {
      relativeUrl: "return-configuration/",
      absoluteUrl: appBaseUrls.inboundManagement + "return-configuration/",
    },
    returnDetails: {
      relativeUrl: "return-details/",
      absoluteUrl: appBaseUrls.inboundManagement + "return-details/",
    },
  },
  configurationManagement: {
    baseUrl: "/configurations/",
    deliveryTrackingConfiguration: {
      relativeUrl: "delivery-tracking/",
      absoluteUrl: appBaseUrls.configurationManagement + "delivery-tracking/",
    },
    returnConfiguration: {
      relativeUrl: "return-configuration/",
      absoluteUrl:
        appBaseUrls.configurationManagement + "return-configuration/",
    },
  },
  billingAndInvoices: "/billing-and-invoices/",
  users: "/users/",
  notifications: "/notifications/",
  onboarding: "/onboarding",
  login: "/login/",

  resetPasswordDone: "/reset-password/done",
};

export default appUrls;
