import { combineReducers } from "redux";
import userReducer from "src/features/user/userState";
import usersReducer from "src/features/usersPage/usersState";
import plansReducer from "src/features/plans/plansState";
import uiReducer from "src/features/ui/uiState";
import navbarReducer from "src/features/navbar/navbarState";
import trackingReducer from "../features/tracking/trakingState";
import scanDetail from "../features/scan/scanState";
import columnPreferenceState from "../features/tableActions/userColPreferanceState";
import configReducer from "src/features/config/configState";
import { signinReducer } from "src/features/signin/signin.reducer";
import brandedPortalSlice from "src/features/brandedPortal/brandedPortal";
import shopifyImageSlice from "src/features/returns/shopifyImages";
import customerAuthReducer from "src/features/return_management/customerAuthReducer";
import customerBrandPortal from "src/features/return_management/order/customerBrandPortalState";
import stepperFormReducer from "src/features/returns/stepperFormSlice";
import returnMethodReducer from "src/features/returns/returnMethodSlice";
// import configDetailReducer from '../features/config/configState';

export default combineReducers({
  // user: userDetailsReducer,
  ui: uiReducer,
  navbar: navbarReducer,
  user: userReducer,
  users: usersReducer,
  plans: plansReducer,
  trackInfo: trackingReducer,
  scan: scanDetail,
  userColumns: columnPreferenceState,
  config: configReducer,
  signin: signinReducer,
  brandedPortal: brandedPortalSlice,
  shopifyImages: shopifyImageSlice,
  customerAuthInfo:customerAuthReducer,
  customerBrandPortal:customerBrandPortal,
  // configDetails: configDetailReducer,
  form: stepperFormReducer,
  returnMethod: returnMethodReducer,
});
