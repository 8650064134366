import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ChatIcon from "@mui/icons-material/Chat";
import palette from "src/theme/palette";
import {
  AddNewComment,
  DeleteComment,
  GetTimelineHistory,
  UpdateComment,
} from "./ReturnsApi";
import {
  unixToDate,
  formatUnixToDate,
  formatDate,
  unixToTime,
} from "src/utils/core";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";

const systemMessages = {
  Initiated: "Return initiated",
  Accepted: "Return accepted by",
  Cancelled: "Return cancelled by",
  Rejected: "Return rejected by",
  Refunded: "Return refunded by",
};

const TimelineHistory = ({ selectedID, usersList }) => {
  const userInfo = useAppSelector(getUserInfo);
  const [timelineHistory, setTimelineHistory] = useState([]);
  const [comment, setComment] = useState("");
  const [editCommentId, setEditCommentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchTimelineHistory = async () => {
    try {
      setIsLoading(true);
      const res = await GetTimelineHistory(selectedID, userInfo);

      res.statusCode === 200 && setTimelineHistory(res.data);
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTimelineHistory();
  }, [selectedID]);

  const handleAddOrUpdateComment = async () => {
    if (!comment) return;

    setIsLoading(true);
    const apiCall = editCommentId ? UpdateComment : AddNewComment;
    const payloadData = {
      tenant_id: userInfo.tenant_id,
      return_list_id: selectedID,
      comment,
    };
    try {
      const res = await apiCall(editCommentId, payloadData);
      if (res.statusCode === 200) {
        setComment("");
        setEditCommentId(null);
        await fetchTimelineHistory();
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditComment = (commentId, commentText) => {
    setEditCommentId(commentId);
    setComment(commentText);
  };

  const handleDeleteComment = async (commentId) => {
    setIsLoading(true);
    try {
      const res = await DeleteComment(commentId, userInfo);
      if (res.statusCode === 200) {
        await fetchTimelineHistory();
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const groupByDate = (history) => {
    const grouped = history.reduce((acc, item) => {
      const date = unixToDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    const sortedDates = Object.keys(grouped).sort(
      (a, b) => new Date(b) - new Date(a)
    );
    return sortedDates.reduce((acc, date) => {
      acc[date] = grouped[date].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      return acc;
    }, {});
  };

  const getDateLabel = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedDate = new Date(date);

    if (formattedDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (formattedDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return date;
    }
  };

  const groupedTimelineHistory = groupByDate(timelineHistory);

  return (
    <Box
      sx={{
        borderRadius: "12px",
        mt: 3,
        overflow: "hidden",
        backgroundColor: "white",
        boxShadow: "0px 2px 8px -4px lightgrey",
      }}
    >
      <Typography
        sx={{
          color: "#000",
          mb: 2,
          padding: "10px 20px",
          fontSize: "17px",
          backgroundColor: "#CFDEE9",
        }}
      >
        Timeline History
      </Typography>
      <Box sx={{ padding: "0 20px 0" }}>
        <Box sx={{ position: "relative" }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={2}
            placeholder="Add a Comment"
          />
          <span
            onClick={handleAddOrUpdateComment}
            style={{
              position: "absolute",
              bottom: "8px",
              color: "#000",
              right: "12px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {editCommentId ? "Update Comment" : "Post Comment"}
          </span>
        </Box>
        <Typography
          sx={{
            color: "#AAAAAA",
            fontSize: "10px",
            textAlign: "right",
            borderLeft: "2px solid #D9D9D9",
            pt: 1,
            ml: "20px",
          }}
        >
          Only you and other staff can see comments
        </Typography>
        {isLoading ? (
          <Typography>Loading comments...</Typography>
        ) : (
          <Box sx={{ paddingLeft: "20px" }}>
            {Object.keys(groupedTimelineHistory).map((date, dateIndex) => (
              <Box key={dateIndex}>
                <Typography
                  sx={{
                    color: "#AAAAAA",
                    fontSize: "14px",
                    paddingLeft: "10px",
                    borderLeft: "2px solid #D9D9D9",
                    pb: 1,
                  }}
                >
                  {getDateLabel(date)}
                </Typography>
                {groupedTimelineHistory[date].map((timeline, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      borderLeft: "2px solid #D9D9D9",
                      alignItems: "center",
                      pb: 1.5,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        position: "relative",
                      }}
                    >
                      {timeline.type === "system" ? (
                        <CircleIcon
                          sx={{
                            position: "absolute",
                            left: "-7px",
                            fontSize: "14px",
                          }}
                        />
                      ) : (
                        <ChatIcon
                          sx={{
                            position: "absolute",
                            left: "-7px",
                            fontSize: "14px",
                          }}
                        />
                      )}
                      {timeline.type === "system" ? (
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            paddingLeft: "10px",
                          }}
                        >
                          {systemMessages[timeline.status]}{" "}
                          {usersList[timeline.created_by]
                            ? ` - ${usersList[timeline.created_by]}`
                            : " - system comment"}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            paddingLeft: "10px",
                          }}
                        >
                          {timeline.comment}{" "}
                          {usersList[timeline.created_by]
                            ? ` - ${usersList[timeline.created_by]}`
                            : `- ${userInfo?.company_name}`}
                        </Typography>
                      )}
                    </Box>

                    {timeline.type !== "system" &&
                    timeline.tenant_id === userInfo.tenant_id ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
                          {unixToTime(timeline.created_at)}
                        </Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <EditNoteIcon
                            onClick={() =>
                              handleEditComment(timeline._id, timeline.comment)
                            }
                            fontSize="small"
                            sx={{
                              color: palette.primary.main,
                              cursor: "pointer",
                            }}
                          />
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteComment(timeline._id)}
                            fontSize="small"
                            sx={{
                              color: palette.error.main,
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
                        {unixToTime(timeline.created_at)} PST
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TimelineHistory;
