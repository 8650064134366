const { Typography, Box, Stack } = require("@mui/material");
import { useEffect, useState } from "react";
import palette from "src/theme/palette";
import { useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import { useDispatch } from "react-redux";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import SelectableItem from "./SelectItem";
import {
  getReturnRules,
  updateReturnRules,
} from "src/features/returnConfiguration/returnRulesApi";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { getUserNameById } from "src/utils/getUserNameById";

const ReturnRules = () => {
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useDispatch();

  const [returnWindowDays, setReturnWindowDays] = useState(0);
  const [productExclusionsList, setProductExclusionsList] = useState([]);
  const [returnReasonList, setReturnReasonList] = useState([]);

  const [isReturnWindowActive, setIsReturnWindowActive] = useState(false);
  const [isProductExclusionsActive, setIsProductExclusionsActive] =
    useState(false);
  const [isReturnReasonActive, setIsReturnReasonActive] = useState(true);

  const [rulesList, setRulesList] = useState([]);
  const [lastUpdatedInfo, setLastUpdatedInfo] = useState("No updated yet");
  const [hasBeenSaved, setHasBeenSaved] = useState(false);

  const [productListLength, setProductListLength] = useState(false);

  const handleSaveReturnRules = async () => {
    dispatch(incrementAwaitingApiCounter());

    const body = {
      rule_name: "return_rule",
      return_window: returnWindowDays,
      is_active_return_window: isReturnWindowActive,
      product_exclusions: productExclusionsList,
      is_active_product_exclusions: isProductExclusionsActive,
      return_reason: returnReasonList,
      is_active_return_reason: true,
    };

    try {
      const response = await updateReturnRules(userInfo, body);
      if (response.statusCode === 200) setHasBeenSaved(false);
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
    }
  };

  useEffect(() => {
    const populateData = async () => {
      dispatch(incrementAwaitingApiCounter());

      try {
        const response = await getReturnRules(userInfo);

        if (response.statusCode === 200) {
          const rules = response.data;

          if (Object.keys(rules).length === 0) {
            setLastUpdatedInfo("No updated yet");
            dispatch(decrementAwaitingApiCounter());
            return;
          }
          setRulesList(rules);
          const returnWindowData = rules.return_window;
          returnWindowData && setReturnWindowDays(returnWindowData);
          setIsReturnWindowActive(rules.is_active_return_window);

          const productExclusionsData = rules.product_exclusions;
          productExclusionsData &&
            setProductExclusionsList(productExclusionsData);

          setIsProductExclusionsActive(rules.is_active_product_exclusions);
          setProductListLength(rules.product_exclusions.length);

          const returnReasonData = rules.return_reason;
          returnReasonData && setReturnReasonList(returnReasonData);
          setIsReturnReasonActive(true);

          const updatedAt = rules.updated_at || rules.created_at;
          const updatedById = rules.updated_by || rules.created_by;

          const updatedByName = await getUserNameById(userInfo, updatedById);

          setLastUpdatedInfo(
            updatedByName +
              " on " +
              moment
                .unix(updatedAt)
                .tz("America/Los_Angeles")
                .format("MMM DD, YYYY")
          );
        }

        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        dispatch(decrementAwaitingApiCounter());
      }
    };

    !hasBeenSaved && populateData();
  }, [hasBeenSaved]);

  const selectableItemData = [
    {
      title: "Returns Window",
      subtitle:
        "This states the time period within which customers can place their return orders.",
      selectItems: {
        label: "Add days",
        options: ["10", "30", "50", "70", "90"],
      },
    },
    {
      title: "Return Reason",
      subtitle:
        "Specify the valid reasons customers can choose to place a return order for the package.",
      returnReason: {
        label: "Add return reasons",
      },
    },
    {
      title: "Product Exclusions",
      subtitle:
        "Choose items that are not eligible for return. Excluded items will not be processed in return orders.",
      productExclusions: true,
    },
  ];

  const handleRemoveProduct = (elementToRemove) => {
    setProductExclusionsList((prev) =>
      prev.filter((el) => el.product_id !== elementToRemove.product_id)
    );
    setHasBeenSaved(true);
  };

  return (
    <Stack
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Stack paddingBottom={"76px"}>
        <Typography textAlign={"right"} paddingTop={2} fontSize={14}>
          {lastUpdatedInfo !== "No updated yet"
            ? `Last modified by ${lastUpdatedInfo}`
            : ``}
        </Typography>
        {selectableItemData.map((el) => (
          <SelectableItem
            key={el.title}
            data={el}
            handleRemoveProduct={handleRemoveProduct}
            productListLength={productListLength}
            returnReasonList={returnReasonList}
            setReturnReasonList={setReturnReasonList}
            productExclusionsList={productExclusionsList}
            setProductExclusionsList={setProductExclusionsList}
            returnWindowDays={returnWindowDays}
            setReturnWindowDays={setReturnWindowDays}
            setHasBeenSaved={setHasBeenSaved}
            isActive={
              el.title === "Returns Window"
                ? isReturnWindowActive
                : el.title === "Return Reason"
                ? true
                : isProductExclusionsActive
            }
            setIsActive={
              el.title === "Returns Window"
                ? setIsReturnWindowActive
                : el.title === "Return Reason"
                ? setIsReturnReasonActive
                : setIsProductExclusionsActive
            }
          />
        ))}
      </Stack>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "20px",
          boxShadow: "0px -8px 30px 0px #00000014",
          backgroundColor: "white",
          bottom: "-20px",
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          zIndex: "1",
        }}
      >
        <LoadingButton
          variant="contained"
          sx={{ width: "130px", backgroundColor: palette.secondary.main }}
          onClick={handleSaveReturnRules}
          disabled={!hasBeenSaved}
        >
          Save
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default ReturnRules;
