import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import {
  TextField,
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
  Switch,
} from "@mui/material";

import typography from "src/theme/typography";
import palette from "src/theme/palette";
import ReturnsHome from "./portal/guestFlow/ReturnsHome";


const styleInput = {
  input: { color: palette.common.black },
  width: "100%",
  marginTop: "10px",

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.light",
  },
  "& .MuiInputLabel-formControl": {
    color: "#00000099",
  },
  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
    color: "#00000099",
  },
  "& .MuiFormHelperText-root": {
    textAlign: "left",
    fontSize: "14px",
  },
};

const btnStyle = {
  width: { xs: 218, lg: 265 },
  height: 48,
  boxShadow: "none",
  fontSize: 16,
  fontFamily: typography.fontFamilySemiBold,
};

const textStyle = {
  color: palette.common.black,
  fontSize: { xs: 16, md: 18 },
};

export default function CreateReturnDummy() {


  return (
    <Box>
      <Helmet>
        <title> Configuration | BeyondCarts </title>
      </Helmet>
          <ReturnsHome isHeader={false}/>
    </Box>
  );
}
