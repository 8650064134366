const {
  Grid,
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Button,
  styled,
} = require("@mui/material");
const { useState, useEffect } = require("react");
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { MuiTelInput } from "mui-tel-input";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { headerUpload } from "src/features/brandedPortal/brandedPortal";
import { handlePhoneChange } from "src/utils/formatPhone";

const BrandHeader = ({data, errors, setErrors, validateColor}) => {
  const { header_text, text_color, background_color, email_id, phone_no } = data.brandedHeaderState;
  const [headText, setHeaderText] = useState(header_text);
  const [txtColor, setTextColor] = useState(text_color || '');
  const [textColorPicker, setTextColorPicker] = useState(false);
  const [bgColor, setBgColor] = useState(background_color || '');
  const [bgColorPicker, setBgColorPicker] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(phone_no);
  const [email, setEmail] = useState(email_id);

  const dispatch = useDispatch();

  const handleTextColor = () => {
    setTextColorPicker(!textColorPicker);
    setErrors({...errors, textcolor: null})
  };

  const handleTextColorChange = (color) => {
    setTextColor(color.hex);
  };

  const handleBgColor = () => {
    setBgColorPicker(!bgColorPicker);
  };

  const handleBgColorChange = (color) => {
    setBgColor(color.hex);
    setErrors({...errors, backgroundcolor: null})
  };

  const previewData = {
    header_text: headText,
    text_color: txtColor,
    background_color: bgColor,
    email_id: email,
    phone_no: phoneNumber,
  }
  useEffect(()=>{
    dispatch(
      headerUpload(previewData)
    );
  },[headText, txtColor, bgColor, email, phoneNumber])
  
  const handleColorChange = (e, name, state) => {
    const { value } = e.target;
    if (value.length <= 7 && (value === '' || /^#[a-zA-Z0-9]*$/.test(value))) {
      state(value);
      validateColor(name, value);
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (value === '') {
      setErrors(prev => ({ ...prev, email: null }));
    } else if (emailPattern.test(value)) {
      setErrors(prev => ({ ...prev, email: null }));
    } else {
      setErrors(prev => ({ ...prev, email: 'Invalid email format' }));
    }
  };

  const handleNumberError = (number) => {
    const contact = number.split(" ").slice(1);
    const combinedLength = contact.join("").length;
  
    if (combinedLength === 0) {
      setErrors(prev => ({ ...prev, phoneNumber: null }));
    } else if (combinedLength < 10) {
      setErrors(prev => ({ ...prev, phoneNumber: 'Phone number must be at least 10 digits' }));
    } else {
      setErrors(prev => ({ ...prev, phoneNumber: null }));
    }
  };

  return (
    <>
      <Grid container spacing={3} sx={{ pb: 3, borderBottom: '2px solid #E9E9E9' }}>
        <Grid item xs={3}>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}>Heading</Typography>
          <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Customize the heading for your tracking page.</Typography>
        </Grid>
        <Grid item xs={9} sx={{ margin: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <OutlinedInput
              size='small'
              value={headText}
              sx={{ color: "#000", width: '300px' }}
              placeholder="Header Text"
              onChange={(e) => setHeaderText(e.target.value)}
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{ "aria-label": "weight" }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ py: 3, borderBottom: '2px solid #E9E9E9' }}>
        <Grid item xs={3}>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}>Color theme</Typography>
          <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Customize the header background and text colors to match your style.</Typography>
        </Grid>
        <Grid item xs={9} sx={{ margin: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box>
              <Typography sx={{ color: "#000" }}>
                Background color
              </Typography>
              <Box sx={{ position: "relative" }}>
                <OutlinedInput
                  size='small'
                  value={bgColor}
                  onClick={handleBgColor}
                  placeholder="#000000"
                  onChange={(e) => handleColorChange(e, 'backgroundcolor', setBgColor)}
                  sx={{ my: 1, width: "100%", color: "#000" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <Box sx={{ width: '20px', height: '20px', borderRadius: '5px', backgroundColor: `${bgColor ? bgColor : 'black'}` }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors?.backgroundcolor}
                />
                {errors?.backgroundcolor && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-15px'}}>{errors?.backgroundcolor}</Typography>}
              </Box>
              {bgColorPicker && (
                <Box style={{ position: "absolute", zIndex: 2 }}>
                  <Box
                    style={{
                      position: "fixed",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                    onClick={() => setBgColorPicker(false)}
                  />
                  <ChromePicker color={bgColor} onChange={handleBgColorChange} />
                </Box>
              )}
            </Box>
            <Box>
              <Typography sx={{ color: "#000" }}>
                Text color
              </Typography>
              <Box sx={{ position: "relative" }}>
                <OutlinedInput
                  size='small'
                  value={txtColor}
                  onClick={handleTextColor}
                  placeholder="#000000"
                  onChange={(e) => handleColorChange(e, 'textcolor', setTextColor)}
                  sx={{ my: 1, width: "100%", color: "#000" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <Box sx={{ width: '20px', height: '20px', borderRadius: '5px', backgroundColor: `${txtColor ? txtColor : 'black'}` }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors?.textcolor}
                />
                {errors?.textcolor && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-15px'}}>{errors?.textcolor}</Typography>}
              </Box>
              {textColorPicker && (
                <Box style={{ position: "absolute", zIndex: 2 }}>
                  <Box
                    style={{
                      position: "fixed",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                    onClick={() => setTextColorPicker(false)}
                  />
                  <ChromePicker color={txtColor} onChange={handleTextColorChange} />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ py: 3 }}>
        <Grid item xs={3}>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}>Customer support</Typography>
          <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Provide your customer support email and phone number for seamless assistance.</Typography>
        </Grid>
        <Grid item xs={9} sx={{ margin: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap:3 }}>
            <Box sx={{ position: "relative" }}>
            <Typography sx={{mb:1}}>Email</Typography>
            <OutlinedInput
              size='small'
              value={email}
              onChange={handleEmailChange}
              sx={{ color: "#000", width: '300px' }}
              placeholder="customersupport@merchant.com"
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{ "aria-label": "weight" }}
              error={errors?.email}
            />
                {errors?.email && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-25px' }}>{errors?.email}</Typography>}
            </Box>
            <Box sx={{position: 'relative'}}>
            <Typography sx={{ mb: 1 }}>Phone</Typography>
            <MuiTelInput
            size="small"
            defaultCountry="US"
            forceCallingCode={true}
            value={phoneNumber}
            placeholder="(xxx) xxx-xxxx"
            onChange={(newValue) => [handlePhoneChange(newValue, setPhoneNumber), handleNumberError(newValue)]}
            />
            {errors?.phoneNumber && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-25px' }}>{errors?.phoneNumber}</Typography>}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BrandHeader;
