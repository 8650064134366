import axios from "axios";
import { deleteToken } from "src/utils/extractUserToken";

export default async function postFormData(url, headers, file, token) {
  const randomInt = Math.floor(1e7 + Math.random() * 9e7);
  const config = {
    headers: {
      ...headers,
      "Content-Type": "multipart/form-data",
      Apikey: process.env.REACT_APP_BACKEND_API_KEY,
      request_id: randomInt,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const formData = new FormData();
  formData.append("file", file);

  let result = {};

  try {
    const response = await axios.post(url, formData, config);
    result = response?.data;
  } catch (e) {
    if ([401, 403].includes(error.response?.status)) {
      deleteToken();
      return;
    }
    throw new Error(
      e?.response?.data?.statusMessage || "Something went wrong! "
    );
  }
  return result;
}
