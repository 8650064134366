import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Button, Typography, Stack, Box, Grid } from "@mui/material";
import DateRangePicker from "src/components/date-picker/DateRangePicker";
import Iconify from "src/components/iconify/Iconify";
import TransitDataCard from "src/components/data-card/TransitDataCard";
import TransitTotalTableDataCard from "src/components/data-card/TransitTotalTableDataCard";
import AverageDeliveryChart from "src/components/chart-bar/AverageDeliveryChart";
import SelectInput from "src/components/select/SelectInput";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { cleanObject } from "src/utils/core";
import { CSVLink } from "react-csv";
import {
  incrementAwaitingApiCounter,
  decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { getAverageTrackingList } from "src/features/tracking/trackingApi";
import { getUserInfo } from "src/features/user/userState";
import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";

export default function AverageTimeTracker() {
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(40, "day").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));
  const [selectedCarrier, setSelectedCarrier] = useState("All Carriers");
  const [selectedClass, setSelectedClass] = useState("All Providers");
  const [showLoader, setShowLoader] = useState(false);
  const [dayTransit, setDayTransit] = useState({
    TimeInTransit: 0,
    PlaceCount: 0,
    Percentage: 0,
    Running: 0,
  });
  const extractData = (result) => {
    if (encConfig.encryption.decrypt) {
      if (result?.data) return decryptData(result?.data);
    }
    return result;
  };
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();

  const filename = generateFileName();

  const carrierSelect = [
    {
      label: "All Carriers",
      value: "All Carriers",
    },
    {
      label: "UPS",
      value: "UPS",
    },
    {
      label: "USPS",
      value: "USPS",
    },
    {
      label: "FEDEX",
      value: "FEDEX",
    },
    {
      label: "DHL",
      value: "DHL",
    },
  ];

  const ClassSelect = [
    {
      label: "All Providers",
      value: "All Providers",
    },
  ];

  if (selectedCarrier === "All Carriers") {
    ClassSelect.push(
      {
        label: "UPS Mail Innovations® Expedited",
        value: "UPS Mail Innovations® Expedited",
      },
      {
        label: "Priority Mail",
        value: "Priority Mail",
      },
      {
        label: "USPS Ground Advantage",
        value: "USPS Ground Advantage",
      }
    );
  } else if (selectedCarrier === "UPS") {
    ClassSelect.push({
      label: "UPS Mail Innovations® Expedited",
      value: "UPS Mail Innovations® Expedited",
    });
  } else if (selectedCarrier === "USPS") {
    ClassSelect.push(
      {
        label: "Priority Mail",
        value: "Priority Mail",
      },
      {
        label: "USPS Ground Advantage",
        value: "USPS Ground Advantage",
      }
    );
  }

  const mainIcon = (name) => `/assets/icons/transitIcons/${name}.svg`;

  const formatPieceCount = (pieceCount) => {
    return pieceCount === 0 || pieceCount === "" || pieceCount === undefined
      ? "-"
      : pieceCount;
  };

  const formatPercentage = (percentage) => {
    return percentage !== "" && percentage !== undefined
      ? `${percentage}%`
      : "-";
  };

  const formatRunningPercentage = (runningPercentage) => {
    return runningPercentage !== "" && runningPercentage !== undefined
      ? `${runningPercentage}%`
      : "-";
  };

  const headers = [
    { label: "Time in Transit" },
    { label: "Piece Count", key: "Piece Count" },
    { label: "Percentage", key: "Percentage" },
    { label: "Running %", key: "Running Percentage" },
  ];

  const csvData = [
    {
      "Time in Transit": "0d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with0day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with0day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with0day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "1d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with1day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with1day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with1day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "2d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with2day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with2day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with2day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "3d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with3day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with3day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with3day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "4d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with4day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with4day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with4day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "5d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with5day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with5day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with5day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "6d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with6day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with6day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with6day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "7d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with7day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with7day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with7day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "8d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with8day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with8day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with8day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "9d",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with9day?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with9day?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with9day?.runningPercentage
      ),
    },
    {
      "Time in Transit": "10d+",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.with10Plusday?.piece
      ),
      Percentage: formatPercentage(
        reportData?.data?.reportData?.with10Plusday?.percentage
      ),
      "Running Percentage": formatRunningPercentage(
        reportData?.data?.reportData?.with10Plusday?.runningPercentage
      ),
    },
    {
      "Time in Transit": "Sum",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.totalDeliveredOrders
      ),
    },
    {
      "Time in Transit": "Min (days)",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.minDayOfDelivery
      ),
    },
    {
      "Time in Transit": "Max (days)",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.maxDayOfDelivery
      ),
    },
    {
      "Time in Transit": "Avg Day Of Delivery",
      "Shipment Count": formatPieceCount(
        reportData?.data?.reportData?.avgDayOfDelivery
      ),
    },
  ];
  const TransitCards = [
    {
      icon: mainIcon("TimeInTransit"),
      title: "Time in Transit",
      tooltip:
        "Displays the duration a shipment has been in transit from origin to its destination, measured in number of days.",
      total:
        dayTransit?.TimeInTransit +
          (dayTransit?.TimeInTransit === 1 ? " Day" : " Days") || 0,
      status: "Time in Transit",
    },
    {
      title: "Shipment Count",
      total: dayTransit?.PlaceCount || "0",
      tooltip:
        "Shows the total number of shipments currently delivered within the system.",
      icon: mainIcon("PlaceCount"),
      status: "Shipment Count",
    },
    {
      title: "Percentage",
      total: dayTransit?.Percentage ? dayTransit?.Percentage + "%" : "0%",
      tooltip:
        "Represents the proportion of shipments that are delivered relative to the total number of shipments, expressed as a percentage.",
      icon: mainIcon("Percentage"),
      status: "Percentage",
    },
    {
      title: "Running %",
      total: dayTransit?.Running ? dayTransit?.Running + "%" : "0%",
      tooltip:
        "Shows the ongoing percentage of shipments that have been delivered, calculated continuously as each delivery is confirmed in relation to the total number of shipments.",
      icon: mainIcon("Running"),
      status: "Running %",
    },
  ];

  const TransitCardTotal = [
    {
      PlaceCount: reportData?.data?.reportData?.totalDeliveredOrders ?? 0,
      Average: reportData?.data?.reportData?.avgDayOfDelivery ?? 0,
      Min: reportData?.data?.reportData?.minDayOfDelivery ?? 0,
      Max: reportData?.data?.reportData?.maxDayOfDelivery ?? 0,
    },
  ];

  const getTrackingNumberList = (params, appendRow = false) => {
    dispatch(incrementAwaitingApiCounter());
    setShowLoader(true);
    const res = getAverageTrackingList(cleanObject(params), userInfo);

    res
      .then((result) => {
        setReportData(result);
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      })
      .catch((error) => {
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      });
  };

  const getCurrentPageParams = () => {
    let queryParams = {
      status: "Delivered",
      averageTimeReport: true,
    };

    // TODO: uncomment this to send the date query param
    if (startDate != false && endDate != false) {
      queryParams["date_range"] = `${startDate}-${endDate}`;
    } else {
      if (queryParams.hasOwnProperty("date_range")) {
        delete queryParams["date_range"];
      }
    }

    if (selectedCarrier != false && selectedCarrier != "All Carriers") {
      queryParams["carrier"] = `${selectedCarrier}`;
    } else {
      if (queryParams.hasOwnProperty("carrier")) {
        delete queryParams["carrier"];
      }
    }

    if (selectedClass != false && selectedClass != "All Providers") {
      queryParams["provider"] = `${selectedClass}`;
    } else {
      if (queryParams.hasOwnProperty("provider")) {
        delete queryParams["provider"];
      }
    }
    return queryParams;
  };

  const onDateChange = (dates) => {
    if (dates != null) {
      const formattedStartDate = dayjs(dates[0]).format("MM/DD/YYYY");
      const formattedEndDate = dayjs(dates[1]).format("MM/DD/YYYY");
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
  };

  const onCarrierSelect = (e) => {
    setSelectedCarrier(e.target.value);
  };

  const onClassSelect = (e) => {
    setSelectedClass(e.target.value);
  };

  const onClickToBarChart = (e) => {
    setDayTransit({
      TimeInTransit: e?.TimeinTransit ?? 0,
      PlaceCount: e?.pieceCount ?? 0,
      Percentage: e?.percentage && e?.percentage != "" ? e?.percentage : 0,
      Running: e?.RunningPer && e?.RunningPer != "" ? e?.RunningPer : 0,
    });
  };

  function generateFileName() {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const month = String(currentTime.getMonth() + 1).padStart(2, "0"); // Add 1 to the month since it's zero-based
    const day = String(currentTime.getDate()).padStart(2, "0");
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentTime.getSeconds()).padStart(2, "0");

    return `Average_time_reports_${year}-${month}-${day}_${hours}_${minutes}_${seconds}.csv`;
  }

  const handleApplyClick = () => {
    const queryParams = getCurrentPageParams();
    getTrackingNumberList(queryParams, false);
  };

  useEffect(() => {
    const queryParams = getCurrentPageParams();
    console.log(queryParams);
    let data = getTrackingNumberList({ ...queryParams }, false);
    setReportData(data);
  }, [startDate, endDate]);

  useEffect(() => {
    setSelectedClass("All Providers");
  }, [selectedCarrier]);

  // useEffect(() => {
  // 	const queryParams = getCurrentPageParams();
  // 	let data = getTrackingNumberList({ ...queryParams }, false);
  // 	setReportData(data);
  // }, []);

  return (
    <>
      <Helmet>
        <title> Average Time Tracker | BeyondCarts </title>
      </Helmet>
      <Stack maxWidth="100%" marginTop={3}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          rowSpacing={{ lg: 2, md: 2, sm: 1, xs: 1 }}
          justifyContent={{ lg: "space-between" }}
          marginBottom={3}
        >
          <Grid item xs="auto">
            <SelectInput
              // sx={{
              // 	marginTop: 0,
              // }}
              name="report_type"
              onChangeAdvancedForm={onCarrierSelect}
              advancedFormData={carrierSelect}
              listItem={carrierSelect}
              value={selectedCarrier}
              defaultValue={selectedCarrier}
            />
          </Grid>
          <Grid item xs="auto">
            <SelectInput
              sx={{
                marginTop: 0,
                height: { lg: 40, md: 40, sm: 35 },
                "&  .MuiFormControl-root": {
                  width: { lg: 174, md: 150, sm: 120 },
                },
                "&  .MuiFormControl-root > .MuiInputBase-root": {
                  height: { lg: 40, md: 33, sm: 33 },
                },
              }}
              name="report_type"
              onChangeAdvancedForm={onClassSelect}
              advancedFormData={ClassSelect}
              listItem={ClassSelect}
              value={selectedClass}
              defaultValue={selectedClass}
            />
          </Grid>
          <Grid item xs="auto">
            <DateRangePicker
              onDateChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
          <Grid item xs="auto">
            <Button
              type="button"
              variant="contained"
              onClick={handleApplyClick}
              sx={{
                height: { xs: 36, lg: 40 },
                width: { md: 110, sm: 90, xs: 90, md: 100 },
                backgroundColor: "secondary.main",
                boxShadow: "none",
              }}
            >
              Apply
            </Button>
          </Grid>

          <Grid item xs="auto">
            <CSVLink
              data={csvData}
              filename={filename}
              onClick={() => {
                if (!reportData?.data?.reportData?.totalDeliveredOrders)
                  return false;
                else return true;
              }}
              style={{
                color: "#FFF",
                textDecoration: "none",
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                disabled={!reportData?.data?.reportData?.totalDeliveredOrders}
                sx={{
                  boxShadow: "none",
                  backgroundColor: "secondary.main",
                  width: {
                    lg: 130,
                    sm: 110,
                    xs: 110,
                    md: 120,
                  },
                  height: { xs: 36, sm: 36, md: 36, lg: 40 },
                  fontSize: {
                    md: 14,
                    lg: 16,
                    sm: 14,
                    xs: 14,
                  },
                  letterSpacing: "0.1px",
                }}
                startIcon={<Iconify icon="ic:outline-sim-card-download" />}
              >
                Export
              </Button>
            </CSVLink>
          </Grid>
        </Grid>

        <AverageDeliveryChart
          onClickToBarChart={onClickToBarChart}
          reportData={reportData}
          showLoader={showLoader}
        />

        <TransitDataCard title="Day Transit" cardItems={TransitCards} />

        <TransitTotalTableDataCard title="Total" cardItems={TransitCardTotal} />
      </Stack>
    </>
  );
}
