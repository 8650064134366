const { GetUsersList } = require("src/pages/returns/ReturnsApi");

export const getUserNameById = async (userInfo, userId) => {
  const usersList = await GetUsersList(userInfo);
  const data = usersList.data;

  for (const key in data) {
    if (key === userId) return data[key];
  }
};
