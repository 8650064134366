import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  opened: true,
};

export const updateMenuState = createAsyncThunk(
  "navbar/updateMenuState",
  async (newState, { getState }) => {
    const currentState = getState();
    if (currentState === newState) return;
    else return newState;
  }
);

const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateMenuState.fulfilled, (state, action) => {
      state.opened = action.payload;
    });
  },
});

export const getCurrentNavBarState = (state) => state.navbar.opened;
export default navbarSlice.reducer;
