import { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { setSigninData } from "src/features/signin/signin.action";
import { MuiTelInput } from "mui-tel-input";
import Iconify from "src/components/iconify/Iconify";
import palette from "src/theme/palette";
import OtpForms from "./formsContainer/otpForm/otpForm";
import {
  createOnboardingUser,
  getExistantTenant,
} from "src/features/onborading/onboardingApi";
import CircularLoader from "src/loader/circular/CircularLoader";
import { handlePhoneChange } from "src/utils/formatPhone";

const inputStyle = {
  input: { color: palette.common.black },
  width: "100%",

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.light",
  },
  "& .MuiInputLabel-formControl": {
    color: "#00000099",
  },
  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
    color: "#00000099",
  },
  "& .MuiFormHelperText-root": {
    textAlign: "left",
    fontSize: "14px",
  },
};

const phoneStyle = {
  input: { color: palette.common.black },
  width: "100%",

  "& .MuiMenu-paper": {
    color: palette.common.black,
    backgroundColor: palette.common.black,
  },
};

const SignupForm = ({ setSignUp, isOtpView, setIsOtpView, isFromLogin }) => {
  const dispatch = useDispatch();

  const signinData = useSelector((state) => state.signin.signinData);
  const {
    emailError,
    emailInvalidError,
    emailExistantError,
    passwordError,
    weakPasswordError,
    rePasswordError,
    nameError,
    lastNameError,
    phoneError,
    invalidPhoneError,
    companyError,
    existantCompanyError,
  } = signinData || {};

  const [showPassword, setShowPassword] = useState(false);
  const [pwsError, setPwsError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [shippingVolume, setShippingVolume] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
  };

  const validatePhone = (phone) => {
    return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g.test(
      phone
    );
  };

  const checkNamePhoneData = () => {
    if (!signinData.email) {
      dispatch(setSigninData({ ...signinData, emailError: true }));
      return true;
    }

    if (!validateEmail(signinData.email)) {
      dispatch(setSigninData({ ...signinData, emailInvalidError: true }));
      return true;
    }

    if (!signinData.password) {
      dispatch(setSigninData({ ...signinData, passwordError: true }));
      return true;
    }

    if (!signinData.repassword) {
      dispatch(setSigninData({ ...signinData, rePasswordError: true }));
      return true;
    }

    if (signinData.password !== signinData.repassword) {
      dispatch(setSigninData({ ...signinData, rePasswordError: true }));
      return true;
    }

    if (!signinData.name) {
      dispatch(setSigninData({ ...signinData, nameError: true }));
      return true;
    }

    if (!signinData.lastName) {
      dispatch(setSigninData({ ...signinData, lastNameError: true }));
      return true;
    }

    if (!signinData.phone) {
      dispatch(setSigninData({ ...signinData, phoneError: true }));
      return true;
    }

    if (!validatePhone(signinData.phone)) {
      dispatch(setSigninData({ ...signinData, invalidPhoneError: true }));
      return true;
    }

    if (!signinData.company) {
      dispatch(setSigninData({ ...signinData, companyError: true }));
      return true;
    }
  };

  const handleSignup = (e) => {
    e.preventDefault();
    if (checkNamePhoneData()) return;

    getExistantTenant(signinData.company)
      .then((r) => {
        console.log("rssasa", r);
        if (r) {
          const { statusCode } = r;

          if (statusCode === 200) {
            const data = {
              email: signinData.email,
              password: signinData.password,
              first_name: signinData.name,
              last_name: signinData.lastName,
              phone_number: signinData.phone,
              company_name: signinData.company,
              shipping_volume: shippingVolume,
            };

            createOnboardingUser(data)
              .then((r) => {
                console.log("r", r);

                const { statusCode } = r;
                if (statusCode === 200) {
                  setIsOtpView(true);
                  dispatch(
                    setSigninData({
                      ...signinData,
                      shippingvolume: shippingVolume,
                    })
                  );
                }
                setIsLoading(false);
              })
              .catch(({ message }) => {
                console.log("message", message);
                setIsLoading(false);
                if (message.includes("Sorry. User name already exists"))
                  dispatch(
                    setSigninData({
                      ...signinData,
                      emailExistantError: true,
                    })
                  );

                if (message.includes("Password did not conform with policy")) {
                  dispatch(
                    setSigninData({
                      ...signinData,
                      weakPasswordError: true,
                    })
                  );
                  let newError = message.substring(
                    message.indexOf("policy") + 6
                  );
                  newError = newError.replace(": ", "");
                  setPwsError(newError);
                }
              });

            setIsLoading(true);
          } else if (statusCode === 400) {
            if (r.statusMessage) {
              if (r.statusMessage.includes("already exists")) {
                dispatch(
                  setSigninData({
                    ...signinData,
                    existantCompanyError: true,
                  })
                );
              }
            }
          }
        } else if (r.statusCode == 400) {
        if (e.includes("already exists")) {
          dispatch(
            setSigninData({
              ...signinData,
              existantCompanyError: true,
            })
          );
        }
        }
      })
      .catch((e) => {
        console.log("e", e);
        if (e.includes("already exists")) {
          dispatch(
            setSigninData({
              ...signinData,
              existantCompanyError: true,
            })
          );
        }
      });
  };

  useEffect(() => {
    if (isFromLogin) return;
    dispatch(setSigninData({}));
  }, []);

  useEffect(() => {
    dispatch(
      setSigninData({
        ...signinData,
        phone: phoneNumber,
      })
    );
  }, [phoneNumber]);

  return (
    <>
      <Helmet>
        <title> Onboarding | BeyondCarts </title>
      </Helmet>

      {isLoading && <CircularLoader />}
      {isOtpView ? (
        <OtpForms setIsLoading={setIsLoading} />
      ) : (
        <Stack
          component="form"
          sx={{
            width: "100%",
            gap: 1.8,
            // paddingTop: 7,
          }}
        >
          <TextField
            sx={inputStyle}
            label="Email *"
            autoComplete="off"
            error={emailError || emailInvalidError || emailExistantError}
            helperText={
              (emailError && "Email address is required.") ||
              (emailInvalidError && "Invalid email address.") ||
              (emailExistantError && "User already exists.")
            }
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  emailError: false,
                  emailInvalidError: false,
                  emailExistantError: false,
                })
              )
            }
            onChange={(e) =>
              dispatch(
                setSigninData({
                  ...signinData,
                  email: e.target.value,
                })
              )
            }
            inputProps={{
              maxLength: 50,
            }}
          />

          <TextField
            sx={inputStyle}
            label="Password"
            autoComplete="new-password"
            required
            type={showPassword ? "text" : "password"}
            error={passwordError || weakPasswordError}
            helperText={
              (passwordError && "Password is required.") ||
              (weakPasswordError && pwsError)
            }
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  passwordError: false,
                  weakPasswordError: false,
                })
              )
            }
            onChange={(e) =>
              dispatch(
                setSigninData({
                  ...signinData,
                  password: e.target.value,
                })
              )
            }
            inputProps={{
              maxLength: 50,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={inputStyle}
            label="Retype password"
            required
            type={showPassword ? "text" : "password"}
            error={rePasswordError}
            helperText={rePasswordError && "Passwords doesn't match."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  rePasswordError: false,
                })
              )
            }
            onChange={(e) =>
              dispatch(
                setSigninData({
                  ...signinData,
                  repassword: e.target.value,
                })
              )
            }
            inputProps={{
              maxLength: 50,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={inputStyle}
            label="First Name"
            required
            error={nameError}
            helperText={nameError && "First Name is required."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  nameError: false,
                })
              )
            }
            onChange={(e) =>
              dispatch(
                setSigninData({
                  ...signinData,
                  name: e.target.value,
                })
              )
            }
            inputProps={{
              maxLength: 50,
            }}
          />

          <TextField
            sx={inputStyle}
            label="Last Name"
            required
            error={lastNameError}
            helperText={lastNameError && "Last Name is required."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  lastNameError: false,
                })
              )
            }
            onChange={(e) =>
              dispatch(
                setSigninData({
                  ...signinData,
                  lastName: e.target.value,
                })
              )
            }
            inputProps={{
              maxLength: 50,
            }}
          />

          <MuiTelInput
            sx={phoneStyle}
            defaultCountry="US"
            forceCallingCode={true}
            label="Phone Number"
            name="phone_number"
            value={phoneNumber}
            error={phoneError || invalidPhoneError}
            helperText={
              (phoneError || invalidPhoneError) && "Invalid phone number."
            }
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  phoneError: false,
                  invalidPhoneError: false,
                })
              )
            }
            onChange={(newValue) => handlePhoneChange(newValue, setPhoneNumber)}
          />

          <TextField
            sx={inputStyle}
            label="Company Name"
            required
            error={companyError || existantCompanyError}
            helperText={
              (companyError && "Company Name is required.") ||
              (existantCompanyError &&
                "Company already onboarded, please contact company admin to signup.")
            }
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  companyError: false,
                  existantCompanyError: false,
                })
              )
            }
            onChange={(e) => {
              dispatch(
                setSigninData({
                  ...signinData,
                  company: e.target.value,
                })
              );
            }}
            inputProps={{
              maxLength: 50,
            }}
          />

          <FormControl as="fieldset">
            <FormLabel
              id="demo-radio-buttons-group-label"
              sx={{
                color: palette.common.black,
                paddingBottom: 1,
                "&.Mui-focused": {
                  color: palette.common.black,
                },
              }}
            >
              Monthly Shipping Volume
            </FormLabel>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={shippingVolume}
              onClick={(e) => {
                if (e.target.value === shippingVolume) {
                  setShippingVolume("");
                  dispatch(
                    setSigninData({
                      ...signinData,
                      shippingvolume: "",
                    })
                  );
                } else {
                  setShippingVolume(e.target.value);
                  dispatch(
                    setSigninData({
                      ...signinData,
                      shippingvolume: e.target.value,
                    })
                  );
                }
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.2,
                paddingLeft: 1,
              }}
            >
              <FormControlLabel
                value="1-2500"
                control={<Radio sx={{ padding: 0.5, marginRight: 0.8 }} />}
                label="1-2500"
              />
              <FormControlLabel
                value="2501-5000"
                control={<Radio sx={{ padding: 0.5, marginRight: 0.8 }} />}
                label="2501-5000"
              />
              <FormControlLabel
                value="5000&Above"
                control={<Radio sx={{ padding: 0.5, marginRight: 0.8 }} />}
                label="5000 & Above"
              />
            </RadioGroup>
          </FormControl>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSignup}
            sx={{
              boxShadow: "none",
              backgroundColor: palette.secondary.main,
              fontSize: 16,
            }}
          >
            Sign up
          </LoadingButton>

          <Box
            sx={{
              height: 22.8,
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <Link
              variant="h6"
              underline="none"
              sx={{ fontWeight: 500 }}
              onClick={() => setSignUp(false)}
            >
              <span style={{ color: "#000" }}>Already have an account?</span>{" "}
              Log in
            </Link>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default SignupForm;
