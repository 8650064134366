import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import typography from "src/theme/typography";

const tableHeadTextStyle = {
	color: "secondary.light",
	fontFamily: typography.fontFamilySemiBold,
	fontSize: { sm: 14, md: 14 },
};

const tableCellTextStyle = {
	color: "#1C272A",
	fontSize: 14,
	fontStyle: "normal",
	lineHeight: "20px",
	letterSpacing: "0.1px",
};

ShippingStatisticsDataCard.propTypes = {
	title: PropTypes.string,
	cardItems: PropTypes.array,
	onClickRow: PropTypes.func,
};

export default function ShippingStatisticsDataCard({
	title,
	cardItems,
	onClickRow,
}) {
	return (
		<Box sx={{ padding: "none" }}>
			<Box
				sx={{
					pl: 2,
					py: { xs: 1, md: 4 },
					marginBottom: 3,
					height: 50,
					display: "flex",
					alignItems: "center",
					color: "common.black",
					bgcolor: "common.white",
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
				}}
			>
				<h4 style={typography.h4}>{title}</h4>
			</Box>

			<TableContainer
				className="statistics-data"
				component={Paper}
				sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
			>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow sx={{ bgcolor: "secondary.main" }}>
							<TableCell
								sx={{ ...tableHeadTextStyle, width: "45%" }}
							>
								Status
							</TableCell>
							<TableCell
								sx={{ ...tableHeadTextStyle, width: "35%" }}
								display="flex"
								aria-label="center"
							>
								Volume (Quantity)
							</TableCell>
							<TableCell
								sx={{ ...tableHeadTextStyle, width: "20%" }}
							>
								Volume (Percentage)
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody
						sx={{
							borderBottomLeftRadius: 16,
							borderBottomRightRadius: 16,
						}}
					>
						{cardItems.length === 0 ||
						cardItems.every((row) => row.volume === 0) ? (
							<TableRow>
								<TableCell colSpan={3} align="center">
									<Typography
										variant="h5"
										sx={{ color: "primary.main" }}
									>
										No data found
									</Typography>
								</TableCell>
							</TableRow>
						) : (
							cardItems
								.filter((row) => row.volume !== 0) // Exclude volume zero
								.sort((a, b) => a.volume - b.volume)
								.map((row, index, array) => (
									<TableRow
										key={row.status}
										onClick={() =>
											index !== array.length - 1 &&
											onClickRow(row)
										}
									>
										<TableCell
											component="th"
											scope="row"
											sx={{
												color: "secondary.light",
												width: "20%",
											}}
										>
											<Grid
												display="flex"
												sx={{
													alignItems: "center",
													gap: 3,
												}}
											>
												<img
													src={row.icon}
													alt=""
													style={{
														borderRadius: "100%",
													}}
												/>
												<Typography
													sx={{
														...tableCellTextStyle,
														fontWeight:
															index ===
															array.length - 1
																? 700
																: 500,
													}}
												>
													{row.status}
												</Typography>
											</Grid>
										</TableCell>
										<TableCell
											sx={{
												...tableCellTextStyle,
												width: "30%",
												fontWeight:
													index === array.length - 1
														? 700
														: 500,
												color:
													index !== array.length - 1
														? "primary.light"
														: "secondary.light",
											}}
										>
											{index === array.length - 1
												? array[array.length - 1].volume
												: row.volume}
										</TableCell>
										<TableCell
											sx={{
												...tableCellTextStyle,
												width: "40%",
												fontWeight:
													index === array.length - 1
														? 700
														: 500,
											}}
										>
											{row.percentage}
										</TableCell>
									</TableRow>
								))
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
