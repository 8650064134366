import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import palette from "src/theme/palette";
import { BoxComponent } from "./BoxConfigStepper";
import BoxEditIcon from "../BoxEditIcon";
import typography from "src/theme/typography";
import { getLabelByValue } from "src/utils/labelUtils";
import { LABELS } from "../Labels";
import { useAppSelector } from "src/hooks/hooks";

const ConfigPreview = ({
  handleEditPrepaidLabelConfiguration,
  resetToInitialStep,
}) => {
  const previewDetails = useSelector((state) => state?.form);
  const isCustomerManagedReturn = useAppSelector(
    (state) => state.returnMethod.customerManagedReturn
  );

  return (
    <Stack
      width={"100%"}
      padding={3}
      gap={2.5}
      sx={{
        backgroundColor: "white",
        border: "1px solid #D9D9D9",
        position: "relative",
      }}
    >
      <Stack gap={0.8}>
        <Typography fontSize={20} fontFamily={typography.fontFamilySemiBold}>
          Warehouse address
        </Typography>
        <Typography>
          {previewDetails.warehouse?.firstName}{" "}
          {previewDetails.warehouse?.lastName},{" "}
          {previewDetails.warehouse?.addressLine},{" "}
          {previewDetails.warehouse?.city}, {previewDetails.warehouse?.state},{" "}
          {previewDetails.warehouse?.country},{" "}
          {previewDetails.warehouse?.zipCode}
        </Typography>
      </Stack>
      <Stack
        position={"absolute"}
        right={28}
        top={18}
        direction={"row"}
        gap={1}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          handleEditPrepaidLabelConfiguration();
          resetToInitialStep && resetToInitialStep(0);
        }}
      >
        <img
          title="Edit Card"
          style={{
            height: "22px",
          }}
          src="/assets/edit-config.svg"
        />
        <Typography
          color={"#256FA5"}
          fontFamily={typography.fontFamilySemiBold}
        >
          Edit configurations
        </Typography>
      </Stack>

      <Divider sx={{ border: "1.3px solid #AAAAAA" }} />
      <Stack gap={0.8}>
        <Typography fontSize={20} fontFamily={typography.fontFamilySemiBold}>
          Carrier & Mail class configuration
        </Typography>
        <Typography>
          {previewDetails?.carrier?.carrier} &{" "}
          {previewDetails?.carrier?.mailClass
            ?.map((val) =>
              val
                .toLowerCase()
                .replace(/[_-]/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            )
            .join(", ")}
          {/* {previewDetails?.carrier?.mailClass?.map((val) => val).join(", ")} */}
        </Typography>
      </Stack>
      <Divider sx={{ border: "1.3px solid #AAAAAA" }} />

      <Stack gap={1.5}>
        <Typography fontSize={20} fontFamily={typography.fontFamilySemiBold}>
          Box configuration
        </Typography>
        <Stack direction={"row"} gap={2}>
          {previewDetails?.boxes?.map((box, index) => (
            <BoxComponent
              key={index}
              box={box}
              isPreviewShow={true}
              // TODO: active box change label
              selected={previewDetails?.activeBox === index}
            />
          ))}
        </Stack>
      </Stack>
      <Divider sx={{ border: "1.3px solid #AAAAAA" }} />

      <Stack gap={0.8}>
        <Typography fontSize={20} fontFamily={typography.fontFamilySemiBold}>
          Label configuration
        </Typography>
        <Typography>
          {getLabelByValue(previewDetails?.label?.labelSize)} &{" "}
          {previewDetails?.label?.labelType} type
        </Typography>
      </Stack>
      <Divider sx={{ border: "1.3px solid #AAAAAA" }} />

      {previewDetails?.paidLabelConfig?.reasons?.length > 0 && (
        <>
          <Stack gap={1}>
            <Typography
              fontSize={20}
              fontFamily={typography.fontFamilySemiBold}
            >
              Paid label configuration
            </Typography>
            <Grid container direction={"row"} gap={2}>
              {previewDetails?.paidLabelConfig?.reasons?.map((el) => (
                <Grid
                  item
                  display={"flex"}
                  paddingX={1.2}
                  paddingY={0.5}
                  gap={0.8}
                  sx={{
                    cursor: "pointer",
                    bgcolor: palette.primary.main,
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                >
                  <Typography>{el}</Typography>
                </Grid>
              ))}
            </Grid>
          </Stack>
          <Divider sx={{ border: "1.3px solid #AAAAAA" }} />{" "}
        </>
      )}

      <Stack gap={0.8}>
        <Typography fontSize={20} fontFamily={typography.fontFamilySemiBold}>
          Email configuration
        </Typography>
        <Typography>
          {previewDetails?.paidLabelConfig?.reasons && "Paid label, "} Free
          label
          {isCustomerManagedReturn && ", Own label"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ConfigPreview;
