import { useState, useEffect } from "react";
import { getSessionData } from "../utils/core";

function DataFetcher() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const loginState = getSessionData("user");

  useEffect(() => {
    if (loginState !== null) {
      setIsLoggedIn(loginState.isLoggedIn);
    }
  }, []);
  return <></>;
}

export default DataFetcher;
