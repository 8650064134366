import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Modal,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  getUserError,
  login,
  validateLogin,
} from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { setSigninData } from "src/features/signin/signin.action";
import {
  retrieveCookies,
  storeCredentialsInCookies,
} from "src/utils/cookiesTracker";
import { resendOtpCode } from "src/features/onborading/onboardingApi";

import Iconify from "src/components/iconify";
import palette from "src/theme/palette";
import CircularLoader from "src/loader/circular/CircularLoader";
import typography from "src/theme/typography";
import { GetImages } from "src/pages/returns/ReturnsApi";
import { shopifyImages } from "src/features/returns/shopifyImages";

const modalStyles = {
  position: "absolute",
  top: { lg: "46%", md: "42.5%", sm: "30%" },
  left: { lg: "47.5%", md: "52%", sm: "50%" },
  transform: "translate(-50%, -50%)",
  width: "550px",
  borderRadius: "10px",
  background: "#FAFAFA",
  padding: "20px 0",
};

const StyledContent = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  color: theme.palette.common.black,
  // padding: theme.spacing(12, 0),
}));

export default function LoginForm({
  setSignUp,
  setIsOtpView,
  setIsLoading,
  setIsFromLogin,
}) {
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [planName, setPlanName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  const loginError = useAppSelector(getUserError);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const rememberMe = watch("remember", false);

  useEffect(() => {
    retrieveCookies(setValue);
  }, [setValue]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    if (Object.keys(errors).length === 0) {
      const response = await validateLogin(data);
      const { statusMessage } = response;
      const responseData = response?.data;
      // negative onboarded scenarios
      if (statusMessage?.includes("User is not confirmed")) {
        try {
          const r = await resendOtpCode({ email: data.email });
          setIsFromLogin(true);

          dispatch(
            setSigninData({
              user_id: responseData.user_id,
              name: responseData.first_name,
              lastName: responseData.last_name,
              email: data.email,
              phone: responseData.phone_number,
              company: responseData.company_name,
              shippingvolume: responseData.shipping_volume,
              password: data.password,
            })
          );

          setIsOtpView(true);
          setSignUp(true);
          setIsLoading(false);
        } catch (error) {
          dispatch(login(data)).then((action) => {
            setIsLoading(false);

            setDisplayErrorMessage(true);
            setErrorMessage(
              "Attempt limit exceeded, please try after some time."
            );
          });
        }

        return;
      }

      if (statusMessage?.includes("Tenant not exist")) {
        setIsLoading(false);
        navigate("/onboarding/carrier");
        dispatch(
          setSigninData({
            user_id: responseData.user_id,
            name: responseData.first_name,
            lastName: responseData.last_name,
            email: data.email,
            phone: responseData.phone_number,
            company: responseData.company_name,
            shippingvolume: responseData.shipping_volume,
            password: data.password,
          })
        );
        return;
      }

      setPlanName(responseData?.plan_name);

      // demo user or added user
      if (responseData?.password === "newPasswordRequired") {
        setIsLoading(false);

        navigate("/reset-password/logged", {
          state: {
            email: data?.email,
            generatedPassword: data?.password,
          },
        });
        return;
      }

      dispatch(login(data)).then((action) => {
        setIsLoading(false);

        if (action.error) {
          setDisplayErrorMessage(true);
          setErrorMessage(action.error.message);
        }
        const { email, password } = action.meta.arg;
        const rememberMe = data.remember;
        if (rememberMe) {
          storeCredentialsInCookies(email, password);
        }
      });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        gap={{ xs: 2, md: 2.5 }}
        sx={{
          width: "100%",
        }}
      >
        <Stack gap={1.8}>
          <TextField
            fullWidth
            sx={{
              "& > div > input": { color: "common.black" },
              // width: { lg: 515 },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "primary.dark",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "primary.dark",
                },
              },
            }}
            label="Email address"
            name="email"
            autoComplete="email"
            // autoFocus
            {...register("email", {
              required: "Email address is required.",
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: "Invalid email address.",
              },
            })}
            error={errors.email || errors.invalidUser}
            helperText={
              errors.email ? errors.email.message : errors.invalidUser?.message
            }
            style={{ color: "black" }}
            onFocus={() => setDisplayErrorMessage(false)}
          />

          <TextField
            name="password"
            fullWidth
            sx={{
              "& > div > input": { color: "common.black" },
              // width: { lg: 515 },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "primary.dark",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "primary.dark",
                },
              },
            }}
            label="Password"
            type={showPassword ? "text" : "password"}
            {...register("password", {
              required: "Password is required.",
            })}
            error={errors.password}
            helperText={errors.password?.message}
            style={{ color: "black" }}
            onFocus={() => setDisplayErrorMessage(false)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormControlLabel
            control={
              <Checkbox
                {...register("remember")}
                sx={{
                  color: "#000000",
                  padding: 0,
                  paddingRight: 0.6,
                }}
              />
            }
            label={
              <Typography
                varient="subtitle1"
                sx={{
                  color: "common.black",
                  fontSize: "18px !important",
                  margin: "0 !important",
                }}
              >
                Remember me
              </Typography>
            }
            sx={{
              color: "common.black",
              fontSize: "18px !important",
              margin: "0",
              fontWeight: 500,
              "& .Mui-checked": {
                bgColor: "black",
              },
            }}
          />
          <Box
            sx={{
              alignItem: "center",
              height: 25,
            }}
          >
            <Link
              href="/reset-password/step-1"
              variant="h6"
              underline="none"
              sx={{
                fontWeight: 500,
                cursor: "pointer",
              }}
              // onClick={() => setOpenModal(true)}
            >
              Forgot password?
            </Link>
          </Box>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            fontSize: 16,
            boxShadow: "none",
            backgroundColor: palette.secondary.main,
            // marginTop: { xs: 2, sm: 2, md: 0, lg: 0 },
          }}
        >
          Login
        </LoadingButton>

        {displayErrorMessage && (
          <StyledContent
            style={{
              alignItems: "center",
              color: "red",
            }}
          >
            <Typography variant="p">
              {loginError?.loginError
                ? displayErrorMessage && `${loginError.loginErrorMessage}`
                : displayErrorMessage && errorMessage}
              {/* {displayErrorMessage && errorMessage} */}
            </Typography>
          </StyledContent>
        )}

        <Box
          sx={{
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          <Link variant="h6" underline="none" onClick={() => setSignUp(true)}>
            <span style={{ color: "#000" }}>Don&apos;t have an account?</span>{" "}
            Sign Up
          </Link>
        </Box>
      </Stack>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          width: "550px",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Box sx={modalStyles}>
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: { xs: 13, md: 20 },
              right: { md: 22, xs: 10 },
              zIndex: 2,
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <img width="30px" src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>

          <Stack spacing={2} alignItems="center">
            <Box
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              sx={{
                fontSize: {
                  lg: 30,
                  md: 27,
                  xs: 27,
                  sm: 27,
                },
                textAlign: "center",
              }}
            >
              {planName} has expired
            </Box>
            <Box
              sx={{
                fontSize: {
                  lg: 16,
                  md: 16,
                  xs: 14,
                  sm: 14,
                },
                textAlign: "center",
              }}
            >
              You need to{" "}
              {planName === "Trial Plan"
                ? "create an account and chose a free or premium plan"
                : "update your payment information"}
            </Box>
          </Stack>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0",
            }}
          >
            <LoadingButton
              size="large"
              type="button"
              variant="contained"
              sx={{
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
                paddingY: 1.3,
                width: "200px",
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={() => {
                setOpenModal(false);
                {
                  planName === "Trial Plan" ? setSignUp(true) : "";
                }
              }}
            >
              {planName === "Trial Plan" ? "Sign up" : "Update Payments"}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
