import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Dialog, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BrandAssets from "./BrandAssets";
import BrandPolicy from "./BrandPolicy";
import { useDispatch, useSelector } from "react-redux";
import { getBrandedPortalInfo, postBrandedPortalInfo } from "src/features/brandedPortal/brandedPortalApi";
import { useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import { brandPortalErrorUpload, brandReturnApi, returnAssetsUpload, returnPolicyUpload } from "src/features/brandedPortal/brandedPortal";
import _ from 'lodash';
import { getTrackingPortalInfo } from "src/features/return_management/order/customerBrandPortalApi";
import { Helmet } from "react-helmet-async";
import useCustomerOrderInfo from "../../features/return_management/hooks/useCustomerOrderInfo";
import CircularLoader from "src/loader/circular/CircularLoader";

const ReturnsBrandPortal = () => {
  const [value, setValue] = useState('assets');
  const userInfo = useAppSelector(getUserInfo);
  const data = useSelector(state => state.brandedPortal);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [initialState, setInitialState] = useState(null);
  const [changedState, setChangedState] = useState(null);
  const [errors, setErrors] = useState(data.brandPortalErrors);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { updateBrandPortalInfo } = useCustomerOrderInfo();
  const [isOpen, setOpen] = useState(false)

  const validateUrl = (name, value) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?(www\\.)?[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,})+(\\/[^\\s]*)?$');
    if (!value || urlPattern.test(value)) {
      setErrors(prev => ({ ...prev, [name]: null }));
    } else {
      setErrors(prev => ({ ...prev, [name]: 'Invalid URL' }));
    }
  };
  useEffect(() => {
    dispatch(brandPortalErrorUpload(errors))
  }, [errors]);
  const validateColor = (name, value) => {
    const colorPattern = /^#[a-fA-F0-9]{6}$/;
    if (!value || colorPattern.test(value)) {
      setErrors(prev => ({ ...prev, [name]: null }));
    } else {
      setErrors(prev => ({ ...prev, [name]: 'Invalid color format' }));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const res = await getBrandedPortalInfo(userInfo.tenant_name, userInfo);
        const data = res.data;
        setIsLoading(false)
        const brandAssetData = {
          logo: data.logo,
          background_image: data.background_image,
          logo_url: data.logo_url,
          favicon: data.favicon,
          ...data.color_theme,
          corner_radius: data.corner_radius,
          heading: data.heading,
        };
        const returnPolicyData = {
          ...data.return_policy,
          ...data.customer_support
        };
        const brandReturnData = {
          ...brandAssetData,
          ...returnPolicyData
        }
        setInitialState(brandReturnData);
        setChangedState(brandReturnData)
        setErrors({});
        dispatch(returnAssetsUpload(brandAssetData));
        dispatch(returnPolicyUpload(returnPolicyData));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch, userInfo]);

  useEffect(() => {
    const filteredAssets = Object.keys(data.brandReturnAssets)
      .filter(key => key !== 'website_url')
      .reduce((obj, key) => {
        obj[key] = data.brandReturnAssets[key];
        return obj;
      }, {});
    const brandReturnData = {
      ...filteredAssets,
      ...data.returnPolicyUpdate
    };
    setChangedState(brandReturnData);
  }, [data]);

  const handleSubmit = (type) => {
    const { logo, logo_url, background_image, favicon, brand_color, secondary_color, page_bg_color, corner_radius, heading } = data.brandReturnAssets
    const { is_custom, link_url, link_text, custom_content, email_id, phone_no } = data.returnPolicyUpdate
    const brandReturnData = {
      logo,
      logo_url,
      background_image,
      favicon,
      color_theme: {
        brand_color,
        secondary_color,
        page_bg_color,
      },
      corner_radius,
      heading,
      return_policy: {
        is_custom,
        link_url,
        link_text,
        custom_content,
      },
      customer_support: {
        email_id,
        phone_no,
      }
    }
    if (type === 'preview') {
      updateBrandPortalInfo(brandReturnData);
      window.open(`/returns/${userInfo.tenant_name}?preview=demo`, '_blank');
    }
    if (type === 'save') {
      setIsLoading(true)
      postBrandedPortalInfo(userInfo.tenant_name, brandReturnData, userInfo).then(res => {
        if (res.statusCode === 200) {
          setIsLoading(false)
          const brandDataInfo = {
            ...data.brandReturnAssets,
            ...data.returnPolicyUpdate
          }
          const filteredAssets = Object.keys(brandDataInfo)
            .filter(key => key !== 'website_url')
            .reduce((obj, key) => {
              obj[key] = brandDataInfo[key];
              return obj;
            }, {});
          setInitialState(filteredAssets);
          setChangedState(filteredAssets);
          setOpen(true);
        }
      });
    }
  };

  const applyTrackingSettings = async () => {
    setIsLoading(true)
    const response = await getTrackingPortalInfo(userInfo);
    const track = response.data;
    setIsLoading(false)
    const brandAssetData = {
      logo: track.logo,
      logo_url: track.logo_url,
      background_image: track.background_image,
      favicon: track.favicon,
      ...track.color_theme,
      corner_radius: track.corner_radius,
      heading: data.brandReturnAssets.heading,
    };
    const returnPolicyData = {
      is_custom: data.returnPolicyUpdate.is_custom,
      link_url: data.returnPolicyUpdate.link_url,
      link_text: data.returnPolicyUpdate.link_text,
      custom_content: data.returnPolicyUpdate.custom_content,
      ...track.customer_support
    };
    const brandReturnData = {
      ...brandAssetData,
      ...returnPolicyData
    }
    setChangedState(brandReturnData)
    dispatch(returnAssetsUpload(brandAssetData));
    dispatch(returnPolicyUpload(returnPolicyData));
  };

  useEffect(() => {
    if (errors) {
      const hasErrors = Object.values(errors).some(error => error !== null && error !== "");
      const statesAreEqual = _.isEqual(initialState, changedState);
      setIsSaveDisabled(hasErrors || statesAreEqual);
    }
  }, [errors, initialState, changedState]);

  const isPreviewDisabled = () => {
    if (errors) {
      const hasErrors = Object.values(errors).some(error => error !== null && error !== "");
      return hasErrors
    }
  };

  return (
    <>
      <Helmet>
        <title>Branded Returns Portal | BeyondCarts</title>
      </Helmet>
      {isLoading && <CircularLoader />}
      <Box sx={{ position: 'relative', height: 'calc(100vh - 88px)' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={applyTrackingSettings} sx={{ mt: 2 }}>Apply settings from Tracking Portal</Button>
        </Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Brand assets" value="assets" />
              <Tab label="Return policy" value="policy" />
            </TabList>
          </Box>
          <TabPanel value="assets">
            <BrandAssets data={data?.brandReturnAssets} errors={errors} validateUrl={validateUrl} validateColor={validateColor} setErrors={setErrors} />
          </TabPanel>
          <TabPanel value="policy">
            <BrandPolicy data={data?.returnPolicyUpdate} validateUrl={validateUrl} errors={errors} setErrors={setErrors} />
          </TabPanel>
        </TabContext>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 3, padding: '20px', 
          boxShadow: '0px -8px 30px 0px #00000014', backgroundColor: 'white', 
            bottom: "-22px",
            width: "calc(100% - -80px)",
            margin: "0 0 0 -40px",
            zIndex:"1",
            position:"sticky"}}>
          <Button variant="contained" sx={{ width: '130px' }} onClick={() => handleSubmit('preview')} disabled={isPreviewDisabled()}>Preview</Button>
          <Button variant="contained" sx={{ width: '130px' }} onClick={() => handleSubmit('save')} disabled={isSaveDisabled}>Save</Button>
        </Box>
        <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="xs"
        >
          <Box sx={{padding:'20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Typography sx={{mb:2}}>All changes saved successfully</Typography>
            <Button variant="contained" sx={{backgroundColor: 'secondary.main', width: "130px", margin: 'auto'}} onClick={() => setOpen(false)}>Ok</Button>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default ReturnsBrandPortal;
