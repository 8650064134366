import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brandedAssetsState: {
    logo: "",
    logo_url: "",
    background_image: "",
    favicon: "",
    corner_radius: "0",
    brand_color: "",
    secondary_color: "",
    page_bg_color: "",
    tracking_analytics: "",
    website_url: "",
  },
  brandedHeaderState: {
    header_text: "",
    text_color: "",
    background_color: '',
    email_id: "",
    phone_no: "",
  },
  brandedFooterState: {
    custom_content: "",
    privacy_url: "",
    terms_url: "",
    link_text: "",
    policy_content: "",
    text_color: "",
    background_color: "",
    facebook: "",
    instagram: "",
  },
  brandReturnAssets: {
    logo: "",
    logo_url: "",
    background_image: "",
    favicon: "",
    brand_color: "",
    secondary_color: "",
    page_bg_color: "",
    corner_radius: "",
    heading: '',
  },
  returnPolicyUpdate: {
    is_custom: false,
    link_url: "",
    link_text: "",
    custom_content: "",
    email_id: "",
    phone_no: "",
  },
  brandPortalErrors: {},
  brandTrackPortalErrors: {},
};

export const brandedPortalSlice = createSlice({
  name: 'brandedPortal',
  initialState,
  reducers: {
    assetsUpload: (state, action) => {
      state.brandedAssetsState = action.payload;
    },
    headerUpload: (state, action) => {
      state.brandedHeaderState = action.payload;
    },
    footerUpload: (state, action) => {
      state.brandedFooterState = action.payload;
    },
    returnAssetsUpload: (state, action) => {
      state.brandReturnAssets = action.payload;
    },
    returnPolicyUpload: (state, action) => {
      state.returnPolicyUpdate = action.payload;
    },
    brandPortalErrorUpload: (state, action) => {
      state.brandPortalErrors = action.payload;
    },
    brandTrackPortalErrorUpload: (state, action) => {
      state.brandTrackPortalErrors = action.payload;
    },
  },
});

export default brandedPortalSlice.reducer;
export const { assetsUpload, headerUpload, footerUpload, returnAssetsUpload, returnPolicyUpload, brandPortalErrorUpload, brandTrackPortalErrorUpload } = brandedPortalSlice.actions;
