import { Box, Modal, IconButton } from "@mui/material";
import { useState } from "react";

const popUpModalStyle = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: { xs: "column", sm: "row" },
  gap: 4,
  paddingX: { xs: 4, md: 8 },
  paddingY: { xs: 2, md: 6 },
  position: { xs: "relative", sm: "absolute" },
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  minWidth: { xs: "auto", md: "80vw", lg: "1000px" },
  maxHeight: { xs: "80vh" },
  height: "auto",
  borderRadius: "20px",
  background: "#FFF",
  overflow: "auto",
  outline: "none",
};

const ModalRowDetails = ({ children, inputData, onExpandhandler }) => {
  const tracking_number = inputData?.tracking_number;
  const [openModal, setOpenModal] = useState(true);

  const handleCloseModal = (e) => {
    setOpenModal(false);
    onExpandhandler(e, tracking_number);
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      {Object.keys(inputData).length === 0 ? null : (
        <Box sx={popUpModalStyle}>
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: { xs: 10, md: 13 },
              right: { xs: 10, md: 15 },
              zIndex: 2,
            }}
            onClick={handleCloseModal}
          >
            <img width="30px" src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>

          {children}
        </Box>
      )}
    </Modal>
  );
};

export default ModalRowDetails;
