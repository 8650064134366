import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Grid, Divider, Button, Stack, Box } from "@mui/material";
import InputForm from "../input-field/InputField";
import DatePickerComp from "../date-picker/DatePicker";
import SelectInput from "../select/SelectInput";
import { useLocation } from "react-router-dom";
import MultiSelectInput from "../select/MulltiSelect";
import appUrls from "src/utils/appUrls";   

export default function AdvancedFilter({
  advancedFilterUsersSearchData,
  advancedFilterInvoicesSearchData,
  onChangeAdvancedForm,
  advancedFormData,
  statusSelect,
  carrierSelect,
  scannedStatus,
  invoicesStatus,
  firstScanEmailStatistics,
  onSubmitAdvancedFilter,
  alertStatus
}) {
  const StyledDivider = styled(Divider)(({ theme }) => ({
    borderStyle: "line",
    borderWidth: theme.spacing(0.1),
    margin: theme.spacing(1, 0),
  }));

  const { pathname } = useLocation();
  return (
    <Stack direction="column">
      <StyledDivider />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { lg: "space-between", sm: "start" },
          marginTop: 1,
          marginBottom: { lg: 6, md: 4, xs: 4, sm: 4 },
          // height:{sm: '500'}
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          {!advancedFilterInvoicesSearchData && (
            <InputForm
              name={`${
                advancedFilterUsersSearchData?.first_name?.name || "first_name"
              }`}
              id={`${
                advancedFilterUsersSearchData?.first_name?.name || "first_name"
              }`}
              label={`${
                advancedFilterUsersSearchData?.first_name?.label ||
                "Customer First Name"
              }`}
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
            />
          )}
          {advancedFilterUsersSearchData ||
          advancedFilterInvoicesSearchData ? null : (
            <DatePickerComp
              name="shipping_date"
              id="shipping_date"
              label="Shipped On"
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
              alertStatus={alertStatus}
            />
          )}
          {advancedFilterUsersSearchData ||
          advancedFilterInvoicesSearchData ? null : (
            <InputForm
              name="customer_email"
              id="email"
              label="Customer Email"
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
            />
          )}

          {advancedFilterUsersSearchData ||
          advancedFilterInvoicesSearchData ? null : pathname.includes(
              appUrls.outboundManagement.reports.baseUrl
            ) ? (
            <SelectInput
              name="status"
              id="status"
              label="Status"
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
              listItem={statusSelect}
              defaultValue={
                pathname ===
                appUrls.outboundManagement.reports.preShipment.absoluteUrl
                  ? "Pre-Shipment"
                  : pathname ===
                    appUrls.outboundManagement.reports.inTransit.absoluteUrl
                  ? "In Transit"
                  : null // Set defaultValue to undefined if none of the conditions match
              }
            />
          ) : (
            <MultiSelectInput
              name="status"
              label="Status"
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
              listItem={statusSelect}
            />
          )}

          {!advancedFilterInvoicesSearchData && (
            <InputForm
              name={`${
                advancedFilterUsersSearchData?.last_name?.name || "last_name"
              }`}
              id={`${
                advancedFilterUsersSearchData?.last_name?.name || "last_name"
              }`}
              label={`${
                advancedFilterUsersSearchData?.last_name?.label ||
                "Customer Last Name"
              }`}
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
            />
          )}

          {advancedFilterUsersSearchData && (
            <InputForm
              name={`${advancedFilterUsersSearchData?.phone_number?.name}`}
              id={`${advancedFilterUsersSearchData?.phone_number?.name}`}
              label={`${advancedFilterUsersSearchData?.phone_number?.label}`}
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
            />
          )}

          {advancedFilterUsersSearchData && (
            <InputForm
              name={`${advancedFilterUsersSearchData?.email?.name}`}
              id={`${advancedFilterUsersSearchData?.email?.name}`}
              type="email"
              label={`${advancedFilterUsersSearchData?.email?.label}`}
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
            />
          )}

          {/* uncomment this is you want this cols to appear on the advanced filter form */}
          {/* {advancedFilterUsersSearchData && (
            <InputForm
              name={`${advancedFilterUsersSearchData?.more_data?.name}`}
              id={`${advancedFilterUsersSearchData?.more_data?.name}`}
              label={`${advancedFilterUsersSearchData?.more_data?.label}`}
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
            />
          )}

          {advancedFilterUsersSearchData && (
            <InputForm
              name={`${advancedFilterUsersSearchData?.more_data_2?.name}`}
              id={`${advancedFilterUsersSearchData?.more_data_2?.name}`}
              label={`${advancedFilterUsersSearchData?.more_data_2?.label}`}
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
            />
          )} */}

          {advancedFilterUsersSearchData ||
          advancedFilterInvoicesSearchData ? null : (
            <>
              <DatePickerComp
                name="estimated_date"
                label="Estimated Delivery"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
              />
              <InputForm
                name="order_id"
                id="order_id"
                label="Order Number"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
              />
              <SelectInput
                name="carrier"
                label="Carriers"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
                listItem={carrierSelect}
              />
              <InputForm
                name="shipping_city"
                id="shipping_city"
                label="City"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
              />
              <InputForm
                name="shipping_state"
                id="shipping_state"
                label="State"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
              />
              <InputForm
                name="zip_code"
                id="zip_code"
                label="Zip Code"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
              />
              <SelectInput
                name="scanned_status"
                label="Scanned Status"
                sx={{}}
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
                listItem={scannedStatus}
              />
            </>
          )}

          {/* Inputs for the invoice page */}
          {advancedFilterInvoicesSearchData && (
            <>
              <DatePickerComp
                name={"purchase_date"}
                id={"purchase_date"}
                label={"Purchase Date"}
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
              />
              <InputForm
                name="total_price"
                id="total_price"
                label="Price"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
              />
              <SelectInput
                name="status"
                label="Status"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
                listItem={invoicesStatus}
              />
              <InputForm
                name="tax"
                id="tax"
                label="Tax"
                onChangeAdvancedForm={onChangeAdvancedForm}
                advancedFormData={advancedFormData}
              />
            </>
          )}

          <Box
            sx={{
              display: "flex",
              width: "100%",
              pt: { lg: 4, xs: 3, sm: 3, md: 3 },
              pr: { xs: 2, md: 0 },
              flexDirection: "row-reverse",
            }}
          >
            <Button
              // size="large"
              type="button"
              variant="contained"
              onClick={onSubmitAdvancedFilter}
              sx={{
                backgroundColor: "secondary.main",
                boxShadow: "none",
                height: { xs: 40, lg: 42 },
              }}
            >
              Continue
            </Button>
          </Box>
        </Grid>
      </Box>
    </Stack>
  );
}

AdvancedFilter.propTypes = {
  advancedFilterUsersSearchData: PropTypes.object,
  advancedFilterInvoicesSearchData: PropTypes.object,
  onChangeAdvancedForm: PropTypes.func,
  advancedFormData: PropTypes.object,
  statusSelect: PropTypes.array,
  carrierSelect: PropTypes.array,
  scannedStatus: PropTypes.array,
  invoicesStatus: PropTypes.array,
  firstScanEmailStatistics: PropTypes.array,
  onSubmitAdvancedFilter: PropTypes.func,
};
