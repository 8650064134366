import { TextField, Backdrop, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ResetPassword from "src/components/reset-password/ResetPassword";
import timer from "src/utils/timer";
import { forgotPassword } from "src/features/user/userApi";
import CircularLoader from "src/loader/circular/CircularLoader";
import "./resetPasswordPage.scss";

const ResetPasswordStep1Page = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendEmailErrorMessage, setSendEmailErrorMessage] = useState("");

  const onSubmit = async (data) => {
    // connects to the api that sends the email with the code to reset the password

    try {
      setIsLoading(true);
      const response = await forgotPassword(data);
      const { email } = data || {};
      await timer(1000);
      if (response?.statusCode === 200) {
        setIsLoading(false);
        setIsSubmited(true);
        navigate("/reset-password/step-2", { state: { email } });
      } else {
        setSendEmailErrorMessage("❌ There was an error sending the email");
        setIsLoading(false);
        setIsSubmited(false);
      }
    } catch (error) {
      setIsLoading(false);
      setSendEmailErrorMessage(
        "❌ You have exceeded the number of attempts or your email is not registered"
      );
    }
  };

  return (
    <>
      <Helmet>
        <title> Reset Password | BeyondCarts </title>
      </Helmet>

      {isLoading && <CircularLoader />}

      <ResetPassword
        title={"Forgot your password?"}
        description={
          "Please enter your email address below. You will receive a code to reset your password."
        }
        btnText={"Submit"}
        btnText2={"Back to log in"}
        handleSubmit={handleSubmit}
        setIsSubmited={setIsSubmited}
        onSubmit={onSubmit}
      >
        <TextField
          className="textField"
          label="Email address"
          name="email"
          autoComplete="email"
          autoFocus
          {...register("email", {
            required: "Email address is required.",
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              message: "Invalid email address.",
            },
          })}
          error={!!errors.email}
          aria-invalid={!!errors.email}
          helperText={
            errors.email
              ? errors.email.message
              : isSubmited
              ? "✅ Email was sent correctly."
              : sendEmailErrorMessage
          }
          sx={{
            marginTop: "2vh",
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: "primary.dark",
              },
              "&.Mui-focused fieldset": {
                borderColor: "primary.dark",
              },
            },
          }}
        />
      </ResetPassword>
    </>
  );
};

export default ResetPasswordStep1Page;
