import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Stack, Snackbar, Alert, Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { logoutUser } from "src/features/user/userState";
import SmartTable from "src/components/smart-table/SmartTable";
import { removeTitleCase, titleCase, unixToDate } from "src/utils/core";
import {
  incrementAwaitingApiCounter,
  decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { getUserInfo } from "src/features/user/userState";
import {
  getInvoices,
  getUpcomingPayments,
} from "src/features/billing/billingApi";
import CircularLoader from "src/loader/circular/CircularLoader";
import CardsComponent from "./cardsComponent/cardsComponent";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import "./billing.scss";
import planNames from "src/utils/planNames";
import { getPlansInfo } from "src/features/plans/plansState";
import moment from "moment-timezone";
import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";

const textStyle = {
  color: palette.common.black,
  fontSize: { xs: 16, md: 18 },
  marginTop: { xs: 1, md: 1.5 },
};

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    if (result?.data) return decryptData(result?.data);
  }
  return result;
};

const BillingInvoice = () => {
  const userInfo = useAppSelector(getUserInfo);
  const searchPlaceholder = "Enter invoice ID";

  const { pathname } = useLocation();
  const billingPathname = "/billing-and-invoices";
  const billingPathname2 = "/billing-and-invoices/";
  const navigate = useNavigate();

  const [invoicesList, setInvoicesList] = useState([]);
  const [totalItemsCount, setTotalItemCount] = useState(0);
  const [showExportInfo, setShowExportInfo] = useState(false);
  const defaultColumn = "purchase_date";
  const [searchKey, setSearchKey] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const dispatch = useAppDispatch();
  const [userSelectedColumns, setUserSelectedColumns] = useState([]);

  // current page of the table
  const [page, setPage] = useState(0);

  // number of table rows per page
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusList, setStatusList] = useState([]);
  const [statusAllData, setStatusAllData] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [advancedFormData, setAdvancedFormData] = useState({});
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [order, setOrder] = useState("");
  const [product, setProduct] = useState("");
  const [searchError, setSearchError] = useState(false);
  const sortDataString = `sort_data[${product}]`;

  const [upcomingPayments, setUpcomingPayments] = useState({});

  const plansInfo = useAppSelector(getPlansInfo);
  const isDemoPlan = userInfo?.plan_id === plansInfo[planNames.demo]?._id;

  const headers = {
    tenant_id: userInfo.tenant_id,
    user_id: userInfo.user_id,
    is_demo_user: isDemoPlan.toString(),
  };

  const trackingInfoRef = useRef({});

  const advancedFilterInvoicesSearchData = {
    purchase_date: { name: "purchase_date", label: "Purchase Date" },
  };

  const getCurrentPageParams = (current_page = 1) => {
    let tempFormData = { ...advancedFormData };
    let queryParams = {
      page_size: rowsPerPage,
      current_page,
      // [sortDataString]: order,
      ...tempFormData,
    };

    return queryParams;
  };

  const onCloseExportInfo = () => {
    setShowExportInfo(false);
  };

  // Function that fetches the invoices list list from the database
  const getInvoicesList = (parms) => {
    dispatch(incrementAwaitingApiCounter());
    setShowLoader(true);

    getInvoices(parms, headers, userInfo)
      .then((result) => {
        const dataFiltered = result.data.records?.map(
          ({
            _id,
            plan_name,
            tenant_id,
            payment_id,
            customer_id,
            plan_id,
            currency,
            purchase_date,
            status,
            createdAt,
            ...rest
          }) => {
            return {
              // invoice_id: payment_id,
              // customer_id: customer_id,
              purchase_date: moment
                .unix(purchase_date)
                .tz("America/Los_Angeles")
                .format("MMM DD, YYYY"),
              ...rest,
              status: titleCase(status),
              created_date: moment
                .unix(createdAt)
                .tz("America/Los_Angeles")
                .format("MMM DD, YYYY"),
            };
          }
        );

        setInvoicesList(dataFiltered);
        setTotalItemCount(result.data.total_count);

        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      })
      .catch((error) => {
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      });
  };

  const onSearchClick = () => {
    setSearchKey(null);
  };

  // Function that manage the pagination. The event parameter is needed for TablePagination API of MUI
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setExpandedRow(null);

    let paginationParams = {
      page_size: rowsPerPage,
      current_page: newPage + 1,
      // [sortDataString]: order,
    };
    // if (startDate && endDate) {
    // 	paginationParams.date_range = `${startDate}-${endDate}`;
    // }
    // if (first_scan) {
    // 	paginationParams.first_scan = first_scan;
    // } else if (statusToShow !== "All Orders") {
    // 	paginationParams.status = statusToShow;
    // }
    if (Object.keys(advancedFormData).length > 0) {
      Object.assign(paginationParams, advancedFormData);
    }
  };

  // Function that changes the number of rows per page
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (totalItemsCount > 1) {
      const queryParams = getCurrentPageParams();
      if (
        pathname === billingPathname ||
        pathname === billingPathname2 ||
        advancedFormData.hasOwnProperty("status")
      ) {
        if (advancedFormData.hasOwnProperty("status")) {
          getTrackingNumerList(
            {
              ...queryParams,
              ...advancedFormData,
              page_size: selected_page,
              current_page: 1,
              // [sortDataString]: order,
            },
            false
          );
        } else {
          getInvoicesList(
            {
              ...queryParams,
              page_size: newRowsPerPage,
              current_page: 1,
              // status: statusToShow,
              // [sortDataString]: order,
            },
            false
          );
          //setCurrentStatusData(statusToShow);
        }
      } else {
        // const queryParams = getCurrentPageParams();
        if (first_scan) {
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: selected_page,
              current_page: 1,
              first_scan: first_scan,
              // [sortDataString]: order,
            },
            false
          );
        } else if (statusToShow) {
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: selected_page,
              current_page: 1,
              status: statusToShow,
              // [sortDataString]: order,
            },
            false
          );
          setPage(0);
        }
      }
    }
  };

  // Search by default column
  const handleSearchBtn = async (e) => {
    e.preventDefault();
    // TODO: this code is commented because the billing id was deleted and therefore this code raised an error:

    // if (e.target.trackingNumber.value === "") setSearchError(true);
    // const searchedVal = e.target.trackingNumber.value.trim();
    // console.log("searchedVal", searchedVal);
    // setSearchKey(searchedVal);
    // setPage(0);
    // setRowsPerPage(10);
    // setTotalItemCount(0);
    // setAdvancedFormData({});
    // setShowAdvancedFilter(false);
    // if (pathname !== billingPathname || pathname !== billingPathname2) {
    //   navigate("/dashboard/billing");
    // }
    // // internal search
    // const filteredRows = invoicesList.filter((row) =>
    //   row[defaultColumn].toString().includes(searchedVal)
    // );
    // if (filteredRows.length > 0) {
    //   setInvoicesList(filteredRows);
    //   setTotalItemCount(filteredRows.length);
    // } else {
    //   // external search
    //   getInvoicesList({
    //     page_size: rowsPerPage,
    //     current_page: 1,
    //     tracking_no: searchedVal,
    //   });
    // }
  };

  // Function to manage what happens when the advanced filter btn is clicked
  const onClickAdvancedFilterBtn = () => {
    setSearchKey(null);
    setAdvancedFormData({});
    setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
    setPage(0);

    fetchData();
  };

  const onChangeAdvancedForm = (e = null, date = null) => {
    if (date == null) {
      const { name, value } = e.target;
      let tempData = { ...advancedFormData };
      if ((value == null || value === "") && tempData.hasOwnProperty(name)) {
        delete tempData[name];
      } else {
        tempData[name] = value;
      }
      setAdvancedFormData({ ...tempData });
    } else {
      setAdvancedFormData({
        ...advancedFormData,
        [e]: date !== "Invalid Date" ? date : null,
      });
    }
  };

  const onSubmitAdvancedFilter = () => {
    fetchData();
  };

  const fetchData = () => {
    setInvoicesList([]);
    let tempFormData = getCurrentPageParams()?.status
      ? {
          ...getCurrentPageParams(),
          status: getCurrentPageParams()?.status.toLowerCase(),
        }
      : getCurrentPageParams();

    if (pathname !== billingPathname || pathname !== billingPathname2) {
      navigate("/billing-and-invoices");
      getInvoicesList(tempFormData);
      setExpandedRow(null);
      setPage(0);
    } else {
      if (!advancedFormData) {
        getInvoicesList({ ...tempFormData });
      } else {
        getInvoicesList({ ...tempFormData });
      }
      setExpandedRow(null);
      setPage(0);
    }
  };

  // Function to handle the column display
  const handleColumnSubmit = (columnList) => {
    setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
  };

  const sortOrder = (columnName, sortOrderval) => {
    let correctColName = columnName;
    if (columnName === "invoice_id") correctColName = "payment_id";
    if (columnName === "created_date") correctColName = "createdAt";

    // this 2 are needed when backend makes the sort. Right now it does not. TODO: delete them
    // setOrder(sortOrderval);
    // setProduct(correctColName);
  };

  useEffect(() => {
    if (!showAdvancedFilter && (searchKey == "" || searchKey == null)) {
      dispatch(incrementAwaitingApiCounter());
      setShowLoader(true);

      setPage(0);

      let queryParams = getCurrentPageParams();
      if (pathname === billingPathname || pathname === billingPathname2) {
        getInvoicesList(
          {
            ...queryParams,
            page_size: rowsPerPage,
            current_page: 1,
            // [sortDataString]: order,
          },
          false
        );

        // fetches the upcoming payments
        getUpcomingPayments(headers, true)
          .then((upcomingPaymentsInfo) => {
            setUpcomingPayments(upcomingPaymentsInfo?.data || {});
            dispatch(decrementAwaitingApiCounter());
          })
          .catch((error) => {
            dispatch(decrementAwaitingApiCounter());
          });

        setShowLoader(false);
      }
    }
  }, [!advancedFormData, pathname]);

  const dataConverted = invoicesList;

  const formatPrice = (str) => {
    if (!str) return "0";
    return str?.toFixed(2);
  };

  const invoicesStatus = [
    {
      label: "Completed",
      value: "Completed",
    },
    {
      label: "Pending",
      value: "Pending",
    },
  ];

  return (
    <Box className="billing-invoices-container">
      <Helmet>
        <title> Billing | BeyondCarts </title>
      </Helmet>

      <CardsComponent is_demo_user={isDemoPlan} />

      <Box className="billing-invoices-payments-container">
        <h3 style={{ ...typography.h3, paddingBottom: 3 }}>Upcoming Payment</h3>

        <Box className="billing-invoices-upcoming-payments-container">
          <Typography
            sx={{
              ...textStyle,
              fontWeight: "bold",
              marginTop: 2,
              marginBottom: 1,
            }}
          >
            {upcomingPayments.plan_name} Details:
          </Typography>
          <Typography sx={textStyle}>
            Billing Cycle: {unixToDate(upcomingPayments?.billing_cycle?.start)}{" "}
            - {unixToDate(upcomingPayments?.billing_cycle?.end)}
          </Typography>
          <Typography sx={textStyle}>
            Onboarded Date: {unixToDate(upcomingPayments?.start_date)}
          </Typography>
          <Typography sx={textStyle}>
            Expiration Date: {unixToDate(upcomingPayments?.expiration_date)}
          </Typography>
          <Typography sx={textStyle}>
            Orders tracked: {upcomingPayments?.orders_tracked}
          </Typography>
          <Typography sx={textStyle}>
            Orders returned: {upcomingPayments?.orders_returned}
          </Typography>
          <Typography sx={textStyle}>
            Tracking price per order: ${" "}
            {parseFloat(upcomingPayments?.tracking_price).toFixed(2)}
          </Typography>
          <Typography sx={textStyle}>
            Return price per order: ${" "}
            {parseFloat(upcomingPayments?.return_price).toFixed(2)}
          </Typography>

          <Typography sx={{ ...textStyle, fontWeight: "bold" }}>
            Price: ${" "}
            {upcomingPayments?.plan_name == "Free Plan"
              ? "0"
              : parseFloat(upcomingPayments?.price).toFixed(2)}
          </Typography>
        </Box>
      </Box>

      <Snackbar
        open={showExportInfo}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={onCloseExportInfo}
      >
        <Alert
          onClose={onCloseExportInfo}
          severity="success"
          sx={{ width: "100%" }}
        >
          Export in progress
        </Alert>
      </Snackbar>

      <Box className="billing-invoices-payments-container">
        <h3 style={typography.h3}>Invoices</h3>

        <Box className="billing-invoices-table-container">
          <Stack>
            <SmartTable
              isAdvancedFilter
              isBillingPage
              placeholder={searchPlaceholder}
              advancedFilterInvoicesSearchData={
                advancedFilterInvoicesSearchData
              }
              isStatusMenu
              isAction
              originalRows={
                typeof dataConverted !== "undefined" ? dataConverted : [{}]
              }
              defaultColumn={defaultColumn}
              statusList={statusList}
              statusAllData={statusAllData}
              expandedRow={expandedRow}
              totelItemCount={totalItemsCount}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              onhandeSeachSubmit={handleSearchBtn}
              searchKey={searchKey}
              onSearchClick={onSearchClick}
              showLoader={showLoader}
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
              onSubmitAdvancedFilter={onSubmitAdvancedFilter}
              trackingInfoRef={trackingInfoRef}
              showAdvancedFilter={showAdvancedFilter}
              onClickAdvancedFilter={onClickAdvancedFilterBtn}
              // trackingPage={true}
              userSelectedColumns={userSelectedColumns}
              handleColumnSubmit={handleColumnSubmit}
              sortOrder={sortOrder}
              searchError={searchError}
              setSearchError={setSearchError}
              invoicesStatus={invoicesStatus}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default BillingInvoice;
