import {PostPortalLogin, sendVerifyEmailOTP} from "../PortalApi";
import {ErrorMessagesSchema} from "../../../utils/errorMessagesSchema";

const { Button, Typography, Box } = require("@mui/material")

const DummyReturn = () => {
    const handleOnClick = async () => {
        window.location.href = `/returns/relieffactor/portal?order_number=1035&email_id=karine.ruby@example.com`;
    };
    return <>
        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box>
                <Typography sx={{ fontSize: '20px', mb: 2 }}>Create return flow for logged user</Typography>
                <Button sx={{display: 'block', margin: 'auto'}} variant="contained" onClick={handleOnClick}>Create Return</Button>
            </Box>
        </Box>
    </>
}

export default DummyReturn