import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import typography from "src/theme/typography";
import appUrls from "src/utils/appUrls";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, IconButton, Stack } from "@mui/material";

export default function PageTitle() {
  const [text, setText] = useState("");
  const { pathname } = useLocation();
  const { tenant_name } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    switch (pathname) {
      case `/returns_support/${tenant_name}/create-return`:
        setText(
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <IconButton onClick={() => navigate("/delivery/tracking/")}>
              <ArrowBackIosIcon />
            </IconButton>
            <Box>Create Return</Box>
          </Stack>
        );
        break;
      case appUrls.outboundManagement.tracking.absoluteUrl:
        setText("Tracking");
        break;
      case appUrls.outboundManagement.carrierOriginScan.absoluteUrl:
        setText("Carrier Origin Scan");
        break;
      case appUrls.outboundManagement.reports.preShipment.absoluteUrl:
        setText("Preshipment Alert");
        break;
      case appUrls.outboundManagement.reports.inTransit.absoluteUrl:
        setText("In Transit Alert");
        break;
      case appUrls.outboundManagement.reports.averageTimeInTransit.absoluteUrl:
        setText("Average Time In Transit");
        break;

      // config management
      case appUrls.configurationManagement.deliveryTrackingConfiguration
        .absoluteUrl:

      case appUrls.configurationManagement.deliveryTrackingConfiguration
        .absoluteUrl + "signin-carrier":
      case appUrls.configurationManagement.deliveryTrackingConfiguration
        .absoluteUrl + "signin-carrier/":
        setText("Configuration");
        break;
      case appUrls.configurationManagement.deliveryTrackingConfiguration
        .absoluteUrl + "signin-manager":
      case appUrls.configurationManagement.deliveryTrackingConfiguration
        .absoluteUrl + "signin-manager/":
        setText("Configuration");
        break;
      case appUrls.configurationManagement.returnConfiguration.absoluteUrl:
        setText("Return Configuration");
        break;

      case appUrls.users:
        setText("Users");
        break;
      case appUrls.billingAndInvoices:
        setText("Billing and Invoices");
        break;
      case appUrls.notifications:
        setText("Notifications");
        break;

      // inbound
      case appUrls.inboundManagement.brandedReturnPortal.absoluteUrl:
        setText("Branded Tracking Portal");
        break;
      case appUrls.inboundManagement.brandedReturnsPortal.absoluteUrl:
        setText("Branded Returns Portal");
        break;
      case appUrls.inboundManagement.manageReturns.absoluteUrl:
        setText("Manage Returns");
        break;
      default:
        if (pathname.includes(appUrls.outboundManagement.tracking.relativeUrl))
          setText("Tracking");
        else setText("");
        break;
    }
  }, [pathname]);

  return <h2 style={{ ...typography.h2, lineHeight: "unset" }}>{text}</h2>;
}
