/*
  Use dots at the end
  
*/

export const ErrorMessagesSchema = {
  newPassword: {
    required: "New Password is required.",
  },
  confirmPassword: {
    required: "To confirm your new password is required.",
  },
  passwordValidation: {
    requirements:
      "Password should meet these requirements: minimum length of 8 characters, 1 capital letter, 1 lower-case letter, 1 number and 1 special character.",
    dontMatch: "Passwords don't match. Please try again.",
    attemptLimit: "Attempt limit exceeded. Try again later.",
  },
  otpCode: {
    invalid: "Invalid OTP. Please enter a valid OTP code.",
    wrong: "Incorrect verification code provided, please try again.",
    expired: "OTP has expired. Please request a new one.",
  },
  returns: {
    invalidCredentials: {
      invalidOrderId: "The Order Number is invalid. Please try again.",
      invalidEmailOrOrderId:
        "The Order Number and/or Email you entered did not match our records. Please check and try again.",
    },
  },
};
