import { Tooltip, styled, tooltipClasses } from "@mui/material";

const CustomTooltip = styled(({ className, bgColor, width, arrowColor, color, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))(({ bgColor, color, arrowColor, width, theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: bgColor || '#fff',
      color: color || 'black',
      padding: '8px',
      maxWidth: width || '250px',
      boxShadow: theme.shadows[1],
      '& .MuiTooltip-arrow': {
        color: arrowColor || '#fff',
    },
    },
  }));

export default CustomTooltip;