// hooks/useCustomerOrderInfo.js
import {useDispatch, useSelector} from 'react-redux';
import {
  getCustomerOrderInfo,
  getEligibleOrderItems,
  getEstimateReturnRequestSummary,
  resetBrandPortal,
  getEstimateReturnRefundSummary,
  updateReturnInfo,
  updateBrandPortalConfig,
  getReturnInformationById,
  updateTenantInfo,
  getBrandPortalConfig,
  getBrandProductImages,
  getTenantInfoData,
  updateTrackingPortalConfig
} from '../order/customerBrandPortalState';
import {loginSuccess, logout} from "../customerAuthReducer";


const useCustomerOrderInfo = () => {
  const dispatch = useDispatch();

  const {
    customerOrderInfo,
    returnRequestEstimate,
    eligibleLineItems,
    tenantInfo,
    returnInfo,
    returnEstimate,
    brandPortalConfig,
    trackingPortalConfig,
    isLoading,
    productImages,
    error
  } = useSelector((state) => state.customerBrandPortal);

  const customAuthInfo = useSelector((state) => state.customerAuthInfo);

  const fetchCustomerOrderInfo = (userInfo, params) => {
    dispatch(getCustomerOrderInfo({userInfo, params}));
  };
  const updateCustomerReturnInfo = (payload) => {
    dispatch(updateReturnInfo(payload));
  };
  const updateTenantInfoData = (payload) => {
    dispatch(updateTenantInfo(payload));
  };
  const updateBrandPortalInfo = (payload) => {
    dispatch(updateBrandPortalConfig(payload));
  };
  const updateTrackingPortalInfo = (payload) => {
    dispatch(updateTrackingPortalConfig(payload));
  };
  const fetchOrderEligibleLineItems = (userInfo,  payload={}) => {
    console.log('payload ', payload);
    dispatch(getEligibleOrderItems({userInfo, payload}));
  };
  const fetchEstimateReturnRequestSummary = (userInfo,  payload={}) => {
    console.log('payload ', payload);
    dispatch(getEstimateReturnRequestSummary({userInfo, payload}));
  };
  const fetchEstimateReturnSummary = (userInfo,  payload={}) => {
    console.log('payload ', payload);
    dispatch(getEstimateReturnRefundSummary({userInfo, payload}));
  };
  const fetchReturnInformationById = (userInfo,  payload={}) => {
    console.log('payload ', payload);
    dispatch(getReturnInformationById({userInfo, payload}));
  };
  const fetchBrandPortalConfig = (userInfo,  payload={}) => {
    console.log('payload ', payload);
    dispatch(getBrandPortalConfig({userInfo, payload}));
  };
  const customerLoginSuccess = (payload) => {
    dispatch(loginSuccess(payload));
  };
  const resetBrandPortalInfo = (payload) => {
    dispatch(resetBrandPortal(payload));
    dispatch(logout(payload));
  };
  const fetchProductImages = (userInfo,  payload={}) => {
    dispatch(getBrandProductImages({userInfo, payload}));
  };
  const fetchTenantInfo = (userInfo, payload={}) => {
    dispatch(getTenantInfoData({userInfo, payload}));
  };
  return {
    customerOrderInfo,
    isLoading,
    error,
    eligibleLineItems,
    returnRequestEstimate,
    returnInfo,
    customAuthInfo,
    returnEstimate,
    brandPortalConfig,
    productImages,
    tenantInfo,
    trackingPortalConfig,
    updateTenantInfoData,
    updateTrackingPortalInfo,
    fetchProductImages,
    updateBrandPortalInfo,
    updateCustomerReturnInfo,
    fetchOrderEligibleLineItems,
    fetchCustomerOrderInfo,
    fetchEstimateReturnRequestSummary,
    customerLoginSuccess,
    fetchEstimateReturnSummary,
    fetchReturnInformationById,
    fetchBrandPortalConfig,
    resetBrandPortalInfo,
    fetchTenantInfo
  };
};

export default useCustomerOrderInfo;
