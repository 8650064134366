import { Typography, Stack, Box } from "@mui/material";
import { useEffect, useState } from "react";
import FullRefundIcon from "./FullRefundIcon";
import NoRefundIcon from "./NoRefundIcon";
import { LoadingButton } from "@mui/lab";
import palette from "src/theme/palette";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { useDispatch } from "react-redux";
import { getUserInfo } from "src/features/user/userState";
import {
  getSpecificReturnConfig,
  updateSpecificReturnConfig,
} from "src/features/returnConfiguration/returnRulesApi";
import { useAppSelector } from "src/hooks/hooks";
import moment from "moment";
import { getUserNameById } from "src/utils/getUserNameById";
import typography from "src/theme/typography";

const ShippingRefund = () => {
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("full_refund");
  const [hasBeenSaved, setHasBeenSaved] = useState(false);
  const [lastUpdatedInfo, setLastUpdatedInfo] = useState("No updated yet");

  const handleSaveShippingRefund = async () => {
    dispatch(incrementAwaitingApiCounter());

    const body = {
      rule_name: "refund_configuration",
      value: selectedOption,
    };

    try {
      const response = await updateSpecificReturnConfig(userInfo, body);
      if (response.statusCode === 200) setHasBeenSaved(false);
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
    }

    setHasBeenSaved(false);
  };

  const handleSelection = (option) => {
    setSelectedOption(option);
    setHasBeenSaved(true);
  };

  useEffect(() => {
    const populateData = async () => {
      dispatch(incrementAwaitingApiCounter());

      try {
        const response = await getSpecificReturnConfig(
          userInfo,
          "refund_configuration"
        );
        if (response.statusCode === 200) {
          const data = response.data;

          if (Object.keys(data).length === 0) {
            setLastUpdatedInfo("No updated yet");
            dispatch(decrementAwaitingApiCounter());
            return;
          }

          setSelectedOption(data.value);

          const updatedAt = data.updated_at || data.created_at;
          const updatedById = data.updated_by || data.created_by;

          const updatedByName = await getUserNameById(userInfo, updatedById);

          const time = moment
            .unix(updatedAt)
            .tz("America/Los_Angeles")
            .format("MMM DD, YYYY");

          setLastUpdatedInfo(updatedByName + " on " + time);
        }
        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        dispatch(decrementAwaitingApiCounter());
      }
    };

    !hasBeenSaved && populateData();
  }, [hasBeenSaved]);

  return (
    <Stack
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Stack>
        <Typography textAlign={"right"} paddingTop={2} fontSize={14}>
          {lastUpdatedInfo !== "No updated yet"
            ? `Last modified by ${lastUpdatedInfo}`
            : ``}
        </Typography>
        <Stack direction={"row"} paddingLeft={2} paddingTop={1} gap={8}>
          <Stack width={"30%"}>
            <Box sx={{ display: "flex", gap: 1, mb: 1, alignItems: "center" }}>
              <Typography
                fontSize={18}
                fontFamily={typography.fontFamilySemiBold}
              >
                Refund policy
              </Typography>
            </Box>
            <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
              Configure if your business will provide a full refund or no refund
              for the shipping charges paid by the customer during the return
              initiation process.
            </Typography>
          </Stack>
          <Stack>
            <Stack direction={"row"} gap={2}>
              <Box
                sx={{
                  width: "170px",
                  height: "100px",
                  borderRadius: "12px",
                  backgroundColor:
                    selectedOption === "full_refund" ? "#002F43" : "white",
                  border: "1px solid",
                  borderColor:
                    selectedOption === "full_refund"
                      ? "transparent"
                      : "#AAAAAA",
                  cursor: "pointer",
                  position: " relative",
                }}
                onClick={() => handleSelection("full_refund")}
              >
                {selectedOption === "full_refund" ? (
                  <img
                    style={{ position: "absolute", top: "8px", right: "8px" }}
                    src="/assets/circleselect.svg"
                  />
                ) : (
                  ""
                )}
                <Box
                  sx={{
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FullRefundIcon
                    color={
                      selectedOption === "full_refund" ? "#fff" : "#002F43"
                    }
                  />
                  <Typography
                    sx={{
                      color:
                        selectedOption === "full_refund" ? "white" : "#002F43",
                      fontSize: "14px",
                      mt: 1,
                    }}
                  >
                    Full refund
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "170px",
                  height: "100px",
                  borderRadius: "12px",
                  backgroundColor:
                    selectedOption === "no_refund" ? "#002F43" : "white",
                  border: "1px solid",
                  borderColor:
                    selectedOption === "no_refund" ? "transparent" : "#AAAAAA",
                  cursor: "pointer",
                  position: "relative",
                }}
                // onClick={() => handleSelection("no_refund")}   // default requirement is   full-refund so am commenting no refund for no action
              >
                {selectedOption === "no_refund" ? (
                  <img
                    style={{ position: "absolute", top: "8px", right: "8px" }}
                    src="/assets/circleselect.svg"
                  />
                ) : (
                  ""
                )}
                <Box
                  sx={{
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NoRefundIcon
                    color={selectedOption === "no_refund" ? "#fff" : "#002F43"}
                  />
                  <Typography
                    sx={{
                      color:
                        selectedOption === "no_refund" ? "#fff" : "#002F43",
                      fontSize: "14px",
                      mt: 1,
                    }}
                  >
                    No refund
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Box flex={1}></Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "20px",
          boxShadow: "0px -8px 30px 0px #00000014",
          backgroundColor: "white",
          bottom: "-20px",
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          zIndex: "1",
        }}
      >
        <LoadingButton
          variant="contained"
          sx={{ width: "130px", backgroundColor: palette.secondary.main }}
          onClick={handleSaveShippingRefund}
          disabled={!hasBeenSaved}
        >
          Save
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default ShippingRefund;
