import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { getUserInfo, updateUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import { getTenant } from "src/features/usersPage/usersApi";
import ShippingStatisticsDataCard from "src/components/data-card/ShippingStatisticsDataCard";
import ShippingMetricsDataCard from "src/components/data-card/ShippingMetricsDataCard";
import EmailStatisticsDataCard from "src/components/data-card/EmailStatisticsDataCard";
import DateRangePicker from "src/components/date-picker/DateRangePicker";
import {
  fetchScanStatus,
  fetchShippingStatistic,
} from "src/features/scan/scanState";
import { useDispatch, useSelector } from "react-redux";
import typography from "src/theme/typography";
import ModalComponent from "src/components/modal/ModalComponent";
import isAdminUser from "src/utils/isAdminUser";
import { getPlansInfo } from "src/features/plans/plansState";
import planNames from "src/utils/planNames";
import OrdersPerCarrier from "src/components/data-card/OrdersPerCarrier";
import { setSigninData } from "src/features/signin/signin.action";
import appUrls from "src/utils/appUrls";

export default function DashboardAppPage() {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "day").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const userInfo = useAppSelector(getUserInfo);
  const signinData = useSelector((state) => state.signin.signinData);

  const [ordersData, setOrdersData] = useState([]);

  useEffect(() => {
    dispatch(
      setSigninData({
        carrier: userInfo.carrier,
      })
    );
  }, []);

  useEffect(() => {
    const initialState = { Date: "" };
    if (signinData?.carrier?.ups) {
      initialState["UPS"] = null;
      initialState["UPSCategories"] = {};
    }
    if (signinData?.carrier?.usps) {
      initialState["USPS"] = null;
      initialState["USPSCategories"] = {};
    }
    if (signinData?.carrier?.fedex) {
      initialState["FEDEX"] = null;
      initialState["FEDEXCategories"] = {};
    }
    if (signinData?.carrier?.dhl) {
      initialState["DHL"] = null;
      initialState["DHLCategories"] = {};
    }

    setOrdersData([initialState]);
  }, [signinData?.carrier]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const plansInfo = useAppSelector(getPlansInfo);
  const [userDetails, setUserDetails] = useState({});
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };

  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }

  const isPremiumExpired =
    userInfo?.is_plan_expired &&
    userInfo?.plan_id === plansInfo[planNames.premium]?._id;

  const isAdmin = isAdminUser(userInfo);

  const mainIcon = (name) => `/assets/icons/dashboardIcons/${name}.svg`;

  const metrics = useAppSelector((state) => state.scan.metrics);

  const scanStatistics = useAppSelector((state) => state.scan.scan_statistics);

  const statusStatistics = useAppSelector(
    (state) => state.scan.status_statistics
  );

  const carrierStatistics = useSelector((state) => state.scan.carriers);

  const shippingMetrics = [
    {
      icon: mainIcon("total_expected_deliveries"),
      title: "Expected Deliveries",
      tooltip:
        "Displays the list of packages that are scheduled to be delivered today.",
      total: metrics?.expected_delivery || 0,
      status: "Expected Deliveries",
    },
    {
      title: "Delayed",
      tooltip:
        "Indicates packages that are not going to arrive on the initially scheduled date due to unforeseen circumstances. This may include delays caused by weather, customs, or logistical issues.",
      total: metrics?.delayed || 0,
      icon: mainIcon("Delayed"),
      status: "Delayed",
    },
    {
      title: "Delivery Attempt",
      tooltip:
        "Shows instances where a delivery attempt was made but the package could not be successfully delivered. This may be due to the absence of the recipient or the inability to access the delivery location.",
      total: metrics?.delivery_attempt || 0,
      icon: mainIcon("DeliveryAttempt"),
      status: "Delivery Attempt",
    },
    {
      title: "Alerts",
      tooltip:
        "Provides notifications about important updates or actions required for your packages. Alerts may include issues requiring immediate attention such as delivery exceptions, customs holds, or return notices.",
      total: metrics?.alert || 0,
      icon: mainIcon("Alerts"),
      status: "Alert",
    },
    {
      title: "Returned",
      tooltip:
        "Lists packages that have been sent back to the sender. This typically occurs if the delivery fails multiple times, the address is incorrect, or the recipient rejects the package upon delivery.",
      total: metrics?.returned || 0,
      icon: mainIcon("Returned"),
      status: "Returned",
    },
  ];

  const emailStatistics = [
    {
      icon: mainIcon("TotalDB"),
      title: "Total Shipments",
      tooltip:
        "Displays the total number of shipments currently being tracked within the system.",
      total: scanStatistics?.db_count || 0,
      status: "Total Shipments",
    },
    {
      title: "Carrier Scan Successful",
      total: scanStatistics?.scanned_count || 0,
      tooltip:
        "Indicates that the package has been successfully scanned by the carrier, confirming its receipt and processing in the tracking system.",
      icon: mainIcon("ScannedSuccessfully"),
      subtitle: `${
        scanStatistics?.scanned_per
          ? scanStatistics.scanned_per === "00.00"
            ? "0.00"
            : scanStatistics.scanned_per
          : 0.0
      }%`,
      status: "Carrier Scan Successful",
    },
    {
      title: "No Carrier Scan",
      tooltip:
        "Shows packages that have not been scanned by the carrier, which may indicate a delay in processing or an entry error.",
      total: scanStatistics?.unscanned_count || 0,
      icon: mainIcon("NoScan"),
      subtitle: `${scanStatistics?.unscanned_per || 0.0}%`,
      status: "No Carrier Scan",
    },
    {
      title: "Carrier Scan with Estimated Delivery Date",
      tooltip:
        "Refers to packages that have been scanned and have an estimated delivery date based on the carrier’s current logistics and routing information.",
      total: scanStatistics?.estimated_date_count || 0,
      icon: mainIcon("scannedwithestimatedDeliveryDate"),
      status: "Carrier Scan with Estimated Delivery Date",
    },
    {
      title: "Carrier Scan without Estimated Delivery Date",
      tooltip:
        "Indicates packages that have been scanned by the carrier but do not yet have an estimated delivery date, possibly due to pending route assignments or other logistic uncertainties.",
      total: scanStatistics?.no_estimated_date_count || 0,
      icon: mainIcon("ScannedwithoutEstimatedDeliveryDate"),
      status: "Carrier Scan without Estimated Delivery Date",
    },
  ];

  const shippingStatistics = [
    {
      icon: mainIcon("alertStatistics"),
      status: "Alert",
      volume:
        statusStatistics?.find((status) => status.status === "Alert")?.volume ||
        0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "Alert")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("agentpickup"),
      status: "Available for Agent Pickup",
      volume:
        statusStatistics?.find(
          (status) => status.status === "Available for Agent Pickup"
        )?.volume || 0,
      percentage: `${
        statusStatistics?.find(
          (status) => status.status === "Available for Agent Pickup"
        )?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("avialableforpickup"),
      status: "Available for Pickup",
      volume:
        statusStatistics?.find(
          (status) => status.status === "Available for Pickup"
        )?.volume || 0,
      percentage: `${
        statusStatistics?.find(
          (status) => status.status === "Available for Pickup"
        )?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("delivered"),
      status: "Delivered",
      volume:
        statusStatistics?.find((status) => status.status === "Delivered")
          ?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "Delivered")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("deliveredtoagent"),
      status: "Delivered to Agent",
      volume:
        statusStatistics?.find(
          (status) => status.status === "Delivered to Agent"
        )?.volume || 0,
      percentage: `${
        statusStatistics?.find(
          (status) => status.status === "Delivered to Agent"
        )?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("deliveryattemptStatistics"),
      status: "Delivery Attempt",
      volume:
        statusStatistics?.find((status) => status.status === "Delivery Attempt")
          ?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "Delivery Attempt")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("intransit"),
      status: "In Transit",
      volume:
        statusStatistics?.find((status) => status.status === "In Transit")
          ?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "In Transit")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("outfordelivery"),
      status: "Out for Delivery",
      volume:
        statusStatistics?.find((status) => status.status === "Out for Delivery")
          ?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "Out for Delivery")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("preshipment"),
      status: "Pre-Shipment",
      volume:
        statusStatistics?.find((status) => status.status === "Pre-Shipment")
          ?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "Pre-Shipment")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("returnedStatistics"),
      status: "Returned",
      volume:
        statusStatistics?.find((status) => status.status === "Returned")
          ?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "Returned")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("forwarded"),
      status: "Forwarded",
      volume:
        statusStatistics?.find((status) => status.status === "Forwarded")
          ?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "Forwarded")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("unknown"),
      status: "Unknown",
      volume:
        statusStatistics?.find((status) => status.status === "Unknown")
          ?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === "Unknown")
          ?.percentage || "0.00"
      }%`,
    },
    {
      icon: mainIcon("totalpackages"),
      status: "Total Packages",
      volume:
        statusStatistics?.reduce((total, status) => total + status.volume, 0) ||
        0,
      percentage: "100%",
    },
  ];

  const onDateChange = (dates) => {
    if (dates != null) {
      const formattedStartDate = dayjs(dates[0]).format("MM/DD/YYYY");
      const formattedEndDate = dayjs(dates[1]).format("MM/DD/YYYY");
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);

      if (formattedStartDate !== "" && formattedEndDate !== "") {
        scanDetails(formattedStartDate, formattedEndDate);
      }
    }
  };

  const scanDetails = async (formattedStartDate, formattedEndDate) => {
    dispatch(
      fetchScanStatus({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        token: userInfo?.accessToken,
        headers,
      })
    );
    dispatch(
      fetchShippingStatistic({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        token: userInfo?.accessToken,
        headers,
      })
    );
  };

  const handleOnClick = (item) => {
    navigate(
      `${appUrls.outboundManagement.tracking.absoluteUrl}filter/${
        item.status
      }/${encodeURIComponent(startDate)}/${encodeURIComponent(endDate)}`
    );
  };

  const onFirstScanHandleOnClick = (item) => {
    navigate(
      `${appUrls.outboundManagement.tracking.absoluteUrl}scan_filter/${
        item.status
      }/${encodeURIComponent(startDate)}/${encodeURIComponent(endDate)}`
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleBtnModalClick = () => {
    setOpenModal(false);
    navigate("/billing-and-invoices", {
      state: { fromUpdateCardBtn: true },
    });
  };

  useEffect(() => {
    const updatedOrdersData = [];

    if (carrierStatistics) {
      if (Object.keys(carrierStatistics).length !== 0) {
        Object.keys(carrierStatistics).forEach((timestamp, index) => {
          let UPSData = {};
          let USPSData = {};
          let FEDEXData = {};
          let DHLData = {};

          if (carrierStatistics[timestamp]?.UPS) {
            UPSData = {
              UPS: carrierStatistics[timestamp].UPS.total_count || 0,
              UPSCategories: carrierStatistics[timestamp].UPS.categories,
            };
          }

          if (carrierStatistics[timestamp]?.USPS) {
            USPSData = {
              USPS: carrierStatistics[timestamp].USPS.total_count || 0,
              USPSCategories: carrierStatistics[timestamp].USPS.categories,
            };
          }

          if (carrierStatistics[timestamp]?.FEDEX) {
            FEDEXData = {
              FEDEX: carrierStatistics[timestamp].FEDEX.total_count || 0,
              FEDEXCategories: carrierStatistics[timestamp].FEDEX.categories,
            };
          }

          if (carrierStatistics[timestamp]?.DHL) {
            DHLData = {
              DHL: carrierStatistics[timestamp].DHL.total_count || 0,
              DHLCategories: carrierStatistics[timestamp].DHL.categories,
            };
          }

          updatedOrdersData.push({
            Date: timestamp,
            ...UPSData,
            ...USPSData,
            ...FEDEXData,
            ...DHLData,
          });
        });
        updatedOrdersData.sort((a, b) => {
          const dateA = new Date(a.Date);
          const dateB = new Date(b.Date);
          return dateA - dateB;
        });
        setOrdersData(updatedOrdersData);
      }
    } else {
      let carriers = Object.keys(userInfo.carrier || {});
      let userCarriers = carriers.reduce((acc, carrier) => {
        acc[carrier.toUpperCase()] = "";
        return acc;
      }, {});
      setOrdersData([{ Date: "", ...userCarriers }]);
    }
  }, [carrierStatistics]);

  useEffect(() => {
    if (isPremiumExpired) setOpenModal(true);
    getTenant(userInfo)
      .then((r) => {
        const { statusCode, data, statusMessage } = r;
        setUserDetails(data);
        // updates the user info when the plan expires
        if (data?.is_plan_expired !== userInfo?.is_plan_expired) {
          dispatch(updateUserInfo(data))
            .then(() => {})
            .catch((err) => console.log(err));
        }

        if (statusCode === 200 && statusMessage === "Tenant doesn't exist") {
          navigate("/onboarding/carrier");
          dispatch(
            setSigninData({
              user_id: data.user_id,
              name: data.first_name,
              lastName: data.last_name,
              email: data.email,
              phone: data.phone_number,
              company: data.company_name,
              shippingvolume: data.shipping_volume,
            })
          );
          return;
        }

        if (statusCode === 200) {
          const d = new Date(0);
          const d1 = new Date();

          d.setUTCSeconds(data.enrolled_at);
          d1.setDate(d1.getDate() - 7);

          // if (d > d1) setStartDate(d);
          // else setStartDate(d1);
          setIsLoaded(true);
        }
      })
      .catch((e) => console.log(e));

    dispatch(
      fetchScanStatus({
        startDate,
        endDate,
        token: userInfo?.accessToken,
        headers,
      })
    );

    dispatch(
      fetchShippingStatistic({
        startDate,
        endDate,
        token: userInfo?.accessToken,
        headers,
      })
    );
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    dispatch(
      fetchScanStatus({
        startDate,
        endDate,
        token: userInfo?.accessToken,
        headers,
      })
    );
    dispatch(
      fetchShippingStatistic({
        startDate,
        endDate,
        token: userInfo?.accessToken,
        headers,
      })
    );
  }, [startDate, endDate]);

  return (
    <>
      <Helmet>
        <title> Dashboard | BeyondCarts </title>
      </Helmet>

      <Stack gap={{ xs: 2, md: 2.5 }}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          paddingTop={{ xs: 2, sm: 3, lg: 4 }}
          paddingBottom={1}
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <h1
            style={{
              ...typography.h1,
              width: window.innerWidth < 600 ? "100%" : "auto",
              textAlign: window.innerWidth < 600 ? "center" : "left",
            }}
          >
            Welcome {userInfo?.first_name}!
          </h1>
          <DateRangePicker
            label="Label Creation Date"
            onDateChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
          />
        </Stack>

        <ShippingMetricsDataCard
          title="Shipping Metrics"
          cardItems={shippingMetrics}
          handleOnClick={handleOnClick}
        />

        <EmailStatisticsDataCard
          sx={{ flexWrap: "wrap" }}
          title="Carrier Origin Scan Statistics"
          cardItems={emailStatistics}
          onFirstScanHandleOnClick={onFirstScanHandleOnClick}
        />

        <OrdersPerCarrier title="Orders Per Carrier" rData={ordersData} />

        <ShippingStatisticsDataCard
          title="Shipping Statistics"
          cardItems={shippingStatistics}
          onClickRow={handleOnClick}
        />
      </Stack>

      {isPremiumExpired && (
        <ModalComponent
          title={"Account Frozen"}
          description={
            "We have encountered an issue with the payment of your subscription, and as a result, your account access is temporarily on hold."
          }
          description2={
            isAdmin
              ? "To continue enjoying all the features and benefits, please take a moment to complete your payment."
              : "To ensure uninterrupted access to our services, we kindly request that you contact your account administrator to complete the payment process."
          }
          btnText={isAdmin ? "Pay now" : "Ok"}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          handleBtnClick={isAdmin ? handleBtnModalClick : handleCloseModal}
        />
      )}
    </>
  );
}
