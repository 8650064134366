import Item from "./Item";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import appUrls from "src/utils/appUrls";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: "block",
    color: "#fff",
    backgroundColor: "#3A7DAE",
    padding: "6px 8px",
    borderRadius: "10px",
    fontSize: "14px",
  },
}));

const NavItems = ({
  open,
  data = [],
  openDeliveryManagementMenu,
  setOpenDeliveryManagementMenu,
  openReportsMenu,
  setOpenReportsMenu,
  openReturnsManagementMenu,
  setOpenReturnsManagementMenu,
  openDeliveryTooltip,
  setOpenDeliveryTooltip,
  openReturnsTooltip,
  setOpenReturnsTooltip,
  openConfigTooltip,
  setOpenConfigTooltip,
  openConfigManagementMenu,
  setOpenConfigManagementMenu,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // dropdown items
  const [deliveryAnchorEl, setDeliveryAnchorEl] = useState(null);
  const [reportsAnchorEl, setReportsAnchorEl] = useState(null);
  const [returnsAnchorEl, setReturnsAnchorEl] = useState(null);
  const [configAnchorEl, setConfigAnchorEl] = useState(null);

  const handleDeliveryClose = () => {
    setDeliveryAnchorEl(null);
    setOpenDeliveryManagementMenu(true);
  };

  const handleReportsClose = () => {
    setReportsAnchorEl(null);
    setOpenReportsMenu(true);
  };

  const handleReturnsClose = () => {
    setReturnsAnchorEl(null);
    setOpenReturnsManagementMenu(true);
  };

  const handleConfigClose = () => {
    setConfigAnchorEl(null);
    setOpenConfigManagementMenu(true);
  };

  const openDeliveryPopover = Boolean(deliveryAnchorEl);
  const openDeliveryId = openDeliveryPopover ? "simple-popover" : undefined;

  const openReportsPopover = Boolean(reportsAnchorEl);
  const openReportsId = openReportsPopover ? "simple-popover" : undefined;

  const openReturnsPopover = Boolean(returnsAnchorEl);
  const openReturnsId = openReturnsPopover ? "simple-popover" : undefined;

  const openConfigPopover = Boolean(configAnchorEl);
  const openConfigId = openConfigPopover ? "simple-popover" : undefined;

  const handleClickDeliveryMenu = (event) => {
    setOpenDeliveryManagementMenu(!openDeliveryManagementMenu);
    setOpenReportsMenu(false);
    setOpenReturnsManagementMenu(false);
    setOpenConfigManagementMenu(false);
    !open && setDeliveryAnchorEl(event.currentTarget);
    openDeliveryManagementMenu
      ? ""
      : navigate(appUrls.outboundManagement.dashboard.absoluteUrl);
  };

  const handleClickReportsMenu = (event) => {
    setOpenReportsMenu(!openReportsMenu);
    !open && setReportsAnchorEl(event.currentTarget);
    openReportsMenu
      ? ""
      : navigate(appUrls.outboundManagement.reports.preShipment.absoluteUrl);
  };

  const handleClickReturnsMenu = (event) => {
    setOpenReturnsManagementMenu(!openReturnsManagementMenu);
    setOpenDeliveryManagementMenu(false);
    setOpenConfigManagementMenu(false);
    !open && setReturnsAnchorEl(event.currentTarget);
    openReturnsManagementMenu
      ? ""
      : navigate(appUrls.inboundManagement.manageReturns.absoluteUrl);
  };

  const handleConfigClickMenu = (event) => {
    setOpenConfigManagementMenu(!openConfigManagementMenu);
    setOpenReturnsManagementMenu(false);
    setOpenDeliveryManagementMenu(false);
    !open && setConfigAnchorEl(event.currentTarget);
    openConfigManagementMenu
      ? ""
      : navigate(
          appUrls.configurationManagement.deliveryTrackingConfiguration
            .absoluteUrl
        );
  };

  // useEffect(() => {
  //   if (
  //     location.pathname ===
  //       appUrls.inboundManagement.returnConfiguration.absoluteUrl ||
  //     location.pathname === appUrls.configuration
  //   ) {
  //     setOpenConfigManagementMenu(true);
  //     setOpenReturnsManagementMenu(false);
  //   }
  // }, [location.pathname]);
  return (
    <List
      sx={{
        color: "#FFFFFF66",
        display: "flex",
        flexDirection: "column",
        gap: 0.7,
      }}
    >
      {data.map((item) => {
        if (item.path === appUrls.outboundManagement.baseUrl) {
          return (
            <>
              <CustomTooltip
                title={open ? "" : item.title}
                placement="right"
                open={openDeliveryPopover || openDeliveryTooltip}
                onOpen={() => setOpenDeliveryTooltip(true)}
                onClose={() => setOpenDeliveryTooltip(false)}
              >
                <ListItemButton
                  aria-describedby={openDeliveryId}
                  key={item.title}
                  component="li"
                  onClick={handleClickDeliveryMenu}
                  className={
                    location.pathname === item.path ||
                    location.pathname.includes(item.path) ||
                    openDeliveryManagementMenu
                      ? "active"
                      : ""
                  }
                  sx={{
                    marginX: 1,
                    borderRadius: "12px",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 1.5,
                    "&.active": {
                      color: "text.active",
                      bgcolor: "action.selected",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      width: 24,
                      height: 24,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {open && <ListItemText primary={item.title} />}
                  {open ? (
                    openDeliveryManagementMenu ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItemButton>
              </CustomTooltip>

              {/* contracted navbar */}
              <Popover
                id={openDeliveryId}
                open={openDeliveryPopover}
                anchorEl={deliveryAnchorEl}
                onClose={handleDeliveryClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  sx: {
                    color: "#fff",
                    backgroundColor: "#3A7DAE",
                    borderRadius: "10px",
                    border: "1px solid #3A7DAE",
                  },
                }}
              >
                <List component="ul" disablePadding>
                  {item.children?.map((child, index) => {
                    // submenu (li) with children
                    if (
                      child.path === appUrls.outboundManagement.reports.baseUrl
                    ) {
                      return (
                        <>
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={0.5}
                            marginLeft={2.5}
                            marginRight={2}
                            marginBottom={index === 3 ? 1 : 0}
                          >
                            <Box
                              sx={{
                                width: "17px",
                                height: "25px",
                                border: "solid 1.5px #fff",
                                borderColor:
                                  "transparent transparent #fff #fff",
                                borderRadius: "0 0 0px 30px",
                                transform: "translate(-0.3px, -14px)",
                              }}
                            />
                            <ListItemButton
                              aria-describedby={openReportsId}
                              component="li"
                              onClick={handleClickReportsMenu}
                              className={openReportsMenu ? "active" : ""}
                              sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 1.5,
                                borderRadius: "12px",
                                "&.active": {
                                  color: "text.active",
                                  bgcolor: "action.selected",
                                },
                              }}
                            >
                              <ListItemText primary={child.title} />
                              {openReportsMenu ? (
                                <ExpandLess
                                  sx={{
                                    rotate: open ? "initial" : "-90deg",
                                  }}
                                />
                              ) : (
                                <ExpandMore
                                  sx={{
                                    rotate: open ? "initial" : "-90deg",
                                  }}
                                />
                              )}
                            </ListItemButton>
                          </Stack>

                          <Popover
                            id={openReportsId}
                            open={openReportsPopover}
                            anchorEl={reportsAnchorEl}
                            onClose={handleReportsClose}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "right",
                            }}
                            PaperProps={{
                              sx: {
                                color: "#fff",
                                backgroundColor: "#3A7DAE",
                                borderRadius: "10px",
                                border: "1px solid #3A7DAE",
                              },
                            }}
                          >
                            <List component="ul" disablePadding>
                              {child.children?.map((grandChild, index) => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={0.5}
                                    marginLeft={2.5}
                                    marginRight={2}
                                    marginTop={index === 0 ? 1 : 0}
                                    marginBottom={index === 2 ? 1 : 0}
                                    borderLeft={
                                      index === 2 ? "none" : "1px solid #fff"
                                    }
                                  >
                                    <Box
                                      sx={{
                                        width: "17px",
                                        height: index === 2 ? "25px" : "17px",
                                        border: "solid 1.5px #fff",
                                        borderColor:
                                          "transparent transparent #fff #fff",
                                        borderRadius: "0 0 0px 30px",
                                        transform:
                                          index === 2
                                            ? "translate(-0.3px, -14px)"
                                            : "translate(-1px, -7px)",
                                      }}
                                    />
                                    <ListItemButton
                                      component="li"
                                      className={
                                        grandChild.path === location.pathname
                                          ? "active"
                                          : ""
                                      }
                                      sx={{
                                        pl: 1.5,
                                        borderRadius: "12px",
                                        "&.active": {
                                          color: "text.active",
                                        },
                                      }}
                                      onClick={() => {
                                        navigate(grandChild.path);
                                        handleReportsClose();
                                        handleDeliveryClose();
                                      }}
                                    >
                                      <ListItemText
                                        primary={grandChild.title}
                                      />
                                    </ListItemButton>
                                  </Stack>
                                );
                              })}
                            </List>
                          </Popover>
                        </>
                      );
                    }

                    // submenu (li) with no children
                    return (
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        marginLeft={2.5}
                        marginRight={2}
                        marginTop={index === 0 ? 1 : 0}
                        borderLeft={"1px solid #fff"}
                      >
                        <Box
                          sx={{
                            width: "17px",
                            height: "17px",
                            border: "solid 1.5px #fff",
                            borderColor: "transparent transparent #fff #fff",
                            borderRadius: "0 0 0px 30px",
                            transform: "translate(-1px, -7px)",
                          }}
                        />
                        <ListItemButton
                          component="li"
                          onClick={() => {
                            navigate(child.path);
                            handleDeliveryClose();
                          }}
                          key={child.title}
                          className={
                            child.path === location.pathname ? "active" : ""
                          }
                          sx={{
                            pl: 1.5,
                            borderRadius: "12px",
                            "&.active": {
                              color: "text.active",
                            },
                          }}
                        >
                          <ListItemText primary={child.title} />
                        </ListItemButton>
                      </Stack>
                    );
                  })}
                </List>
              </Popover>

              {/* expanded navbar */}
              {open && (
                <Collapse
                  in={openDeliveryManagementMenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="ul" disablePadding>
                    {item.children?.map((child, index) => {
                      if (
                        child.path ===
                        appUrls.outboundManagement.reports.baseUrl
                      ) {
                        return (
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={0.5}
                              marginLeft={4}
                              marginRight={2}
                            >
                              <Box
                                sx={{
                                  width: "17px",
                                  height: "25px",
                                  border: open && "solid 1.5px #fff",
                                  borderColor:
                                    "transparent transparent #fff #fff",
                                  borderRadius: "0 0 0px 30px",
                                  transform: "translate(-0.3px, -14px)",
                                }}
                              />
                              <ListItemButton
                                component="li"
                                disableRipple={open ? false : true}
                                onClick={
                                  open ? handleClickReportsMenu : () => {}
                                }
                                className={openReportsMenu ? "active" : ""}
                                sx={{
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  px: 1.5,
                                  borderRadius: "12px",
                                  ":hover": {
                                    cursor: !open && "unset",
                                    bgcolor: !open && "transparent",
                                  },
                                  "&.active": {
                                    color: "text.active",
                                    bgcolor: open && "action.selected",
                                  },
                                }}
                              >
                                <ListItemText
                                  primary={child.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                                {open ? (
                                  openReportsMenu ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )
                                ) : null}
                              </ListItemButton>
                            </Stack>
                            <Collapse
                              in={open && openReportsMenu}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="ul" disablePadding>
                                {child.children?.map((grandChild, index) => {
                                  return (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap={0.5}
                                      marginLeft={10}
                                      marginRight={3}
                                      marginTop={index === 0 ? 1 : 0}
                                      borderLeft={
                                        index === 2 || !open
                                          ? "none"
                                          : "1px solid #fff"
                                      }
                                    >
                                      <Box
                                        sx={{
                                          width: "17px",
                                          height: index === 2 ? "25px" : "17px",
                                          border: open && "solid 1.5px #fff",
                                          borderColor:
                                            "transparent transparent #fff #fff",
                                          borderRadius: "0 0 0px 30px",
                                          transform:
                                            index === 2
                                              ? "translate(-0.3px, -14px)"
                                              : "translate(-1px, -7px)",
                                        }}
                                      />
                                      <ListItemButton
                                        component="li"
                                        className={
                                          grandChild.path === location.pathname
                                            ? "active"
                                            : ""
                                        }
                                        sx={{
                                          pl: 1.5,
                                          borderRadius: "12px",
                                          "&.active": {
                                            color: "text.active",
                                          },
                                        }}
                                        onClick={() =>
                                          navigate(grandChild.path)
                                        }
                                      >
                                        <ListItemText
                                          primary={grandChild.title}
                                        />
                                      </ListItemButton>
                                    </Stack>
                                  );
                                })}
                              </List>
                            </Collapse>
                          </>
                        );
                      }

                      return (
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={0.5}
                          marginLeft={4}
                          marginRight={2}
                          marginTop={index === 0 ? 0.3 : 0}
                          borderLeft={open && "1px solid #fff"}
                        >
                          <Box
                            sx={{
                              width: "17px",
                              height: "17px",
                              border: open && "solid 1.5px #fff",
                              borderColor: "transparent transparent #fff #fff",
                              borderRadius: "0 0 0px 30px",
                              transform: "translate(-1px, -7px)",
                            }}
                          />
                          <ListItemButton
                            component="li"
                            disableRipple={open ? false : true}
                            onClick={
                              open
                                ? () => {
                                    navigate(child.path);
                                    setOpenReportsMenu(false);
                                  }
                                : () => {}
                            }
                            key={child.title}
                            className={
                              child.path === location.pathname ? "active" : ""
                            }
                            sx={{
                              pl: 1.5,
                              borderRadius: "12px",
                              ":hover": {
                                cursor: !open && "unset",
                                bgcolor: !open && "transparent",
                              },
                              "&.active": {
                                color: "text.active",
                              },
                            }}
                          >
                            <ListItemText
                              primary={child.title}
                              sx={{
                                opacity: open ? 1 : 0,
                              }}
                            />
                          </ListItemButton>
                        </Stack>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </>
          );
        } else if (item.path === appUrls.inboundManagement.baseUrl) {
          return (
            <>
              <CustomTooltip
                title={open ? "" : item.title}
                placement="right"
                open={openReturnsPopover || openReturnsTooltip}
                onOpen={() => setOpenReturnsTooltip(true)}
                onClose={() => setOpenReturnsTooltip(false)}
              >
                <ListItemButton
                  aria-describedby={openReturnsId}
                  component="li"
                  onClick={handleClickReturnsMenu}
                  className={
                    location.pathname === item.path ||
                    location.pathname.includes(item.path) ||
                    openReturnsManagementMenu
                      ? "active"
                      : ""
                  }
                  sx={{
                    marginX: 1,
                    borderRadius: "12px",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 1.5,
                    "&.active": {
                      color: "text.active",
                      bgcolor: "action.selected",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      width: 24,
                      height: 24,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {open && <ListItemText primary={item.title} />}
                  {open ? (
                    openReturnsManagementMenu ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItemButton>
              </CustomTooltip>

              {/* contracted navbar */}
              <Popover
                id={openReturnsId}
                open={openReturnsPopover}
                anchorEl={returnsAnchorEl}
                onClose={handleReturnsClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  sx: {
                    color: "#fff",
                    backgroundColor: "#3A7DAE",
                    borderRadius: "10px",
                    border: "1px solid #3A7DAE",
                  },
                }}
              >
                <List component="ul" disablePadding>
                  {item.children?.map((child, index) => {
                      const lastIndex = item.children.length - 1;
                    return (
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        marginLeft={2.5}
                        marginRight={2}
                        marginTop={index === 0 ? 1 : 0}
                        marginBottom={index === lastIndex ? 1 : 0}
                        borderLeft={index === lastIndex ? "none" : "1px solid #fff"}
                      >
                        <Box
                          sx={{
                            width: "17px",
                            height: index === lastIndex ? "30px" : "17px",
                            border: "solid 1.5px #fff",
                            borderColor: "transparent transparent #fff #fff",
                            borderRadius: "0 0 0px 30px",
                            transform:
                              index === lastIndex
                                ? "translate(-0.3px, -14px)"
                                : "translate(-1px, -7px)",
                          }}
                        />
                        <ListItemButton
                          component="li"
                          onClick={() => {
                            navigate(child.path);
                            handleReturnsClose();
                          }}
                          key={child.title}
                          className={
                            child.path === location.pathname ? "active" : ""
                          }
                          sx={{
                            pl: 1.5,
                            borderRadius: "12px",
                            "&.active": {
                              color: "text.active",
                            },
                          }}
                        >
                          <ListItemText primary={child.title} />
                        </ListItemButton>
                      </Stack>
                    );
                  })}
                </List>
              </Popover>

              {/* expanded navbar */}
              {open && (
                <Collapse
                  in={openReturnsManagementMenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="ul" disablePadding>
                    {item.children?.map((child, index) => {
                      const lastIndex = item.children.length - 1;
                      return (
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={0.5}
                          marginLeft={4}
                          marginRight={2}
                          marginTop={index === 0 ? 0.3 : 0}
                          marginBottom={index === lastIndex ? 1 : 0}
                          borderLeft={
                            index === lastIndex || !open ? "none" : "1px solid #fff"
                          }
                        >
                          <Box
                            sx={{
                              width: "17px",
                              height: index === lastIndex ? "30px" : "17px",
                              border: open && "solid 1.5px #fff",
                              borderColor: "transparent transparent #fff #fff",
                              borderRadius: "0 0 0px 30px",
                              transform:
                                index === lastIndex
                                  ? "translate(-0.3px, -14px)"
                                  : "translate(-1px, -7px)",
                            }}
                          />
                          <ListItemButton
                            component="li"
                            disableRipple={open ? false : true}
                            onClick={
                              open ? () => navigate(child.path) : () => {}
                            }
                            className={
                              child.path === location.pathname ? "active" : ""
                            }
                            sx={{
                              pl: 1.5,
                              borderRadius: "12px",
                              ":hover": {
                                cursor: !open && "unset",
                                bgcolor: !open && "transparent",
                              },
                              "&.active": {
                                color: "text.active",
                              },
                            }}
                          >
                            <ListItemText
                              primary={child.title}
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </Stack>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </>
          );
        } else if (item.path === appUrls.configurationManagement.baseUrl) {
          // const isPathActive =
          //   location.pathname === item.path ||
          //   location.pathname.includes(item.path);
          // const isConfigManagementActive =
          //   openConfigManagementMenu &&
          //   !(
          //     location.pathname ==
          //     appUrls.inboundManagement.returnConfiguration.absoluteUrl
          //   );
          return (
            <>
              <CustomTooltip
                title={open ? "" : item.title}
                placement="right"
                open={openConfigPopover || openConfigTooltip}
                onOpen={() => setOpenConfigTooltip(true)}
                onClose={() => setOpenConfigTooltip(false)}
              >
                <ListItemButton
                  aria-describedby={openConfigId}
                  component="li"
                  onClick={handleConfigClickMenu}
                  className={
                    location.pathname === item.path ||
                    location.pathname.includes(item.path) ||
                    openConfigManagementMenu
                      ? "active"
                      : ""
                  }
                  sx={{
                    marginX: 1,
                    borderRadius: "12px",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 1.5,
                    "&.active": {
                      color: "text.active",
                      bgcolor: "action.selected",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      width: 24,
                      height: 24,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {open && <ListItemText primary={item.title} />}
                  {open ? (
                    openConfigManagementMenu ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItemButton>
              </CustomTooltip>

              {/* contracted navbar */}
              <Popover
                id={openConfigId}
                open={openConfigPopover}
                anchorEl={configAnchorEl}
                onClose={handleConfigClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  sx: {
                    color: "#fff",
                    backgroundColor: "#3A7DAE",
                    borderRadius: "10px",
                    border: "1px solid #3A7DAE",
                  },
                }}
              >
                <List component="ul" disablePadding>
                  {item.children?.map((child, index) => {
                    return (
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        marginLeft={2.5}
                        marginRight={2}
                        marginTop={index === 0 ? 1 : 0}
                        marginBottom={index === 1 ? 1 : 0}
                        borderLeft={index === 1 ? "none" : "1px solid #fff"}
                      >
                        <Box
                          sx={{
                            width: "17px",
                            height: index === 1 ? "25px" : "17px",
                            border: "solid 1.5px #fff",
                            borderColor: "transparent transparent #fff #fff",
                            borderRadius: "0 0 0px 30px",
                            transform:
                              index === 1
                                ? "translate(-0.3px, -14px)"
                                : "translate(-1px, -7px)",
                          }}
                        />
                        <ListItemButton
                          component="li"
                          onClick={() => {
                            navigate(child.path);
                            handleConfigClose();
                          }}
                          key={child.title}
                          className={
                            child.path === location.pathname ? "active" : ""
                          }
                          sx={{
                            pl: 1.5,
                            borderRadius: "12px",
                            "&.active": {
                              color: "text.active",
                            },
                          }}
                        >
                          <ListItemText primary={child.title} />
                        </ListItemButton>
                      </Stack>
                    );
                  })}
                </List>
              </Popover>

              {/* expanded navbar */}
              {open && (
                <Collapse
                  in={openConfigManagementMenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="ul" disablePadding>
                    {item.children?.map((child, index) => {
                      return (
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={0.5}
                          marginLeft={4}
                          marginRight={2}
                          marginTop={index === 0 ? 0.3 : 0}
                          marginBottom={index === 1 ? 1 : 0}
                          borderLeft={
                            index === 1 || !open ? "none" : "1px solid #fff"
                          }
                        >
                          <Box
                            sx={{
                              width: "17px",
                              height: index === 1 ? "25px" : "17px",
                              border: open && "solid 1.5px #fff",
                              borderColor: "transparent transparent #fff #fff",
                              borderRadius: "0 0 0px 30px",
                              transform:
                                index === 1
                                  ? "translate(-0.3px, -14px)"
                                  : "translate(-1px, -7px)",
                            }}
                          />
                          <ListItemButton
                            component="li"
                            disableRipple={open ? false : true}
                            onClick={
                              open ? () => navigate(child.path) : () => {}
                            }
                            className={
                              child.path === location.pathname ? "active" : ""
                            }
                            sx={{
                              pl: 1.5,
                              borderRadius: "12px",
                              ":hover": {
                                cursor: !open && "unset",
                                bgcolor: !open && "transparent",
                              },
                              "&.active": {
                                color: "text.active",
                              },
                            }}
                          >
                            <ListItemText
                              primary={child.title}
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </Stack>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </>
          );
        }

        return (
          <Item
            key={item.title}
            item={item}
            open={open}
            setOpenDeliveryManagementMenu={setOpenDeliveryManagementMenu}
            setOpenReturnsManagementMenu={setOpenReturnsManagementMenu}
            setOpenConfigManagementMenu={setOpenConfigManagementMenu}
          />
        );
      })}
    </List>
  );
};
export default NavItems;
