import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  styled,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useEffect, useState } from "react";
import { footerUpload } from "src/features/brandedPortal/brandedPortal";
import { useDispatch, useSelector } from "react-redux";
import { ChromePicker } from "react-color";

const BrandFooter = ({data, errors, setErrors, validateUrl, validateColor}) => {
  const { privacy_url, terms_url, text_color, background_color, facebook, instagram } = data.brandedFooterState;
  const [linkURL, setLinkURL] = useState(privacy_url);
  const [termsURL, setTermsURL] = useState(terms_url);
  const [txtColor, setTextColor] = useState(text_color || '');
  const [textColorPicker, setTextColorPicker] = useState(false);
  const [bgColor, setBgColor] = useState(background_color || '');
  const [bgColorPicker, setBgColorPicker] = useState(false);
  const [fbURL, setFbURL] = useState(facebook);
  const [instaURL, setInstaURL] = useState(instagram);

  const handleURLChange = (e, name, state) => {
    const { value } = e.target;
    state(value);
    validateUrl(name, value);
  };
  
  const handleColorChange = (e, name, state) => {
    const { value } = e.target;
    if (value.length <= 7 && (value === '' || /^#[a-zA-Z0-9]*$/.test(value))) {
      state(value);
      validateColor(name, value);
    }
  };
  const dispatch = useDispatch();

  const handleTextColor = () => {
    setTextColorPicker(!textColorPicker);
  };

  const handleTextColorChange = (color) => {
    setTextColor(color.hex);
    setErrors({...errors, textcolor: null})
  };

  const handleBgColor = () => {
    setBgColorPicker(!bgColorPicker);
  };

  const handleBgColorChange = (color) => {
    setBgColor(color.hex);
    setErrors({...errors, bgcolor: null})

  };

  const previewData = {
    privacy_url: linkURL,
    terms_url: termsURL,
    text_color: txtColor,
    background_color: bgColor,
    facebook: fbURL,
    instagram: instaURL,
  }
  useEffect(()=>{
    dispatch(
      footerUpload(previewData)
    );
  },[linkURL, termsURL, txtColor, bgColor, fbURL, instaURL])

  const CustomOutlinedInput = styled(OutlinedInput)(() => ({
    color: "#000",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#80808052",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#808080",
    },
  }));

  return (
    <>
      <Grid container spacing={2} sx={{pb:3, borderBottom: '2px solid #E9E9E9'}}>
        <Grid item xs={3}>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}>Company terms & policy</Typography>
          <Typography sx={{ fontSize: '14px', color: '#AAAAAA' }}>Specify or customize the URLs for your privacy policy and terms of service.</Typography>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap:3 }}>
            <Box sx={{position: 'relative'}}>
              <Typography sx={{ color: "#000", mb:1}}>
                Privacy URL
              </Typography>
              <OutlinedInput
                size='small'
                value={linkURL}
                sx={{ color: "#000", width: '300px' }}
                onChange={(e) => handleURLChange(e, 'privacyurl', setLinkURL)}
                id="outlined-adornment-weight"
                placeholder="www.yoursite.com/privacy"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{ "aria-label": "weight" }}
                error={errors?.privacyurl}
              />
                {errors?.privacyurl && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-20px'}}>{errors?.privacyurl}</Typography>}
            </Box>
            <Box sx={{position: 'relative'}}>
              <Typography sx={{ color: "#000", mb:1 }}>
                Terms URL
              </Typography>
              <OutlinedInput
                size="small"
                value={termsURL}
                sx={{ color: "#000", width: '300px' }}
                onChange={(e) => handleURLChange(e, 'termsurl', setTermsURL)}
                id="outlined-adornment-weight"
                placeholder="www.yoursite.com/terms"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{ "aria-label": "weight" }}
                error={errors?.termsurl}
              />
                {errors?.termsurl && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-20px'}}>{errors?.termsurl}</Typography>}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ py: 3, borderBottom: '2px solid #E9E9E9' }}>
        <Grid item xs={3}>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}>Color theme</Typography>
          <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Customize the footer background and text colors to match your style.</Typography>
        </Grid>
        <Grid item xs={9} sx={{ margin: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box>
              <Typography sx={{ color: "#000" }}>
                Background color
              </Typography>
              <Box sx={{ position: "relative" }}>
                <OutlinedInput
                  size='small'
                  value={bgColor}
                  onClick={handleBgColor}
                  placeholder="#000000"
                  onChange={(e) => handleColorChange(e, 'bgcolor', setBgColor)}
                  sx={{ my: 1, width: "100%", color: "#000" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <Box sx={{ width: '20px', height: '20px', borderRadius: '5px', backgroundColor: `${bgColor ? bgColor : 'black'}` }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors?.bgcolor}
                />
                {errors?.bgcolor && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-15px'}}>{errors?.bgcolor}</Typography>}
              </Box>
              {bgColorPicker && (
                <Box style={{ position: "absolute", zIndex: 2 }}>
                  <Box
                    style={{
                      position: "fixed",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                    onClick={() => setBgColorPicker(false)}
                  />
                  <ChromePicker color={bgColor} onChange={handleBgColorChange} />
                </Box>
              )}
            </Box>
            <Box>
              <Typography sx={{ color: "#000" }}>
                Text color
              </Typography>
              <Box sx={{ position: "relative" }}>
                <OutlinedInput
                  size='small'
                  value={txtColor}
                  onClick={handleTextColor}
                  placeholder="#000000"
                  onChange={(e) => handleColorChange(e, 'textcolor', setTextColor)}
                  sx={{ my: 1, width: "100%", color: "#000" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <Box sx={{ width: '20px', height: '20px', borderRadius: '5px', backgroundColor: `${txtColor ? txtColor : 'black'}` }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors?.textcolor}
                />
                {errors?.textcolor && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-15px'}}>{errors?.textcolor}</Typography>}
              </Box>
              {textColorPicker && (
                <Box style={{ position: "absolute", zIndex: 2 }}>
                  <Box
                    style={{
                      position: "fixed",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                    onClick={() => setTextColorPicker(false)}
                  />
                  <ChromePicker color={txtColor} onChange={handleTextColorChange} />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{py:3}} spacing={2}>
        <Grid item xs={3}>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}>Social media</Typography>
          <Typography sx={{ fontSize: '14px', color: '#AAAAAA' }}>Add and customize the URLs for your social media profiles.</Typography>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap:3 }}>
            <Box sx={{position: 'relative'}}>
              <Typography sx={{ color: "#000", mb:1}}>
                Facebook
              </Typography>
              <OutlinedInput
                size='small'
                value={fbURL}
                sx={{ color: "#000", width: '300px' }}
                onChange={(e) => handleURLChange(e, 'fburl', setFbURL)}
                id="outlined-adornment-weight"
                placeholder="www.facebook.com/yourcompany"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{ "aria-label": "weight" }}
                error={errors?.fburl}
              />
                {errors?.fburl && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-20px'}}>{errors?.fburl}</Typography>}
            </Box>
            <Box sx={{position: 'relative'}}>
              <Typography sx={{ color: "#000", mb:1 }}>
                Instagram
              </Typography>
              <OutlinedInput
                size="small"
                value={instaURL}
                sx={{ color: "#000", width: '300px' }}
                id="outlined-adornment-weight"
                onChange={(e) => handleURLChange(e, 'instaurl', setInstaURL)}
                placeholder="www.instagram.com/yourcompany"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{ "aria-label": "weight" }}
                error={errors?.instaurl}
              />
                {errors?.instaurl && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-20px'}}>{errors?.instaurl}</Typography>}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BrandFooter;
