import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { titleCase, removeTitleCase } from "src/utils/core";
import { useState } from "react";
import typography from "src/theme/typography";

export default function MultipleSelect({
  columnsDetails,
  selectedColumns,
  handleColumnSubmit,
  isUsersPage = false,
}) {
  const filteredColumnDetails = isUsersPage
    ? columnsDetails.filter((item) => item !== "id")
    : columnsDetails;

  const [tempSelectedColumns, setTempSelectedColumns] = useState(
    selectedColumns.map((item) => {
      return titleCase(item);
    })
  );

  const handleColumnChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedColumnTitles =
      typeof value === "string"
        ? value?.split(",")
        : value.map((item) => titleCase(item));
    const sortedColumns = selectedColumnTitles.sort((a, b) => {
      return b.localeCompare(a);
    });
    setTempSelectedColumns(sortedColumns);
  };

  return (
    <div style={{ display: "flex", gap: "5px" }}>
<FormControl
  sx={{
    width: { lg: 195, md: 150, sm: 120, xs: 120 },
    bgcolor: "common.white",
    borderRadius: 1,
    "& .MuiInputBase-formControl": {
      paddingLeft: 0,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "primary.dark",
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.dark",
      },
    },
  }}
  size="small"
>
  <Select
    displayEmpty
    id="demo-multiple-checkbox"
    multiple
    value={tempSelectedColumns.map((item) => titleCase(item))}
    onChange={handleColumnChange}
    renderValue={(selected) => {
      if (selected.length === 0) {
        return <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Columns</span>;
      }
      return selected.join(", ");
    }}
    inputProps={{ "aria-label": "Without label" }}
    sx={{
      color: "common.black",
      paddingLeft: 2,
      height: { lg: 40, xs: 36 },
      "& .MuiSelect-root": {
        color: tempSelectedColumns.length === 0 ? 'rgba(0, 0, 0, 0.54)' : 'common.black',
      },
    }}
  >
    {filteredColumnDetails?.length > 0 ? (
      filteredColumnDetails.map((item) => (
        <MenuItem
          sx={{
            color: "common.black",
            fontFamily: typography.fontFamilyRegular,
          }}
          key={item}
          value={titleCase(item)}
        >
          <Checkbox
            checked={tempSelectedColumns.indexOf(titleCase(item)) > -1}
          />
          <ListItemText primary={titleCase(item).replace("Per", "%")} />
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled>
        <ListItemText primary="No data available" />
      </MenuItem>
    )}
  </Select>
</FormControl>

      <Button
        type="button"
        variant="contained"
        onClick={() => handleColumnSubmit(tempSelectedColumns)}
        sx={{
          height: { xs: 36, lg: 40 },
          width: { md: 110, sm: 90, xs: 90, md: 100 },
          backgroundColor: "secondary.main",
          boxShadow: "none",
        }}
      >
        Apply
      </Button>
    </div>
  );
}

MultipleSelect.propTypes = {
  columnsDetails: PropTypes.array,
  selectedColumns: PropTypes.array,
  handleColumnSubmit: PropTypes.func,
};
