import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import typography from "src/theme/typography";
import { setSigninData } from "src/features/signin/signin.action";
import {
  styleInput,
  months,
  years,
  cc_format,
  creditCardType,
} from "./cardsUtils";
import palette from "src/theme/palette";
import CountrySelect from "./countrySelect";
import CircularLoader from "src/loader/circular/CircularLoader";
import "./billing.scss";

const Billing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signinData = useSelector((state) => state.signin.signinData);
  const {
    creditCard,
    creditCardError,
    cvv,
    cvvError,
    expirationMonth,
    expirationYear,
    expirationError,
    fullName,
    fullNameError,
    billingAddr,
    billingAddrError,
    city,
    cityError,
    zipcode,
    zipcodeError,
    state,
    stateError,
    plan,
  } = signinData || {};

  const [card, setCard] = useState("");
  const [creditLogo, setCreditLogo] = useState();

  const handleCardChange = (e) => {
    const valueParsed = e.target.value.replaceAll(" ", "");
    const valueInt = parseInt(valueParsed) || "";
    const value = valueInt.toString();
    if (value.length > 16) return;

    setCard(cc_format(value));
    creditCardType(value, setCreditLogo);
    dispatch(
      setSigninData({ ...signinData, creditCard: value, cardError: "" })
    );
  };

  const handleCvvChange = (e) => {
    const value = e.target.value;
    if (value.length > 3) return;
    if (
      value
        .split("")
        .filter((el) =>
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(el)
        ).length === value.length
    )
      dispatch(setSigninData({ ...signinData, cvv: value }));
    else if (value.includes("-")) return;
    else return;
  };

  useEffect(() => {
    if (!signinData?.user_id) {
      navigate("/login");
      return;
    }

    setCard(cc_format(creditCard));
    creditCardType(creditCard, setCreditLogo);
  }, []);

  return (
    <Box className="billing-container">
      {signinData.isOnboardingPaymentCardLoad && <CircularLoader />}
      <h1 style={{ ...typography.h1, textAlign: "center" }}>
        Billing Information
      </h1>

      <Typography
        sx={{
          paddingTop: 1,
          color: palette.common.black,
          fontSize: 20,
          textAlign: "center",
        }}
      >
        Choose a payment method below
      </Typography>

      {plan?.name === "Free Plan" && (
        <Typography
          sx={{
            paddingTop: 1.5,
            color: palette.grey[700],
            fontSize: { xs: 14, md: 16 },
            textAlign: "center",
            width: { xs: "70%", md: 600 },
            margin: { sm: "auto" },
          }}
        >
          Since you have selected a free plan, no charges will be made to your
          credit card until you decide to upgrade to a premium plan or the free
          plan has expired.
        </Typography>
      )}

      <Box className="billing-card-container">
        <img src="/assets/card.svg" alt="main-logo" />
        <Typography
          sx={{
            color: palette.common.black,
            fontSize: 18,
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Credit Card
        </Typography>
      </Box>

      <Box className="billing-inps-container">
        <TextField
          sx={styleInput}
          label="Credit Card *"
          autoComplete="off"
          value={card}
          fullWidth
          error={creditCardError}
          helperText={creditCardError && "Credit Card is required."}
          onFocus={() =>
            dispatch(
              setSigninData({
                ...signinData,
                creditCardError: false,
              })
            )
          }
          onChange={handleCardChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <img
                    style={{
                      height: "25px",
                      cursor: "default",
                      marginRight: "20px",
                    }}
                    src={creditLogo}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box className="billing-inp-container">
          <TextField
            sx={styleInput}
            type="string"
            label="CVV *"
            autoComplete="off"
            value={cvv}
            error={cvvError}
            helperText={cvvError && "Valid CVV is required."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  cvvError: false,
                })
              )
            }
            onChange={handleCvvChange}
          />

          <FormControl fullWidth>
            <InputLabel id="credit-month-select-label">Month *</InputLabel>
            <Select
              labelId="credit-month-select-label"
              label="Month"
              defaultValue=""
              value={expirationMonth}
              onChange={(e) =>
                dispatch(
                  setSigninData({
                    ...signinData,
                    expirationMonth: e.target.value,
                  })
                )
              }
              sx={{ ...styleInput, color: palette.common.black }}
              error={expirationError}
              onFocus={() =>
                dispatch(
                  setSigninData({
                    ...signinData,
                    expirationError: false,
                  })
                )
              }
            >
              {months.map((m, index) => (
                <MenuItem
                  value={index + 1}
                  sx={{ color: palette.common.black }}
                >
                  {m}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={expirationError}>
              {expirationError && "Month is required."}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="credit-year-select-label">Year *</InputLabel>
            <Select
              labelId="credit-year-select-label"
              label="Year"
              defaultValue=""
              value={expirationYear}
              onChange={(e) =>
                dispatch(
                  setSigninData({
                    ...signinData,
                    expirationYear: e.target.value,
                  })
                )
              }
              sx={{ ...styleInput, color: palette.common.black }}
              error={expirationError}
              onFocus={() =>
                dispatch(
                  setSigninData({
                    ...signinData,
                    expirationError: false,
                  })
                )
              }
            >
              {years.map((m) => (
                <MenuItem value={m} sx={{ color: palette.common.black }}>
                  {m}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={expirationError}>
              {expirationError && "Year is required."}
            </FormHelperText>
          </FormControl>
        </Box>

        <TextField
          sx={styleInput}
          fullWidth
          label="Full Name *"
          error={fullNameError}
          helperText={fullNameError && "Full Name is required."}
          onFocus={() =>
            dispatch(
              setSigninData({
                ...signinData,
                fullNameError: false,
              })
            )
          }
          value={fullName}
          onChange={(e) => {
            if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
            dispatch(
              setSigninData({
                ...signinData,
                fullName: e.target.value,
              })
            );
          }}
          inputProps={{
            maxLength: 50,
          }}
        />

        <TextField
          sx={styleInput}
          label="Billing Address *"
          fullWidth
          error={billingAddrError}
          helperText={billingAddrError && "Billing Address is required."}
          onFocus={() =>
            dispatch(
              setSigninData({
                ...signinData,
                billingAddrError: false,
              })
            )
          }
          value={billingAddr}
          onChange={(e) => {
            if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
            dispatch(
              setSigninData({
                ...signinData,
                billingAddr: e.target.value,
              })
            );
          }}
          inputProps={{
            maxLength: 50,
          }}
        />

        <Box className="billing-inp-container">
          <TextField
            sx={styleInput}
            label="City *"
            error={cityError}
            helperText={cityError && "City is required."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  cityError: false,
                })
              )
            }
            value={city}
            onChange={(e) => {
              if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
              dispatch(
                setSigninData({
                  ...signinData,
                  city: e.target.value,
                })
              );
            }}
            inputProps={{
              maxLength: 50,
            }}
          />

          <TextField
            sx={styleInput}
            label="Zipcode *"
            error={zipcodeError}
            helperText={zipcodeError && "Zipcode is required."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  zipcodeError: false,
                })
              )
            }
            value={zipcode}
            onChange={(e) => {
              if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
              dispatch(
                setSigninData({
                  ...signinData,
                  zipcode: e.target.value,
                })
              );
            }}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Box>

        <TextField
          sx={styleInput}
          label="State *"
          fullWidth
          error={stateError}
          helperText={stateError && "State is required."}
          onFocus={() =>
            dispatch(
              setSigninData({
                ...signinData,
                stateError: false,
              })
            )
          }
          value={state}
          onChange={(e) => {
            if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
            dispatch(
              setSigninData({
                ...signinData,
                state: e.target.value,
              })
            );
          }}
          inputProps={{
            maxLength: 50,
          }}
        />

        <CountrySelect sx={styleInput} />

        {signinData.cardError && (
          <p
            style={{
              color: "red",
              position: "absolute",
              width: "inherit",
              textAlign: "center",
            }}
          >
            {signinData.cardError.includes("declined")
              ? "Your card was declined."
              : signinData.cardError}
          </p>
        )}
      </Box>
    </Box>
  );
};

export default Billing;
