import { Stack, AppBar, Toolbar, Container, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";

import AccountPopover from "./AccountPopover";
import Searchbar from "./Searchbar";
import PageTitle from "./PageTitle";
import CompanyNameAndLogo from "./CompanyNameAndLogo";
import NotificationsPopover from "./NotificationsPopover/NotificationsPopover";
import appUrls from "src/utils/appUrls";

const HEADER_MOBILE = 64;

const StyledRoot = styled(Box)(({ theme }) => ({
  boxShadow: "none",
  background: "none",
  position: "relative",
  width: "100%",
  zIndex: 1,
  // paddingTop: theme.spacing(4),
  // paddingBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    paddingBottom: theme.spacing(1.7),
  },
  paddingRight: "0px !important",
}));

const StyledToolbar = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  // minHeight: HEADER_MOBILE,
  [theme.breakpoints.down("sm")]: {
    alignItems: "flex-start",
  },
}));

const StyledMobileToolbar = styled(Toolbar)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  "&.MuiToolbar-root": {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function Header() {
  const { pathname } = useLocation();
  const isDashboard =
    pathname === appUrls.outboundManagement.baseUrl ||
    pathname === appUrls.outboundManagement.baseUrl + "/";

  return (
    <StyledRoot>
      {window.innerWidth > 600 ? (
        <StyledToolbar>
          {isDashboard && <Searchbar />}
          {!isDashboard && <PageTitle />}
          <CompanyNameAndLogo display={true} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <NotificationsPopover />
            <AccountPopover />
          </Stack>
        </StyledToolbar>
      ) : (
        <StyledMobileToolbar>
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            {isDashboard && <Searchbar />}
            {!isDashboard && <PageTitle />}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={{
                xs: 1,
              }}
            >
              <NotificationsPopover />
              <AccountPopover />
            </Stack>
          </Container>
          <CompanyNameAndLogo display={true} />
        </StyledMobileToolbar>
      )}
    </StyledRoot>
  );
}
