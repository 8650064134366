import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, Stack, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import CircularLoader from "src/loader/circular/CircularLoader";
import {PostPortalLogin} from "../PortalApi";
import ReturnsLayout from "./ReturnsLayout";
import {useSelector} from 'react-redux';
import {ErrorMessagesSchema} from "src/utils/errorMessagesSchema";
import useCustomerOrderInfo from "src/features/return_management/hooks/useCustomerOrderInfo";
import {resetBrandPortal} from "../../../features/return_management/order/customerBrandPortalState";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ReturnsLoggedUser = () => {
  const query = useQuery();
  const order_number_param = query.get('order_number');
  const email_id_param = query.get('email_id');
  const navigate = useNavigate();
  const {tenant_name} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector((state) => state.brandedPortal);

  const {updateCustomerReturnInfo, resetBrandPortalInfo, tenantInfo, customerLoginSuccess} = useCustomerOrderInfo();
  const {setError, formState: {errors}} = useForm({mode: "onChange"});

  useEffect(() => {
    resetBrandPortalInfo('');
    loginAction(order_number_param, email_id_param);
  }, [tenantInfo?.tenant_id]);

  const loginAction = async (order_number, email_id) => {
    try {
      setIsLoading(true);
      const login_payload = {order_number, email_id};
      const loginResponse = await PostPortalLogin(tenantInfo, login_payload);
      const customerInfo = loginResponse.data;
      const payload = {
        user_info: customerInfo,
        return_flow: "logged_user",
        tenant_name,
      };
      customerLoginSuccess(payload);
      actionLoggedUser(customerInfo, true);
    } catch (e) {
      console.log(e);
      setError("invalidOrderOrEmailID", {
        type: "credentialsError",
        message: ErrorMessagesSchema.returns.invalidCredentials.invalidEmailOrOrderId,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const actionLoggedUser = (customerInfo, logged_user = false) => {
    const return_info = customerInfo?.return_info;
    if (return_info && return_info._id) {
      if (logged_user) {
        updateCustomerReturnInfo(return_info);
      }
      console.log('errors ', errors)
      window.location.href = `/returns/${tenant_name}/tracking-items`;

      //navigate(`/returns/${tenant_name}/tracking-items`);
    } else {
      window.location.href = `/returns/${tenant_name}/create-return`;
    }
  };

  return (
    <ReturnsLayout isHeader={false}>
      {isLoading && <CircularLoader/>}
      {(!isLoading && Object.keys(errors).length > 0) &&

              <Stack width={"100%"} gap={2.5}>
                {errors.invalidOrderOrEmailID && (
                  <Stack
                    padding={"6px 10px"}
                    color={"#FF1616"}
                    bgcolor={"#FF161633"}
                    borderRadius={"5px"}
                    fontSize={12}
                  >
                    <p>The order ID and/or Email ID did not match our records. Please check and try again.</p>
                  </Stack>
                )}
        </Stack>
      }
    </ReturnsLayout>
  );
};

export default ReturnsLoggedUser;
