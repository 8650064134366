export const labelSizeOptions = [
    { label: "4*6 label", value: 1 },
    { label: "4*5 label", value: 2},
    { label: "7*4 label", value: 3 },
    { label: "8.5*11 label", value: 4 },
  ];


  export function getLabelByValue(value) {
    const option = labelSizeOptions.find(option => option.value === value);
    return option ? option.label : "Label not found";
  }

  export const mailClassOptions = [
    { label: "Parcel select", value: "PARCEL_SELECT" },
    { label: "Parcel select lightweight", value: "PARCEL_SELECT_LIGHTWEIGHT" },
    { label: "USPS Connect Local", value: "USPS_CONNECT_LOCAL" },
    { label: "USPS Connect Regional", value: "USPS_CONNECT_REGIONAL" },
    { label: "USPS Connect Mail", value: "USPS_CONNECT_MAIL" },
    { label: "USPS Ground Advantage", value: "USPS_GROUND_ADVANTAGE" },
    { label: "Priority Mail Express", value: "PRIORITY_MAIL_EXPRESS" },
    { label: "Priority Mail", value: "PRIORITY_MAIL" },
    { label: "First Class Package Service", value: "FIRST-CLASS_PACKAGE_SERVICE" },
    { label: "Library Mail", value: "LIBRARY_MAIL" },
    { label: "Media Mail", value: "MEDIA_MAIL" },
    { label: "Bound Printed Matter", value: "BOUND_PRINTED_MATTER" },
  ];
  export  const labelMakeOptions = [
    {label:'Free Label',value: 'PREPAID_FREE_LABEL'},
    {label:'Paid Label',value: 'PREPAID_PAID_LABEL'}
];

  //----------
    export const formatDateTime = (date) => {
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return `${formattedDate} & ${formattedTime}`;
  };