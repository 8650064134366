export const handlePhoneChange = (newValue, setPhoneNumber) => {
  let newPhone = newValue.split(" ");
  let newPhoneStr = "";

  for (let index = 0; index < newPhone.length; index++) {
    // dial
    if (index === 0) newPhoneStr += newPhone[index] + " ";
    // phone number without dial
    else newPhoneStr += newPhone[index];
  }

  newPhone = newPhoneStr.split(" ");

  // if the length phone number without dial > 10
  if (newPhone[1]?.length > 10) return;

  setPhoneNumber(newPhoneStr);
};

export const formatPhone = (phone) => {
  let newPhone = "";
  for (let index = 0; index < phone.length; index++) {
    if (index === 0) newPhone += "(";
    if (index === 3) newPhone += ") ";
    if (index === 6) newPhone += " ";

    newPhone += phone[index];
  }

  return newPhone;
};

export const changePhone = (e, setPhoneNumber) => {
  let phoneNum = e.target.value;
  phoneNum = phoneNum.replaceAll("(", "");
  phoneNum = phoneNum.replaceAll(")", "");
  phoneNum = phoneNum.replaceAll("-", "");
  phoneNum = phoneNum.replaceAll(" ", "");

  if (phoneNum.length > 10) return;

  setPhoneNumber(phoneNum);
};
