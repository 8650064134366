import "./info-logo.scss";

const InfoLogo = ({ infoTxt = "", contentPositionStyle = {} }) => {
  return (
    <div className="tooltip">
      <img className="info-img" src="/assets/info.svg" />
      <span class="tooltiptext" style={contentPositionStyle}>
        {infoTxt}
      </span>
    </div>
  );
};

export default InfoLogo;
