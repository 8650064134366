import { styled } from "@mui/material/styles";
import { Typography, Box, Card, Grid } from "@mui/material";
import PropTypes from "prop-types";
import typography from "src/theme/typography";
import CustomTooltip from "../tooltip/Tooltip";
// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  item: PropTypes.object.isRequired,
  onClickRow: PropTypes.func,
};

function AppWidgetSummary({ item, onFirstScanHandleOnClick }) {
  const StyledIcon = styled("div")(() => ({
    display: "flex",
    borderRadius: "100%",
    alignItems: "center",
    justifyContent: "center",
  }));
  return (
    <CustomTooltip title={item.tooltip}>
      <Card
        sx={{
          py: { lg: 2, sm: 1, xs: 1 },
          px: { lg: 1, sm: 2, xs: 1.5 },
          boxShadow: 0,
          textAlign: "center",
          width: { md: "18%" },
          display: "flex",
          alignItems: "center",
          justifyContent: {xs:'normal' , md:'space-evenly'},
          height: { lg: 116, sm: 60, md: 85.53 },
          borderRadius: 1.5,
        }}
        className="metrics-card"
        onClick={() => {
          onFirstScanHandleOnClick(item);
          // console.log(item);
        }}
      >
        <Box
          sx={{
            width: { md: 30, xs: 50 },
          }}
        >
          <StyledIcon
            sx={{
              width: { lg: 60, md: 44.35, sm: 31.76, xs: 35 },
            }}
          >
            <img src={item.icon} alt="" />
          </StyledIcon>
        </Box>

        <Box
          sx={{
            textAlign: "start",
            paddingLeft: { lg: "33px !important", sm: 1, md: 3 },
            color: "secondary.light",
            maxWidth: 300,
          }}
        >
          <Typography
            variant="shippingCardValue"
            sx={{
              fontSize: { lg: 22, sm: 14, md: 18 },
              opacity: 0.7,
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
            }}
          >
            {item.total}
            {item.subtitle && (
              <Typography className="count-subtitle">
                ({item.subtitle})
              </Typography>
            )}
          </Typography>

          <p style={typography.caption} className="caption">
            {item.title}
          </p>
        </Box>
      </Card>
      </CustomTooltip>
  );
}

EmailStatisticsDataCard.propTypes = {
  title: PropTypes.string,
  cardItems: PropTypes.array,
};

export default function EmailStatisticsDataCard({
  title,
  cardItems,
  onFirstScanHandleOnClick,
}) {
  const StyledRoot = styled("div")(() => ({
    maxWidth: "100%",
  }));

  return (
    <StyledRoot>
      <Box
        sx={{
          pl: 2,
          py: { xs: 1, md: 4 },
          height: 50,
          display: "flex",
          alignItems: "center",
          color: "common.black",
          bgcolor: "common.white",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <h4 style={typography.h4}>{title}</h4>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          my: 3,
          gap: 2,
        }}
      >
          {cardItems.map((item, index) => (
            <AppWidgetSummary
              onFirstScanHandleOnClick={onFirstScanHandleOnClick}
              item={item}
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.title}-${index}`}
            />
          ))}
      </Box>
    </StyledRoot>
  );
}
