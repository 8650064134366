export const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export const backendUserUrl =
	backendBaseUrl + process.env.REACT_APP_USER_ROUTES;

export const backendTrackingUrl =
	backendBaseUrl + process.env.REACT_APP_TRACKING_ROUTES;

export const backendTenantUrl =
	backendBaseUrl + process.env.REACT_APP_TENANT_ROUTES;

export const internalApiGateway_url = process.env.REACT_APP_BACKEND_INTERNAL_URL;
