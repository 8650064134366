import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Stack,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import palette from "src/theme/palette";
import CloseIcon from "@mui/icons-material/Close";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { updatePaidLabelConfig } from "src/features/returns/stepperFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import { getReturnRules } from "src/features/returnConfiguration/returnRulesApi";

const PaidLabelConfigStepper = ({ handleNext, handleBack }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // const variants = [
  //   'Defective Product',
  //   'Top Up',
  //   'Change of Mind',
  //   'Incorrect Item',
  //   'Order by mistake',
  // ];

  const dispatch = useDispatch();
  const userInfo = useAppSelector(getUserInfo);

  const [variantName, setVariantName] = React.useState([]);
  const [returnRulesData, setReturnRulesData] = useState([]);

  const return_reason = useSelector(
    (state) => state?.form?.paidLabelConfig?.reasons
  );

  const handleDeleteReturnReason = (elementToDelete) => {
    const newReturnReasonList = variantName.filter(
      (el) => el !== elementToDelete
    );

    setVariantName(newReturnReasonList);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setVariantName(typeof value === "string" ? value.split(",") : value);
    // if (value.length > 0) setError(false);
  };
  const handleNextClick = () => {
    dispatch(updatePaidLabelConfig({ variantName }));
    handleNext();
  };

  useEffect(() => {
    getReturnReasonsForTenant();
    if (return_reason?.length > 0) {
      setVariantName(return_reason);
    }
  }, []);

  const getReturnReasonsForTenant = async () => {
    try {
      const response = await getReturnRules(userInfo);
      if (response?.statusCode == 200) {
        setReturnRulesData(response?.data?.return_reason);
      }
    } catch (err) {}
  };

  return (
    <Stack gap={5} padding={5}>
      <Stack gap={1}>
        <Typography variant="h5" textAlign={"center"}>
          Paid label configuration
        </Typography>
        <Typography variant="body2" textAlign={"center"} color={"#AAAAAA"}>
          Customize your return reason for a prepaid return label.
        </Typography>
      </Stack>

      <Stack alignItems={"center"} gap={2}>
        <FormControl sx={{ width: "30%" }}>
          <InputLabel id="demo-multiple-checkbox-label">
            Select return reasons
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={variantName}
            onChange={handleChange}
            input={<OutlinedInput label="Select return reasons" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            label="Select return reasons"
          >
            {returnRulesData.map((variant) => (
              <MenuItem key={variant} value={variant}>
                <Checkbox checked={variantName.includes(variant)} />
                <ListItemText primary={variant} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {variantName && (
          <Grid
            container
            direction={"row"}
            gap={2}
            width={"60%"}
            justifyContent={"center"}
            paddingX={1}
          >
            {variantName &&
              variantName?.map((el) => (
                <Grid
                  item
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  paddingX={1.2}
                  paddingY={0.5}
                  gap={0.8}
                  onClick={() => handleDeleteReturnReason(el)}
                  sx={{
                    cursor: "pointer",
                    bgcolor: palette.primary.main,
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                >
                  <Typography>{el}</Typography>
                  <CloseIcon
                    fontSize="small"
                    sx={{
                      width: "17px",
                      fill: "#fff",
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </Stack>

      <Stack gap={1} direction={"row"} justifyContent={"flex-end"}>
        <ArrowCircleLeftIcon
          onClick={handleBack}
          sx={{
            cursor: "pointer",
            width: "50px !important",
            height: "50px !important",
            color: "#002F43",
            transition: "transform 0.3s ease-in-out",
            "@keyframes pulse": {
              "0%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.1)" },
              "100%": { transform: "scale(1)" },
            },
          }}
        />
        <ArrowCircleRightIcon
          onClick={handleNextClick}
          sx={{
            color: "#002F43",
            cursor: "pointer",
            width: "50px !important",
            height: "50px !important",
            transition: "transform 0.3s ease-in-out",
            "@keyframes pulse": {
              "0%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.1)" },
              "100%": { transform: "scale(1)" },
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default PaidLabelConfigStepper;
