import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import dayjs from "dayjs";
import { unixToDate, cleanObject, removeTitleCase } from "src/utils/core";
import { Stack, Snackbar, Alert } from "@mui/material";
import getTrackingList, {
  getTrackingInfo,
  sendTrackInformationToRefresh,
} from "src/features/tracking/trackingApi";
import SmartTable from "../components/smart-table/SmartTable";
import { useSelector } from "react-redux";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import {
  incrementAwaitingApiCounter,
  decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { SocketContext } from "../components/socket-handler/sockethandler";
import appUrls from "src/utils/appUrls";
import { getPlansInfo } from "src/features/plans/plansState";
import planNames from "src/utils/planNames";

function TrackingPage(callback, deps) {
  let {
    trackingNumber,
    status,
    first_scan,
    dashboardStartDate,
    dashboardEndDate,
  } = useParams();
  if (first_scan === "Total Shipments") first_scan = "Total in Database";
  const socket = useContext(SocketContext);
  const { pathname } = useLocation();
  const trackingPathname = appUrls.outboundManagement.baseUrl + "tracking";
  const trackingPathname2 = appUrls.outboundManagement.tracking.absoluteUrl;
  const trackingInfo = useSelector((state) => state.user.trackingInfo);
  const mailScan = useAppSelector((state) => state.user.mailScan);
  const [expandedRow, setExpandedRow] = useState(null);
  const [mainTrackingList, setMainTrackingList] = useState([]);
  const [totalItemsCount, setTotalItemCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "day").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));
  const [advancedFormData, setAdvacnedFromData] = useState({});
  const [rawSingleTrackingInfo, setRawSingleTrackingInfo] = useState({});
  const [refreshTrackingInfo, setRefreshTrackingInfo] = useState({});
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [statusAllData, setStatusAllData] = useState("");
  const [statusToShow, setStatusToShow] = useState(statusAllData);
  const [showExportInfo, setShowExportInfo] = useState(false);
  const [userSelectedColumns, setUserSelectedColumns] = useState([]);
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const [statusToFetch, setStatusToFetch] = useState("");
  const [order, setOrder] = useState("");
  const [product, setProduct] = useState("");
  const sortDataString = `sort_data[${product}]`;
  const [noDateRange, setnoDateRange] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();
  const trackingInfoRef = useRef({});
  const plansInfo = useAppSelector(getPlansInfo);
  const isDemoPlan = userInfo?.plan_id === plansInfo[planNames.demo]?._id;

  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
    // is_demo_user: userInfo?.isDemoPlan, 
    is_demo_user: isDemoPlan.toString(),
  };

  // formate key names
  const originalRows1 = mainTrackingList?.map(
    ({
      carrier,
      shipping_date,
      shipping_city,
      tracking_number,
      status,
      order_id: order_number,
      estimated_delivery_date,
      scanned_status,
      customer_first_name,
      customer_last_name,
      customer_email,
    }) => ({
      first_name: customer_first_name,
      last_name: customer_last_name,
      email: customer_email,
      carrier,
      shipped_on: shipping_date,
      destination: shipping_city,
      tracking_number,
      status,
      estimated: estimated_delivery_date,
      scanned_status,
      order_number,
    })
  );

  // change scan status
  const scanStatus = {
    1: "Scanned",
    2: "Un Scanned",
    3: "Delivered without Scan",
    4: "Invalid Email",
    5: "Delivered",
    6: "Un Scanned Exit met",
  };

  const dataConverted = [];

  const allFormatetted = originalRows1?.forEach((item) => {
    dataConverted.push({
      ...item,
      scanned_status: scanStatus[item.scanned_status],
      shipped_on: item.shipped_on ? `${unixToDate(item.shipped_on)}` : "--",
      estimated: item.estimated ? `${unixToDate(item.estimated)}` : "--",
      status: item.status,
      order_number: Object.values(item.order_number).toString(),
    });
  });

  const defaultColumn = "tracking_number";
  const statusColumn = "status";

  const carrierSelect = [
    {
      label: "Select Carrier",
      value: "",
    },
    {
      label: "UPS",
      value: "UPS",
    },
    {
      label: "USPS",
      value: "USPS",
    },
    {
      label: "FEDEX",
      value: "FEDEX",
    },
    {
      label: "DHL",
      value: "DHL",
    },
  ];

  const completeStatusList = [
    "Delivered",
    "In Transit",
    "Pre-Shipment",
    "Alert",
  ];

  const statusSelect = [
    {
      label: "Alert",
      value: "Alert",
    },
    {
      label: "Available for Agent Pickup",
      value: "Available for Agent Pickup",
    },
    {
      label: "Available for Pickup",
      value: "Available for Pickup",
    },
    {
      label: "Delivered",
      value: "Delivered",
    },
    {
      label: "Delivered to Agent",
      value: "Delivered to Agent",
    },
    {
      label: "Delivery Attempt",
      value: "Delivery Attempt",
    },
    {
      label: "Forwarded",
      value: "Forwarded",
    },
    {
      label: "In Transit",
      value: "In Transit",
    },
    {
      label: "Out for Delivery",
      value: "Out for Delivery",
    },
    {
      label: "Pre-Shipment",
      value: "Pre-Shipment",
    },
    {
      label: "Returned",
      value: "Returned",
    },
    {
      label: "Unknown",
      value: "Unknown",
    },
  ];

  const scannedStatus = [
    {
      label: "Select Status",
      value: "",
    },
    {
      label: "Scanned",
      value: 1,
    },
    {
      label: "Un Scanned",
      value: 2,
    },
    {
      label: "Delivered Without Scan",
      value: 3,
    },
    {
      label: "Invalid Email",
      value: 4,
    },
    {
      label: "Delivered",
      value: 5,
    },
    {
      label: "Un Scanned Exit Met",
      value: 6,
    },
  ];

  const firstScanEmailStatistics = [
    {
      label: "Select Status",
      value: "",
    },
    {
      label: "Scanned Successfully",
      value: "Scanned Successfully",
    },
    {
      label: "No Scan",
      value: "No Scan",
    },
    {
      label: "Scanned with Estimated Delivery Date",
      value: "Scanned with Estimated Delivery Date",
    },
    {
      label: "Scanned without Estimated Delivery Date",
      value: "Scanned without Estimated Delivery Date",
    },
    {
      label: "Delivered Without Scan",
      value: "Delivered Without Scan",
    },
  ];

  useEffect(() => {
    if (!showAdvancedFilter && (searchKey == "" || searchKey == null)) {
      setPage(0);
      let queryParams = getCurrentPageParams();
      if (pathname === trackingPathname || pathname === trackingPathname2) {
        getTrackingNumerList(
          {
            ...queryParams,
            page_size: rowsPerPage,
            current_page: 1,
            [sortDataString]: order,
          },
          false
        );
      }
    }
  }, [startDate, !advancedFormData, pathname]);

  const getCurrentPageParams = (
    isStatusStrict = true,
    current_page = 1,
    statusToAdd = null
  ) => {
    let tempFormData = { ...advancedFormData };
    let queryParams = {
      page_size: rowsPerPage,
      current_page,
      [sortDataString]: order,
      ...tempFormData,
    };
    if (noDateRange == true) {
      if (queryParams.hasOwnProperty("date_range")) {
        delete queryParams["date_range"];
      }
    }
    if (startDate != false && endDate != false) {
      if (first_scan) {
        if (first_scan === "Carrier Scan Successful") {
          queryParams.scanned_status = 1;
        }
        if (first_scan === "No Carrier Scan") {
          queryParams.scanned_status = 2;
        }
        if (first_scan === "Carrier Scan with Estimated Delivery Date") {
          first_scan = "Scanned with Estimated Delivery Date";
        }
        if (first_scan === "Carrier Scan without Estimated Delivery Date") {
          first_scan = "Scanned without Estimated Delivery Date";
        }
        queryParams["date_range"] = `${dashboardStartDate}-${dashboardEndDate}`;
      } else {
        queryParams["date_range"] = `${startDate}-${endDate}`;
      }
    } else {
      if (queryParams.hasOwnProperty("date_range")) {
        delete queryParams["date_range"];
      }
    }
    if (statusToAdd !== "All Orders" && statusToAdd !== null) {
      queryParams.status = statusToAdd;
    } else if (statusToShow == "All Orders" && tempFormData.status) {
      queryParams.status = tempFormData.status;
    } else if (statusToShow !== "All Orders") {
      if (!showAdvancedFilter) {
        queryParams.status = statusToFetch;
      } else if (advancedFormData) {
        queryParams = {
          ...queryParams,
          ...tempFormData,
        };
      } else {
        queryParams.status = statusToFetch;
      }
    }
    return queryParams;
  };

  const sortOrder = (columnName, sortOrderval) => {
    let correctColName = columnName;
    if (columnName === "tracking_number") correctColName = "tracking_no";
    if (columnName === "shipped_on") correctColName = "shipping_date";
    if (columnName === "destination") correctColName = "shipping_city";
    if (columnName === "estimated") correctColName = "estimated_date";
    if (columnName === "order_number") correctColName = "order_id";
    if (columnName === "email") correctColName = "customer_email";

    setOrder(sortOrderval);
    setProduct(correctColName);
  };

  useEffect(() => {
    if (typeof trackingInfo !== "undefined" && trackingInfo.length > 0) {
      setUserSelectedColumns(trackingInfo);
    }
  }, [trackingInfo]);

  const getTrackingNumerList = (
    parms,
    appendRow = false,
    isOnlyTrackNo = false
  ) => {
    dispatch(incrementAwaitingApiCounter());
    setShowLoader(true);
    let res;
    if (parms.status === "All Orders") {
      const { status, ...parmsWithoutStatus } = parms;
      res = getTrackingList(cleanObject(parmsWithoutStatus), headers, userInfo);
    } else {
      res = getTrackingList(cleanObject(parms), headers, userInfo);
    }
    res
      .then((result) => {
        const responseData = result.data.items;
        if (mainTrackingList.length > 0 && appendRow && !isOnlyTrackNo) {
          setMainTrackingList([
            // ...new Set(mainTrackingList),
            ...result.data.items,
          ]);
        } else {
          setMainTrackingList(result.data.items);
        }

        if (isOnlyTrackNo && responseData.length === 1) {
          setStatusList([responseData[0].status]);
          setStatusToShow(responseData[0].status);
        }
        if (result.data.current_page == 1) {
          setTotalItemCount(result.data.total_count);
        }

        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      })
      .catch((error) => {
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      });
  };

  const getSingleTrackNumberInfo = async (trackNumber) => {
    setModalLoader(true);
    const res = getTrackingInfo(trackNumber, null, userInfo);
    res
      .then((result) => {
        setRawSingleTrackingInfo(result.data);
        setModalLoader(false);
      })
      .catch((error) => {
        setModalLoader(false);
      });
  };

  const checkTrackingNumber = (arr, trackNumber) => {
    const found = arr.some((el) => el.tracking_number === trackNumber);
    return found;
  };

  const getDateRange = (dates) => {
    if (dates == null) {
      setnoDateRange(true);
    }
    if (dates != null && dates.length > 0) {
      const formattedStartDate = dayjs(dates[0]).format("MM/DD/YYYY");
      const formattedEndDate = dayjs(dates[1]).format("MM/DD/YYYY");
      const date_range = `${formattedStartDate}-${formattedEndDate}`;
      setSearchKey(null);
      if (!showAdvancedFilter) {
        if (first_scan) {
          getTrackingNumerList(
            {
              page_size: rowsPerPage,
              current_page: 1,
              date_range: date_range,
              first_scan: first_scan,
            },
            false
          );
          setStatusToShow(first_scan);
          setStartDate(formattedStartDate);
          setEndDate(formattedEndDate);
        } else if (status) {
          getTrackingNumerList(
            {
              page_size: rowsPerPage,
              current_page: 1,
              date_range: date_range,
              status: status,
            },
            false
          );
          setStatusToShow(status);
          setStartDate(formattedStartDate);
          setEndDate(formattedEndDate);
          setPage(0);
        } else {
          let queryParams = getCurrentPageParams();
          setStatusToShow(statusToShow);
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: rowsPerPage,
              current_page: 1,
              date_range: date_range,
            },
            false
          );

          setStartDate(formattedStartDate);
          setEndDate(formattedEndDate);
          setPage(0);
        }
      } else {
        setAdvacnedFromData({
          ...advancedFormData,
          date_range: date_range,
        });
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
      }
      if (pathname === trackingPathname || pathname === trackingPathname2) {
        setStatusToShow(statusToShow);
        setStatusList(completeStatusList);
      }
    } else {
      setStartDate(false);
      setEndDate(false);
      if (!showAdvancedFilter) {
        setStatusToShow("All Orders");
        if (statusToShow === "All Orders") {
          if (noDateRange == true) {
            setStatusToShow("All Orders");
            setnoDateRange(false);
          }
        }
      } else {
        if (advancedFormData.hasOwnProperty("date_range")) {
          delete advancedFormData["date_range"];
          setAdvacnedFromData({ ...advancedFormData });
        }
      }
    }
  };

  //conditional refresh
  const onRefreshClicked = (event, trackNumbers, isAll) => {
    if (userInfo?.is_demo_user) return;
    if (!isAll) {
      sendTrackInformationToRefresh({ tracking_no: trackNumbers }, userInfo);
    } else {
      trackNumbers.forEach((number) => {
        sendTrackInformationToRefresh({ tracking_no: number }, userInfo);
      });
    }
  };

  const onExpandhandler = (event, trackNumber, index) => {
    event.preventDefault();
    if (expandedRow !== trackNumber) {
      getSingleTrackNumberInfo(trackNumber);
      setExpandedRow(trackNumber);
      setSearchKey(trackingNumber);
    } else {
      setExpandedRow(null);
      setSearchKey(null);
    }
  };

  // Search
  const handleSearchBtn = (e) => {
    e.preventDefault();
    if (e.target.trackingNumber.value === "") setSearchError(true);
    const searchedVal = e.target.trackingNumber.value.trim();
    setSearchKey(searchedVal);
    setPage(0);
    setRowsPerPage(10);
    setStartDate(false);
    setEndDate(false);
    setTotalItemCount(0);
    setAdvacnedFromData({});
    setShowAdvancedFilter(false);
    if (pathname !== trackingPathname || pathname !== trackingPathname2) {
      navigate(appUrls.outboundManagement.tracking.absoluteUrl);
    }
    setStatusList([]);
    setStatusAllData("All Orders");
    setStatusToShow("");

    // internal search
    const filteredRows = mainTrackingList.filter((row) =>
      row[defaultColumn].toString().includes(searchedVal.toLowerCase())
    );
    if (filteredRows.length > 0) {
      setMainTrackingList(filteredRows);
      setTotalItemCount(filteredRows.length);
    } else {
      // external search
      getTrackingNumerList(
        {
          page_size: rowsPerPage,
          current_page: 1,
          tracking_no: searchedVal,
        },
        false,
        true
      );
      // getSingleTrackNumberInfo(searchedVal);
    }

    // setExpandedRow(searchedVal);
  };

  const onSearchClick = () => {
    setSearchKey(null);
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setExpandedRow(null);

    let paginationParams = {
      page_size: rowsPerPage,
      current_page: newPage + 1,
      [sortDataString]: order,
    };

    if (startDate && endDate) {
      paginationParams.date_range = `${startDate}-${endDate}`;
    }

    if (first_scan) {
      if (first_scan === "Carrier Scan Successful") {
        paginationParams.scanned_status = 1;
      }
      if (first_scan === "No Carrier Scan") {
        paginationParams.scanned_status = 2;
      }
      if (first_scan === "Carrier Scan with Estimated Delivery Date") {
        first_scan = "Scanned with Estimated Delivery Date";
      }
      if (first_scan === "Carrier Scan without Estimated Delivery Date") {
        first_scan = "Scanned without Estimated Delivery Date";
      }
      paginationParams.first_scan = first_scan;
    } else if (statusToShow !== "All Orders") {
      paginationParams.status = statusToShow;
    }

    if (Object.keys(advancedFormData).length > 0) {
      Object.assign(paginationParams, advancedFormData);
    }
    getTrackingNumerList(paginationParams, false);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (totalItemsCount > 1) {
      const queryParams = getCurrentPageParams();
      if (
        pathname === trackingPathname ||
        pathname === trackingPathname2 ||
        advancedFormData.hasOwnProperty("status")
      ) {
        if (advancedFormData.hasOwnProperty("status")) {
          getTrackingNumerList(
            {
              ...queryParams,
              ...advancedFormData,
              page_size: newRowsPerPage,
              current_page: 1,
              [sortDataString]: order,
            },
            false
          );
        } else {
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: newRowsPerPage,
              current_page: 1,
              status: statusToShow,
              [sortDataString]: order,
            },
            false
          );
          //setCurrentStatusData(statusToShow);
        }
      } else {
        // const queryParams = getCurrentPageParams();
        if (first_scan) {
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: newRowsPerPage,
              current_page: 1,
              first_scan: first_scan,
              [sortDataString]: order,
            },
            false
          );
        } else if (statusToShow) {
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: newRowsPerPage,
              current_page: 1,
              status: statusToShow,
              [sortDataString]: order,
            },
            false
          );
          setPage(0);
        }
      }
    }
  };

  const onClickAdvancedFilter = () => {
    if (pathname === trackingPathname || pathname === trackingPathname2) {
      if (!showAdvancedFilter) {
        setStartDate(false);
        setEndDate(false);
      } else {
        setStartDate(dayjs().subtract(7, "day").format("MM/DD/YYYY"));
        setEndDate(dayjs().format("MM/DD/YYYY"));
        setStatusToShow("All Orders");
        setStatusAllData("All Orders");
        setStatusList(completeStatusList);
      }
    }
    setSearchKey(null);
    setAdvacnedFromData({});
    setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
    setPage(0);
  };

  const onChangeAdvancedForm = (e = null, date = null) => {
    if (date == null) {
      const { name, value } = e.target;
      let tempData = { ...advancedFormData };
      if ((value == null || value === "") && tempData.hasOwnProperty(name)) {
        delete tempData[name];
      } else {
        tempData[name] = value;
      }
      setAdvacnedFromData({ ...tempData });
    } else {
      setAdvacnedFromData({
        ...advancedFormData,
        [e]: date !== "Invalid Date" ? date : null,
      });
    }
  };

  const onSubmitAdvancedFilter = () => {
    setMainTrackingList([]);
    const tabStatus = [];
    let tempFormData = getCurrentPageParams(false);
    if (tempFormData.hasOwnProperty("status") && tempFormData.status != "") {
      const statusValue =
        tempFormData.status.length === 1 ? tempFormData.status : "All Orders";
      setStatusList([statusValue]);
      setStatusToShow(statusValue);
      setStatusAllData(statusValue);
    } else {
      setStatusAllData("All Orders");
      setStatusToShow("All Orders");
      setStatusList(["All Orders"]);
      getTrackingNumerList({ ...tempFormData });
      if (!tabStatus.includes(statusToShow) && statusToShow !== "All Orders") {
        setStatusToShow("All Orders");
      }
    }
    if (pathname !== trackingPathname || pathname !== trackingPathname2) {
      navigate(appUrls.outboundManagement.tracking.absoluteUrl);
      getTrackingNumerList({ ...tempFormData });
      setExpandedRow(null);
      setPage(0);
    } else {
      if (tempFormData.hasOwnProperty("status") && tempFormData.status != "") {
        if (tempFormData.status.length == 1) {
          setStatusList([tempFormData.status]);
          setStatusToShow(tempFormData.status);
          setStatusAllData(tempFormData.status);
        } else {
          setStatusList(["All Orders"]);
          setStatusToShow("All Orders");
          setStatusAllData("All Orders");
        }
        if (!advancedFormData) {
          getTrackingNumerList({ ...tempFormData });
        } else {
          getTrackingNumerList({ ...tempFormData });
        }
      } else {
        setStatusToShow("All Orders");
      }
      setExpandedRow(null);
      setPage(0);
    }
  };

  // Status filter
  const onStatusChange = (e) => {
    const mainStatus = e.target.name;
    const statusToAdd = mainStatus;
    setStatusToFetch(mainStatus);
    setStatusToShow(mainStatus);
    setSearchKey(null);

    let queryParams = getCurrentPageParams(statusToAdd);

    // If we are on All Orders
    if (mainStatus === statusAllData) {
      navigate(appUrls.outboundManagement.tracking.absoluteUrl);
      if (pathname === trackingPathname || pathname === trackingPathname2) {
        setStatusToShow(mainStatus);
        if (mainStatus !== "All orders") {
          getTrackingNumerList({
            ...queryParams,
            status: mainStatus,
          });
        } else {
          const { status, ...queryParamsWithoutStatus } = queryParams;
          getTrackingNumerList(queryParamsWithoutStatus);
        }
      }
      setExpandedRow(null);
      setStatusList(completeStatusList);
    } else {
      if (trackingNumber) {
        getTrackingNumerList({
          page_size: rowsPerPage,
          current_page: 1,
          status: mainStatus,
        });
        navigate(appUrls.outboundManagement.tracking.absoluteUrl);
      } else if (first_scan) {
        //call the API if we have not stored data
        getTrackingNumerList({
          ...queryParams,
          page_size: rowsPerPage,
          current_page: 1,
          first_scan: mainStatus,
        });
      } else if (!showAdvancedFilter) {
        getTrackingNumerList({
          ...queryParams,
          page_size: rowsPerPage,
          current_page: 1,
          status: mainStatus,
        });
      }
    }

    setPage(0);
  };

  const onCloseExportInfo = () => {
    setShowExportInfo(false);
  };

  const handleColumnSubmit = (columnList) => {
    setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
    // dispatch(
    // 	updateUserPreferences({
    // 		trackingInfo: columnList.map((item) => removeTitleCase(item)),
    // 		mailScan: mailScan,
    // 	})
    // );
    // updateColumnPreferences(
    // 	{
    // 		table_name: "tracking_info",
    // 		table_columns: columnList.map((item) => removeTitleCase(item)),
    // 	},
    // 	userInfo?.accessToken
    // );
  };

  const exportButtonClicked = () => {
    setShowExportInfo(true);
    let parmas = {
      export_data: true,
      export_data_to: userInfo?.user?.user_mail,
      ...advancedFormData,
    };
    if (startDate !== false && endDate !== false) {
      parmas.date_range = `${startDate}-${endDate}`;
    }
    if (searchKey) {
      parmas.tracking_no = searchKey;
    }
    if (statusToShow !== "All Orders") {
      parmas.status = statusToShow;
    }
    exportTrackingNumerList(parmas);
  };

  const exportTrackingNumerList = (parms, appendRow = false) => {
    const res = getTrackingList(cleanObject(parms), headers, userInfo);
    res.then((result) => {}).catch((error) => {});
  };

  useEffect(() => {
    const tenant_id = userInfo.tenant_id;
    function cbHandleRefreshTrackingDetails(data) {
      setRefreshTrackingInfo(data);
    }

    socket.on(
      tenant_id + "_refresh_tracking_details",
      cbHandleRefreshTrackingDetails
    );
    return () => {
      socket.off(
        tenant_id + "refresh_tracking_details",
        cbHandleRefreshTrackingDetails
      );
    };
  }, []);

  useEffect(() => {
    if (typeof refreshTrackingInfo.trackNo !== "undefined") {
      const { trackNo } = refreshTrackingInfo;
      const index = mainTrackingList.findIndex(
        (object) => object.tracking_number === trackNo
      );
      if (index > -1) {
        const tableList = [...mainTrackingList];
        tableList[index] = refreshTrackingInfo.tableResponseData;
        setMainTrackingList(tableList);
        if (
          rawSingleTrackingInfo.hasOwnProperty("tracking_number") &&
          rawSingleTrackingInfo.tracking_number === trackNo
        ) {
          setRawSingleTrackingInfo({
            ...refreshTrackingInfo.trackNoInfo,
          });
        }
      }
    }
  }, [refreshTrackingInfo]);

  useEffect(() => {
    if (pathname === trackingPathname || pathname === trackingPathname2) {
      if (statusToShow && statusToShow !== "All Orders") {
        setStatusList[[statusToShow]];
      } else {
        setStatusList(completeStatusList);
        setStatusToShow("All Orders");
      }
    }

    if (advancedFormData.hasOwnProperty("status")) {
      const Status = " ";
      setStatusToShow(Status);
      setStatusList(Status);
      // setStatusToShow(advancedFormData.status);
      setStatusAllData("");
    } else if (status) {
      setStatusToShow(status);
      setStatusAllData("All Orders");
    } else {
      setStatusAllData("All Orders");
    }
  }, [pathname]);

  useEffect(() => {
    if (!isLoaded) return;
    const queryParams = getCurrentPageParams();

    if (first_scan) {
      let scan_fetch = "";

      switch (first_scan) {
        case "Carrier Scan Successful":
          scan_fetch = "Scanned Successfully";
          break;
        case "No Carrier Scan":
          scan_fetch = "No Scan";
          break;
        case "Carrier Scan with Estimated Delivery Date":
          scan_fetch = "Scanned with Estimated Delivery Date";
          break;
        case "Carrier Scan without Estimated Delivery Date":
          scan_fetch = "Scanned without Estimated Delivery Date";
          break;
        case "Delivered before BeyondCarts Scan":
          scan_fetch = "Delivered Without Scan";
          break;

        default:
          scan_fetch = first_scan;
          break;
      }

      getTrackingNumerList(
        {
          ...queryParams,
          page_size: rowsPerPage,
          current_page: 1,
          first_scan: scan_fetch,
          [sortDataString]: order,
        },
        false
      );
    } else if (statusToShow) {
      if (
        statusToShow === "All Orders" ||
        statusToShow.includes([
          "Delivered",
          "In Transit",
          "Pre-Shipment",
          "Alert",
        ])
      ) {
        getTrackingNumerList(
          {
            ...queryParams,
            page_size: rowsPerPage,
            current_page: 1,
            [sortDataString]: order,
          },
          false
        );
      } else {
        getTrackingNumerList(
          {
            ...queryParams,
            page_size: rowsPerPage,
            current_page: 1,
            status: statusToShow,
            [sortDataString]: order,
          },
          false
        );
      }
    }
    setPage(0);
  }, [order, product]);

  //from dashboard track num search
  useEffect(() => {
    if (trackingNumber) {
      if (!checkTrackingNumber(mainTrackingList, trackingNumber)) {
        getTrackingNumerList(
          {
            page_size: rowsPerPage,
            current_page: 1,
            tracking_no: trackingNumber,
          },
          false,
          true
        );
        getSingleTrackNumberInfo(trackingNumber);
      } else {
        getSingleTrackNumberInfo(trackingNumber);
      }
      setExpandedRow(trackingNumber);
      setSearchKey(trackingNumber);
      setStartDate(false);
      setEndDate(false);
      // setStatusList([status]);
      // setStatusToShow(status);
    } else if (first_scan) {
      // Check if first_scan exists then call the api

      setStartDate(dashboardStartDate);
      setEndDate(dashboardEndDate);
      setStatusList([first_scan]);
      setStatusToShow(first_scan);

      let scan_fetch = "";

      switch (first_scan) {
        case "Carrier Scan Successful":
          scan_fetch = "Scanned Successfully";
          break;
        case "No Carrier Scan":
          scan_fetch = "No Scan";
          break;
        case "Carrier Scan with Estimated Delivery Date":
          scan_fetch = "Scanned with Estimated Delivery Date";
          break;
        case "Carrier Scan without Estimated Delivery Date":
          scan_fetch = "Scanned without Estimated Delivery Date";
          break;
        case "Delivered before BeyondCarts Scan":
          scan_fetch = "Delivered Without Scan";
          break;

        default:
          scan_fetch = first_scan;
          break;
      }

      getTrackingNumerList({
        page_size: rowsPerPage,
        current_page: 1,
        date_range: `${dashboardStartDate}-${dashboardEndDate}`,
        first_scan: scan_fetch,
      });
    } else if (status) {
      setStartDate(dashboardStartDate);
      setEndDate(dashboardEndDate);
      setStatusList([status]);
      setStatusToShow(status);

      getTrackingNumerList({
        page_size: rowsPerPage,
        current_page: 1,
        date_range: `${dashboardStartDate}-${dashboardEndDate}`,
        status,
      });
    } else if (!showAdvancedFilter) {
      if (startDate && endDate) {
        if (first_scan) {
          setStatusList([status]);
          setStatusToShow(status);
          getTrackingNumerList({
            page_size: rowsPerPage,
            current_page: 1,
            date_range: `${startDate}-${endDate}`,
            first_scan,
          });
        } else if (status) {
          setStatusList([status]);
          setStatusToShow(status);
          getTrackingNumerList({
            page_size: rowsPerPage,
            current_page: 1,
            date_range: `${startDate}-${endDate}`,
            status,
          });
        } else {
          // getTrackingNumerList(
          //   {page_size: rowsPerPage, current_page: 1, date_range: `${startDate}-${endDate}`},
          //   false
          // );
        }
      }
    }

    setIsLoaded(true);
  }, [trackingNumber, endDate]);

  return (
    <>
      <Helmet>
        <title> Tracking | BeyondCarts </title>
      </Helmet>
      <Snackbar
        open={showExportInfo}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={onCloseExportInfo}
      >
        <Alert
          onClose={onCloseExportInfo}
          severity="success"
          sx={{ width: "100%" }}
        >
          Export in progress
        </Alert>
      </Snackbar>

      <Stack>
        <SmartTable
          isSearch
          isAdvancedFilter
          isStatusMenu
          isRowSelectable
          isAction
          originalRows={
            typeof dataConverted !== "undefined" ? dataConverted : [{}]
          }
          defaultColumn={defaultColumn}
          //
          statusColumn={statusColumn}
          statusList={statusList}
          statusAllData={statusAllData}
          getDateRange={getDateRange}
          onRefreshClicked={onRefreshClicked}
          onExpandhandler={onExpandhandler}
          expandedRow={expandedRow}
          totelItemCount={totalItemsCount}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          onhandeSeachSubmit={handleSearchBtn}
          searchKey={searchKey}
          onSearchClick={onSearchClick}
          startDate={startDate}
          endDate={endDate}
          onChangeAdvancedForm={onChangeAdvancedForm}
          advancedFormData={advancedFormData}
          carrierSelect={carrierSelect}
          statusSelect={statusSelect}
          scannedStatus={scannedStatus}
          firstScanEmailStatistics={firstScanEmailStatistics}
          onSubmitAdvancedFilter={onSubmitAdvancedFilter}
          exapndRowIntputData={rawSingleTrackingInfo}
          trackingInfoRef={trackingInfoRef}
          showAdvancedFilter={showAdvancedFilter}
          onClickAdvancedFilter={onClickAdvancedFilter}
          onStatusChange={onStatusChange}
          statusToShow={statusToShow}
          exportButtonClicked={exportButtonClicked}
          trackingPage={true}
          userSelectedColumns={userSelectedColumns}
          handleColumnSubmit={handleColumnSubmit}
          modalLoader={modalLoader}
          sortOrder={sortOrder}
          searchError={searchError}
          setSearchError={setSearchError}
        />
      </Stack>
    </>
  );
}

export default TrackingPage;
