import { Box, Modal, Stack, IconButton, Button } from "@mui/material";
import palette from "src/theme/palette";
import typography from "src/theme/typography";

const popUpModalStyle = {
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: "80%", sm: "auto", lg: "580px", md: "auto" },
  maxWidth: { lg: "770px" },
  borderRadius: "12px",
  background: "#FFF",
  overflow: "auto",
  overflow: "auto-y",
  // overflowX: "hidden",
  outline: "none",
};

const ModalComponent = ({
  openModal,
  handleCloseModal,
  handleBtnClick,
  title,
  // description can be a jsx or string
  description,
  description2,
  btnText,
  cancelBtnText,
  hideCloseIcon,
}) => {
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Stack
        sx={{
          ...popUpModalStyle,
          padding: 4,
          gap: 3.5,
        }}
      >
        <Stack
          gap={3}
          direction="column"
          alignItems={"center"}
          sx={{
            color: "common.black",
            fontFamily: typography.fontFamilyRegular,
            backgroundColor: "white",
          }}
        >
          {!hideCloseIcon && (
            <IconButton
              aria-label="close"
              sx={{
                position: "fixed",
                top: 8,
                right: 8,
                zIndex: 2,
              }}
              onClick={handleCloseModal}
            >
              <img width="30px" src="/assets/icons/table/close.svg" alt="X" />
            </IconButton>
          )}

          {title && (
            <Box
              sx={{
                fontFamily: typography.fontFamilySemiBold,
                color: palette.secondary.main,
                fontSize: {
                  lg: 34,
                  md: 27,
                  xs: 22,
                  sm: 22,
                },
                lineHeight: "normal",
              }}
            >
              <h4 style={{ ...typography.h2, textAlign: "center" }}>{title}</h4>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}>
            {description && (
              <Box
                sx={{
                  fontSize: {
                    md: 16,
                    xs: 14,
                  },
                  textAlign: "center",
                }}
              >
                {description}
              </Box>
            )}
            {description2 && (
              <Box
                sx={{
                  fontSize: {
                    md: 16,
                    xs: 14,
                  },
                  textAlign: "center",
                }}
              >
                {description2}
              </Box>
            )}
          </Box>
        </Stack>
        {cancelBtnText ? (
          <Stack direction={"row"} gap={2} width={"70%"}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                boxShadow: "none",
                backgroundColor: "grey.600",
                paddingY: 1.3,
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
                "&:hover": {
                  backgroundColor: "primary.lightIcon",
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={handleCloseModal}
            >
              {cancelBtnText}
            </Button>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              type="submit"
              sx={{
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
                paddingY: 1.3,
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={handleBtnClick}
            >
              {btnText}
            </Button>
          </Stack>
        ) : (
          <Stack direction={"row"} gap={2} minWidth={"30%"} maxWidth={"60%"}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              type="submit"
              sx={{
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
                paddingY: 1.3,
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={handleBtnClick}
            >
              {btnText}
            </Button>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default ModalComponent;
