import { Stack, Box, Modal, IconButton } from "@mui/material";
import { useState } from "react";
import typography from "src/theme/typography";
import { useAppDispatch } from "src/hooks/hooks";
import { updateUserInfo } from "src/features/user/userState";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const popUpModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  borderRadius: "20px",
  background: "#FFF",
  overflow: "auto",
  overflow: "auto-y",
  overflowX: "hidden",
  outline: "none",
};

const ModalUserRowDetails = ({
  children,
  inputData,
  onExpandhandler,
  loggedUserNewData,
  setIsRefresh,
}) => {
  const id = inputData?.id;
  const [openModal, setOpenModal] = useState(true);
  const dispatch = useAppDispatch();

  const handleCloseModal = (e) => {
    setIsRefresh(true);
    setOpenModal(false);
    onExpandhandler(e, id);

    if (Object.keys(loggedUserNewData).length > 0) {
      dispatch(updateUserInfo(loggedUserNewData));
    }

    dispatch(decrementAwaitingApiCounter());
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={popUpModalStyle}>
        {Object.keys(inputData).length === 0 ? null : (
          <Stack
            direction="row"
            sx={{
              color: "#000",
              padding: 4,
              fontSize: { sm: 14, lg: 20 },
              fontFamily: typography.fontFamilyRegular,
              backgroundColor: "white",
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                position: "fixed",
                top: { sm: 15, lg: 12 },
                right: { lg: 12, md: 30, sm: 10 },
                zIndex: 2,
              }}
              onClick={handleCloseModal}
            >
              <img width="34px" src="/assets/icons/table/close.svg" alt="X" />
            </IconButton>

            {children}
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default ModalUserRowDetails;
