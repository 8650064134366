import getData from "src/dataFetcher/getDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendBaseUrl } from "src/utils/backendUrls";
import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    return decryptData(result);
  }
  return result;
};

export async function getLabel(userInfo) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}tenant/returns/get_label/${userInfo?.tenant_id}`,
    {},
    headers,
    token
  );
  return result;
}

export async function updateTrackingDetails(userInfo, body, return_id) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${backendBaseUrl}tenant/returns/update_return_label/${return_id}`,
    {},
    body,
    token
  );
  return JSON.parse(extractData(result.data));
}
