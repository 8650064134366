import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

import PolicyIcon from "@mui/icons-material/Policy";
import PortalLogin from "./PortalLogin";
import ReturnMethods from "./returnMethods";
import ReturnDetails from "./ReturnDetails";
import ShippingInstructions from "./ShippingInstructions";
import { useState } from "react";
import { useSelector } from "react-redux";
import { retrieveLogoUrl } from "src/features/config/configApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularLoader from "src/loader/circular/CircularLoader";

const Portal = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [orders, setOrders] = useState(null);
  const [eligibleItems, setEligibleItems] = useState({});
  const [isReturn, setIsReturn] = useState(false);
  const [isReturnSuccess, setIsReturnSuccess] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector((state) => state.brandedPortal);

  return (
    <>
      <Stack
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ height: "100vh" }}
      >
        {/* bg img */}
        <img
          src={
            `${retrieveLogoUrl}${data.brandedAssetsState.background_image}` ||
            "https://images5.alphacoders.com/616/616552.jpg"
          }
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            zIndex: -1,
          }}
        />

        {/* header */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          paddingTop={2}
          paddingLeft={2}
          width={"100%"}
          gap={0.6}
        >
          <ArrowBackIcon
            sx={{
              width: 20,
            }}
          />
          <Link href={`${data.brandedAssetsState.website_url}`}>
            Back to store
          </Link>
        </Stack>

        {/* body */}
        <Stack alignItems={"center"} justifyContent={"center"}>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <Stack alignItems={"center"} justifyContent={"center"} gap={2}>
              <img
                // style={{ objectFit: "contain" }}
                src={
                  `${retrieveLogoUrl}${data.brandedAssetsState.logo}` ||
                  "/assets/no-image.png"
                }
                width={90}
                height={"auto"}
              />
              <Box
                sx={{
                  width: { xs: "90%", sm: 520 },
                  backgroundColor: "#fff",
                  borderRadius: `${data.brandedAssetsState.portalCornerRadius}`,
                  boxShadow: "0px 6px 30px 0px #00000014",
                  zIndex: 1,
                }}
              >
                {!isReturn && isVerified && (
                  <ReturnMethods
                    returnInfo={data}
                    orders={orders}
                    setIsVerified={setIsVerified}
                    eligibleItems={eligibleItems}
                    setIsReturn={setIsReturn}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                  />
                )}
                {!isReturn && !isVerified && (
                  <PortalLogin
                    portalInfo={data}
                    setIsVerified={setIsVerified}
                    setOrders={setOrders}
                    setEligibleItems={setEligibleItems}
                    setIsLoading={setIsLoading}
                  />
                )}
                {isReturn && !isReturnSuccess && (
                  <ReturnDetails
                    returnInfo={data}
                    setIsReturn={setIsReturn}
                    setIsVerified={setIsVerified}
                    orders={orders}
                    selectedItems={selectedItems}
                    setIsReturnSuccess={setIsReturnSuccess}
                    setIsLoading={setIsLoading}
                  />
                )}
                {isReturn && isReturnSuccess && (
                  <ShippingInstructions
                    shippingInfo={data.policyInstructionsState.shipping_info}
                  />
                )}
                <Box
                  sx={{
                    padding: "10px",
                    backgroundColor: "#f7f7f7",
                    display: "flex",
                    alignItems: "center",
                    borderBottomLeftRadius: `${data.brandedAssetsState.portalCornerRadius}`,
                    borderBottomRightRadius: `${data.brandedAssetsState.portalCornerRadius}`,
                  }}
                >
                  <IconButton>
                    <PolicyIcon />
                  </IconButton>
                  <a
                    href={
                      data.policyInstructionsState.return_policy.policy_url ||
                      ""
                    }
                  >
                    {data.policyInstructionsState.return_policy.link_text}
                  </a>
                </Box>
              </Box>
            </Stack>
          )}
        </Stack>

        {/* footer */}
        {data.brandedFooterState.link_text && (
          <Box paddingBottom={2}>
            <a href={data.brandedFooterState.link_url || ""}>
              {data.brandedFooterState.link_text}
            </a>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default Portal;
