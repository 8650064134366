import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import {
  backendUserUrl,
  backendTenantUrl,
  backendBaseUrl,
} from "src/utils/backendUrls";

const extractData = (result) => {
	if (encConfig.encryption.decrypt) {
	  if (result?.data) return decryptData(result?.data);
	}
	return result;
  };

// get a tenant by its id
export async function getTenantUser(params = null, headers, userId, userInfo) {
  const tenant = userInfo?.tenant_name;
  const token = userInfo?.accessToken;

  const result = await getData(
    `${backendUserUrl}${tenant}/user/${userId}`,
    params,
    headers,
    token
  );
  return result;
}

// gets the list of tenant users
export async function getUsersList(userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const params = null;
  const headers = {
    tenant_id: userInfo.tenant_id,
    user_id: userInfo.user_id,
  };
  const result = await getData(
    `${backendUserUrl}${tenant}/user`,
    params,
    headers,
    token
  );
  return result;
}

// creates a new user and adds it to the user list on the db
export async function createUser(headers, body, userInfo) {
  const tenant = userInfo?.tenant_name;
  const token = userInfo?.accessToken;
  return await postData(`${backendBaseUrl}account`, headers, body, token);
}

// updates the first, last name and phone number of a user
export async function updateTenantUser(body = null, userId, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const header = {
    tenant_id: userInfo?.tenant_id,
  };

  return await putData(
    `${backendUserUrl}${tenant}/user/${userId}`,
    header,
    body,
    token
  );
}

// updates the password of a user
export async function updateTenantPassword(body = null, userId, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const header = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.id,
  };

  return await putData(
    `${backendUserUrl}${tenant}/user/password/${userId}`,
    header,
    body,
    token
  );
}

// promotes to admin a user
export async function promoteToAdmin(headers, selectedUserId, userInfo) {
  const tenant = userInfo?.tenant_name;
  const token = userInfo?.accessToken;
  const userId = {
    userId: selectedUserId,
  };
  const res = await postData(
    `${backendUserUrl}${tenant}/user/promote`,
    headers,
    userId,
    token
  );
  return JSON.parse(extractData({data:res}));
}

export async function getTenant(userInfo) {
  const token = userInfo?.accessToken;
  const tenant_id = userInfo?.tenant_id;
  const header = {};
  if (userInfo?.is_demo_user) {
    header.is_demo_user = true;
  }
  const result = await getData(
    `${backendTenantUrl}frontEnd/tenant/${tenant_id}`,
    null,
    header,
    token
  );
  return result;
}
