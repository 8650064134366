import {
  Stack,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Divider,
  Grid,
  Button,
  Dialog,
} from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from "@mui/lab";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { unixToDate, unixToTime, dateDiff } from "../../utils/core";
import SvgColor from "../svg-color/SvgColor";
import moment from "moment";
import ModalRowDetails from "../modal/ModalRowDetails";
import typography from "src/theme/typography";
import CircularLoader from "src/loader/circular/CircularLoader";
import { PostPortalLogin } from "../../pages/portal/PortalApi";
import { ErrorMessagesSchema } from "../../utils/errorMessagesSchema";
import useCustomerOrderInfo from "../../features/return_management/hooks/useCustomerOrderInfo";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
import { getUserInfo } from "../../features/user/userState";
import { getPlansInfo } from "src/features/plans/plansState";
import planNames from "src/utils/planNames";

function OrderItem({ item, isLast, isFirst }) {
  const { date, location, status, mapped_category } = item;
  let status_length = status.length;
  if (location?.city) {
    status_length += location?.city.length;
  }
  if (location?.state) {
    status_length += location?.state.length;
  }
  if (location?.postal_code) {
    status_length += location?.postal_code.length;
  }
  return (
    <TimelineItem sx={{ minHeight: 80 }}>
      <TimelineOppositeContent
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack
          gap={0.5}
          alignItems="center"
          sx={{ minWidth: 100 }}
          divider={
            <Divider
              flexItem
              sx={{
                bgcolor: "#ACACAC",
              }}
            />
          }
        >
          <Typography
            sx={{
              fontSize: { xs: 12, md: 14 },
            }}
          >
            {unixToDate(date)}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 12, md: 14 },
            }}
          >
            {unixToTime(date)} PST
          </Typography>
        </Stack>
      </TimelineOppositeContent>
      <TimelineSeparator>
        {mapped_category === "Delivered" ? (
          <TimelineDot
            sx={{
              marginTop: 3.5,
              backgroundColor: "none",
              padding: 0,
              marginLeft: "-5px",
            }}
          >
            <img
              src="/assets/icons/timeline/delivered.svg"
              style={{ maxWidth: 20, zIndex: 2 }}
            />
          </TimelineDot>
        ) : (
          <TimelineDot
            sx={{
              backgroundColor: "primary.light",
              marginTop: 3.9,
            }}
          />
        )}

        {isLast ? null : (
          <TimelineConnector
            sx={{
              height: status_length > 40 ? 130 : 70,
              position: "absolute",
              marginTop: "43px",
              bgcolor: "#ACACAC",
              marginLeft: "-2px",
            }}
          />
        )}
      </TimelineSeparator>

      <TimelineContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: typography.fontFamilyRegular,
            fontSize: { xs: 14, md: 16 },
          }}
        >
          {status} ({mapped_category})
        </Typography>
        {isLast ? null : (
          <Typography
            sx={{
              lineHeight: 22 / 14,
              fontFamily: typography.fontFamilyRegular,
              fontSize: { xs: 11, md: 13 },
            }}
          >
            {location.city ? `${location.city}, ` : null}
            {location.state ? `${location.state} ` : null}
            &nbsp;&nbsp;
            {location.postal_code ?? location.postal_code}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  item: PropTypes.shape({
    date: PropTypes.number,
    location: PropTypes.object,
    status: PropTypes.string,
  }),
};

function TrackingTableRowDetails({ inputData, onExpandhandler }) {
  const navigate = useNavigate();
  const userInfo = useAppSelector(getUserInfo);
  const plansInfo = useAppSelector(getPlansInfo);
  const isDemoPlan = userInfo?.plan_id === plansInfo[planNames.demo]?._id;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const {
    updateCustomerReturnInfo,
    updateTenantInfoData,
    customerLoginSuccess,
    resetBrandPortalInfo,
  } = useCustomerOrderInfo();

  const handleCreateReturn = async () => {
    resetBrandPortalInfo("");
    const orderInfo = inputData.order_info;
    const order_number = orderInfo[0].order_details.increment_id;
    const customer_email = inputData.customer_email;
    // const order_number = "1042";
    // const customer_email = "aegontarg1@yopmail.com";
    loginAction(order_number, customer_email);
  };

  const [isLoading, setIsLoading] = useState(false);
  const tenantInfo = {
    tenant_name: userInfo.tenant_name,
    tenant_id: userInfo.tenant_id,
    company_name: userInfo.company_name,
    is_demo_user: isDemoPlan,
  };
  const loginAction = async (order_number, email_id) => {
    try {
      setIsLoading(true);
      const login_payload = { order_number, email_id };
      const loginResponse = await PostPortalLogin(tenantInfo, login_payload);

      const customerInfo = loginResponse.data;
      console.log(customerInfo);
      if (!loginResponse?.return_info?._id) {
        const payload = {
          user_info: customerInfo,
          return_flow: "customer_support",
          tenant_name: tenantInfo.tenant_name,
        };
        updateTenantInfoData(tenantInfo);
        customerLoginSuccess(payload);
        actionLoggedUser(customerInfo, true);
      } else {
        setIsDialogOpen(true);
        setDialogMessage(
          "Sorry! The return order has already been created. Please check the status in Manage Returns."
        );
      }
    } catch (e) {
      console.log(e);
      // setError("invalidOrderOrEmailID", {
      //   type: "credentialsError",
      //   message: ErrorMessagesSchema.returns.invalidCredentials.invalidEmailOrOrderId,
      // });
    } finally {
      setIsLoading(false);
    }
  };

  const actionLoggedUser = (customerInfo, logged_user = false) => {
    const return_info = customerInfo?.return_info;
    if (return_info != null && return_info?._id) {
      if (logged_user) {
        updateCustomerReturnInfo(return_info);
      }
      navigate(`/returns_support/${tenantInfo.tenant_name}/tracking-items`);
    } else {
      navigate(`/returns_support/${tenantInfo.tenant_name}/create-return`);
    }
  };
  const StyledDetatilsRoot = styled(Box)(() => ({
    display: "flex",
    // justifyContent: "flex-start",
    flexDirection: "column",
    // justifyContent: "left",
    flex: 1,
    // minHeight: 300,
    // width: "max-content",
    gap: 13,
    fontSize: 18,
    fontFamily: typography.fontFamilyRegular,
  }));
  const StyledTableRow = styled(TableRow)(() => ({
    // display: "flex",
    // justifyContent: "space-between",
    // paddingTop: 10,
  }));

  const StyledHeadTableCell = styled(TableCell)(() => ({
    color: "#000",
    background: "none",
    borderBottom: "1px solid #ACACAC",
    padding: 0,
  }));
  const StyledTableCell = styled(TableCell)(() => ({
    color: "#000",
    border: "none",
    padding: 0,
    paddingTop: 4,
    fontSize: 18,
    fontFamily: typography.fontFamilyRegular,
  }));
  const StyledValueTableCell = styled(TableCell)(() => ({
    color: "#000",
    border: "none",
    padding: 0,
    paddingTop: 4,
    fontWeight: 600,
    verticalAlign: "top",
    fontSize: 18,
  }));
  const currentDateUnix = moment(new Date()).format("X");
  const days_count = dateDiff(
    unixToDate(inputData?.label_created_date),
    unixToDate(currentDateUnix)
  );
  let estimate_time_in_transit = "";
  if (days_count > 1) {
    estimate_time_in_transit = days_count + " Days";
  } else {
    estimate_time_in_transit = days_count + " Day";
  }
  const days_delivered_count = dateDiff(
    unixToDate(inputData?.label_created_date),
    unixToDate(inputData?.delivered_date)
  );
  let delivered_time_in_transit = "";
  if (days_delivered_count > 1) {
    delivered_time_in_transit = days_delivered_count + " Days";
  } else {
    delivered_time_in_transit = days_delivered_count + " Day";
  }

  return Object.keys(inputData).length === 0 ? (
    <CircularLoader />
  ) : isLoading ? (
    <CircularLoader />
  ) : (
    <ModalRowDetails inputData={inputData} onExpandhandler={onExpandhandler}>
      <StyledDetatilsRoot>
        <Dialog fullWidth={true} open={isDialogOpen} maxWidth="xs">
          <Box sx={{ padding: "20px" }}>
            <Typography
              sx={{ color: "#000", textAlign: "center", fontSize: "16px" }}
            >
              {dialogMessage}
            </Typography>
            <Button
              variant="outlined"
              sx={{
                display: "block",
                margin: "auto",
                mt: 2,
                borderColor: "secondary.main",
                color: "secondary.main",
              }}
              onClick={() => setIsDialogOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Dialog>
        {/* Order details */}
        <Table sx={{ marginRight: 5 }} aria-label="Order Details table">
          <TableHead>
            <TableRow>
              <StyledHeadTableCell colSpan={3}>
                <Stack
                  direction="row"
                  spacing={3}
                  alignItems="center"
                  sx={{ paddingBottom: 1 }}
                >
                  <Typography variant="h6">Order Details </Typography>

                  <SvgColor
                    src="/assets/icons/navbar/tracking.svg"
                    color="common.black"
                    sx={{ width: 20, height: 20 }}
                  />
                  <Typography variant="h6">
                    {inputData?.latest_status?.category}
                  </Typography>
                </Stack>
              </StyledHeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              paddingRight: 5,
            }}
          >
            <StyledTableRow>
              <StyledTableCell
                sx={{
                  width: { sm: 122, md: 165, lg: 180 },
                }}
                align="left"
              >
                Tracking Number
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  width: 15,
                }}
              >
                :
              </StyledTableCell>
              <StyledValueTableCell
                sx={{
                  color: "primary.light",
                  fontFamily: typography.fontFamilySemiBold,
                }}
              >
                {inputData?.tracking_number}
              </StyledValueTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Carrier</StyledTableCell>
              <StyledTableCell>:</StyledTableCell>
              <StyledValueTableCell>
                {inputData?.carrier ? inputData?.carrier : "--"}
              </StyledValueTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Order Number</StyledTableCell>
              <StyledTableCell>:</StyledTableCell>
              <StyledValueTableCell
                sx={{
                  fontFamily: typography.fontFamilySemiBold,
                }}
              >
                {inputData?.order_info?.map((item, i) =>
                  inputData.order_info.length === i + 1
                    ? item.order_details.increment_id
                    : `${item.order_details.increment_id}, `
                )}
              </StyledValueTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Customers Name</StyledTableCell>
              <StyledTableCell>:</StyledTableCell>
              <StyledValueTableCell
                sx={{
                  fontFamily: typography.fontFamilySemiBold,
                }}
              >
                {inputData?.customer_name
                  ? inputData.customer_name.includes("null")
                    ? ""
                    : inputData?.customer_name
                  : ""}
              </StyledValueTableCell>
            </StyledTableRow>
            <StyledTableRow sx={{ verticalAlign: "top" }}>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>:</StyledTableCell>
              <StyledValueTableCell
                sx={{
                  fontFamily: typography.fontFamilySemiBold,
                }}
              >
                {inputData?.shipping_city && inputData.shipping_city + ", "}
                {inputData?.shipping_state && inputData.shipping_state + " "}
                {inputData?.shipping_postal_code}
              </StyledValueTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        {/* Time in transit */}
        <Table sx={{ marginRight: 5 }} aria-label="Time in transit">
          <TableHead>
            <TableRow>
              <StyledHeadTableCell colSpan={3} sx={{ paddingBottom: 1 }}>
                <Typography variant="h6">Time in Transit</Typography>
              </StyledHeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell
                colSpan={3}
                sx={{
                  fontSize: { xs: 18, md: 20 },
                  color:
                    currentDateUnix < inputData?.delivered_date
                      ? "status.alert"
                      : "common.black",
                  fontFamily: typography.fontFamilySemiBold,
                }}
              >
                {inputData?.delivered_date
                  ? delivered_time_in_transit
                  : estimate_time_in_transit}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                sx={{
                  width: { lg: 180, sm: 122, md: 165 },
                }}
              >
                Shipped On
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  width: 15,
                }}
              >
                :
              </StyledTableCell>
              <StyledValueTableCell
                sx={{
                  fontFamily: typography.fontFamilySemiBold,
                }}
              >
                {unixToDate(inputData?.label_created_date)}
              </StyledValueTableCell>
            </StyledTableRow>

            {inputData?.delivered_date && (
              <StyledTableRow>
                <StyledTableCell
                  sx={{
                    width: { lg: 180, sm: 122, md: 165 },
                  }}
                >
                  Delivered On
                </StyledTableCell>
                <StyledTableCell>:</StyledTableCell>
                <StyledValueTableCell
                  sx={{
                    fontFamily: typography.fontFamilySemiBold,
                  }}
                >
                  {inputData?.delivered_date
                    ? unixToDate(inputData?.delivered_date)
                    : "--"}
                </StyledValueTableCell>
              </StyledTableRow>
            )}
            {!inputData?.delivered_date && (
              <StyledTableRow>
                <StyledTableCell
                  sx={{
                    width: { lg: 180, sm: 122, md: 165 },
                  }}
                >
                  Estimated Delivery
                </StyledTableCell>
                <StyledTableCell>:</StyledTableCell>
                <StyledValueTableCell
                  sx={{
                    fontFamily: typography.fontFamilySemiBold,
                  }}
                >
                  {inputData?.estimated_delivery_date_new &&
                  inputData?.estimated_delivery_date_new != "" &&
                  inputData?.estimated_delivery_date_new != null
                    ? unixToDate(inputData?.estimated_delivery_date_new)
                    : inputData?.estimated_delivery_date &&
                      inputData?.estimated_delivery_date != "" &&
                      inputData?.estimated_delivery_date != null
                    ? unixToDate(inputData?.estimated_delivery_date)
                    : "--"}
                </StyledValueTableCell>
              </StyledTableRow>
            )}
            {inputData?.estimated_delivery_date &&
              inputData?.estimated_delivery_date_new &&
              inputData?.estimated_delivery_date_new != "" &&
              inputData?.estimated_delivery_date_new != null && (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{
                      width: { lg: 180, sm: 122, md: 165 },
                    }}
                    align="left"
                  >
                    Original Est. Delivery
                  </StyledTableCell>
                  <StyledTableCell>:</StyledTableCell>
                  <StyledValueTableCell
                    sx={{
                      fontFamily: typography.fontFamilySemiBold,
                    }}
                  >
                    {unixToDate(inputData?.estimated_delivery_date)}
                  </StyledValueTableCell>
                </StyledTableRow>
              )}
          </TableBody>
        </Table>
      </StyledDetatilsRoot>
      <StyledDetatilsRoot>
        <Stack>
          <StyledHeadTableCell>
            {inputData?.latest_status?.category === "Delivered" && (
              <Button
                type="button"
                variant="contained"
                onClick={handleCreateReturn}
                sx={{
                  position: "absolute",
                  top: 17,
                  right: 0,
                  marginRight: "94px",
                  height: { xs: 36, lg: 40 },
                  width: "128px",
                  backgroundColor: "secondary.main",
                  boxShadow: "none",
                }}
              >
                Create Return
              </Button>
            )}
            <Typography variant="h6" sx={{ paddingBottom: 1 }}>
              Tracking Details
            </Typography>
          </StyledHeadTableCell>

          <Timeline
            sx={{
              padding: 0,
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            {inputData?.events?.map((item, index) => (
              <OrderItem
                key={item.date}
                item={item}
                isLast={index === inputData.events.length - 1}
                isFirst={index === 0}
              />
            ))}
          </Timeline>
        </Stack>
      </StyledDetatilsRoot>
    </ModalRowDetails>
  );
}

TrackingTableRowDetails.propTypes = {
  inputData: PropTypes.object,
  onExpandhandler: PropTypes.func,
};

export default TrackingTableRowDetails;
