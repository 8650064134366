import { decryptData, encryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendBaseUrl, backendUserUrl } from "src/utils/backendUrls";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    return decryptData(result);
  }
  return result;
};

export async function updateProfileInfo(data, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const header = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  return await putData(
    `${backendUserUrl}${tenant}/profile/info`,
    header,
    data,
    token
  );
}

export async function getUpcomingPayments(headers, userInfo) {
  const tenant = userInfo?.tenant_name;
  const token = userInfo?.accessToken;

  return await getData(
    `${backendUserUrl}${tenant}/billing/upcoming-payment`,
    null,
    headers,
    token
  );
}

export async function verifyEmail(data) {
  const headers = null;
  const result = await postData(
    `${backendUserUrl}frontend/user/verify_email`,
    headers,
    data
  );
  return result;
}

export async function forceUpdatePassword(data) {
  const headers = null;
  const result = await putData(
    `${backendUserUrl}frontend/user/password`,
    headers,
    data
  );
  return result;
}

export async function updatePassword(headers, data, userInfo) {
  const tenant = userInfo?.tenant_name;
  const token = userInfo?.accessToken;

  const result = await putData(
    `${backendUserUrl}${tenant}/profile/password`,
    headers,
    data,
    token
  );
  return result;
}

export async function resetPassword(data) {
  const headers = {
    action: "resetPassword",
    tenant: "frontend",
  };
  const result = await postData(`${backendBaseUrl}account`, headers, data);
  return JSON.parse(extractData(result));
  // return result;
}

export async function forgotPassword(data) {
  const headers = null;
  const result = await postData(
    `${backendUserUrl}frontend/user/forgot/password`,
    headers,
    data
  );
  return JSON.parse(extractData(result));
}

export async function loginCall(data) {
  const url = `${backendBaseUrl}account`;

  const headers = {
    action: "login",
  };

  const result = await postData(url, headers, data);
  return JSON.parse(extractData(result));
}
