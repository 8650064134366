// usersState.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUsersList } from "./usersApi";
import { logoutUser } from "../user/userState";

// Initial state for the list of users
const initialState = {
  usersList: {
    statusCode: 0,
    data: [],
  },
  isLoading: false,
  error: null,
};

// Async thunk to fetch the list of users
export const getUsers = createAsyncThunk(
  "users/fetchUsersList",
  async (userInfo) => {
    const response = await getUsersList(userInfo);
    return response;
  }
);

// Async thunk to update user list
// export const updateUsersList = createAsyncThunk(
// 	"users/updateUsersList",
// 	async ({ userId, newProfileInfo }) => {
// 		const response = await updateUserProfile(userId, newProfileInfo);
// 		return response.data; // Assuming the API response contains updated user data
// 	}
// );

// Create the user slice
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.usersList = initialState.usersList;
      });
    // .addCase(updateUserProfileInfo.fulfilled, (state, action) => {
    // 	// Update the user in the list with the updated profile info
    // 	state.users = state.users.map((user) =>
    // 		user.id === action.payload.id ? action.payload : user
    // 	);
    // });
  },
});

export const { selectProduct } = usersSlice.actions;

// Selectors
export const getUsersInfo = (state) => state?.users;
// export const getLoadingStatus = (state) => state?.users?.isLoading;
// export const getError = (state) => state?.users?.error;

export default usersSlice.reducer;
