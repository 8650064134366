import { Box, Button, Typography } from "@mui/material"
import ProductList from "./components/ProductList";
import palette from "src/theme/palette";
import { CreateReturn } from "./PortalApi";

const ReturnDetails = ({returnInfo, setIsReturn, setIsVerified, orders, selectedItems, setIsReturnSuccess, setIsLoading }) => {
    const data = {
        order_id: orders.order_id,
        line_items: selectedItems
    }
    const handleReturn = async () => {
        setIsLoading(true);
        try {
            const res = await CreateReturn(data);
            if (res.statusCode === 200) {
                setIsReturnSuccess(true);
            }
        } catch (e) {
            console.error(e.message);
        } finally {
            setIsLoading(false);
        }
    }
    return <>
        <Typography sx={{ padding: '20px', borderBottom: 'solid', borderColor: palette.secondary.lighter, borderWidth: '0.5px' }}>Confirm your selection</Typography>
        <Box sx={{ maxHeight: '380px', width: '100%', overflow: 'auto' }}>
            {/* <ProductList showPrice={true} showQuantity={true}/>
            <Box sx={{ padding: '20px' }}>
                <Typography sx={{ fontSize: '18px' }}>Refund Summary</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px', borderBottom: '1px solid #f7f7f7' }}>
                <Typography sx={{ color: '#0009' }}>Price:</Typography>
                <Typography>89.00</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px', borderBottom: '1px solid #f7f7f7' }}>
                <Typography sx={{ color: '#0009' }}>Shipping:</Typography>
                <Typography>-0</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px', borderBottom: '1px solid #f7f7f7' }}>
                <Typography sx={{ color: '#0009' }}>Total:</Typography>
                <Typography>$ 89.00</Typography>
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
                <Button variant="outlined" sx={{ borderColor: returnInfo.brandedAssetsState.brand_background_color, color: returnInfo.brandedAssetsState.brand_color}} onClick={() => [setIsVerified(true), setIsReturn(false)]}>Back</Button>
                <Button variant="contained" sx={{ backgroundColor: returnInfo.brandedAssetsState.brand_background_color, color: returnInfo.brandedAssetsState.brand_color}} onClick={handleReturn}>Create Return</Button>
            </Box>
        </Box>
    </>
}

export default ReturnDetails