import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

import ShopifyForm from "./shopifyForm/shopifyForm";
import MagentoForm from "./magentoForm/magentoForm";
import ShipstationForm from "./shipstationForm/shipstationForm";
import typography from "src/theme/typography";

import palette from "src/theme/palette";

import "./signinManage.scss";

const SigninManage = (navigateTo) => {
  const navigate = useNavigate();
  const signinData = useSelector((state) => state.signin.signinData);
  const { manager, user_id } = signinData || {};

  useEffect(() => {
    if (!user_id) navigate("/login");

    if (navigateTo.redirect === "magento") {
      window.scrollTo(0, 0);
    }
    if (navigateTo.redirect === "shipstation") {
      window.scrollTo(0, 0);
    }
    if (navigateTo.redirect === "shopify") {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="signin-manage-container">
      <h1 style={{ ...typography.h1, textAlign: "center" }}>
        Sign into your shipment manager accounts
      </h1>

      <Typography
        sx={{
          color: palette.common.black,
          fontSize: 18,
          textAlign: "center",
          paddingTop: 2,
        }}
      >
        This allows us to actually manage your data sources
      </Typography>
      {manager === "shopify" && <ShopifyForm />}
      {manager === "magento" && <MagentoForm />}
      {manager === "shipstation" && <ShipstationForm />}
    </div>
  );
};

export default SigninManage;
