import { useAppSelector } from "src/hooks/hooks";
import SvgColor from "src/components/svg-color/SvgColor";
import { getPlansInfo } from "src/features/plans/plansState";
import planNames from "src/utils/planNames";
import appUrls from "src/utils/appUrls";

const icon = (name) => <img src={`/assets/icons/navbar/${name}.svg`} />;

const navDetails = (user) => {
  const plansInfo = useAppSelector(getPlansInfo);
  const isDemoPlan = user?.plan_id === plansInfo[planNames.demo]?._id;

  let config = [
    {
      title: "Delivery Tracking",
      path: appUrls.outboundManagement.baseUrl,
      icon: icon("outbound-management"),
      children: [
        {
          title: "Dashboard",
          path: appUrls.outboundManagement.dashboard.absoluteUrl,
        },
        {
          title: "Tracking",
          path: appUrls.outboundManagement.tracking.absoluteUrl,
        },
        {
          title: "Carrier Origin Scan",
          path: appUrls.outboundManagement.carrierOriginScan.absoluteUrl,
        },
        {
          title: "Reports",
          path: appUrls.outboundManagement.reports.baseUrl,
          children: [
            {
              title: "Preshipment Alert",
              path: appUrls.outboundManagement.reports.preShipment.absoluteUrl,
            },
            {
              title: "In Transit Alert",
              path: appUrls.outboundManagement.reports.inTransit.absoluteUrl,
            },
            {
              title: "Average Time In Transit",
              path: appUrls.outboundManagement.reports.averageTimeInTransit
                .absoluteUrl,
            },
          ],
        },
      ],
    },
  ];

  if (
    user?.role?.toLowerCase() === "administrator" ||
    user?.role?.toLowerCase() === "admin"
  ) {
    config.push({
      title: "Returns & Refunds",
      path: appUrls.inboundManagement.baseUrl,
      icon: icon("inbound-management"),
      children: [
        {
          title: "Manage Returns",
          path: appUrls.inboundManagement.manageReturns.absoluteUrl,
        },
        {
          title: "Branded Returns Portal",
          path: appUrls.inboundManagement.brandedReturnsPortal.absoluteUrl,
        },
        {
          title: "Branded Tracking Portal",
          path: appUrls.inboundManagement.brandedReturnPortal.absoluteUrl,
        },
      ],
    });
    config.push({
      title: "Configuration Management",
      path: appUrls.configurationManagement.baseUrl,
      icon: icon("configuration"),
      children: [
        {
          title: "Delivery Tracking Configuration",
          path: appUrls.configurationManagement.deliveryTrackingConfiguration
            .absoluteUrl,
          icon: icon("configuration"),
        },
        {
          title: "Return Configuration",
          path: appUrls.configurationManagement.returnConfiguration.absoluteUrl,
        },
      ],
    });

    config.push({
      title: "Billing and Invoices",
      path: appUrls.billingAndInvoices,
      icon: icon("billing-and-invoices"),
    });

    config.push({
      title: "Users",
      path: appUrls.users,
      icon: icon("users"),
    });
  } else {
    config.push({
      title: "Returns & Refunds",
      path: appUrls.inboundManagement.baseUrl,
      icon: icon("inbound-management"),
      children: [
        {
          title: "Manage Returns",
          path: appUrls.inboundManagement.manageReturns.absoluteUrl,
        },
      ],
    });
  }

  if (isDemoPlan) {
    config.push({
      title: "Onboarding",
      path: "/onboarding/carrier",
      icon: icon("onboarding"),
    });
  }

  return config;
};

export default navDetails;
