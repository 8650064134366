import { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    Slider,
    Typography,
    styled,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { ChromePicker } from "react-color";
import { assetsUpload, returnAssetsUpload } from "src/features/brandedPortal/brandedPortal";
import { useDispatch, useSelector } from "react-redux";
import {
    postBrandedPortalInfo,
    uploadBrandedPortalImages,
    uploadToS3,
} from "src/features/brandedPortal/brandedPortalApi";
import { useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import { retrieveLogoUrl } from "src/features/config/configApi";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from "src/components/tooltip/Tooltip";
import palette from "src/theme/palette";
const BrandAssets = ({ data, errors, validateUrl, validateColor, setErrors }) => {
    const [logos, setLogo] = useState(data?.logo);
    const [background, setBackground] = useState(data?.background_image);
    const [logoPreview, setLogoPreview] = useState(data?.logo ? `${retrieveLogoUrl}${data.logo}` : '/assets/no-img.svg');
    const [backgroundPreview, setBackgroundPreview] = useState(data?.background_image ? `${retrieveLogoUrl}${data.background_image}` : '/assets/no-img.svg');
    const [favicons, setFavicon] = useState(data?.favicon);
    const [faviconPreview, setFaviconPreview] = useState(data?.favicon ? `${retrieveLogoUrl}${data.favicon}` : '/assets/no-img.svg');
    const [storeURL, setStoreURL] = useState(data?.logo_url);
    const [radius, setRadius] = useState(data?.corner_radius);
    const [bgColor, setBgColor] = useState(data?.page_bg_color || '');
    const [secondaryColor, setSecondaryColor] = useState(data?.secondary_color || '');
    const [secondaryColorPicker, setSecondaryColorPicker] = useState('');
    const [bgColorPicker, setBgColorPicker] = useState('');
    const [brandColor, setBrandColor] = useState(data?.brand_color || '');
    const [brandColorPicker, setBrandColorPicker] = useState("");
    const [headerText, setHeaderText] = useState(data?.heading);
    const userInfo = useAppSelector(getUserInfo)

    useEffect(() => {
        setLogo(data?.logo)
        setLogoPreview(data?.logo ? `${retrieveLogoUrl}${data.logo}` : '/assets/no-img.svg')
        setBackground(data?.background_image)
        setBackgroundPreview(data?.background_image ? `${retrieveLogoUrl}${data.background_image}` : '/assets/no-img.svg')
        setFavicon(data?.favicon)
        setFaviconPreview(data?.favicon ? `${retrieveLogoUrl}${data.favicon}` : '/assets/no-img.svg')
        setStoreURL(data?.logo_url)
        setRadius(data?.corner_radius)
        setBgColor(data?.page_bg_color || '')
        setSecondaryColor(data?.secondary_color || '')
        setBrandColor(data?.brand_color || '')
        setHeaderText(data?.heading)
    },[data])

    const handleURLChange = (e, name, state) => {
        const { value } = e.target;
        state(value);
        validateUrl(name, value);
    };

    const handleColorChange = (e, name, state) => {
        const { value } = e.target;
        if (value.length <= 7 && (value === '' || /^#[a-zA-Z0-9]*$/.test(value))) {
            state(value);
            validateColor(name, value);
        }
    };

    const ImageUploadSection = ({
        label,
        imagePreview,
        handleChange,
        handleClick,
        id,
        handleRemove
    }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Box sx={{ position: "relative", pl: 1 }}>
                    <Box onClick={handleClick} style={{ cursor: "pointer", position: 'relative' }}>
                        <img src="/assets/img-frame.svg" alt="image frame" />
                        <img
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                maxHeight: 40,
                                display: "block",
                            }}
                            src={imagePreview}
                        />
                    </Box>
                    <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id={id}
                        type="file"
                        onChange={handleChange}
                    />
                </Box>
            </Box>
            <Box>
                <Typography sx={{ color: palette.primary.main, mb: 1 }}>{label === 'Logo' ? 'Upload Logo' : label === 'Favicon' ? 'Upload Favicon': 'Upload Background Image'}</Typography>
                <Typography sx={{ color: "#AAAAAA", fontSize: '14px' }}>Format : JPG, PNG or SVG</Typography>
            </Box>
        </Box>
        {imagePreview !== '/assets/no-img.svg' && (
            <Button variant="outlined" sx={{ width: '130px' }} onClick={handleRemove}>Remove</Button>
        )}
        </Box>
    );
    const dispatch = useDispatch();
    const previewData = {
        logo: logos,
        background_image: background,
        logo_url: storeURL,
        favicon: favicons,
        corner_radius: radius,
        website_url: '',
        brand_color: brandColor,
        secondary_color: secondaryColor,
        page_bg_color: bgColor,
        heading: headerText
    }
    useEffect(() => {
        dispatch(
            returnAssetsUpload(previewData)
        );
    }, [logos, background, favicons, radius, storeURL, brandColor, secondaryColor, bgColor, headerText])

    const handleFileChange = (setFile, setPreview) => async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Step 1: Set blob URL for immediate preview
            const fileURL = URL.createObjectURL(file);
            // setPreview(fileURL);
    
            // Define file upload details
            const fileType = file.type;
            const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/svg+xml"];
    
            if (!validImageTypes.includes(fileType)) {
                alert("File format must be JPG, PNG, or SVG.");
                URL.revokeObjectURL(fileURL); // Cleanup blob URL
                return;
            }
    
            // Prepare upload data
            const fileExtension = file.name.split(".").pop();
            const fileBody = {
                content_type: fileType,
                file_extension: fileExtension,
                upload_task: "brand_portal",
            };
    
            try {
                const uploadResponse = await uploadBrandedPortalImages(fileBody, userInfo);
                if (uploadResponse.statusCode === 200) {
                    const headers = {
                        "Content-Type": fileType,
                        "Content-Length": file.size.toString(),
                        "x-amz-acl": "public-read",
                    };
                    await uploadToS3(uploadResponse.data.pre_signed_url, file, headers);
                    
                    // Step 2: Update preview with the final URL after successful upload
                    const finalURL = `${retrieveLogoUrl}${uploadResponse.data.file_path}`;
                    setFile(uploadResponse.data.file_path);
                    setPreview(finalURL); // Update to final URL
                } else {
                    throw new Error("Failed to get pre-signed URL");
                }
            } catch (error) {
                console.error(error.message);
                alert("Failed to upload image.");
            } finally {
                URL.revokeObjectURL(fileURL); // Cleanup blob URL
            }
        }
    };

    const handleLogoClick = () => document.getElementById("logo-button").click();
    const handleFavClick = () => document.getElementById("fav-button").click();
    const handleBackgroundClick = () => document.getElementById("background-button").click();

    const handleRemoveLogo = () => {
        setLogo(null);
        setLogoPreview('/assets/no-img.svg');
    };
    
    const handleRemoveFavicon = () => {
        setFavicon(null);
        setFaviconPreview('/assets/no-img.svg');
    };

    const handleRemoveBackground = () => {
        setBackground(null);
        setBackgroundPreview('/assets/no-img.svg');
    };
    
    const handleBrandColorChange = (color) => {
        setBrandColor(color.hex);
        setErrors({ ...errors, brandcolor: null })
    };

    const handleSecondaryColorChange = (color) => {
        setSecondaryColor(color.hex);
        setErrors({ ...errors, secondarycolor: null })
    };

    const handleBrandColor = () => {
        setBrandColorPicker(!brandColorPicker);
    };

    const handleBgColorChange = (color) => {
        setBgColor(color.hex);
        setErrors({ ...errors, backgroundcolor: null })
    };

    const handleSecondaryColor = () => {
        setSecondaryColorPicker(!secondaryColorPicker)
    }

    const handleBgColor = () => {
        setBgColorPicker(!bgColorPicker);
    };

    return (
        <>
            <Grid container spacing={3} sx={{ pb: 3, borderBottom: '2px solid #E9E9E9' }}>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Branded Logo</Typography>
                        <IconButton size="small" color='primary'><CustomTooltip title={<img src='/assets/brandlogotooltipimg.svg' />}><InfoOutlinedIcon /></CustomTooltip></IconButton>
                    </Box>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>You can customize the logo to suit your branding needs.</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #E6E1E1', padding: '5px 10px 5px 0', borderRadius: '12px', justifyContent: 'space-between' }}>
                        <ImageUploadSection
                            label="Logo"
                            imagePreview={logoPreview}
                            handleChange={handleFileChange(setLogo, setLogoPreview)}
                            handleClick={handleLogoClick}
                            id="logo-button"
                            handleRemove={handleRemoveLogo}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ py: 3, borderBottom: '2px solid #E9E9E9' }}>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Favicon</Typography>
                        <IconButton size="small" color='primary'><CustomTooltip title={<img src='/assets/favicontooltip.svg' />}><InfoOutlinedIcon /></CustomTooltip></IconButton>
                    </Box>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>You can customize the favicon to suit your branding needs.</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #E6E1E1', padding: '5px 10px 5px 0', borderRadius: '12px', justifyContent: 'space-between' }}>
                        <ImageUploadSection
                            label="Favicon"
                            imagePreview={faviconPreview}
                            handleChange={handleFileChange(setFavicon, setFaviconPreview)}
                            handleClick={handleFavClick}
                            id="fav-button"
                            handleRemove={handleRemoveFavicon}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ py: 3, borderBottom: '2px solid #E9E9E9' }}>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Background Image</Typography>
                    </Box>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Customize the background image to match your specific branding requirements.</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #E6E1E1', padding: '5px 10px 5px 0', borderRadius: '12px', justifyContent: 'space-between' }}>
                        <ImageUploadSection
                            label="Background"
                            imagePreview={backgroundPreview}
                            handleChange={handleFileChange(setBackground, setBackgroundPreview)}
                            handleClick={handleBackgroundClick}
                            id="background-button"
                            handleRemove={handleRemoveBackground}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ py: 3, borderBottom: '2px solid #E9E9E9' }}>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', gap: 2, mb: 1, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Brand portal heading</Typography>
                    </Box>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Edit and customize the brand portal heading.</Typography>
                </Grid>
                <Grid item xs={9} sx={{ margin: 'auto' }}>
                    <Box sx={{ position: "relative" }}>
                        <OutlinedInput
                            size="small"
                            placeholder="Header Text"
                            value={headerText}
                            onChange={(e) => setHeaderText(e.target.value)}
                            id="outlined-adornment-weight"
                            sx={{ color: "#000", width: '300px' }}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{ "aria-label": "weight" }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ py: 3, borderBottom: '2px solid #E9E9E9' }}>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', gap: 2, mb: 1, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Logo URL</Typography>
                    </Box>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Customize the destination URL for the logo click.</Typography>
                </Grid>
                <Grid item xs={9} sx={{ margin: 'auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ position: "relative" }}>
                            <OutlinedInput
                                size="small"
                                value={storeURL}
                                placeholder="www.yoursite.com"
                                onChange={(e) => handleURLChange(e, 'logourl', setStoreURL)}
                                id="outlined-adornment-weight"
                                sx={{ color: "#000", width: '300px' }}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{ "aria-label": "weight" }}
                                error={errors?.logourl}
                            />
                            {errors?.logourl && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom: '-25px' }}>{errors?.logourl}</Typography>}
                        </Box>
                        {/* <Button variant="outlined">Apply Return Portal Settings</Button> */}
                    </Box>
                </Grid>
            </Grid>



            <Grid container spacing={3} sx={{ py: 3, borderBottom: '2px solid #E9E9E9' }}>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Color theme</Typography>
                        <IconButton size="small" color='primary'><CustomTooltip placement='right' title={<img src='/assets/brandcolortooltip.svg' />}><InfoOutlinedIcon /></CustomTooltip></IconButton>
                    </Box>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Choose or customize the colors for your brand.</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box>
                            <Typography sx={{ color: "#000", marginLeft: 1 }}>
                                Brand color
                            </Typography>
                            <Box sx={{ position: "relative" }}>
                                <OutlinedInput
                                    size='small'
                                    value={brandColor}
                                    onClick={handleBrandColor}
                                    placeholder="#000000"
                                    onChange={(e) => handleColorChange(e, 'brandcolor', setBrandColor)}
                                    sx={{ m: 1, color: "#000" }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconButton edge="start">
                                                <Box sx={{ width: '20px', height: '20px', borderRadius: '5px', backgroundColor: `${brandColor ? brandColor : 'black'}` }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={errors?.brandcolor}
                                />
                                {errors?.brandcolor && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom: '-15px', ml: 1 }}>{errors?.brandcolor}</Typography>}
                            </Box>
                            {brandColorPicker && (
                                <Box style={{ position: "absolute", zIndex: 2 }}>
                                    <Box
                                        style={{
                                            position: "fixed",
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                        }}
                                        onClick={() => setBrandColorPicker(false)}
                                    />
                                    <ChromePicker
                                        color={brandColor}
                                        onChange={handleBrandColorChange}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#000", marginLeft: 1 }}>
                                Secondary color
                            </Typography>
                            <Box sx={{ position: "relative" }}>
                                <OutlinedInput
                                    size="small"
                                    value={secondaryColor}
                                    onClick={handleSecondaryColor}
                                    placeholder="#000000"
                                    onChange={(e) => handleColorChange(e, 'secondarycolor', setSecondaryColor)}
                                    sx={{ m: 1, color: "#000" }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconButton edge="start">
                                                <Box sx={{ width: '20px', height: '20px', borderRadius: '5px', backgroundColor: `${secondaryColor ? secondaryColor : 'black'}` }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={errors?.secondarycolor}
                                />
                                {errors?.secondarycolor && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom: '-15px', ml: 1 }}>{errors?.secondarycolor}</Typography>}
                            </Box>
                            {secondaryColorPicker && (
                                <Box style={{ position: "absolute", zIndex: 2 }}>
                                    <Box
                                        style={{
                                            position: "fixed",
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                        }}
                                        onClick={() => setSecondaryColorPicker(false)}
                                    />
                                    <ChromePicker color={secondaryColor} onChange={handleSecondaryColorChange} />
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#000", marginLeft: 1 }}>
                                Page background color
                            </Typography>
                            <Box sx={{ position: "relative" }}>
                                <OutlinedInput
                                    size="small"
                                    value={bgColor}
                                    onClick={handleBgColor}
                                    placeholder="#000000"
                                    onChange={(e) => handleColorChange(e, 'backgroundcolor', setBgColor)}
                                    sx={{ m: 1, color: "#000" }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconButton edge="start">
                                                <Box sx={{ width: '20px', height: '20px', borderRadius: '5px', backgroundColor: `${bgColor ? bgColor : 'black'}` }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={errors?.backgroundcolor}
                                />
                                {errors?.backgroundcolor && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom: '-15px', ml: 1 }}>{errors?.backgroundcolor}</Typography>}
                            </Box>
                            {bgColorPicker && (
                                <Box style={{ position: "absolute", zIndex: 2 }}>
                                    <Box
                                        style={{
                                            position: "fixed",
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                        }}
                                        onClick={() => setBgColorPicker(false)}
                                    />
                                    <ChromePicker color={bgColor} onChange={handleBgColorChange} />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ py: 3 }}>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Corner radius</Typography>
                        <IconButton size="small" color='primary'><CustomTooltip title={<img src='/assets/cornerradiustooltip.svg' />}><InfoOutlinedIcon /></CustomTooltip></IconButton>
                    </Box>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>Customize the radius by dragging the slider.</Typography>
                </Grid>
                <Grid item xs={9} sx={{ margin: 'auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Slider value={radius} sx={{ width: '300px', ml: '15px' }} onChange={(e) => setRadius(e.target.value)} />
                            <span>{radius}px</span>
                        </Box>
                        {/* <Button variant="outlined">Apply Return Portal Settings</Button> */}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default BrandAssets;
