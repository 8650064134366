import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import { removeTitleCase, unixToDate } from "src/utils/core";
import { Stack, Snackbar, Alert } from "@mui/material";
import SmartTable from "src/components/smart-table/SmartTable";

import { logoutUser, getUserInfo } from "src/features/user/userState";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { getUsers } from "src/features/usersPage/usersState";
import { getUsersList } from "src/features/usersPage/usersApi";
import { getTenantUser } from "src/features/usersPage/usersApi";
import {
  incrementAwaitingApiCounter,
  decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { capitalCase } from "change-case";
import { encConfig } from "src/dataFetcher/config";
import appUrls from "src/utils/appUrls";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    if (result?.data) return decryptData(result?.data);
  }
  return result;
};

const UsersPage = () => {
  const { pathname } = useLocation();

  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const trackingInfoRef = useRef({});

  const [expandedRow, setExpandedRow] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [totalItemsCount, setTotalItemCount] = useState(0);

  // current page of the table
  const [page, setPage] = useState(0);

  // number of table rows per page
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchKey, setSearchKey] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [advancedFormData, setAdvancedFormData] = useState({});
  const [expandedUserInfo, setExpandedUserInfo] = useState({});
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [statusAllData, setStatusAllData] = useState("");
  const [showExportInfo, setShowExportInfo] = useState(false);
  const [userSelectedColumns, setUserSelectedColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [product, setProduct] = useState("");
  const [modalLoader, setModalLoader] = useState(false);
  const sortDataString = `sort_data[${product}]`;
  const [isRefresh, setIsRefresh] = useState(false);
  const [seletectedUserId, setSelectedUserId] = useState(0);

  const searchPlaceholder = "Enter user first name";
  const defaultColumn = "email";
  const dataConverted = [];

  const advancedFilterUsersSearchData = {
    id: { name: "user_id", label: "User id" },
    first_name: { name: "user_first_name", label: "User First Name" },
    last_name: { name: "user_last_name", label: "User Last Name" },
    phone_number: { name: "user_phone_number", label: "User Phone Number" },
    email: { name: "user_email", label: "User Email" },
  };

  // formate key names and column order
  const originalRowsFormatted = usersList?.map(
    ({
      user_name,
      last_loggedin_at,
      role,
      createdAt,
      createdBy,
      createdSource,
      updatedAt,
      updatedBy,
      updatedSource,
      login_failed_attempts,
      last_forgot_password_trigerred_at,
      status,
      company_name,
      _id,
      is_onBoarded,
      ...rest
    }) => ({
      email: user_name,
      ...rest,
      role: capitalCase(role),
      last_login_date: last_loggedin_at,
      created_date: createdAt,
      created_by: createdBy,
      created_source: createdSource,
      updated_date: updatedAt,
      updated_by: updatedBy,
      updated_source: updatedSource,
      failed_attempts: login_failed_attempts,
      id: _id,
    })
  );

  originalRowsFormatted?.forEach((item) => {
    dataConverted.push({
      ...item,
    });
  });

  // fetches the users list
  const fetchData = async (isLoadAll = false, selected_page = null) => {
    let queryParams = getCurrentPageParams();

    if (selected_page != null) {
      queryParams["page_size"] = selected_page;
    }

    // TODO:  delete all of this when figure out how to load users when login in. In this way, calls dont occur everytime we go to the users page.
    if (isLoadAll) {
      let data;

      dispatch(getUsers(userInfo))
        .then((action) => {
          data = action.payload;
          if (action.error) console.log(action.error?.message);

          const dataFiltered = data?.data.map(
            ({
              tenant_id,
              last_loggedin_at,
              updatedAt,
              createdAt,
              is_demo_user,
              isMarketingUser,
              last_loggedout_at,
              last_password_reset_at,
              shipping_volume,
              createdSource,
              job_title,
              ...rest
            }) => {
              return {
                ...rest,
                updatedAt: updatedAt ? unixToDate(updatedAt) : "--",
                last_loggedin_at: last_loggedin_at
                  ? unixToDate(last_loggedin_at)
                  : "--",
                createdAt: createdAt ? unixToDate(createdAt) : "--",
                createdSource: createdSource,
              };
            }
          );

          setUsersList(dataFiltered || []);
          setTotalItemCount(dataFiltered?.length || 0);
        })
        .catch((err) => {
          console.log("e", err);
        });
    }
  };

  const getCurrentPageParams = (current_page = 1) => {
    let tempFormData = { ...advancedFormData };
    let queryParams = {
      page_size: rowsPerPage,
      current_page,
      [sortDataString]: order,
      ...tempFormData,
    };

    return queryParams;
  };

  // Function that gets the users list from the database
  const getUsersLogic = (parms, appendRow = false, isOnlyTrackNo = false) => {
    dispatch(incrementAwaitingApiCounter());
    setShowLoader(true);
    const res = getUsersList(userInfo);
    // const res = getTrackingList(cleanObject(parms), userInfo?.accessToken);
    res
      .then((result) => {
        // console.log('resulttt', result);

        const responseData = result.data;
        // if (usersList.length > 0 && appendRow && !isOnlyTrackNo) {
        //   setUsersList([...new Set(usersList), ...result.data]);
        // } else {
        //   setUsersList(result.data);
        // }
        // if (isOnlyTrackNo && responseData.length === 1) {
        //   setStatusList([responseData[0].status]);
        // }
        // if (result.data.current_page == 1) {
        //   setTotalItemCount(result.data.total_count);
        // }
        // dispatch(decrementAwaitingApiCounter());
        // setShowLoader(false);
      })
      .catch((error) => {
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      });
  };

  const onExpandhandler = (event, userId, index) => {
    event.preventDefault();

    setSelectedUserId(userId);

    if (expandedRow !== userId) {
      getSingleUserInfo(userId);
      setExpandedRow(userId);
    } else {
      setExpandedRow(null);
      setSearchKey(null);
    }
  };

  // gets the info of the user that was clicked on the table row
  const getSingleUserInfo = async (userId) => {
    if (!userId) return;

    setModalLoader(true);

    const headers = {
      tenant_id: userInfo.tenant_id,
      user_id: userId,
    };

    const response = await getTenantUser(null, headers, userId, userInfo);
    const data = response?.data;

    if (data) {
      const filtered_data = {
        first_name: data?.first_name || "",
        last_name: data?.last_name || "",
        phone_number: data?.phone_number || "",
        user_name: data?.user_name || "",
        id: data?._id || "",
        role: data?.role || "",
      };

      setExpandedUserInfo(filtered_data);
    }
    setModalLoader(false);
  };

  // Search by default column
  const handleSearchBtn = (e) => {
    const searchedVal = e.target.trackingNumber.value.trim();
    setSearchKey(searchedVal);
    setPage(0);
    setRowsPerPage(10);
    setTotalItemCount(0);
    setAdvancedFormData({});
    setShowAdvancedFilter(false);

    dispatch(getUsers(userInfo))
      .then((action) => {
        const data = action.payload;
        if (action.error) console.log(action.error?.message);

        const dataFiltered = data?.data.map(
          ({
            tenant_id,
            last_loggedin_at,
            updatedAt,
            createdAt,
            is_demo_user,
            isMarketingUser,
            last_loggedout_at,
            last_password_reset_at,
            shipping_volume,
            createdSource,
            ...rest
          }) => {
            return {
              ...rest,
              updatedAt: updatedAt ? unixToDate(updatedAt) : "--",
              last_loggedin_at: last_loggedin_at
                ? unixToDate(last_loggedin_at)
                : "--",
              createdAt: createdAt ? unixToDate(createdAt) : "--",
              createdSource: createdSource,
            };
          }
        );

        const filteredRows = dataFiltered.filter((row) => {
          const name1 = row["first_name"].toLowerCase();
          const name2 = searchedVal.toLowerCase();
          return name1.includes(name2);
        });

        setUsersList(filteredRows);
        setTotalItemCount(filteredRows.length);
      })
      .catch((err) => {
        console.log("e", err);
      });
  };

  // function to clean the search text
  const onSearchClick = () => {
    setSearchKey(null);
  };

  // Function that manage the pagination. The event parameter is needed for TablePagination API of MUI
  const handleChangePage = (event, newPage) => {
    // console.log('Page #', newPage);
    setPage(newPage);
    setExpandedRow(null);
    if (newPage <= page || usersList.length > rowsPerPage * (page + 1)) {
      return;
    }
    let paginationParams = {
      page_size: rowsPerPage,
      current_page: newPage + 1,
      [sortDataString]: order,
    };
    if (Object.keys(advancedFormData).length > 0) {
      Object.assign(paginationParams, advancedFormData);
    }
    getUsersLogic(paginationParams, true);
  };

  // Function that changes the number of rows per page
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (totalItemsCount > 1) {
      if (
        pathname === appUrls.outboundManagement.tracking.absoluteUrl ||
        advancedFormData.hasOwnProperty("status")
      ) {
        fetchData(false, newRowsPerPage);
      }
    }
  };

  // Function to manage what happens when the advanced filter btn is clicked
  const onClickAdvancedFilterBtn = () => {
    setSearchKey(null);
    setAdvancedFormData({});
    setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
    setPage(0);

    // fetch data
    fetchData(true);
  };

  // TODO: review this
  const onChangeAdvancedForm = (e = null) => {
    const { name, value } = e.target;
    let tempData = { ...advancedFormData };
    tempData[name] = value;
    setAdvancedFormData({ ...tempData });
  };

  function isDataisValid(df, key, value) {
    if (typeof value != "undefined" && value != "" && value != null) {
      if (
        typeof df[key] != "undefined" &&
        df[key] != "" &&
        df[key] != null &&
        df[key]
          .toString()
          .toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim())
      ) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  // TODO: review this
  const onSubmitAdvancedFilter = () => {
    let tempFormData = getCurrentPageParams(false);

    dispatch(getUsers(userInfo))
      .then((action) => {
        const data = action.payload;
        if (action.error) console.log(action.error?.message);

        const dataFiltered = data?.data.map(
          ({
            tenant_id,
            last_loggedin_at,
            updatedAt,
            createdAt,
            is_demo_user,
            isMarketingUser,
            last_loggedout_at,
            last_password_reset_at,
            shipping_volume,
            createdSource,
            ...rest
          }) => {
            return {
              ...rest,
              updatedAt: updatedAt ? unixToDate(updatedAt) : "--",
              last_loggedin_at: last_loggedin_at
                ? unixToDate(last_loggedin_at)
                : "--",
              createdAt: createdAt ? unixToDate(createdAt) : "--",
              createdSource: createdSource,
            };
          }
        );
        console.log(tempFormData);
        const newUserData = dataFiltered.filter((df) => {
          if (
            isDataisValid(df, "first_name", tempFormData.user_first_name) &&
            isDataisValid(df, "last_name", tempFormData.user_last_name) &&
            isDataisValid(df, "phone_number", tempFormData.user_phone_number) &&
            isDataisValid(df, "user_name", tempFormData.user_email)
          ) {
            return true;
          }
          return false;
        });
        // console.log(newUserData);
        setUsersList(newUserData || []);
        setTotalItemCount(newUserData?.length || 0);
      })
      .catch((err) => {
        console.log("e", err);
      });

    setExpandedRow(null);
    setPage(0);
  };

  // Function to handle the column display
  const handleColumnSubmit = (columnList) => {
    setUserSelectedColumns(
      columnList.map((item) => {
        if (!item.includes(" ")) return item[0].toLowerCase() + item.slice(1);
        else return removeTitleCase(item);
      })
    );
  };

  const onCloseExportInfo = () => {
    setShowExportInfo(false);
  };

  useEffect(() => {
    const abortController = new AbortController();

    fetchData(true);
    getSingleUserInfo(seletectedUserId);

    return () => {
      setIsRefresh(false);
      abortController.abort();
    };
  }, [isRefresh, userInfo]);

  return (
    <>
      <Helmet>
        <title> Users | BeyondCarts </title>
      </Helmet>
      <Snackbar
        open={showExportInfo}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={onCloseExportInfo}
      >
        <Alert
          onClose={onCloseExportInfo}
          severity="success"
          sx={{ width: "100%" }}
        >
          Export in progress
        </Alert>
      </Snackbar>

      <Stack>
        <SmartTable
          columnsToAvoid={["id"]}
          isSearch
          isAdvancedFilter
          isUsersPage
          placeholder={searchPlaceholder}
          advancedFilterUsersSearchData={advancedFilterUsersSearchData}
          isStatusMenu
          isRowSelectable
          // isAction
          originalRows={
            typeof dataConverted !== "undefined" ? dataConverted : [{}]
          }
          defaultColumn={defaultColumn}
          statusList={statusList}
          statusAllData={statusAllData}
          onExpandhandler={onExpandhandler}
          expandedRow={expandedRow}
          totelItemCount={totalItemsCount}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          onhandeSeachSubmit={handleSearchBtn}
          searchKey={searchKey}
          onSearchClick={onSearchClick}
          showLoader={showLoader}
          onChangeAdvancedForm={onChangeAdvancedForm}
          advancedFormData={advancedFormData}
          onSubmitAdvancedFilter={onSubmitAdvancedFilter}
          exapndRowIntputData={expandedUserInfo}
          trackingInfoRef={trackingInfoRef}
          showAdvancedFilter={showAdvancedFilter}
          onClickAdvancedFilter={onClickAdvancedFilterBtn}
          // exportButtonClicked={handleExportBtn}
          // trackingPage={true}
          userSelectedColumns={userSelectedColumns}
          handleColumnSubmit={handleColumnSubmit}
          modalLoader={modalLoader}
          setIsRefresh={setIsRefresh}
          usersList={dataConverted}
        />
      </Stack>
    </>
  );
};

export default UsersPage;
