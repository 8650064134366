import React from 'react';

const PolicyIcon = ({ color = 'currentColor' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2 17.5L16 14.7V17H17V13H13V14H15.3L12.5 16.8L13.2 17.5ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V8.7C17.6833 8.55 17.3583 8.42083 17.025 8.3125C16.6917 8.20417 16.35 8.125 16 8.075V2H2V16H8.05C8.1 16.3667 8.17917 16.7167 8.2875 17.05C8.39583 17.3833 8.525 17.7 8.675 18H2ZM2 15V16V2V8.075V8V15ZM4 14H8.075C8.125 13.65 8.20417 13.3083 8.3125 12.975C8.42083 12.6417 8.54167 12.3167 8.675 12H4V14ZM4 10H10.1C10.6333 9.5 11.2292 9.08333 11.8875 8.75C12.5458 8.41667 13.25 8.19167 14 8.075V8H4V10ZM4 6H14V4H4V6ZM15 20C13.6167 20 12.4375 19.5125 11.4625 18.5375C10.4875 17.5625 10 16.3833 10 15C10 13.6167 10.4875 12.4375 11.4625 11.4625C12.4375 10.4875 13.6167 10 15 10C16.3833 10 17.5625 10.4875 18.5375 11.4625C19.5125 12.4375 20 13.6167 20 15C20 16.3833 19.5125 17.5625 18.5375 18.5375C17.5625 19.5125 16.3833 20 15 20Z"
      fill={color || '#000'}
    />
  </svg>
);

export default PolicyIcon;