import { Grid, FormLabel, Select, MenuItem, FormControl } from "@mui/material";
import PropTypes from "prop-types";
import typography from "src/theme/typography";

function SelectInput({
  label,
  name,
  advancedFormData,
  onChangeAdvancedForm,
  listItem,
  defaultValue,
}) {
  return (
    <Grid
      item
      lg={label ? 3 : ""}
      // sm={4}
      width={{
        xs: "48%",
        sm: "50%",
        md: "60%",
        lg: !label && "12vw",
        xl: !label && "15vw",
      }}
    >
      {label && (
        <FormLabel
          htmlFor={`${name}-simple-select`}
          variant="subtitle2"
          sx={{
            color: "secondary.light",
            marginBottom: 1,
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {label}
        </FormLabel>
      )}
      <FormControl
        // fullWidth
        sx={{
          borderRadius: 1,
          width: "100%",
          bgcolor: "common.white",
          color: "primary.main",
          marginTop: label ? { lg: 1, sm: 0.5, xs: 0.5 } : {},

          "& .MuiInputBase-formControl": {
            paddingLeft: 0,
          },
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "primary.dark",
            },
            "&.Mui-focused fieldset": {
              borderColor: "primary.dark",
            },
          },
        }}
      >
        <Select
          labelId={`${name}-simple-select-label`}
          id={`${name}-simple-select`}
          name={name}
          value={defaultValue || advancedFormData[name]}
          onChange={(e) => onChangeAdvancedForm(e)}
          sx={{
            color: "common.black",
            paddingLeft: 2,
            height: { lg: 40, xs: 36 },
          }}
        >
          {defaultValue && (
            <MenuItem
              key={defaultValue}
              sx={{
                color: "common.black",

                // fontFamily: typography.fontFamilyRegular,
              }}
              value={defaultValue}
              selected
            ></MenuItem>
          )}
          {listItem?.map((item) => (
            <MenuItem
              key={item.value}
              sx={{
                color: "common.black",
                // fontFamily: typography.fontFamilyRegular,
              }}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  advancedFormData: PropTypes.object,
  onChangeAdvancedForm: PropTypes.func,
  listItem: PropTypes.array,
  sx: PropTypes.object,
  defaultValue: PropTypes.string,
};

export default SelectInput;
