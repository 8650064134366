import React, { useState } from "react";
import {
  Grid,
  FormLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import Button from "src/theme/overrides/Button";

function MultiSelectInput({
  label,
  name,
  advancedFormData,
  onChangeAdvancedForm,
  listItem,
  defaultValue,
}) {
  const [selectedValues, setSelectedValues] = useState(defaultValue || []);

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectedValues(value);
    onChangeAdvancedForm({ target: { name, value } }); // Forward the selected values to the parent component
  };

  return (
    <Grid item lg={3} sm={4} width={{ xs: "48%", sm: "50%", md: "60%" }}>
      {label && (
        <FormLabel
          htmlFor={`${name}-simple-select`}
          variant="subtitle2"
          sx={{
            color: "secondary.light",
            marginBottom: 1,
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {label}
        </FormLabel>
      )}
      <FormControl
        fullWidth
        sx={{
          borderRadius: 2,
          border: "4 solid #E0E0E0",
          width: "100%",
          bgcolor: "#FFF",
          color: "primary.main",
          marginTop: { lg: 1, sm: 0.5, xs: 0.5 },
          div: {
            height: { lg: 14, md: 8, sm: 2, xs: "35px !important" },
          },
        }}
      >
        <Select
          labelId={`${name}-simple-select-label`}
          id={`${name}-simple-select`}
          name={name}
          value={selectedValues}
          onChange={handleSelectChange}
          multiple
          sx={{
            color: "#000",
          }}
          renderValue={(selected) => selected.join(", ")}
        >
          {listItem?.map((item) => (
            <MenuItem
              key={item.value}
              sx={{ color: "#000" }}
              value={item.value}
            >
              <Checkbox checked={selectedValues.includes(item.value)} />
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

MultiSelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  advancedFormData: PropTypes.object,
  onChangeAdvancedForm: PropTypes.func,
  listItem: PropTypes.array,
  sx: PropTypes.object,
  defaultValue: PropTypes.array,
};

export default MultiSelectInput;
