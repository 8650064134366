import { FormControl, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import typography from "src/theme/typography";
import "./resetPassword.scss";

const ResetPassword = ({
  title,
  description,
  children,
  btnText,
  btnText2,
  handleSubmit,
  onSubmit,
  style,
}) => {
  const navigate = useNavigate();
  return (
    <div id="page">
      <div className="wrapper">
        <img
          src="/assets/beyond-carts-logo.svg"
          className="logo"
          alt="logo"
        ></img>
        <div className="content">
          <h2 style={{ ...typography.h3, textAlign: "center" }}>{title}</h2>

          <div className="container">
            {typeof description === "string" ? (
              <p className={"description"}>{description}</p>
            ) : (
              <div>
                {description?.map((el, i) => (
                  <p
                    key={i}
                    className={`description ${i === 1 && "description2"}`}
                  >
                    {el}
                  </p>
                ))}
              </div>
            )}
            <FormControl
              component="form"
              className="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              {children}
              <Button
                size="large"
                className="primaryBtn"
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  backgroundColor: "secondary.main",
                }}
              >
                {btnText}
              </Button>
              <Button
                className="secondaryBtn"
                disableElevation
                onClick={() => navigate("/login")}
              >
                {btnText2}
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
