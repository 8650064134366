import {useEffect, useState} from "react";
import {
  Stack,
  Checkbox,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel, Typography, Grid,
} from "@mui/material";

const TrackingItemCard = ({data, currency_symbol, returnInfo, themeConfig}) => {
    return (
      <Box sx={{display: 'flex', alignItems: 'center', width: '80%', padding: '20px 0 0 20px'}}>
        <img width={125} height={125} style={{objectFit: 'cover', border: '1px solid #E4E4E4', borderRadius: '10px'}}
             src={`${data.imgUrl}`}/>
        <Box sx={{width: '100%', pl: 2}}>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{marginY: 'auto'}}>
              <Box sx={{display: "flex", gap: 1, alignItems: 'center', justifyContent: 'space-between'}}>
                <Box>
                  <Typography sx={{
                    color: `${themeConfig?.color_theme?.secondary_color}`,
                    fontSize: '14px',
                    mb: 1,
                    padding: '5px 10px',
                    borderRadius: '20px',
                    width: 'max-content',
                    backgroundColor: '#E7E7E7'
                  }}>Reason for Refund : {data.reason}</Typography>
                  <Typography sx={{
                    color: `${themeConfig?.color_theme?.secondary_color}`,
                    fontSize: '14px',
                    mb: 1
                  }}>{data.title}</Typography>
                  <Typography sx={{color: `${themeConfig?.color_theme?.secondary_color}`, fontSize: '13px'}}>SKU <span
                    style={{color: `${themeConfig?.color_theme?.secondary_color}`}}>{data?.sku}</span></Typography>
                </Box>
                <Box>
                  <Typography sx={{
                    color: `${themeConfig?.color_theme?.secondary_color}`,
                    fontSize: '14px',
                    mb: 1
                  }}>{currency_symbol} {data.price}</Typography>
                  <Typography sx={{
                    color: `${themeConfig?.color_theme?.secondary_color}`,
                    fontSize: '13px',
                    textAlign: 'right'
                  }}>Qty <span
                    style={{color: `${themeConfig?.color_theme?.secondary_color}`}}>{data.quantity}</span></Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
;

export default TrackingItemCard;

