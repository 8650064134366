import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import typography from "src/theme/typography";
import { useState, useMemo } from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tab,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import palette from "src/theme/palette";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TuneIcon from "@mui/icons-material/Tune";
import { DatePicker } from "antd";
import AdvancedFilter from "./AdvancedFilter";
import EnhancedTableHead from "./TableHead";
import ReturnsDetail from "src/pages/returns/ReturnsDetail";
import CircularLoader from "src/loader/circular/CircularLoader";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useNavigate } from "react-router-dom";
import appUrls from "src/utils/appUrls";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable({
  rows,
  headCells,
  orderByDefault,
  statusList,
  isSearch,
  isDatePicker,
  isAdvanceFilter,
  isExport,
  getFilterData,
  tenantName,
  getDateRangeData,
  getSearchData,
  refresh,
  setRefresh,
  isFromManageReturns,
  setOpenAddBlockedCustomerModal,
  setUnblockCustomerEmail,
}) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(orderByDefault);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [type, setType] = useState(isFromManageReturns ? "Initiated" : "");
  const [open, setOpen] = useState(false);
  const { RangePicker } = DatePicker;
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setType(newValue);
  };
  const filteredHeadCells = headCells.filter((cell) => {
    if (type === "Refunded" || cell.id !== "refunded_amount") {
      return true;
    }
    return false;
  });
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id, line_items) => {
    setSelectedID(id);
    setLineItems(line_items);
    navigate(appUrls.inboundManagement.returnDetails.absoluteUrl, {
      state: { selectedID: id, lineItems: line_items },
    });
  };

  const handleUnblockCustomerClick = (event, email) => {
    setUnblockCustomerEmail(email);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(() => {
    const sortedRowsByUpdate = [...rows].sort((a, b) => {
      return new Date(b.updated_at) - new Date(a.updated_at);
    });
    const filteredRows = isFromManageReturns
      ? sortedRowsByUpdate.filter(
          (row) =>
            (type === "Initiated" && row.return_status === null) ||
            (row.return_status &&
              row.return_status.toLowerCase() === type.toLowerCase())
        )
      : sortedRowsByUpdate;
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rowsPerPage, rows, type]);

  const totalFilteredCount = useMemo(() => {
    if (!isFromManageReturns) return rows.length;
    return rows.filter(
      (row) =>
        (type === "Initiated" && row.return_status === null) ||
        (row.return_status &&
          row.return_status.toLowerCase() === type.toLowerCase())
    ).length;
  }, [rows, type]);

  const recordCounts = useMemo(() => {
    if (!statusList) return rows.length;
    return statusList.reduce((acc, status) => {
      acc[status] = rows.filter(
        (row) =>
          (status === "Initiated" && row.return_status === null) ||
          (row.return_status &&
            row.return_status.toLowerCase() === status.toLowerCase())
      ).length;
      return acc;
    }, {});
  }, [rows, statusList]);

  const StyledTableCell = styled(TableCell)(() => {
    const defaultStyle = {
      fontFamily: typography.fontFamilySemiBold,
      fontSize: { xs: 14, sm: 14, lg: 16 },
      textAlign: "center",
      color: "#000",
      textWrap: "nowrap",
    };
    return defaultStyle;
  });

  const StyledTableRow = styled(TableRow)(() => ({
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: isFromManageReturns
        ? "#efefef !important"
        : "transparent",
    },
  }));

  const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    color: theme.palette.common.black,
    "& .MuiInputBase-root": {
      border: "1px solid #757575",
      borderRadius: 4,
      width: "58px",
    },
    "& .MuiPaper-root": {
      color: theme.palette.common.black,
    },
  }));

  const handleClose = () => {
    setRefresh(!refresh);
    setOpen(false);
  };

  const handleRangeChange = (dates) => {
    if (dates?.length === 2) {
      const [start, end] = dates;
      const dateRange = {
        date_range: `${start.format("MM/DD/YYYY")}-${end.format("MM/DD/YYYY")}`,
      };
      getDateRangeData(dateRange);
    }
  };

  const handleSearch = () => {
    getSearchData(
      isFromManageReturns ? { order_number: search } : { email_id: search }
    );
  };

  const handleExport = () => {
    const exportData = visibleRows.map(
      ({ _id, created_at, updated_at, line_items, ...rest }) => ({
        ...rest,
        line_items_count: line_items ? Object.keys(line_items).length : 0,
      })
    );

    const capitalizeFirstLetter = (string) =>
      string.charAt(0).toUpperCase() + string.slice(1);

    const headers = Object.keys(exportData[0])
      .map(capitalizeFirstLetter)
      .join(",");

    const csvData = [
      headers,
      ...exportData.map((item) => Object.values(item).join(",")),
    ].join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_data.csv");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 3,
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
          {isSearch && (
            <OutlinedInput
              size="small"
              value={search}
              placeholder={
                isFromManageReturns ? "Order Number" : "Search by Email"
              }
              sx={{
                color: "#000",
                marginBottom: isFromManageReturns ? "20px" : 0,
                marginTop: "20px",
                overflow: "hidden",
              }}
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleSearch}
                    sx={{
                      backgroundColor: "secondary.main",
                      color: "#fff",
                      borderRadius: 0,
                      right: "-2px",
                      "&:hover": {
                        backgroundColor: "secondary.main",
                      },
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
          {isAdvanceFilter && (
            <Button
              variant="outlined"
              startIcon={<TuneIcon />}
              sx={{
                padding: "7px 20px",
                color: "secondary.main",
                border: "1px rgba(128, 128, 128, 0.32) solid",
                "&.btn-active, :hover": {
                  color: "common.white",
                  backgroundColor: "secondary.main",
                },
                boxShadow: "none",
              }}
              onClick={() => setIsShowFilter(!isShowFilter)}
            >
              Advanced Filter
            </Button>
          )}
          {isDatePicker && (
            <RangePicker
              size="large"
              format="MM-DD-YYYY"
              onChange={handleRangeChange}
            />
          )}
          {isExport && (
            <Button
              variant="contained"
              disabled={visibleRows.length > 0 ? false : true}
              sx={{
                boxShadow: "none",
                padding: "7px 24px",
                backgroundColor: "secondary.main",
              }}
              onClick={handleExport}
              startIcon={<SimCardDownloadIcon />}
            >
              Export
            </Button>
          )}
        </Box>
        {isFromManageReturns ? (
          <Button
            variant="contained"
            sx={{
              boxShadow: "none",
              padding: "7px 14px",
              bgcolor: "secondary.main",
            }}
            onClick={() => window.open(`/returns/${tenantName}`, "_blank")}
          >
            Create Return
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              boxShadow: "none",
              padding: "7px 14px",
              bgcolor: "secondary.main",
            }}
            onClick={() => setOpenAddBlockedCustomerModal(true)}
          >
            Block Customer
          </Button>
        )}
      </Box>
      {isShowFilter && <AdvancedFilter getFilterData={getFilterData} />}
      <TabContext value={type}>
        {statusList && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              {statusList.map((status) => {
                return (
                  <Tab
                    key={status}
                    label={
                      <span>
                        {status}{" "}
                        <span
                          style={{
                            backgroundColor: "#D6D6D6",
                            fontSize: "12px",
                            padding: "1px 10px",
                            borderRadius: "10px",
                            color: "#000",
                          }}
                        >{`${recordCounts[status]}`}</span>
                      </span>
                    }
                    value={status}
                  />
                );
              })}
            </TabList>
          </Box>
        )}
        <TabPanel value={type} sx={{ padding: "24px 0" }}>
          <Paper sx={{ width: "100%", mb: 2, borderRadius: "12px" }}>
            <TableContainer sx={{ borderRadius: "12px" }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={filteredHeadCells.filter(
                    (headCell) => headCell.id !== "_id"
                  )}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {visibleRows.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={filteredHeadCells.length}
                        sx={{
                          color: palette.primary.main,
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        No data found
                      </TableCell>
                    </TableRow>
                  ) : (
                    visibleRows.map((row, index) => {
                      return (
                        <StyledTableRow
                          hover
                          onClick={
                            isFromManageReturns
                              ? (event) =>
                                  handleClick(event, row._id, row.line_items)
                              : () =>
                                  handleUnblockCustomerClick(
                                    event,
                                    row.email_id
                                  )
                          }
                          tabIndex={-1}
                          key={row.id}
                          selected={selectedID}
                          sx={{
                            cursor: isFromManageReturns ? "pointer" : "auto",
                          }}
                        >
                          {filteredHeadCells
                            .filter((headCell) => headCell.id !== "_id")
                            .map((headCell) => (
                              <StyledTableCell
                                key={headCell.id}
                                component="th"
                                scope="row"
                              >
                                {headCell.id === "line_items"
                                  ? Object.keys(row[headCell.id]).length
                                  : row[headCell.id]}
                              </StyledTableCell>
                            ))}
                        </StyledTableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <StyledTablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={totalFilteredCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
