const BoxEditIcon=({color})=>{
    return(<>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3803_23976)">
<path d="M13.2994 4L7.33695 9.96247C6.7432 10.5562 4.98068 10.8312 4.58693 10.4375C4.19318 10.0437 4.46193 8.28122 5.05568 7.68747L11.0244 1.71873C11.1716 1.55814 11.3498 1.42906 11.5483 1.33924C11.7468 1.24943 11.9614 1.20074 12.1792 1.19616C12.3969 1.19158 12.6134 1.23116 12.8155 1.31255C13.0176 1.39393 13.2011 1.51543 13.3549 1.66968C13.5087 1.82393 13.6297 2.00774 13.7105 2.21003C13.7913 2.41232 13.8304 2.62888 13.8252 2.84666C13.82 3.06444 13.7707 3.27894 13.6804 3.47716C13.59 3.67538 13.4604 3.85325 13.2994 4Z"  stroke={color||"white"} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.875 2.5H3.75C3.08696 2.5 2.45111 2.76339 1.98227 3.23223C1.51343 3.70107 1.25 4.33696 1.25 5V11.25C1.25 11.9131 1.51343 12.5489 1.98227 13.0177C2.45111 13.4866 3.08696 13.75 3.75 13.75H10.625C12.0063 13.75 12.5 12.625 12.5 11.25V8.125" stroke={color||"white"} stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_3803_23976">
<rect width="15" height="15" fill="white"/>
</clipPath>
</defs>
</svg>

    </>)
}
export default BoxEditIcon;