import { Helmet } from "react-helmet-async";
import EnhancedTable from "src/components/enhanced-table/EnhancedTable";
import { useEffect } from "react";
import { getReturnList } from "./ReturnsApi";
import { useState } from "react";
import { unixToDate } from "src/utils/core";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";

import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { useDispatch } from "react-redux";

const Returns = () => {
  const orderByDefault = "updated_at";
  const [originalRows, setOriginalRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(incrementAwaitingApiCounter());

    getReturnList({ userInfo }).then((data) => {
      setOriginalRows(data?.data?.items);
      dispatch(decrementAwaitingApiCounter());
    });
  }, [refresh]);

  const getFilterData = (data) => {
    if (data) {
      dispatch(incrementAwaitingApiCounter());

      getReturnList({ data, userInfo })
        .then((data) => {
          setOriginalRows(data?.data?.items || []);
          dispatch(decrementAwaitingApiCounter());
        })
        .catch(() => dispatch(decrementAwaitingApiCounter()));
    }
  };

  const getDateRangeData = (data) => {
    if (data) {
      dispatch(incrementAwaitingApiCounter());

      getReturnList({ data, userInfo })
        .then((data) => {
          setOriginalRows(data?.data?.items || []);
          dispatch(decrementAwaitingApiCounter());
        })
        .catch(() => dispatch(decrementAwaitingApiCounter()));
    }
  };

  const getSearchData = (data) => {
    if (data) {
      dispatch(incrementAwaitingApiCounter());

      getReturnList({ data, userInfo })
        .then((data) => {
          setOriginalRows(data?.data?.items || []);
          dispatch(decrementAwaitingApiCounter());
        })
        .catch(() => dispatch(decrementAwaitingApiCounter()));
    }
  };

  const transformRow = (row) => {
    if (row?.refund) {
      delete row["refund"];
    }
    const transformedRow = {};
    for (const key in row) {
      if (
        key === "order_id" ||
        key === "created_by" ||
        key === "created_source" ||
        key === "updated_by" ||
        key === "updated_source" ||
        key === "tenant_id" ||
        key === "tenant_store" ||
        key === "return_flow" ||
        key === "billing_address" ||
        key === "customer_own_label" ||
        key === "label_type" ||
        key === "return_id"
      ) {
        continue;
      }
      if (row[key] === null) {
        transformedRow[key] = "-";
      } else if (key === "created_at" || key === "updated_at") {
        transformedRow[key] = unixToDate(row[key]);
      }
      // else if (key === 'line_items') {
      //     transformedRow[key] = row[key].length;
      // }
      else {
        transformedRow[key] = row[key];
      }
    }
    return transformedRow;
  };

  const rows = originalRows.map(transformRow);

  const generateHeadCells = (rows) => {
    if (rows.length === 0) return [];

    const firstRowKeys = Object.keys(rows[0]);

    const formatLabel = (key) => {
      if (key === "return_id") {
        return "Return ID";
      }
      if (key === "tracking_no") {
        return "Tracking Number";
      }
      if (key === "created_at") {
        return "Created Date";
      }
      if (key === "updated_at") {
        return "Updated Date";
      }
      return key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    return firstRowKeys.map((key) => ({
      id: key,
      label: formatLabel(key),
    }));
  };

  const statusList = [
    "Initiated",
    "Accepted",
    "Canceled",
    "Shipped",
    "Arriving Today",
    "Refunded",
    "Rejected",
  ];

  const headCells = generateHeadCells(rows);

  return (
    <>
      <Helmet>
        <title>Manage Returns | BeyondCarts</title>
      </Helmet>
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        tenantName={userInfo.tenant_name}
        orderByDefault={orderByDefault}
        statusList={statusList}
        isSearch
        isDatePicker
        isAdvanceFilter
        isExport
        getFilterData={getFilterData}
        getDateRangeData={getDateRangeData}
        getSearchData={getSearchData}
        refresh={refresh}
        setRefresh={setRefresh}
        isFromManageReturns
      />
    </>
  );
};

export default Returns;
