import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import ReturnRules from "./ReturnRules";
import { Box, Stack, Tab } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import EmailTemplate from "./EmailTemplate/EmailTemplate";
import AutoApproval from "./AutoApproval";
import ShippingRefund from "./ShippingRefund/ShippingRefund";
import ReturnMethod from "./ReturnMethod/ReturnMethod";
import palette from "src/theme/palette";
import BlockedCustomer from "./BlockedCustomer/BlockedCustomer";

const ReturnConfiguration = () => {
  const [value, setValue] = useState("return_rules");
  const [data, setData] = useState([""]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Return Configuration | BeyondCarts</title>
      </Helmet>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 2 }}>
          <TabList onChange={handleChange}>
            <Tab label="Return rules" value="return_rules" />
            <Tab label="Auto approval" value="auto_approval" />
            <Tab
              label="Shipping refund configuration"
              value="shipping_refund_configuration"
            />
            <Tab label="Return method" value="return_method" />
            <Tab label="Blocked customer" value="blocked_customer" />
            <Tab label="Email template" value="email_template" />
          </TabList>
        </Box>
        {data && (
          <>
            <TabPanel
              value="return_rules"
              sx={{
                padding: 0,
              }}
            >
              <ReturnRules />
            </TabPanel>
            <TabPanel
              value="auto_approval"
              sx={{
                padding: 0,
              }}
            >
              <AutoApproval />
            </TabPanel>
            <TabPanel
              value="shipping_refund_configuration"
              sx={{
                padding: 0,
              }}
            >
              <ShippingRefund />
            </TabPanel>
            <TabPanel
              value="return_method"
              sx={{
                padding: 0,
              }}
            >
              <ReturnMethod />
            </TabPanel>
            <TabPanel
              value="blocked_customer"
              sx={{
                padding: 0,
              }}
            >
              <BlockedCustomer />
            </TabPanel>
            <TabPanel
              value="email_template"
              sx={{
                padding: 0,
              }}
            >
              <EmailTemplate />
            </TabPanel>
          </>
        )}
      </TabContext>
    </>
  );
};

export default ReturnConfiguration;
