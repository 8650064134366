import axios from "axios";
import {decryptData} from "src/dataFetcher/chiper";
import {encConfig} from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData, {postDataNoEnc} from "src/dataFetcher/postDataFetcher";
import {backendBaseUrl, backendTrackingUrl, internalApiGateway_url} from "src/utils/backendUrls";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    return decryptData(result);
  }
  return result;
};

export async function getTrackingTimlineInfo(tracking_number, userInfo) {
  let headers = {
    tenant_id: userInfo?.tenant_id,
    // tenant_id: '6544c4caf9c56d2f8b0b29c6',
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
    headers.demo_record = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendTrackingUrl}returns/tracking-info/${tracking_number}`,
    {},
    headers,
    token
  );
  return result;
}

export async function getTrackingInfoFromCarrier(tenant_id, carrier, tracking_number,) {
  try {
    const result = await postDataNoEnc(
      `${internalApiGateway_url}/v1/carrier/track/sync`,
      {},
      {
        "trackNo": tracking_number,
        "carrierCode": carrier,
        "tenant_id": tenant_id
      },
    );
    return result;
  } catch (e) {
    return null
  }
}

export async function PostPortalLogin(userInfo, data) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    is_demo_user: userInfo.is_demo_user
  };
  const result = await postData(
    `${backendBaseUrl}returns/returns/brand_portal/customer/login`,
    headers,
    data,
    {}
  );
  return JSON.parse(extractData(result));
}

// export async function PostPortalLogin(userInfo, data) {
//   try {
//     const headers = {tenant_id: userInfo?.tenant_id};
//     const response = await axios.post(
//       `https://jyzuqize6hpxvfihwbwulsst5i0ntcmw.lambda-url.us-east-2.on.aws/order/api/V1/returns/brand_portal/customer/login`,
//       data,
//       {headers: headers}
//     );
//     return response.data;
//   } catch (error) {
//     throw error.response;
//   }
// }
export async function getOrderStatus(userInfo, data) {
  try {
    const headers = {tenant_id: userInfo?.tenant_id};
    const response = await axios.post(
      `https://jyzuqize6hpxvfihwbwulsst5i0ntcmw.lambda-url.us-east-2.on.aws/order/api/V1/returns/brand_portal/customer/login`,
      data,
      {headers: headers}
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function CreateReturn(userInfo, data) {
  try {
    const headers = {tenant_id: userInfo?.tenant_id};
    const response = await axios.post(
      `https://jyzuqize6hpxvfihwbwulsst5i0ntcmw.lambda-url.us-east-2.on.aws/order/api/V1/returns/create_return`,
      data,
      {headers: headers}
    );
    return response.data;
  } catch (e) {
    console.error(e, "return creation error");
    throw e;
  }
}

export async function SendOTP(data) {
  try {
    const response = await axios.post(
      `https://jyzuqize6hpxvfihwbwulsst5i0ntcmw.lambda-url.us-east-2.on.aws/order/api/V1/returns/create_return`,
      data
    );
    return response.data;
  } catch (e) {
    console.error(e, "return creation error");
    throw e;
  }
}

export async function sendVerifyEmailOTP(data) {
  const result = await postData(
    `${backendBaseUrl}tenant/admin/otp_action/generate_otp`,
    {},
    data,
    {}
  );
  return JSON.parse(extractData(result));
}

// export async function sendVerifyEmailOTP(data) {
//   try {
//     const response = await axios.post(
//       `https://qmcundq0fl.execute-api.us-east-2.amazonaws.com/v1/tenant/admin/otp_action/generate_otp`,
//       data
//     );
//     // TODO:
//     return true;
//   } catch (error) {
//     return false;
//   }
// }

export async function verifyEmailOTP(data) {
  const result = await postData(
    `${backendBaseUrl}tenant/admin/otp_action/verify_otp`,
    {},
    data,
    {}
  );
  return JSON.parse(extractData(result));
}

// export async function verifyEmailOTP(data) {
//   try {
//     const response = await axios.post(
//       `https://qmcundq0fl.execute-api.us-east-2.amazonaws.com/v1/tenant/admin/otp_action/verify_otp`,
//       data
//     );
//     return true;
//   } catch (error) {
//     return false;
//   }
// }
