import { Box, TableCell, TableHead, TableRow, TableSortLabel, styled } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import typography from "src/theme/typography";

const EnhancedTableHead = (props) => {
    const StyledTableCell = styled(TableCell)(() => {
        const defaultStyle = {
            fontFamily: typography.fontFamilySemiBold,
            fontSize: { xs: 14, sm: 14, lg: 16 },
            textAlign: "center",
            color: '#000',
            textWrap: "nowrap",
            "& .Mui-active": {
                color: '#000!important',
            },
            "& .MuiTableSortLabel-root:hover": {
                color: '#000!important',
            },
        };
        return defaultStyle
    });
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead