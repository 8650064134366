import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { setSigninData } from "src/features/signin/signin.action";
import { getPlans } from "src/features/onborading/onboardingApi";
import typography from "src/theme/typography";

import palette from "src/theme/palette";

import "./plans.scss";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import { convertLegacyProps } from "antd/es/button";
import { getPlansInfo } from "src/features/plans/plansState";
import planNames from "src/utils/planNames";

const Plans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signinData = useSelector((state) => state.signin.signinData);

  const { plansError, plan } = signinData || {};

  const selectedStyle = {
    backgroundColor: "#f2f3f6",
    transform: "scale(1.05)",
  };

  const [plans, setPlans] = useState([]);
  const [planSelected, setPlanSelected] = useState();

  const userInfo = useAppSelector(getUserInfo);
  const plansInfo = useAppSelector(getPlansInfo);

  const isDemoPlan = userInfo?.plan_id === plansInfo[planNames.demo]?._id;

  const handlePlanSelection = (plan) => {
    const obj =
      plan._id === plansInfo[planNames.premium]?._id && isDemoPlan
        ? { switched_to_premium: true }
        : { switched_to_premium: false };

    dispatch(
      setSigninData({
        ...signinData,
        plan,
        plan_id: plan._id,
        plansError: false,
        ...obj,
      })
    );
    setPlanSelected(plan);
  };

  useEffect(() => {
    if (!signinData?.user_id) {
      navigate("/login");
      return;
    }

    getPlans(isDemoPlan)
      .then((r) => {
        const { data, statusCode } = r;
        if (statusCode === 200) setPlans(data);
        if (plan) setPlanSelected(plan);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="plans-container">
      <h1 style={{ ...typography.h1, textAlign: "center" }}>
        Pick the plan that best suits you!
      </h1>
      <Typography
        sx={{
          color: palette.common.black,
          fontSize: 18,
          textAlign: "center",
          paddingTop: 2,
        }}
      >
        You can change your plan at anytime if you aren't satisfied.
      </Typography>

      <div className="plans-cards-container">
        {plans.map((p, index) => {
          if (!p?.display) return;
          return (
            <div
              key={index}
              className="plans-card"
              style={p._id === planSelected?._id ? selectedStyle : null}
              onClick={() => handlePlanSelection(p)}
            >
              <div>
                <h3 className="plans-card-title">{p.name}</h3>
                <h4 className="plans-card-title2">{p.sub_header}</h4>
              </div>

              <Typography
                sx={{
                  color: palette.common.black,
                  textAlign: "center",
                  paddingTop: 1,
                }}
              >
                {p.description}
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  columnGap: "5px",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: palette.primary.main,
                    fontSize: 18,
                    textAlign: "center",
                  }}
                >
                  ${p.price_per_order}
                </Typography>
                <Typography
                  sx={{
                    color: palette.common.black,
                    fontSize: 18,
                    textAlign: "center",
                  }}
                >
                  /order
                </Typography>
              </div>
            </div>
          );
        })}
      </div>

      {plansError && (
        <Typography
          sx={{
            color: palette.error.main,
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          You need to select 1 plan.
        </Typography>
      )}
    </div>
  );
};

export default Plans;
