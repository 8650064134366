import palette from "./palette";

function pxToRem(value) {
  return `${value / 16}rem`;
}

// Local Fonts imported in public/fonts
const FONT_PRIMARY_REGULAR = "LatoRegular";
const FONT_PRIMARY_MEDIUM = "LatoMedium";
const FONT_PRIMARY_SEMIBOLD = "LatoSemiBold";

const flexContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const typography = {
  fontFamilyRegular: FONT_PRIMARY_REGULAR,
  fontFamily: FONT_PRIMARY_MEDIUM,
  fontFamilySemiBold: FONT_PRIMARY_SEMIBOLD,
  bigTitle: {
    color: palette.primary.main,
    lineHeight: 80 / 64,
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  h1: {
    color: palette.primary.main,
    lineHeight: 80 / 64,
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  h2: {
    color: palette.secondary.main,
    lineHeight: 64 / 48,
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  h3: {
    color: palette.primary.main,
    lineHeight: 64 / 48,
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  h4: {
    color: palette.secondary.main,
    lineHeight: 64 / 48,
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  h5: {
    lineHeight: 64 / 48,
    fontSize: 22,
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  h6: {
    lineHeight: 64 / 48,
    fontSize: 20,
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  subtitle3: {
    lineHeight: "34.32px",
    fontFamily: FONT_PRIMARY_SEMIBOLD,
    letterSpacing: "0.17px",
  },
  subtitle1: {
    lineHeight: 1.5,
    fontFamily: FONT_PRIMARY_REGULAR,
    fontSize: pxToRem(18),
  },
  subtitle2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  subtitle4: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
    fontFamily: FONT_PRIMARY_REGULAR,
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.2,
    opacity: 0.8,
    fontFamily: FONT_PRIMARY_MEDIUM,
  },
  overline: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "uppercase",
  },
  dataPickerLabel: {
    color: palette.secondary.main,
    backgroundColor: palette.common.white,
    height: "100%",
    textAlign: "center",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    transform: "translateX(5px)",
    border: "1px solid #d9d9d9",
    fontFamily: FONT_PRIMARY_SEMIBOLD,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "capitalize",
  },
  normalText: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(18),
  },
  secondaryButton: {
    lineHeight: 28 / 18,
    fontSize: pxToRem(20),
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  shippingCardValue: {
    fontSize: "28px",
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  cardTitle: {
    fontSize: "22px",
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
  ReportsCardValue: {
    fontSize: "32px",
    fontFamily: FONT_PRIMARY_SEMIBOLD,
  },
};

export default typography;
