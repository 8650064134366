import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchScanData, fetchShippingStatisticData } from "./scanApi";

const initialState = {
	scan_statistics: {
		db_count: 0,
		scanned_count: 0,
		unscanned_count: 0,
		delivered_count: 0,
		estimated_date_count: 0,
		no_estimated_date_count: 0,
		scanned_per: "00.00",
		unscanned_per: "0.00",
		delivered_per: "0.00",
	},
	metrics: {
		expected_delivery: 0,
		delayed: 0,
		returned: 0,
		alert: 0,
		delivery_attempt: 0,
	},
	carriers: {},
	status_statistics: [
		{
			status: "Out for Delivery",
			volume: 0,
			percentage: "0.00",
		},
		{
			status: "Available for Pickup",
			volume: 0,
			percentage: "0.00",
		},
		{
			status: "Pre-Shipment",
			volume: 0,
			percentage: "0.00",
		},
		{
			status: "In Transit",
			volume: 0,
			percentage: "00.00",
		},
		{
			status: "Delivered",
			volume: 0,
			percentage: "0.00",
		},
	],
};

export const fetchScanStatus = createAsyncThunk(
	"scan/ScanStatus",
	async ({ startDate, endDate, token, headers }) => {
		const response = await fetchScanData(
			startDate,
			endDate,
			token,
			headers
		);
		return response.data;
	}
);

export const fetchShippingStatistic = createAsyncThunk(
	"scan/FetchShippingStatisticData",
	async ({ startDate, endDate, token, headers }) => {
		const response = await fetchShippingStatisticData(
			startDate,
			endDate,
			token,
			headers
		);
		return response.data;
	}
);

const scanSlice = createSlice({
	name: "scan",
	initialState,
	reducers: {
		resetScanState: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchScanStatus.fulfilled, (state, action) => {
				// state = action?.payload;
				if (action?.payload) {
					const { metrics, scan_statistics, status_statistics } =
						action?.payload;
					state.metrics = metrics;
					state.scan_statistics = scan_statistics;
				}
			})
			.addCase(fetchShippingStatistic.fulfilled, (state, action) => {
				if (action?.payload) {
					const { status_statistics, carriers } = action?.payload;
					state.carriers = carriers;
					state.status_statistics = status_statistics;
				}
			});
		// .addCase(fetchScanStatus.rejected, (state, action) => {
		//     state.= action.error.message;
		//   });
	},
});

export const { resetScanState } = scanSlice.actions;

export default scanSlice.reducer;
