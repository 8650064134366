export const textFieldStyles = (height, sx) => {
	return {
		width: { lg: 240, md: 220, sm: 220, xs: 220 },
		boxShadow: "none",
		backgroundColor: "common.white",
		fontSize: { xs: 14, sm: 14, md: 14, lg: 16 },
		lineHeight: "24px",
		"& :nth-of-type(1)": {
			fontSize: { xs: 14, sm: 14, md: 14, lg: 16 },
		},
		"& > div > input": {
			boxSizing: "border-box",
			color: "primary.main",
			height: height || { xs: 36, lg: 40 },
		},
		"& > div": {
			padding: 0,
		},
		"& .MuiOutlinedInput-root": {
			"&:hover fieldset": {
				borderColor: "primary.dark",
			},
			"&.Mui-focused fieldset": {
				borderColor: "primary.dark",
			},
		},
		...sx,
	};
};
