import getData from "src/dataFetcher/getDataFetcher";

import { backendBaseUrl, backendUserUrl } from "src/utils/backendUrls";
import { getDataNoEnc } from "src/dataFetcher/getDataFetcher";
import postData, { postDataNoEnc } from "../../../dataFetcher/postDataFetcher";
import axios from "axios";
import { encConfig } from "src/dataFetcher/config";
import { decryptData } from "src/dataFetcher/chiper";
import putData from "src/dataFetcher/putDataFetcher";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    return decryptData(result);
  }
  return result;
};

export async function getCustomerOrderInformation(userInfo, paramsData) {
  const url = backendBaseUrl;
  let headers = { tenant_id: userInfo?.tenant_id };
  if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
    headers["is_demo_user"] = true;
  }
  const token = "";
  const result = await getData(
    `${url}returns/returns/order_info/${paramsData.order_number}`,
    {},
    headers,
    token
  );
  return result;
}

export async function getOrderEligibleLineItems(userInfo, payload) {
  const url = backendBaseUrl;
  let headers = { tenant_id: userInfo?.tenant_id };
  if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
    headers["is_demo_user"] = true;
  }
  const token = "";
  const result = await postData(
    `${url}returns/returns/refund/eligible_line_items`,
    headers,
    payload,
    token
  );
  return JSON.parse(extractData(result));
}

export async function getEstimateReturnSummary(userInfo, payload) {
  const url = backendBaseUrl;
  let headers = { tenant_id: userInfo?.tenant_id };
  if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
    headers["is_demo_user"] = true;
  }
  const token = "";
  const result = await postData(
    `${url}returns/refunds/info/estimate/refund`,
    headers,
    payload,
    token
  );
  return JSON.parse(extractData(result));
}

export async function getEstimateRefundSummary(userInfo, payload) {
  const url = backendBaseUrl;
  let headers = { tenant_id: userInfo?.tenant_id };
  if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
    headers["is_demo_user"] = true;
  }
  const token = "";
  const result = await postData(
    `${url}returns/refunds/info/return_estimate_summary`,
    headers,
    payload,
    token
  );
  return JSON.parse(extractData(result));
}

export async function createReturnRequest(userInfo, payload) {
  const url = backendBaseUrl;
  let headers = { tenant_id: userInfo?.tenant_id };
  if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
    headers["is_demo_user"] = true;
  }
  const token = "";
  const result = await postData(
    `${url}returns/returns/create_return`,
    headers,
    payload,
    token
  );
  return JSON.parse(extractData(result));
}

export async function getReturnInformationByReturnID(userInfo, payload) {
  const url = backendBaseUrl;
  let headers = { tenant_id: userInfo?.tenant_id };
  if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
    headers["is_demo_user"] = true;
  }
  const token = "";
  const result = await getData(
    `${url}tenant/brand_portal/returns/list/${payload.return_id}`,
    {},
    headers,
    token
  );
  return result;
}

export async function getBrandPortalInfo(tenantInfo, params) {
  const url = backendBaseUrl;
  let headers = { tenant_id: tenantInfo?.tenant_id };
  if (tenantInfo?.is_demo_user && tenantInfo.is_demo_user == "true") {
    headers["is_demo_user"] = true;
  }
  const token = "";
  const result = await getData(
    `${url}tenant/${tenantInfo.tenant_name}/return/brand_portal/info`,
    {},
    headers,
    token
  );
  return result;
}

export async function getTrackingPortalInfo(tenantInfo, params) {
  const url = backendBaseUrl;
  let headers = { tenant_id: tenantInfo?.tenant_id };

  if (tenantInfo?.is_demo_user && tenantInfo.is_demo_user == "true") {
    headers["is_demo_user"] = true;
  }
  const token = tenantInfo?.accessToken;
  const result = await getData(
    `${url}tenant/${tenantInfo.tenant_name}/return/tracking_portal/info`,
    {},
    headers,
    token
  );
  return result;
}

export async function putBrandPortalInfo(userInfo, payload) {
  let headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
    headers["is_demo_user"] = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${backendBaseUrl}tenant/${userInfo.tenant_name}/return/tracking_portal/info`,
    headers,
    payload,
    token
  );
  return JSON.parse(extractData(result.data));
}

// export async function putBrandPortalInfo(tenantInfo, payload) {
//   const url = "https://qmcundq0fl.execute-api.us-east-2.amazonaws.com/v1/tenant";
//   const headers = {tenant_id: tenantInfo?.tenant_id};
//   const token = "";
//   const result = axios.put(
//     `${url}/relieffactor/return/tracking_portal/info`,
//     payload, {
//       headers,
//     }
//   );
//   return result;
// }

export async function getBrandPortalProductInfo(userInfo, params) {
  const url = backendBaseUrl;
  let headers = { tenant_id: userInfo?.tenant_id };
  if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
    headers["is_demo_user"] = true;
  }
  const token = "";
  const result = await getData(
    `${url}returns/returns/products/images`,
    {},
    headers,
    token
  );
  return result;
}

export async function getTenantInfo(userInfo, params) {
  const url = backendBaseUrl;
  const token = "";
  const result = await getData(
    `${url}tenant/returns/portal/tenant/tenant_info?tenant_name=${params.tenant_name}`,
    {},
    {},
    token
  );
  return result;
}
