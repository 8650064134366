export const emailConfigurationTexts = {
  twoWayReturn: {
    templateName: "2_way_label",
    title: "2 Way Returns",
    subtitle:
      "This email is automatically triggered when the return is accepted with 2 way label, this configuration is tenant specific.",
    button1Text: "Edit template",
    button2Text: "Preview",
  },
  customerManagedReturns: {
    templateName: "own_label",
    title: "Customer managed return label",
    subtitle:
      "This email is automatically triggered when the return is accepted with customer's own label, they need to update the tracking number to us.",
    button1Text: "Edit template",
    button2Text: "Preview",
  },
  freeLabel: {
    templateName: "free_label",
    title: "Free Label",
    subtitle:
      "This email is automatically triggered when the return is accepted with a free label, the label will be provided by BeyondCarts.",
    button1Text: "Edit template",
    button2Text: "Preview",
  },
  paidLabel: {
    templateName: "paid_label",
    title: "Paid Label",
    subtitle:
      "This email is automatically triggered when the return is accepted with a paid label, the shipping cost will be deducted from refund amount.",
    button1Text: "Edit template",
    button2Text: "Preview",
  },

  emailTemplatePageArr: [
    {
      templateName: "otp_triggered",
      title: "OTP Mail",
      subtitle:
        "When a customer initiates a return order, a One-Time Password (OTP) will be sent to their registered email address for return verification. This step ensures the secure processing of the return request.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
    {
      templateName: "request_initiated",
      title: "Return Request Initiated",
      subtitle:
        "This email confirms that the customer's return order has been well received and is being successfully initiated.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
    {
      templateName: "request_initiated_customer_support",
      title: "Return Request Initiated (By CX)",
      subtitle:
        "This email confirms the acceptance of the return order and provides detailed instructions for the customer to return their package.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
    {
      templateName: "free_label",
      title: "Free Label",
      subtitle:
        "This email is automatically triggered when the return is accepted with a free label, the label will be provided by BeyondCarts.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
    {
      templateName: "refund_delivery",
      title: "Refund during delivery",
      subtitle:
        "This email is sent to notify the customer that the refund for their return order has been processed.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
    {
      templateName: "return_reject",
      title: "Return Rejection",
      subtitle:
        "This email is sent to notify the customer that their return order has been rejected.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
    {
      templateName: "direct_refund",
      title: "Refund without return",
      subtitle:
        "This email is sent to notify the customer that their refund has been processed without the need for a return.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
    {
      templateName: "tracking_number_updated",
      title: "Tracking Number Updated",
      subtitle:
        "This email is sent to notify the customer that the traking number has been updated for an order.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
    {
      templateName: "update_tracking_number_reminder",
      title: "Update Tracking Number Reminder",
      subtitle:
        "This email is sent to notify the customer that they have not update the tracking number for an order.",
      button1Text: "Edit template",
      button2Text: "Preview",
    },
  ],
};
