import {useEffect, useState} from "react";
import {
  Stack,
  Checkbox,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel, Typography,
} from "@mui/material";
import ItemCardProductInfo from "./ItemCardProductInfo";


const TrackingPageItemCard = ({data,currency_symbol, returnInfo}) => {
    return (
      <Box sx={{boxShadow: "0px 9px 30px 0px #00000014", margin: "auto", mb: 2, width: '852px', borderRadius: '10px', zIndex:2, backgroundColor: 'white'}}>
        <Box sx={{
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: "space-between"
        }}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
            <ItemCardProductInfo currency_symbol={currency_symbol} data={data}/>.
          </Box>
          <Typography sx={{padding: '5px 10px', borderRadius: '20px', color: '#FF8E09', backgroundColor: '#ffe8ce'}}>{returnInfo?.return_status}</Typography>
        </Box>
        <hr style={{border: '1px solid #D9D9D8'}}/>
        <Box sx={{padding: '20px', display: 'flex', gap: 1}}>
          <Box>
            <Typography sx={{color: '#AAAAAA'}}>Return Reason :</Typography>
            <Typography sx={{color: '#AAAAAA', mt: 2}}>Additional Comment :</Typography>
          </Box>
          <Box>
            <Typography sx={{color: '#000'}}>{data.reason}</Typography>
            <Typography sx={{color: '#000', mt: 2}}>{data.customer_notes}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }
;

export default TrackingPageItemCard;
