import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendBaseUrl } from "src/utils/backendUrls";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    return decryptData(result);
  }
  return result;
};

export async function getReturnRules(userInfo) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/rules/info/return_rule`,
    null,
    headers,
    token
  );
  return result;
}

export async function enableReturnRule(userInfo, action, data) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${backendBaseUrl}tenant/${tenant_name}/return/rule/info/${
      action ? "active" : "inactive"
    }`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result));
}

export async function updateReturnRules(userInfo, data) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/rules/info`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result.data));
}

export async function getProductList(userInfo, params) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}returns/returns/products/list`,
    params,
    headers,
    token
  );
  return result;
}

export async function getSpecificReturnConfig(userInfo, config_name) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/rules/info/${config_name}`,
    {},
    headers,
    token
  );
  return result;
}

export async function updateSpecificReturnConfig(userInfo, data) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/rules/info`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result.data));
}
