import { Box } from "@mui/material";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import { retrieveLogoUrl } from "src/features/config/configApi";
import typography from "src/theme/typography";

const CompanyNameAndLogo = ({ display }) => {
  const userInfo = useAppSelector(getUserInfo);

  return (
    <Box
      sx={{
        display: display ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <img
        style={{
          display: "block",
          height: "50px",
        }}
        src={
          userInfo?.tenant_logo ? retrieveLogoUrl + userInfo.tenant_logo : ""
        }
      />
      <h4 style={typography.h3}>{userInfo?.company_name}</h4>
    </Box>
  );
};

export default CompanyNameAndLogo;
