import { useEffect, useState } from "react";
import {
  Stack,
  Box,
  TextField,
  Divider,
  Button,
  FormHelperText,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ModalUserRowDetails from "../modal/ModalUserRowDetails";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import Iconify from "src/components/iconify";
import typography from "src/theme/typography";
import {
  getTenantUser,
  promoteToAdmin,
  updateTenantPassword,
  updateTenantUser,
} from "src/features/usersPage/usersApi";
import { MuiTelInput } from "mui-tel-input";
import palette from "src/theme/palette";
import { useForm } from "react-hook-form";
import CircularLoader from "src/loader/circular/CircularLoader";
import isAdminUser from "src/utils/isAdminUser";
import { ErrorMessagesSchema } from "src/utils/errorMessagesSchema";
import { passwordValidator } from "src/utils/validators";
import { handlePhoneChange } from "src/utils/formatPhone";

const styleInput = {
  input: { color: palette.common.black },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "primary.dark",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.dark",
    },
  },
};

const UsersTableRowDetails = ({
  inputData,
  onExpandhandler,
  usersList,
  setIsRefresh,
}) => {
  const selectedUserId = inputData?.id;
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const [statusMessage, setStatusMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [isAdminMessage, setIsAdminMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openPasswordHasChanged, setOpenPasswordHasChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [roleChanged, setRoleChanged] = useState(false);
  const [isRoleChanged, setIsRoleChanged] = useState(false);
  const isAdmin = roleChanged
    ? !isAdminUser(inputData)
    : isAdminUser(inputData);
  const [isBasicUser, setIsBasicUser] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [adminUsersCount, setAdminUsersCount] = useState(
    usersList.filter((d) => d?.role?.toLowerCase() === "administrator").length
  );

  // tenant that created the logged tenant
  const isPrimaryTenant = userInfo?.createdBy === inputData?.id;

  const isLoggedUser = inputData?.id === userInfo?.user_id;

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: async () => {
      try {
        if (Object.keys(inputData).length !== 0) {
          const data = await getTenantUser(
            null,
            {
              tenant_id: userInfo.tenant_id,
              user_id: userInfo.user_id,
            },
            isLoggedUser ? userInfo.user_id : inputData?.id,
            userInfo
          );

          setIsLoading(false);

          return {
            first_name: data?.data?.first_name,
            last_name: data?.data?.last_name,
            user_name: data?.data?.user_name,
            phone_number: setPhoneNumber(data?.data?.phone_number || ""),
          };
        }
      } catch (error) {}
    },
  });

  const passwordInput = watch("new_password");
  const confirmPasswordInput = watch("confirm_password");

  useEffect(() => {
    if (errors.new_password && passwordInput) clearErrors("new_password");
  }, [passwordInput]);

  useEffect(() => {
    setPasswordError(false);
    if (errors.confirm_password && confirmPasswordInput)
      clearErrors("confirm_password");
  }, [confirmPasswordInput]);

  const [loggedUserNewData, setLoggedUserNewData] = useState({});

  const onSubmitUpdate = async (data) => {
    setIsLoading(true);

    // update tenant user in db
    try {
      // update logged user in redux slice
      if (isLoggedUser) setLoggedUserNewData(data);

      const body = {
        role: inputData?.role,
        ...data,
        phone_number: phoneNumber,
      };

      const response = await updateTenantUser(body, inputData?.id, userInfo);

      if (response?.status === 200) {
        setIsError(false);
        setSuccessfulUpdate(true);
      }

      setIsLoading(false);
    } catch (err) {
      setIsError(true);
      setIsLoading(false);
      setStatusMessage("There has been an unexpected error. Try again");
    }
  };

  const handlePromoteToAdmin = async () => {
    const headers = {
      tenant_id: userInfo.tenant_id,
      user_id: userInfo.user_id,
    };

    if (isAdmin) {
      // update tenant user role to basic when its admin
      const response = onSubmitUpdate({
        first_name: inputData?.first_name,
        last_name: inputData?.last_name,
        phone_number: inputData?.phone_number,
        role: "basic",
      });
      // TODO: here there is a mistake! when we change role from admin to basic for the logged user, actually that is not been updated in the backend. If i loggin again, he is still admin. but we are calling the api onSubmitUpdate
      setRoleChanged(!roleChanged);
      setIsRoleChanged(true);
      setIsBasicUser(true);
      return;
    }

    let response;
    setIsLoading(true);

    try {
      response = await promoteToAdmin(headers, selectedUserId, userInfo);
      if (response?.statusCode === 200) {
        setAdminUsersCount((prev) => prev + 1);
        setRoleChanged(!roleChanged);
        setIsRoleChanged(true);
        setIsBasicUser(false);
        setSuccessfulUpdate(true);
      } else {
        setIsError(true);
        setIsAdminMessage("❌There was an error promoting this user to admin");
      }
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsAdminMessage(error.message);
      setIsLoading(false);
    }
  };

  const onChangePasswordSubmit = async (data) => {
    if (data.new_password !== data.confirm_password) {
      setPasswordError(true);
      setStatusMessage(`${ErrorMessagesSchema.passwordValidation.dontMatch}`);
      return;
    }

    if (!passwordValidator(data?.new_password)) {
      setPasswordError(true);
      setStatusMessage(
        `${ErrorMessagesSchema.passwordValidation.requirements}`
      );
      return;
    }

    setIsLoading(true);

    const obj = {
      password: data?.new_password,
      confirm_password: data?.confirm_password,
    };

    let response;
    try {
      setStatusMessage("");
      response = await updateTenantPassword(obj, selectedUserId, userInfo);

      setIsLoading(false);

      if (
        response?.data?.statusMessage?.startsWith(
          "Password does not conform to policy"
        )
      ) {
        setIsError(true);
        setStatusMessage(
          `${ErrorMessagesSchema.passwordValidation.requirements}`
        );
      } else if (
        response?.data?.statusMessage?.startsWith("Attempt limit exceeded")
      ) {
        setIsError(true);
        setStatusMessage(
          `${ErrorMessagesSchema.passwordValidation.attemptLimit}`
        );
      }

      setIsError(false);
      reset();

      setOpenPasswordHasChanged(true);
    } catch (error) {
      setIsError(true);
      setStatusMessage("There has been an unexpected error. Try again");
    }
  };

  return isLoading ? (
    <CircularLoader />
  ) : (
    <ModalUserRowDetails
      inputData={inputData}
      onExpandhandler={onExpandhandler}
      loggedUserNewData={loggedUserNewData}
      setIsRefresh={setIsRefresh}
    >
      {openChangePassword ? (
        <>
          {/* modal: successful password update */}
          {openPasswordHasChanged ? (
            <Stack
              gap={4}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "100%",
                color: "common.black",
                paddingY: 2,
                paddingX: 3,
                fontFamily: typography.fontFamilyRegular,
                backgroundColor: "white",
              }}
            >
              <h2
                style={{
                  fontFamily: typography.fontFamilySemiBold,
                  lineHeight: 1.3,
                  textAlign: "center",
                  width: "70%",
                }}
              >
                User password updated successfully
              </h2>
              <Button
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  boxShadow: "none",
                  backgroundColor: palette.secondary.main,
                  width: {
                    lg: 300,
                    md: 300,
                    xs: "100%",
                  },
                }}
                onClick={() => {
                  setOpenPasswordHasChanged(false);
                  setOpenChangePassword(false);
                }}
              >
                Back
              </Button>
            </Stack>
          ) : (
            <Stack
              gap={6}
              direction={"column"}
              sx={{
                width: "100%",
                color: "common.black",
                fontFamily: typography.fontFamilyRegular,
                backgroundColor: "white",
              }}
            >
              <FormControl
                component="form"
                className="form"
                onSubmit={handleSubmit(onChangePasswordSubmit)}
                sx={{ width: { lg: 600, md: 500, xs: 300 } }}
              >
                <Stack>
                  <h2
                    style={{
                      ...typography.h2,
                      textAlign: "center",
                    }}
                  >
                    Change user password
                  </h2>
                </Stack>
                <Stack
                  gap={2}
                  alignItems={"center"}
                  sx={{
                    width: "100%",
                    color: "common.black",
                    paddingX: 3,
                    fontFamily: typography.fontFamilyRegular,
                    backgroundColor: "white",
                  }}
                >
                  <TextField
                    fullWidth
                    sx={styleInput}
                    label="New Password"
                    name="new_password"
                    type={showPassword ? "text" : "password"}
                    autoFocus
                    {...register("new_password", {
                      required: `${ErrorMessagesSchema.newPassword.required}`,
                    })}
                    error={!!errors.new_password}
                    aria-invalid={!!errors.new_password}
                    helperText={
                      errors.new_password && errors.new_password.message
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    fullWidth
                    label="Confirm Password"
                    name="confirm_password"
                    sx={styleInput}
                    type={showPassword ? "text" : "password"}
                    {...register("confirm_password", {
                      required: `${ErrorMessagesSchema.confirmPassword.required}`,
                    })}
                    error={!!errors.confirm_password || passwordError}
                    aria-invalid={!!errors.confirm_password}
                    helperText={
                      errors.confirm_password
                        ? errors.confirm_password.message
                        : passwordError
                        ? statusMessage
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: 2,
                      boxShadow: "none",
                      backgroundColor: palette.secondary.main,
                      width: {
                        lg: 350,
                        md: 350,
                        xs: "100%",
                      },
                    }}
                  >
                    Change Password
                  </Button>
                </Stack>
              </FormControl>
            </Stack>
          )}
        </>
      ) : (
        <>
          {successfulUpdate ? (
            <Stack
              gap={3}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "100%",
                color: "common.black",
                paddingY: 0.5,
                paddingX: 5,
                fontFamily: typography.fontFamilyRegular,
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box
                  style={{
                    fontFamily: typography.fontFamilySemiBold,
                  }}
                  sx={{
                    fontSize: {
                      lg: 30,
                      md: 27,
                      xs: 27,
                      sm: 27,
                    },
                    textAlign: "center",
                  }}
                >
                  User information
                </Box>
                <Box
                  sx={{
                    fontSize: {
                      lg: 16,
                      md: 16,
                      xs: 14,
                      sm: 14,
                    },
                    textAlign: "center",
                  }}
                >
                  {isRoleChanged
                    ? `The user's role has been changed to ${
                        isBasicUser ? "Basic." : "Administrator."
                      }`
                    : "User information updated successfully."}
                </Box>
              </Box>
              <Button
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  boxShadow: "none",
                  backgroundColor: palette.secondary.main,
                  width: {
                    lg: 300,
                    md: 300,
                    xs: "100%",
                  },
                }}
                onClick={() => {
                  setSuccessfulUpdate(false);
                }}
              >
                Back
              </Button>
            </Stack>
          ) : (
            <Stack spacing={5}>
              <Stack spacing={2} alignItems="center">
                <Box
                  style={{
                    fontFamily: typography.fontFamilySemiBold,
                  }}
                  sx={{
                    fontSize: {
                      lg: 30,
                      md: 27,
                      xs: 27,
                      sm: 27,
                    },
                    textAlign: "center",
                  }}
                >
                  User Information
                </Box>
                <Box
                  sx={{
                    fontSize: {
                      lg: 16,
                      md: 16,
                      xs: 14,
                      sm: 14,
                    },
                    textAlign: "center",
                  }}
                >
                  Edit user information
                </Box>
              </Stack>
              <FormControl
                component="form"
                onSubmit={handleSubmit(onSubmitUpdate)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box display={"flex"} gap={4}>
                  <Stack
                    gap={2}
                    sx={{
                      width: {
                        lg: 400,
                        md: 300,
                        sm: 200,
                      },
                    }}
                  >
                    <TextField
                      className="textField"
                      label={"First Name"}
                      name={"first_name"}
                      {...register("first_name", {
                        validate: (value) =>
                          !/[^A-Za-z0-9 _]+/.test(value) && value !== "",
                      })}
                      error={!!errors.first_name}
                      helperText={
                        errors.first_name && "Valid First Name is required."
                      }
                      sx={styleInput}
                      inputProps={{ maxLength: 40 }}
                    />

                    <MuiTelInput
                      sx={styleInput}
                      defaultCountry="US"
                      forceCallingCode={true}
                      label="Phone Number"
                      name="phone_number"
                      value={phoneNumber}
                      {...register("phone_number", {
                        required: "Phone number is required.",
                      })}
                      error={!!errors.phone_number}
                      helperText={
                        errors.phone_number && errors.phone_number.message
                      }
                      onChange={(newValue) =>
                        handlePhoneChange(newValue, setPhoneNumber)
                      }
                    />
                  </Stack>
                  <Stack
                    gap={2}
                    sx={{
                      width: {
                        lg: 400,
                        md: 300,
                        sm: 200,
                      },
                    }}
                  >
                    <TextField
                      className="textField"
                      label={"Last Name"}
                      name={"last_name"}
                      {...register("last_name", {
                        validate: (value) =>
                          !/[^A-Za-z0-9 _]+/.test(value) && value !== "",
                      })}
                      error={!!errors.last_name}
                      helperText={
                        errors.last_name && "Valid Last Name is required."
                      }
                      sx={styleInput}
                      inputProps={{ maxLength: 40 }}
                    />
                    <TextField
                      className="textField"
                      disabled
                      key={"Email Address"}
                      label={"Email Address"}
                      name={"email"}
                      {...register("user_name")}
                      sx={styleInput}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Stack>
                </Box>

                {statusMessage && (
                  <FormHelperText
                    error={isError}
                    sx={{
                      fontSize: { xs: 12, lg: 14 },
                    }}
                  >
                    {statusMessage}
                  </FormHelperText>
                )}

                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  disableElevation
                  sx={{
                    boxShadow: 0,
                    backgroundColor: palette.secondary.main,
                    width: {
                      lg: 300,
                      md: 300,
                      xs: "100%",
                    },
                  }}
                >
                  Update
                </Button>
              </FormControl>
              <Divider />
              <Stack
                gap={2}
                alignItems="center"
                sx={{
                  marginTop: "30px !important",
                }}
              >
                <Box
                  style={{
                    fontFamily: typography.fontFamilySemiBold,
                  }}
                  sx={{
                    fontSize: {
                      lg: 30,
                      md: 27,
                      xs: 27,
                      sm: 27,
                    },
                    textAlign: "center",
                  }}
                >
                  Security Information
                </Box>
                <Box
                  sx={{
                    fontSize: {
                      lg: 16,
                      md: 16,
                      xs: 14,
                      sm: 14,
                    },
                    textAlign: "center",
                  }}
                >
                  Edit user security information
                </Box>
              </Stack>
              <Box
                sx={{
                  marginTop: "30px !important",
                }}
              >
                <Stack gap={2} direction={"row"} justifyContent={"center"}>
                  <Button
                    variant="contained"
                    size="large"
                    disableElevation
                    sx={{
                      boxShadow: 0,
                      backgroundColor: palette.secondary.main,
                      width: {
                        lg: 300,
                        md: 300,
                        xs: "100%",
                      },
                    }}
                    onClick={() => setOpenChangePassword(true)}
                  >
                    Change Password
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    disableElevation
                    disabled={
                      (adminUsersCount === 1 && isAdmin) || isPrimaryTenant
                    }
                    sx={{
                      boxShadow: 0,
                      backgroundColor: palette.secondary.main,
                      width: {
                        lg: 300,
                        md: 300,
                        xs: "100%",
                      },
                    }}
                    onClick={handlePromoteToAdmin}
                  >
                    {isAdmin
                      ? "Change Role to Basic"
                      : "Promote to Administrator"}
                  </Button>
                </Stack>
                {isAdminMessage && (
                  <FormHelperText
                    error={isError}
                    sx={{
                      fontSize: { xs: 12, lg: 14 },
                    }}
                  >
                    {isAdminMessage}
                  </FormHelperText>
                )}
              </Box>
            </Stack>
          )}
        </>
      )}
    </ModalUserRowDetails>
  );
};

export default UsersTableRowDetails;
