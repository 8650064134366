import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";
import { setSigninData } from "src/features/signin/signin.action";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import typography from "src/theme/typography";
import ModalComponent from "src/components/modal/ModalComponent";
import palette from "src/theme/palette";
import planNames from "src/utils/planNames";
import { getPlansInfo } from "src/features/plans/plansState";
import "./carrier.scss";

const selectedStyle = {
  backgroundColor: "#dee2e6",
  border: "1px solid #256fa5",
  transition: "all 0.5s ease",
};

const checkedLogoStyle = {
  position: "absolute",
  top: "-15px",
  right: "-15px",
  zIndex: "2",
};

const Carrier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signinData = useSelector((state) => state.signin.signinData);
  const { isFromConfig } = signinData || {};
  const { pathname } = useLocation();
  const userInfo = useAppSelector(getUserInfo);
  const plansInfo = useAppSelector(getPlansInfo);

  const isDemoExpired =
    userInfo?.is_plan_expired &&
    userInfo?.plan_id === plansInfo[planNames.demo]?._id;
  const isDemoExpiredOrDemoOnboarding =
    isDemoExpired || userInfo?.plan_id === plansInfo[planNames.demo]?._id;
  const [openModal, setOpenModal] = useState(false);

  const { carrierError, carrier } = signinData || {};

  const [isUSPSSelected, setIsUSPSSelected] = useState(false);
  const [isUPSSelected, setIsUPSSelected] = useState(false);
  const [isFedexSelected, setIsFedexSelected] = useState(false);
  const [isDHLSelected, setIsDHLSelected] = useState(false);
  const handleSelectCarrier = (carrierSelected) => {
    switch (carrierSelected) {
      case "usps":
        if (isUSPSSelected) {
          setIsUSPSSelected(false);
          dispatch(
            setSigninData({
              ...signinData,
              carrier: { ...carrier, usps: null },
              carrierError: false,
            })
          );
        } else {
          setIsUSPSSelected(true);
          dispatch(
            setSigninData({
              ...signinData,
              carrier: { ...carrier, usps: true },
              carrierError: false,
              continueBtn: true,
            })
          );
        }
        break;

      case "ups":
        if (isUPSSelected) {
          setIsUPSSelected(false);
          dispatch(
            setSigninData({
              ...signinData,
              carrier: { ...carrier, ups: null },
              carrierError: false,
            })
          );
        } else {
          setIsUPSSelected(true);
          dispatch(
            setSigninData({
              ...signinData,
              carrier: { ...carrier, ups: true },
              carrierError: false,
              continueBtn: true,
            })
          );
        }
        break;

      case "fedex":
        if (isFedexSelected) {
          setIsFedexSelected(false);
          dispatch(
            setSigninData({
              ...signinData,
              carrier: { ...carrier, fedex: null },
              carrierError: false,
            })
          );
        } else {
          setIsFedexSelected(true);
          dispatch(
            setSigninData({
              ...signinData,
              carrier: { ...carrier, fedex: true },
              carrierError: false,
              continueBtn: true,
            })
          );
        }
        break;

      case "dhl":
        if (isDHLSelected) {
          setIsDHLSelected(false);
          dispatch(
            setSigninData({
              ...signinData,
              carrier: { ...carrier, dhl: null },
              carrierError: false,
            })
          );
        } else {
          setIsDHLSelected(true);
          dispatch(
            setSigninData({
              ...signinData,
              carrier: { ...carrier, dhl: true },
              carrierError: false,
              continueBtn: true,
            })
          );
        }
        break;

      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (isDemoExpiredOrDemoOnboarding) {
      // to show the informative modal on load
      isDemoExpired && setOpenModal(true);
      if (pathname == "/configuration") {
        if (carrier?.ups) setIsUPSSelected(true);
        if (carrier?.usps) setIsUSPSSelected(true);
        if (carrier?.fedex) setIsFedexSelected(true);
        if (carrier?.dhl) setIsDHLSelected(true);
      } else {
        dispatch(
          setSigninData({
            carrier: null,
            name: userInfo?.first_name,
            lastName: userInfo?.last_name,
            email: userInfo?.email,
            phone: userInfo?.phone_number,
            shippingvolume: userInfo?.shipping_volume,
            company: userInfo?.company_name,
            user_id: userInfo?.user_id,
            continueBtn: false,
          })
        );
      }
    } else {
      if (carrier?.ups) setIsUPSSelected(true);
      if (carrier?.usps) setIsUSPSSelected(true);
      if (carrier?.fedex) setIsFedexSelected(true);
      if (carrier?.dhl) setIsDHLSelected(true);
    }
  }, []);

  useEffect(() => {
    // if we are trying to access from anywhere else, we should not show the carrier page
    if (!signinData?.user_id && !isDemoExpiredOrDemoOnboarding) {
      navigate("/login");
      return;
    }

    if (isDemoExpiredOrDemoOnboarding) {
      if (!carrier) {
        setIsUPSSelected(false);
        setIsUSPSSelected(false);
        setIsFedexSelected(false);
        setIsDHLSelected(false);
      }

      if (carrier?.ups) setIsUPSSelected(true);
      if (carrier?.usps) setIsUSPSSelected(true);
      if (carrier?.fedex) setIsFedexSelected(true);
      if (carrier?.dhl) setIsDHLSelected(true);
    }

    if (isFromConfig) return;
  }, [signinData]);

  useEffect(() => {
    if (!carrier) return;
    else if (
      Object.keys(carrier).filter((key) => !!carrier[key]).length === 0
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          carrierError: true,
          continueBtn: false,
        })
      );
    }
    // else
    //   dispatch(
    //     setSigninData({
    //       ...signinData,
    //       continueBtn: true,
    //     })
    //   );
  }, [carrier]);

  return (
    <>
      <div className="carrier-container">
        <h1 style={{ ...typography.h1, textAlign: "center" }}>
          Which carriers do you ship with?
        </h1>

        <Typography
          sx={{
            color: palette.common.black,
            fontSize: { sx: 16, md: 18 },
            textAlign: "center",
          }}
        >
          We understand how important it is to stay informed about your
          shipments. Please select your preferred carrier from the list below
          for real-time tracking and timely notifications in case of any delays.
        </Typography>

        <Box className="carriers-img-container">
          <Box>
            <img
              className="carrier-img"
              src="/assets/usps.png"
              style={isUSPSSelected ? selectedStyle : null}
              onClick={() => handleSelectCarrier("usps")}
            />
            {isUSPSSelected && (
              <img src="/assets/checkLogo.svg" style={checkedLogoStyle}></img>
            )}
          </Box>

          <Box>
            <img
              className="carrier-img"
              src="/assets/ups.png"
              style={isUPSSelected ? selectedStyle : null}
              onClick={() => handleSelectCarrier("ups")}
            />
            {isUPSSelected && (
              <img src="/assets/checkLogo.svg" style={checkedLogoStyle} />
            )}
          </Box>

          <Box>
            <img
              className="fedex-img"
              src="/assets/fedex.png"
              style={isFedexSelected ? selectedStyle : null}
              onClick={() => handleSelectCarrier("fedex")}
            />
            {isFedexSelected && (
              <img src="/assets/checkLogo.svg" style={checkedLogoStyle} />
            )}
          </Box>

          <Box>
            <img
              className="dhl-img"
              src="/assets/dhl.png"
              style={isDHLSelected ? selectedStyle : null}
              onClick={() => handleSelectCarrier("dhl")}
            />
            {isDHLSelected && (
              <img src="/assets/checkLogo.svg" style={checkedLogoStyle} />
            )}
          </Box>
        </Box>

        <button
          onClick={() =>
            window.open(
              "https://calendly.com/beyondcarts/beyondcarts",
              "_blank"
            )
          }
        >
          Need Additional help? →
        </button>

        {carrierError && (
          <Typography
            sx={{
              color: palette.error.main,
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            You need to select 1 carrier
          </Typography>
        )}
      </div>

      <ModalComponent
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleBtnClick={handleCloseModal}
        title={`Your ${userInfo.plan_name} has expired`}
        description={
          "You need to complete the onboarding process by filling out additional information."
        }
        btnText={"Complete Onboarding"}
      ></ModalComponent>
    </>
  );
};

export default Carrier;
