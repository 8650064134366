import { InputAdornment, TextField, Button } from "@mui/material";
import PropTypes from "prop-types";

import Iconify from "../iconify";
import { textFieldStyles } from "./styles";

Search.propTypes = {
	placeholder: PropTypes.string,
	size: PropTypes.string,
	onSubmitClicked: PropTypes.func,
	searchValue: PropTypes.string,
	sx: PropTypes.shape({}),
	onSearchClick: PropTypes.func,
};

Search.defaultProps = {
	size: "",
};

export default function Search({
	placeholder,
	size,
	onSubmitClicked,
	searchValue,
	sx,
	onSearchClick,
	height,
	error,
	onSearchFocus,
}) {
	return (
		<form onSubmit={onSubmitClicked}>
			<TextField
				sx={textFieldStyles(height, sx)}
				variant="outlined"
				name="trackingNumber"
				size={size}
				onClick={onSearchClick}
				value={searchValue}
				placeholder={placeholder}
				onFocus={onSearchFocus}
				error={error}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button
								variant="contained"
								type="submit"
								sx={{
									boxShadow: "none",
									backgroundColor: "secondary.main",
									alignContent: "center",
									justifyContent: "center",
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									height: height || { xs: 36, lg: 40 },
									width: height ? { sm: 50, lg: 60 } : {},
									minWidth: 30,
									"& .MuiButton-startIcon": {
										margin: "0",
									},
								}}
								startIcon={
									<Iconify
										sx={{
											width: height
												? {
														lg: 20,
														md: 20,
														sm: 16,
														xs: 16,
												  }
												: {},
											height: height ? "auto" : {},
											margin: 0,
											color: "text.active",
										}}
										icon="eva:search-fill"
									/>
								}
							/>
						</InputAdornment>
					),
				}}
			/>
		</form>
	);
}
