import palette from "src/theme/palette";

const styleInput = {
  input: { color: palette.common.black },
  width: "100%",

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.light",
  },
  "& .MuiInputLabel-formControl": {
    color: "#00000099",
  },
  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
    color: "#00000099",
  },
  "& .MuiFormHelperText-root": {
    textAlign: "left",
    fontSize: "14px",
  },
};

const months = [
  "01-January",
  "02-February",
  "03-March",
  "04-April",
  "05-May",
  "06-June",
  "07-July",
  "08-August",
  "09-September",
  "10-October",
  "11-November",
  "12-December",
];

const years = Array.from([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (x) => {
  const yrs = new Date();
  yrs.setFullYear(yrs.getFullYear() + x);
  return yrs.getFullYear();
});

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const cc_format = (value) => {
  if (!value) return;
  let newValue = "";

  for (let i = 0; i < value.length; i++) {
    if (i === 4 || i === 8 || i === 12) newValue += " ";
    newValue += value[i];
  }

  return newValue;
};

const showBrandLogo = (brand) => {
  if (brand === "mastercard") return "/assets/master.svg";
  if (brand === "visa") return "/assets/visa.svg";
  if (brand === "amex") return "/assets/amex.svg";
};

const creditCardType = (cc, setCreditLogo) => {
  if (!cc) return;

  let amex = new RegExp("^3[47][0-9]{13}$");
  let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
  let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

  let mastercard = new RegExp("^5[1-5][0-9]{14}$");
  let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

  let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
  let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
  let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

  let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
  let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

  if (visa.test(cc)) {
    setCreditLogo("/assets/visa.svg");
    return;
  }
  if (amex.test(cc)) {
    setCreditLogo("/assets/amex.svg");
    return;
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    setCreditLogo("/assets/master.svg");
    return;
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return "DISCOVER";
  }
  if (diners.test(cc)) {
    return "DINERS";
  }
  if (jcb.test(cc)) {
    return "JCB";
  }
  if (cup1.test(cc) || cup2.test(cc)) {
    return "CHINA_UNION_PAY";
  }
  if (cc.length < 12) setCreditLogo(null);
};

export {
  styleInput,
  months,
  years,
  capitalize,
  cc_format,
  showBrandLogo,
  creditCardType,
};
