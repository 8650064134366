import React, {useState} from 'react';
import {Dialog, Box, Typography, Button} from '@mui/material';
import useCustomerOrderInfo from "../../../features/return_management/hooks/useCustomerOrderInfo";

const CustomerModal = ({isOpen, handleClose}) => {
  const {
    brandPortalConfig,
  } = useCustomerOrderInfo();
  return (
    <Dialog
      fullWidth={true}
      open={isOpen}
      maxWidth='xs'
      onClose={handleClose}
    >
      <Box sx={{padding: "20px"}}>
        <img src='/assets/returnbox.svg' style={{paddingTop: '20px', margin: 'auto'}}/>
        <Typography sx={{fontSize: '20px', textAlign: 'center', mt: 2}}>Thank you! Your return request has been
          submitted successfully.</Typography>
        {/*<Typography sx={{textAlign: 'center', mt: 2}}>RMA No : {returnId}</Typography>*/}
        <Typography sx={{textAlign: 'center', mt: 2, fontSize: '14px'}}>We will send a detailed email of the return
          instructions soon!</Typography>
        <Button
          variant="contained"
          sx={{width: '130px',backgroundColor:`${brandPortalConfig?.color_theme?.brand_color}`, margin: 'auto', display: 'block', my: 3}}
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default CustomerModal