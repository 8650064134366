import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { unixToDate, dateToUnix, removeTitleCase } from "src/utils/core";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import { Stack } from "@mui/material";
import {
  incrementAwaitingApiCounter,
  decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { getPlansInfo } from "src/features/plans/plansState";
import getMailScans from "src/features/mailScans/mailScansApi";
import SmartTable from "../components/smart-table/SmartTable";
import planNames from "src/utils/planNames";
import dayjs from "dayjs";

function MailScans() {
  const mailScan = useAppSelector((state) => state.user.mailScan);
  const [mainTrackingList, setMainTrackingList] = useState([]);
  const [totalItemsCount, setTotalItemCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showLoader, setShowLoader] = useState(false);
  // const [startDate, setStartDate] = useState(0);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "day").format("MM/DD/YYYY")
  );
  // const [endDate, setEndDate] = useState(0);
  const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));

  const userInfo = useAppSelector(getUserInfo);
  const plansInfo = useAppSelector(getPlansInfo);
  const isDemoPlan = userInfo?.plan_id === plansInfo[planNames.demo]?._id;

  const [advancedFormData, setAdvacnedFromData] = useState({
    report_type: "label_created_date",
  });
  const [userSelectedColumns, setUserSelectedColumns] = useState([]);
  const dispatch = useAppDispatch();
  const columnsToRemove = [
    "scanned_with_estimated_delivery_date",
    "scanned_without_estimated_delivery_date",
  ];

  const dateTypeList = [
    {
      label: "Label Created Date",
      value: "label_created_date",
    },
    {
      label: "Order Created Date",
      value: "order_created_date",
    },
    {
      label: "Tracking Date",
      value: "db_created_date",
    },
  ];

  // get Tracking number list
  const getmailScansList = () => {
    dispatch(incrementAwaitingApiCounter());
    const headers = {
      tenant_id: userInfo?.tenant_id,
      user_id: userInfo?.user_id,
      is_demo_user: isDemoPlan.toString(),
    };
    getMailScans(advancedFormData, headers, userInfo)
      .then((result) => {
        if (startDate && endDate) {
          const startDateUnix = dateToUnix(startDate);
          const endDateUnix = dateToUnix(endDate);
          const dateFilterdScanList = result.data.items.filter(
            (obj) =>
              startDateUnix <= obj.created_date &&
              endDateUnix >= obj.created_date
          );
          setMainTrackingList(dateFilterdScanList);
          setTotalItemCount(dateFilterdScanList.length);
          dispatch(decrementAwaitingApiCounter());
        } else {
          setMainTrackingList(result.data.items);
          setTotalItemCount(result.data.total_count);
          dispatch(decrementAwaitingApiCounter());
        }
      })
      .catch((error) => {});
  };

  const originalRows1 = mainTrackingList?.map(
    ({
      created_date,
      db_count,
      scanned_count,
      scanned_per,
      unscanned_per,
      // delivered_per,
      estimated_date_count,
      no_estimated_date_count,
    }) => ({
      label_created_date: created_date,
      total_shipments_count: db_count,
      successful_scans_count: scanned_count,
      successful_scans_per: scanned_per,
      no_scans_per: unscanned_per,
      estimated_date_count,
      no_estimated_date_available_count: no_estimated_date_count,
      // delivered_per,
    })
  );

  const dataConverted = [];

  originalRows1?.forEach((item) => {
    dataConverted.push({
      ...item,
      label_created_date: item.label_created_date
        ? `${unixToDate(item.label_created_date)}`
        : "--",
    });
  });

  // add % to percentage value

  const defaultColumn = "label_created_date";

  const getDateRange = (dates) => {
    if (dates != null && dates.length > 0) {
      const formattedStartDate = dayjs(dates[0]).format("MM/DD/YYYY");
      const formattedEndDate = dayjs(dates[1]).format("MM/DD/YYYY");
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      const startDateUnix = dateToUnix(formattedStartDate);
      const endDateUnix = dateToUnix(formattedEndDate);
      const dateFilterdScanList = mainTrackingList.filter(
        (obj) =>
          startDateUnix <= obj.created_date && endDateUnix >= obj.created_date
      );
      setMainTrackingList(dateFilterdScanList);
      setTotalItemCount(dateFilterdScanList.length);
    } else {
      setStartDate(false);
      setEndDate(false);
    }
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const onChangeAdvancedForm = (e) => {
    const { name, value } = e.target;
    setAdvacnedFromData({ ...advancedFormData, [name]: value });
  };

  const handleColumnSubmit = (columnList) => {
    setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
  };

  useEffect(() => {
    if (typeof mailScan !== "undefined" && mailScan.length > 0) {
      setUserSelectedColumns(mailScan);
    }
  }, [mailScan]);

  useEffect(() => {
    getmailScansList();
  }, [startDate, endDate, advancedFormData]);

  return (
    <>
      <Helmet>
        <title> Carrier Origin Scan | BeyondCarts </title>
      </Helmet>

      <Stack>
        <SmartTable
          isSearch={false}
          isAdvancedFilter={false}
          isStatusMenu={false}
          isRowSelectable={false}
          isAction={false}
          isMailScans={true}
          originalRows={
            typeof dataConverted !== "undefined" ? dataConverted : [{}]
          }
          defaultColumn={defaultColumn}
          getDateRange={getDateRange}
          totelItemCount={totalItemsCount}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          showLoader={showLoader}
          startDate={startDate}
          endDate={endDate}
          onChangeAdvancedForm={onChangeAdvancedForm}
          advancedFormData={advancedFormData}
          dateTypeList={dateTypeList}
          userSelectedColumns={userSelectedColumns}
          handleColumnSubmit={handleColumnSubmit}
          columnsToRemove={columnsToRemove}
        />
      </Stack>
    </>
  );
}

export default MailScans;
