import { Helmet } from "react-helmet-async";
import EnhancedTable from "src/components/enhanced-table/EnhancedTable";
import { useEffect } from "react";
import {
  getBlockedCustomersList,
  unblockBlockedCustomer,
} from "src/features/returnConfiguration/blockedCustomersApi";
import { useState } from "react";
import { unixToDate } from "src/utils/core";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { useDispatch } from "react-redux";
import AddBlockedCustomer from "./AddBlockedCustomer";
import { LoadingButton } from "@mui/lab";
import palette from "src/theme/palette";
import UnblockCustomerModal from "./UnblockCustomerModal";
import ModalComponent from "src/components/modal/ModalComponent";
import { getUserNameById } from "src/utils/getUserNameById";

const BlockedCustomer = () => {
  const orderByDefault = "return_id";
  const [originalRows, setOriginalRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openAddBlockedCustomerModal, setOpenAddBlockedCustomerModal] =
    useState(false);
  const [openUnblockCustomerModal, setOpenUnblockCustomerModal] =
    useState(false);
  const [openUnblockCustomerModalSuccess, setOpenUnblockCustomerModalSuccess] =
    useState(false);
  const [unblockCustomerEmail, setUnblockCustomerEmail] = useState("");

  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useDispatch();

  const populateTable = async () => {
    try {
      dispatch(incrementAwaitingApiCounter());
      const response = await getBlockedCustomersList(userInfo, {});
      if (response.statusCode === 200) {
        const items = response.data.items;

        const itemsWithUserNames = await Promise.all(
          items.map(async (item) => {
            const userName = await getUserNameById(userInfo, item.created_by);
            return { ...item, created_by: userName };
          })
        );

        setOriginalRows(itemsWithUserNames);
      }
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
    }
  };

  useEffect(() => {
    populateTable();
  }, [refresh]);

  const handleUnblockCustomer = async () => {
    const body = {
      email_id: unblockCustomerEmail,
    };
    console.log("body", body);
    try {
      dispatch(incrementAwaitingApiCounter());

      const response = await unblockBlockedCustomer(userInfo, body);
      console.log("unblock response", response);
      // TODO: uncomment this:
      // if (response.statusCode === 200) {
      setOpenUnblockCustomerModal(false);
      setOpenUnblockCustomerModalSuccess(true);
      dispatch(decrementAwaitingApiCounter());

      // }
    } catch (error) {
      console.log("error", error);
      // TODO: delete this 2 lines:
      setOpenUnblockCustomerModal(false);
      setOpenUnblockCustomerModalSuccess(true);
      dispatch(decrementAwaitingApiCounter());
    }
  };

  const handleUnblockCustomerSuccess = () => {
    setOpenUnblockCustomerModalSuccess(false);
    setRefresh((prev) => !prev);
  };

  const getFilterData = (data) => {
    if (data) {
      dispatch(incrementAwaitingApiCounter());

      getBlockedCustomersList(userInfo, {})
        .then((data) => {
          setOriginalRows(data?.data || []);
          dispatch(decrementAwaitingApiCounter());
        })
        .catch(() => dispatch(decrementAwaitingApiCounter()));
    }
  };

  const getDateRangeData = (data) => {
    if (data) {
      dispatch(incrementAwaitingApiCounter());

      getBlockedCustomersList(userInfo, {})
        .then((data) => {
          setOriginalRows(data?.data || []);
          dispatch(decrementAwaitingApiCounter());
        })
        .catch(() => dispatch(decrementAwaitingApiCounter()));
    }
  };

  const getSearchData = async (params) => {
    if (params) {
      dispatch(incrementAwaitingApiCounter());

      try {
        const response = await getBlockedCustomersList(userInfo, params);
        if (response.statusCode === 200)
          setOriginalRows(response.data.items || []);
        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        dispatch(decrementAwaitingApiCounter());
      }
    }
  };

  const transformRow = (row) => {
    if (row?.updated_source) {
      delete row["refund"];
    }
    const transformedRow = {};
    for (const key in row) {
      if (
        key === "created_source" ||
        key === "updated_source" ||
        key === "tenant_id" ||
        key === "status"
      ) {
        continue;
      }
      if (row[key] === null) {
        transformedRow[key] = "-";
      } else if (key === "created_at" || key === "updated_at") {
        transformedRow[key] = unixToDate(row[key]);
      } else {
        transformedRow[key] = row[key];
      }
    }
    return transformedRow;
  };

  let rows = originalRows.map(transformRow);
  rows = rows.map((el) => ({
    ...el,
    unblock: (
      <LoadingButton
        variant="outlined"
        onClick={() => setOpenUnblockCustomerModal(true)}
        sx={{
          width: { xs: 100, md: 120 },
          height: { xs: 36, lg: 40 },
          border: `1px ${palette.error.main} solid`,
          color: palette.error.main,
          ":hover": {
            backgroundColor: palette.error.lighter,
            border: `1px ${palette.error.main} solid`,
          },
        }}
      >
        Unblock
      </LoadingButton>
    ),
  }));

  const generateHeadCells = (rows) => {
    if (rows.length === 0) return [];

    const firstRowKeys = Object.keys(rows[0]);

    const formatLabel = (key) => {
      if (key === "email_id") return "Email";
      if (key === "created_at") return "Blocked Date";
      if (key === "created_by") return "Blocked By";
      return key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const header = firstRowKeys.map((key) => ({
      id: key,
      label: formatLabel(key),
    }));

    return header;
  };

  const headCells = generateHeadCells(rows);

  const handleCloseAddBlockedCustomerModal = () => {
    setOpenAddBlockedCustomerModal(false);
    setRefresh((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>Blocked Customer | BeyondCarts</title>
      </Helmet>
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        orderByDefault={orderByDefault}
        isSearch
        // isDatePicker
        // isAdvanceFilter
        // isExport
        getFilterData={getFilterData}
        getDateRangeData={getDateRangeData}
        getSearchData={getSearchData}
        refresh={refresh}
        setRefresh={setRefresh}
        setOpenAddBlockedCustomerModal={setOpenAddBlockedCustomerModal}
        setUnblockCustomerEmail={setUnblockCustomerEmail}
      />
      {openAddBlockedCustomerModal && (
        <AddBlockedCustomer
          openAddBlockedCustomerModal={openAddBlockedCustomerModal}
          handleCloseAddBlockedCustomerModal={
            handleCloseAddBlockedCustomerModal
          }
        />
      )}

      {openUnblockCustomerModal && (
        <UnblockCustomerModal
          title={"Unblock Customer"}
          description={"Are you sure to unblock this customer?"}
          openModal={openUnblockCustomerModal}
          handleCancelBtn={() => setOpenUnblockCustomerModal(false)}
          handleUnblockBtn={handleUnblockCustomer}
          handleCloseModal={() => setOpenUnblockCustomerModal(false)}
        />
      )}

      {openUnblockCustomerModalSuccess && (
        <ModalComponent
          title={"Unblock Customer"}
          description={"Customer has been unblocked successfully"}
          btnText={"Close"}
          openModal={openUnblockCustomerModalSuccess}
          handleBtnClick={handleUnblockCustomerSuccess}
          handleCloseModal={handleUnblockCustomerSuccess}
        />
      )}
    </>
  );
};

export default BlockedCustomer;
