import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  BarChart,
  Bar,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Label,
  Tooltip,
} from "recharts";
import palette from "src/theme/palette";
import typography from "src/theme/typography";

const OrdersPerCarrier = ({ title, rData }) => {
  const [numericValue, setNumericValue] = useState(
    (70 / 100) * window.innerWidth
  );
  let hasUPS = false;
  let hasUSPS = false;
  let hasFEDEX = false;
  let hasDHL = false;

  rData.forEach((data) => {
    if (Object.keys(data).includes("UPS")) hasUPS = true;
    if (Object.keys(data).includes("USPS")) hasUSPS = true;
    if (Object.keys(data).includes("FEDEX")) hasFEDEX = true;
    if (Object.keys(data).includes("DHL")) hasDHL = true;
  });

  const StyledRoot = styled("div")(() => ({
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    padding: 14,
    paddingTop: 8,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    // borderStartEndRadius: 1,
    display: "flex",
    position: "relative",
    alignItems: "center",
  }));

  useEffect(() => {
    const updateNumericValue = () => {
      let newNumericValue = (70 / 100) * window.innerWidth;
      if (newNumericValue < 550) {
        newNumericValue += 50;
      }
      setNumericValue(newNumericValue);
    };
    window.addEventListener("resize", updateNumericValue);
    return () => {
      window.removeEventListener("resize", updateNumericValue);
    };
  }, []);

  const tooltipMappings = {
    Alert: "alert",
    "In Transit": "in_transit",
    Delivered: "delivered",
    "Pre-Shipment": "pre-shipment",
    "Out for Delivery": "out_for_delivery",
    Returned: "returned",
    "Delivery Attempt": "delivery_attempt",
    "Available for Pickup": "available_for_pickup",
    "Available for Agent Pickup": "available_for_agent_pickup",
    "Delivered To Agent": "delivered_to_agent",
    Unknown: "unknown",
    Forwarded: "forwarded",
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          {data?.UPSCategories && (
            <>
              <p style={{ fontWeight: "bold", fontSize: "14px" }}>UPS:</p>
              {Object.keys(data.UPSCategories).length !== 0 &&
                Object.keys(data.UPSCategories).map((ups, index) => {
                  return (
                    <p
                      key={index}
                      style={{
                        fontSize: "12px",
                        color: palette.status[tooltipMappings[ups]],
                      }}
                    >
                      {ups + ": " + data.UPSCategories[ups]}
                    </p>
                  );
                })}
            </>
          )}
          {data?.FEDEXCategories && (
            <>
              <p style={{ fontWeight: "bold", fontSize: "14px" }}>FEDEX:</p>
              {Object.keys(data.FEDEXCategories).length !== 0 &&
                Object.keys(data.FEDEXCategories).map((fedex, index) => {
                  return (
                    <p
                      key={index}
                      style={{
                        fontSize: "12px",
                        color: palette.status[tooltipMappings[fedex]],
                      }}
                    >
                      {fedex + ": " + data.FEDEXCategories[fedex]}
                    </p>
                  );
                })}
            </>
          )}
          {data?.USPSCategories && (
            <>
              <p style={{ fontWeight: "bold", fontSize: "14px" }}>USPS:</p>
              {Object.keys(data.USPSCategories).length !== 0 &&
                Object.keys(data.USPSCategories).map((usps, index) => {
                  return (
                    <p
                      key={index}
                      style={{
                        fontSize: "12px",
                        color: palette.status[tooltipMappings[usps]],
                      }}
                    >
                      {usps + ": " + data.USPSCategories[usps]}
                    </p>
                  );
                })}
            </>
          )}
          {data?.DHLCategories && (
            <>
              <p style={{ fontWeight: "bold", fontSize: "14px" }}>DHL:</p>
              {Object.keys(data.DHLCategories).length !== 0 &&
                Object.keys(data.DHLCategories).map((dhl, index) => {
                  return (
                    <p
                      key={index}
                      style={{
                        fontSize: "12px",
                        color: palette.status[tooltipMappings[dhl]],
                      }}
                    >
                      {dhl + ": " + data.DHLCategories[dhl]}
                    </p>
                  );
                })}
            </>
          )}
        </div>
      );
    }
    return null;
  };

  const yDomainValue = () => {
    const orderValuesArr = [];
    rData.map((el) => {
      if (el?.UPS) orderValuesArr.push(el.UPS);
      else if (el?.USPS) orderValuesArr.push(el.USPS);
      else if (el?.DHL) orderValuesArr.push(el.DHL);
      else if (el?.FEDEX) orderValuesArr.push(el.FEDEX);
    });

    if (Math.max(...orderValuesArr) === -Infinity)
      return {
        min: 0,
        max: 2,
      };

    return {
      min: 0,
      max: Math.max(...orderValuesArr),
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box
        sx={{
          pl: 2,
          py: { xs: 1, md: 4 },
          height: 50,
          display: "flex",
          alignItems: "center",
          color: "common.black",
          bgcolor: "common.white",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <h4 style={typography.h4}>{title}</h4>
      </Box>

      <StyledRoot sx={{ marginBottom: 3 }}>
        {rData[0]?.Date === "" && (
          <Typography
            variant="h5"
            sx={{
              color: "primary.main",
              position: "absolute",
              left: "40%",
              paddingTop: 1,
            }}
          >
            No data found
          </Typography>
        )}
        <BarChart
          className="barchartStyle"
          data={rData}
          width={numericValue}
          height={400}
        >
          <CartesianGrid stroke="transparent" />
          <Legend verticalAlign="top" align="right" iconSize={20} />
          <XAxis
            dataKey="Date"
            axisLine={{ stroke: "#D9D9D9", width: "2px" }}
            tick={{
              fill: "#000",
              fontFamily: typography.fontFamilySemiBold,
              fontSize: 12,
              fontStyle: "normal",
              lineHeight: "111%",
              letterSpacing: "0.15px",
            }}
          >
            <Label
              value="Date"
              style={{ fontWeight: "600" }}
              position="insideBottom"
              offset={-4}
            />
          </XAxis>
          <YAxis
            axisLine={{ stroke: "#D9D9D9", width: "2px" }}
            allowDecimals={false}
            domain={[yDomainValue().min, yDomainValue().max]}
            tick={{
              fill: "#000",
              fontFamily: typography.fontFamilySemiBold,
              fontSize: 12,
              fontStyle: "normal",
              lineHeight: "111%",
              letterSpacing: "0.15px",
            }}
          >
            <Label
              value="Number of Orders"
              style={{ fontWeight: "600" }}
              angle={-90}
              position="insideLeft"
            />
          </YAxis>
          {rData[0]?.Date !== "" && <Tooltip content={<CustomTooltip />} />}
          {hasUPS && (
            <Bar dataKey="UPS" fill="#479DA6" label={{ position: "top" }} />
          )}
          {hasUSPS && (
            <Bar dataKey="USPS" fill="#256fa5" label={{ position: "top" }} />
          )}
          {hasFEDEX && (
            <Bar dataKey="FEDEX" fill="#642b92" label={{ position: "top" }} />
          )}
          {hasDHL && (
            <Bar dataKey="DHL" fill="#838383" label={{ position: "top" }} />
          )}
        </BarChart>
      </StyledRoot>
    </Box>
  );
};

export default OrdersPerCarrier;
