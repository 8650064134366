import { Box, Button, Grid, Typography } from "@mui/material";
import palette from "src/theme/palette";
import ProductList from "./components/ProductList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GetImages } from "../returns/ReturnsApi";
import { shopifyImages } from "src/features/returns/shopifyImages";
import { useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";


const ReturnMethods = ({ returnInfo, orders, eligibleItems, setIsReturn, selectedItems, setSelectedItems, setIsVerified }) => {
    const [quantities, setQuantities] = useState({});
    const dispatch = useDispatch();
    const userInfo = useAppSelector(getUserInfo);

    useEffect(() => {
        GetImages(userInfo).then(res => {
            if (res.statusCode === 200) {
                dispatch(shopifyImages(res.data))
            }
        }
        )
    }, [])
    const shopifyImage = useSelector(state => state.shopifyImages.shopifyImages);
    const eligibleQuantityMap = {};
    Object.values(eligibleItems).forEach(items => {
        items.forEach(item => {
            eligibleQuantityMap[item.line_item_id] = item.quantity;
        });
    });

    const updatedOrders = orders?.line_items.map(order => ({
        ...order,
        image: shopifyImage[order.product_id].image,
        eligible_quantity: eligibleQuantityMap[order.id] || null
    }));

    const handleAddQuantity = (id) => {
        const item = Object.values(eligibleItems).flat().find(item => item.line_item_id === id);
        if (item && !selectedItems.some(selected => selected.line_item_id === id)) {
            setSelectedItems([...selectedItems, { ...item, return_reason: '' }]);
        }
        setQuantities(prevQuantities => {
            const currentQty = prevQuantities[id] || 0;
            if (currentQty < updatedOrders.find(order => order.id === id).eligible_quantity) {
                return { ...prevQuantities, [id]: currentQty + 1 };
            }
            return prevQuantities;
        });
    };

    const handleRemoveQuantity = (id) => {
        setSelectedItems(selectedItems.filter(item => item.line_item_id !== id));
        setQuantities(prevQuantities => {
            const currentQty = prevQuantities[id] || 0;
            if (currentQty > 0) {
                return { ...prevQuantities, [id]: currentQty - 1 };
            }
            return prevQuantities;
        });
    };

    const handleReasonChange = (id, reason) => {
        setSelectedItems(prevItems =>
            prevItems.map(item =>
                item.line_item_id === id ? { ...item, return_reason: reason } : item
            )
        );
    };
    const isContinueVisible = Object.values(quantities).some(qty => qty > 0) &&
        selectedItems.every(item => item.return_reason.trim() !== '');
    return <>
        <Typography sx={{ padding: '20px', borderBottom: 'solid', borderColor: palette.secondary.lighter, borderWidth: '0.5px' }}>What would you like to return?</Typography>
        <Grid container>
            <Box sx={{ maxHeight: '380px', width: '100%', overflow: 'auto' }}>
                {updatedOrders?.map(data => {
                    return <ProductList key={data.id}
                        data={data}
                        qty={quantities[data.id] || 0}
                        reason={selectedItems.find(item => item.line_item_id === data.id)?.return_reason || ''}
                        handleAddQuantity={() => handleAddQuantity(data.id)}
                        handleRemoveQuantity={() => handleRemoveQuantity(data.id)}
                        handleReasonChange={(e) => handleReasonChange(data.id, e.target.value)} />
                })}
            </Box>
        </Grid>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button variant="outlined" sx={{ borderColor: returnInfo.brandedAssetsState.brand_background_color, color: returnInfo.brandedAssetsState.brand_color }} onClick={() => setIsVerified(false)}>Back</Button>
            <Button disabled={!isContinueVisible} variant="contained" sx={{ boxShadow: 'none', backgroundColor: returnInfo.brandedAssetsState.brand_background_color, color: returnInfo.brandedAssetsState.brand_color }} onClick={() => setIsReturn(true)}>Continue</Button>
        </Box>
    </>
}

export default ReturnMethods;