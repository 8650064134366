import { Box, Modal, Stack, IconButton, Button } from "@mui/material";
import palette from "src/theme/palette";
import typography from "src/theme/typography";

const popUpModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "auto", lg: "580px", md: "auto" },
  borderRadius: "20px",
  background: "#FFF",
  overflow: "auto",
  overflowX: "hidden",
  outline: "none",
};

const ModalComp = ({
  openModal,
  handleCloseModal,
  handlePublishBtn,
  handleCancelBtn,
  title,
  description,
}) => {
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          ...popUpModalStyle,
          paddingY: 2,
          paddingX: 4,
        }}
      >
        <Stack
          gap={2}
          direction="column"
          sx={{
            color: "common.black",
            paddingY: 2,
            paddingX: { xs: 0, md: 4 },
            fontFamily: typography.fontFamilyRegular,
            backgroundColor: "white",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: { xs: 8, md: 20 },
              right: { xs: 8, md: 22 },
              zIndex: 2,
            }}
            onClick={handleCloseModal}
          >
            <img width="30px" src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>

          <Box
            sx={{
              fontFamily: typography.fontFamilySemiBold,
              color: palette.secondary.main,
              fontSize: {
                lg: 34,
                md: 27,
                xs: 22,
                sm: 22,
              },
              lineHeight: "normal",
            }}
          >
            <h3 style={{ ...typography.h2, textAlign: "center" }}>{title}</h3>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}>
            <Box
              sx={{
                fontSize: {
                  lg: 17,
                  md: 16,
                  xs: 14,
                  sm: 14,
                },
                textAlign: "center",
              }}
            >
              {description}
            </Box>
          </Box>
          <Stack direction={"row"} gap={2}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                boxShadow: "none",
                backgroundColor: "grey.600",
                paddingY: 1.3,
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
                "&:hover": {
                  backgroundColor: "primary.lightIcon",
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={handleCancelBtn}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              type="submit"
              sx={{
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
                paddingY: 1.3,
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={handlePublishBtn}
            >
              Publish
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalComp;
