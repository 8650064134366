const {
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  Button,
  Stack,
} = require("@mui/material");
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EmailIcon from "@mui/icons-material/Email";
import { PostPortalLogin } from "./PortalApi";
import { useState } from "react";
import { getEligibleItems, getOrderInfo } from "../returns/ReturnsApi";
import typography from "src/theme/typography";

const PortalLogin = ({
  setIsVerified,
  setOrders,
  setEligibleItems,
  portalInfo,
  setIsLoading,
}) => {
  const [orderNumber, setOrderNumber] = useState("");
  const [email, setEmail] = useState("");
  const handleSubmit = () => {
    setIsLoading(true);
    PostPortalLogin({ order_number: orderNumber, email_id: email })
      .then((res) => {
        if (res.statusCode === 200) {
          getOrderInfo(orderNumber).then((res) => {
            if (res.statusCode === 200) {
              setOrders(res.data);
              getEligibleItems(res.data.order_id).then((res) =>
                setEligibleItems(res.data)
              );
              setIsLoading(false);
            }
          });
          setIsVerified(res.data.verified_email);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.message);
      });
  };

  return (
    <Stack alignItems={"center"} sx={{ padding: "30px" }}>
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: `${portalInfo.brandedHeaderState.text_size + "px"}`,
          color: `${portalInfo.brandedHeaderState.text_color}`,
        }}
      >
        {portalInfo.brandedHeaderState.header_text}
      </Typography>
      <Box sx={{ marginY: "30px" }}>
        <OutlinedInput
          sx={{ mb: 2 }}
          onChange={(e) => setOrderNumber(e.target.value)}
          fullWidth={true}
          startAdornment={
            <InputAdornment position="start">
              <ConfirmationNumberIcon />
            </InputAdornment>
          }
          placeholder="Order ID"
        />
        <OutlinedInput
          fullWidth={true}
          onChange={(e) => setEmail(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment
              position="end"
              sx={{
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontFamily: typography.fontFamilySemiBold,
                  color: `${portalInfo.brandedHeaderState.text_color}`,
                }}
              >
                Get Otp
              </Typography>
            </InputAdornment>
          }
          placeholder="Email address"
        />
      </Box>
      <Button
        variant="contained"
        sx={{
          backgroundColor: `${portalInfo.brandedAssetsState.brand_background_color}`,
          color: `${portalInfo.brandedAssetsState.brand_color}`,
          width: { xs: 180, sm: 250 },
          borderRadius: "10px",
        }}
        size={"large"}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Stack>
  );
};

export default PortalLogin;
