import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Dialog, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BrandAssets from "./BrandAssets";
import { Helmet } from "react-helmet-async";
import BrandHeader from "./BrandHeader";
import BrandFooter from "./BrandFooter";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "src/components/tooltip/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useCustomerOrderInfo from "src/features/return_management/hooks/useCustomerOrderInfo";

import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import {
  getBrandPortalInfo,
  getTrackingPortalInfo,
  putBrandPortalInfo,
} from "src/features/return_management/order/customerBrandPortalApi";
import _ from "lodash";
import {
  assetsUpload,
  brandTrackPortalErrorUpload,
  footerUpload,
  headerUpload,
} from "src/features/brandedPortal/brandedPortal";
import { getBrandedPortalInfo } from "src/features/brandedPortal/brandedPortalApi";
import CircularLoader from "src/loader/circular/CircularLoader";

const BrandedPortal = () => {
  const dispatch = useDispatch();
  const { updateTrackingPortalInfo } = useCustomerOrderInfo();
  const [value, setValue] = useState("assets");
  const userInfo = useAppSelector(getUserInfo);
  const data = useSelector((state) => state.brandedPortal);
  const [initialState, setInitialState] = useState(null);
  const [changedState, setChangedState] = useState(null);
  const [errors, setErrors] = useState(data.brandTrackPortalErrors);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    dispatch(brandTrackPortalErrorUpload(errors))
  }, [errors]);
  const validateUrl = (name, value) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?(www\\.)?[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,})+(\\/[^\\s]*)?$');
    if (!value || urlPattern.test(value)) {
      setErrors(prev => ({ ...prev, [name]: null }));
    } else {
      setErrors(prev => ({ ...prev, [name]: 'Invalid URL' }));
    }
  };

  const validateColor = (name, value) => {
    const colorPattern = /^#[a-fA-F0-9]{6}$/;
    if (!value || colorPattern.test(value)) {
      setErrors(prev => ({ ...prev, [name]: null }));
    } else {
      setErrors(prev => ({ ...prev, [name]: 'Invalid color format' }));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchInitialState = async () => {
      const response = await getTrackingPortalInfo(userInfo);
      const data = response.data;
      setIsLoading(false);
      const config = {
        logo: data.logo,
        background_image: data.background_image,
        logo_url: data.logo_url,
        color_theme: data.color_theme,
        favicon: data.favicon,
        footer: data.footer,
        corner_radius: data.corner_radius,
        tracking_analytics: data.tracking_analytics,
        header: data.header,
        customer_support: data.customer_support,
        website_url: data.website_url,
        social_media: data.social_media,
      };
      setInitialState(config);
      const brandAssetsData = {
        logo: data.logo,
        background_image: data.background_image,
        logo_url: data.logo_url,
        favicon: data.favicon,
        corner_radius: data.corner_radius,
        website_url: data.website_url,
        ...data.color_theme,
        tracking_analytics: data.tracking_analytics,
      };
      const brandedHeaderData = {
        ...data.header,
        ...data.customer_support,
      };
      const brandedFooterData = {
        ...data.footer,
        ...data.social_media,
      };
      console.log({brandAssetsData, brandedHeaderData, brandedFooterData})
      dispatch(assetsUpload(brandAssetsData));
      dispatch(headerUpload(brandedHeaderData));
      setErrors({});
      dispatch(footerUpload(brandedFooterData));
    };

    fetchInitialState();
  }, []);

  useEffect(() => {
    const {
      logo,
      background_image,
      logo_url,
      favicon,
      corner_radius,
      website_url,
      brand_color,
      secondary_color,
      page_bg_color,
      tracking_analytics,
    } = data.brandedAssetsState;
    const { header_text, text_color, background_color, email_id, phone_no } =
      data.brandedHeaderState;
    const { privacy_url, terms_url, facebook, instagram } =
      data.brandedFooterState;
    const previewData = {
      logo,
      background_image,
      logo_url,
      favicon,
      color_theme: {
        brand_color,
        secondary_color,
        page_bg_color,
      },
      corner_radius,
      tracking_analytics,
      header: {
        header_text,
        text_color,
        background_color,
      },
      footer: {
        custom_content: false,
        privacy_url,
        terms_url,
        link_text: "",
        policy_content: "",
        text_color: data.brandedFooterState.text_color,
        background_color: data.brandedFooterState.background_color,
      },
      customer_support: {
        email_id,
        phone_no,
      },
      social_media: {
        facebook,
        instagram,
      },
      website_url,
    };
    setChangedState(previewData);
  }, [data]);

  const handleSubmit = (type) => {
    setIsLoading(true);
    const {
      logo,
      background_image,
      logo_url,
      favicon,
      corner_radius,
      website_url,
      brand_color,
      secondary_color,
      page_bg_color,
      tracking_analytics,
    } = data.brandedAssetsState;
    const { header_text, text_color, background_color, email_id, phone_no } =
      data.brandedHeaderState;
    const { privacy_url, terms_url, facebook, instagram } =
      data.brandedFooterState;
    const previewData = {
      logo,
      background_image,
      logo_url,
      favicon,
      color_theme: {
        brand_color,
        secondary_color,
        page_bg_color,
      },
      corner_radius,
      tracking_analytics,
      header: {
        header_text,
        text_color,
        background_color,
      },
      footer: {
        custom_content: false,
        privacy_url,
        terms_url,
        link_text: "",
        policy_content: "",
        text_color: data.brandedFooterState.text_color,
        background_color: data.brandedFooterState.background_color,
      },
      customer_support: {
        email_id,
        phone_no,
      },
      social_media: {
        facebook,
        instagram,
      },
      website_url,
    };
    if (type === 'save') {
      setInitialState(previewData)
      setChangedState(previewData)
      putBrandPortalInfo(userInfo, previewData).then(res => {
        if (res.statusCode === 200) {
          refetch();
        }
      });
    }
    if (type === "preview") {
      updateTrackingPortalInfo(previewData)
      setIsLoading(false)
     window.open(`/returns/${userInfo.tenant_name}/demo?preview=demo`, "_blank");
    }
  };

  const refetch = async () => {
    setIsLoading(true);
    const response = await getTrackingPortalInfo(userInfo);
    const data = response.data;
    setIsLoading(false);
    const config = {
      logo: data.logo,
      background_image: data.background_image,
      logo_url: data.logo_url,
      color_theme: data.color_theme,
      favicon: data.favicon,
      footer: data.footer,
      corner_radius: data.corner_radius,
      tracking_analytics: data.tracking_analytics,
      header: data.header,
      customer_support: data.customer_support,
      website_url: data.website_url,
      social_media: data.social_media,
    };
    updateTrackingPortalInfo(config);
    setOpen(true)
  };

  const applyBrandSettings = async () => {
    setIsLoading(true);
    const response = await getBrandedPortalInfo(userInfo.tenant_name, userInfo);
    const brand = response.data;
    setIsLoading(false);
    const brandAssetsData = {
      logo: brand.logo,
      background_image: brand.background_image,
      logo_url: brand.logo_url,
      favicon: brand.favicon,
      corner_radius: brand.corner_radius,
      website_url: '',
      ...brand.color_theme,
      tracking_analytics: data.brandedAssetsState.tracking_analytics,
    };
    const brandedHeaderData = {
      header_text: data.brandedHeaderState.header_text,
      text_color: data.brandedHeaderState.text_color,
      background_color: data.brandedHeaderState.background_color,
      ...brand?.customer_support,
    };
    dispatch(assetsUpload(brandAssetsData));
    dispatch(headerUpload(brandedHeaderData));
  };
  
  useEffect(() => {
    if (errors) {
    const hasErrors = Object.values(errors).some(error => error !== null && error !== "");
    const statesAreEqual = _.isEqual(initialState, changedState);
    setIsSaveDisabled(hasErrors || statesAreEqual);
    }
  },[errors, initialState, changedState]);

  const isPreviewDisabled = () => {
    if(errors) {
    const hasErrors = Object.values(errors).some(error => error !== null && error !== "");
    return hasErrors;
    }
  };

  return (
    <>
      <Helmet>
        <title>Branded Tracking Portal | BeyondCarts</title>
      </Helmet>
      {isLoading && <CircularLoader />}
      <Box sx={{ position: "relative", height: "calc(100vh - 88px)" }}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={applyBrandSettings}
            sx={{ mt: 2 }}
          >
            Apply settings from Brand Portal
          </Button>
        </Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="Brand assets" value="assets" />
              <Tab
                label="Header"
                icon={
                  <CustomTooltip
                    title={<img src="/assets/brandheadertooltipimg.svg" />}
                  >
                    &nbsp;
                    <InfoOutlinedIcon fontSize="small" />
                  </CustomTooltip>
                }
                iconPosition="end"
                value="header"
              />
              <Tab
                label="Footer"
                icon={
                  <CustomTooltip
                    title={<img src="/assets/footertooltipimg.svg" />}
                  >
                    &nbsp;
                    <InfoOutlinedIcon fontSize="small" />
                  </CustomTooltip>
                }
                iconPosition="end"
                value="footer"
              />
            </TabList>
          </Box>
          <TabPanel value="assets">
            <BrandAssets data={data} errors={errors} setErrors={setErrors} validateUrl={validateUrl} validateColor={validateColor} />
          </TabPanel>
          <TabPanel value="header" sx={{ height: "calc(100vh - 229px)" }}>
            <BrandHeader data={data} errors={errors} setErrors={setErrors} validateColor={validateColor}/>
          </TabPanel>
          <TabPanel value="footer" sx={{ height: "calc(100vh - 229px)" }}>
            <BrandFooter data={data} errors={errors} setErrors={setErrors} validateUrl={validateUrl} validateColor={validateColor}/>
          </TabPanel>
        </TabContext>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 3,
            padding: "20px",
            boxShadow: "0px -8px 30px 0px #00000014",
            backgroundColor: "white",
            bottom: "-22px",
            width: "calc(100% - -80px)",
            margin: "0 0 0 -40px",
            zIndex:"1",
            position:"sticky"
          }}
        >
          <Button
            variant="contained"
            sx={{ width: "130px" }}
            onClick={() => handleSubmit("preview")}
            disabled={isPreviewDisabled()}
          >
            Preview
          </Button>
          <Button
            variant="contained"
            sx={{ width: "130px" }}
            onClick={() => handleSubmit("save")}
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </Box>
        <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="xs"
        >
          <Box sx={{padding:'20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Typography sx={{mb:2}}>All changes saved successfully</Typography>
            <Button variant="contained" sx={{backgroundColor: 'secondary.main', width: "130px", margin: 'auto'}} onClick={() => setOpen(false)}>Ok</Button>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default BrandedPortal;
