import {useEffect, useState} from "react";
import {
  Stack,
  Checkbox,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel, Typography,
} from "@mui/material";


const ItemCardProductInfo = ({data, currency_symbol}) => {
    return (
      <>
        <img src={data.imgUrl} style={{maxWidth:"65px"}} width={130} height={"auto"}/>
        <Stack justifyContent={"space-between"} paddingY={1.3}>
          <h3
            style={{
              fontSize: 20,
            }}
          >
            {data.title}
          </h3>
          <Stack
            direction={"row"}
            gap={2}
            color={"#AAAAAA"}
            paddingBottom={1}
          >
            <p>{data.description}</p>
            <p>/</p>
            <p>Qty {data.quantity}</p>
          </Stack>

          <p>{currency_symbol} {data.price}</p>
        </Stack>
      </>
    );
  }
;

export default ItemCardProductInfo;
