import getData from "src/dataFetcher/getDataFetcher";
import { backendTrackingUrl } from "src/utils/backendUrls";

export default async function getMailScans(params, headers, userInfo) {
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendTrackingUrl}scan-status/details`,
    params,
    headers,
    token
  );
  return result;
}
