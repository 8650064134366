import React, { useEffect, useState } from "react";
import { emailConfigurationTexts } from "./EmailConfigurationTexts";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModalComponent from "src/components/modal/ModalComponent";
import { selectCustomerManagedReturn } from "src/features/returns/returnMethodSlice";
import { useSelector } from "react-redux";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import PreviewTemplate from "../../EmailTemplate/PreviewTemplate";
import EmailTemplateItem from "src/components/EmailTemplates/EmailTemplateItem";
import EmailTemplateEdition from "../../EmailTemplate/EmailTemplateEdition";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import {
  getEmailTemplatesConfig,
  getEmailTemplatesList,
} from "src/features/returnConfiguration/emailTemplatesApi";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import { getUserNameById } from "src/utils/getUserNameById";
import moment from "moment";
import { LABELS } from "../Labels";
import { formatDateTime } from "src/utils/labelUtils";

const popUpModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "auto", md: "auto" },
  borderRadius: "20px",
  background: "#FFF",
  overflowX: "hidden",
  outline: "none",
};

const EmailConfiguration = ({
  handleSave,
  customerManagedReturn,
  isTwoWayDeliveryLabel,
  openEditTemplateUI,
  setOpenEditTemplateUI,
}) => {
  const [openConfirmSaveModal, setOpenConfirmSaveModal] = useState(false);
  const [showEditPreviewTemplateBtns, setShowEditPreviewTemplateBtns] =
    useState(false);

  const isCustomerManagedReturn = useAppSelector(
    (state) => state.returnMethod.customerManagedReturn
  );
  const selectedReturnMethod = useAppSelector(
    (state) => state.returnMethod.selectedMethod
  );

  const [emailTemplateItemData, setEmailTemplateItemData] = useState([
    ...(isTwoWayDeliveryLabel ? [emailConfigurationTexts.twoWayReturn] : []),
    ...(customerManagedReturn
      ? [emailConfigurationTexts.customerManagedReturns]
      : []),
  ]);

  const [logoFile, setLogoFile] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [templateNameSelected, setTemplateNameSelected] = useState(false);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const [templateLastUpdateList, setTemplateLastUpdateList] = useState([]);

  const handleEditConfiguration = () => {
    setShowEditPreviewTemplateBtns(true);
  };

  useEffect(() => {
    if (isTwoWayDeliveryLabel && selectedReturnMethod !== LABELS.TWO_WAY) {
      setShowEditPreviewTemplateBtns(true);
      return;
    } else {
      setShowEditPreviewTemplateBtns(false);
    }

    if (isCustomerManagedReturn && selectedReturnMethod === LABELS.TWO_WAY) {
      if (isCustomerManagedReturn === customerManagedReturn) {
        setShowEditPreviewTemplateBtns(false);
        return;
      } else if (isCustomerManagedReturn) {
        setShowEditPreviewTemplateBtns(true);
      }
    } else setShowEditPreviewTemplateBtns(customerManagedReturn);
  }, [customerManagedReturn]);

  useEffect(() => {
    const getEmailTemplateInfo = async () => {
      dispatch(incrementAwaitingApiCounter());

      try {
        const configResponse = await getEmailTemplatesConfig(userInfo);
        const templatesResponse = await getEmailTemplatesList(userInfo);

        if (
          configResponse?.statusCode === 200 &&
          templatesResponse?.statusCode === 200
        ) {
          const configData = configResponse.data;

          setLogoFile(configData?.value?.brand_logo || "/assets/no-img.svg");

          const templateData = templatesResponse.data;
          setEmailTemplateList(templateData);

          const lastUpdateDate = templateData.map((el) => ({
            template_name: el.template_name,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
          }));
          setTemplateLastUpdateList(lastUpdateDate);
        }

        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        console.error("Error fetching template info:", error);
        dispatch(decrementAwaitingApiCounter());
      }
    };

    getEmailTemplateInfo();
  }, [refresh, openEditTemplateUI]);

  const handleLastUpdatedInfo = async (currentTextData) => {
    if (templateLastUpdateList?.length > 0) {
      const currentTemplate = templateLastUpdateList.find(
        (updateInfo) =>
          updateInfo.template_name === currentTextData.templateName
      );
      if (currentTemplate) {
        const updatedById =
          currentTemplate.updated_by || currentTemplate.created_by;
        const updatedAt =
          currentTemplate.updated_at || currentTemplate.created_at;

        const updatedByName =
          (await getUserNameById(userInfo, updatedById)) || "Unknown User";

        return `${updatedByName} on ${moment
          .unix(updatedAt)
          .tz("America/Los_Angeles")
          .format("MMM DD, YYYY")}`;
      } else return "No updated yet";
    } else return "No updated yet";
  };

  useEffect(() => {
    const updateSelectableItemData = async () => {
      // dispatch(incrementAwaitingApiCounter());

      let updatedData = [
        ...(isTwoWayDeliveryLabel
          ? [emailConfigurationTexts.twoWayReturn]
          : []),
        ...(customerManagedReturn
          ? [emailConfigurationTexts.customerManagedReturns]
          : []),
      ];

      updatedData = await Promise.all(
        updatedData.map(async (el) => {
          const lastUpdated = await handleLastUpdatedInfo(el);
          return { ...el, lastUpdatedInfo: lastUpdated };
        })
      );

      setEmailTemplateItemData(updatedData);

      // dispatch(decrementAwaitingApiCounter());
    };

    updateSelectableItemData();
  }, [
    isTwoWayDeliveryLabel,
    customerManagedReturn,
    emailConfigurationTexts,
    templateLastUpdateList,
  ]);

  return (
    <>
      {openEditTemplateUI ? (
        <Stack
          gap={2}
          padding={5}
          paddingTop={2}
          width={"100%"}
          sx={{
            backgroundColor: "white",
            border: "1px solid #D9D9D9",
          }}
        >
          <EmailTemplateEdition
            template_name={openEditTemplateUI}
            setOpenEditTemplateUI={setOpenEditTemplateUI}
            emailTemplateItemData={emailTemplateItemData}
            logoFile={logoFile}
            setRefresh={setRefresh}
          />
        </Stack>
      ) : (
        <Stack
          gap={4}
          padding={5}
          width={"100%"}
          sx={{
            backgroundColor: "white",
            border: "1px solid #D9D9D9",
            position: "relative",
          }}
        >
          <Stack gap={1}>
            <Typography variant="h5" textAlign={"center"}>
              Email configuration
              <Tooltip
                title="You can edit this later from Email templates."
                placement="right"
                arrow
              >
                <IconButton>
                  <InfoOutlinedIcon fontSize="small" color="action" />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body2" textAlign={"center"} color={"#AAAAAA"}>
              Select the email configuration for your return label
            </Typography>
          </Stack>
          <Stack
            position={"absolute"}
            right={35}
            top={25}
            direction={"row"}
            gap={1}
            alignItems={"center"}
            sx={{
              cursor: "pointer",
            }}
            onClick={handleEditConfiguration}
          >
            <img
              title="Edit Card"
              style={{
                height: "26px",
              }}
              src="/assets/edit-config.svg"
            />
            <Typography
              color={"#256FA5"}
              fontFamily={typography.fontFamilySemiBold}
            >
              Edit configurations
            </Typography>
          </Stack>

          <Stack>
            {emailTemplateItemData.map((el, index) => (
              <EmailTemplateItem
                key={index}
                data={el}
                setOpenEditTemplateUI={setOpenEditTemplateUI}
                setOpenPreview={setOpenPreview}
                setTemplateNameSelected={setTemplateNameSelected}
                emailTemplateList={emailTemplateList}
                lastUpdatedInfo={el?.lastUpdatedInfo}
                showBtns={showEditPreviewTemplateBtns}
                hideCheckbox
                hideDivider={index === emailTemplateItemData.length - 1}
              />
            ))}
          </Stack>

          {showEditPreviewTemplateBtns && (
            <Stack gap={1} direction={"row"} justifyContent={"flex-end"}>
              <CheckCircleIcon
                onClick={() => setOpenConfirmSaveModal(true)}
                sx={{
                  color: "#002F43",
                  cursor: "pointer",
                  width: "50px !important",
                  height: "50px !important",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </Stack>
          )}

          {openConfirmSaveModal && (
            <ModalComponent
              openModal={openConfirmSaveModal}
              handleCloseModal={() => setOpenConfirmSaveModal(false)}
              title={"Are you sure you want to make this change?"}
              btnText={"Proceed"}
              cancelBtnText={"Cancel"}
              handleBtnClick={() => {
                handleSave();
                setOpenConfirmSaveModal(false);
              }}
              description={
                <Typography width={600}>
                  {`Note: Returns from [${formatDateTime((new Date()))}of change] will
                  reflect the 2 way delivery label setting.`}
                </Typography>
              }
            />
          )}
        </Stack>
      )}

      <Modal open={openPreview} onClose={() => setOpenPreview(false)}>
        <Box sx={popUpModalStyle}>
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: 11,
              right: 11,
              zIndex: 2,
            }}
            onClick={() => setOpenPreview(false)}
          >
            <img width={25} src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>
          <PreviewTemplate
            template_name={templateNameSelected}
            logoFile={logoFile}
            isRenderingFromModal
          />
        </Box>
      </Modal>
    </>
  );
};

export default EmailConfiguration;
