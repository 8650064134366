const InformationIcon = ({ color }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25 45.8327C15.179 45.8327 10.2686 45.8327 7.2176 42.7816C4.16663 39.7308 4.16663 34.8202 4.16663 24.9993C4.16663 15.1784 4.16663 10.268 7.2176 7.21699C10.2686 4.16602 15.179 4.16602 25 4.16602C34.8208 4.16602 39.7314 4.16602 42.7822 7.21699C45.8333 10.268 45.8333 15.1784 45.8333 24.9993C45.8333 34.8202 45.8333 39.7308 42.7822 42.7816C39.7314 45.8327 34.8208 45.8327 25 45.8327ZM25 36.9785C25.8629 36.9785 26.5625 36.2789 26.5625 35.416V22.916C26.5625 22.0531 25.8629 21.3535 25 21.3535C24.137 21.3535 23.4375 22.0531 23.4375 22.916V35.416C23.4375 36.2789 24.137 36.9785 25 36.9785ZM25 14.5827C26.1506 14.5827 27.0833 15.5154 27.0833 16.666C27.0833 17.8166 26.1506 18.7493 25 18.7493C23.8493 18.7493 22.9166 17.8166 22.9166 16.666C22.9166 15.5154 23.8493 14.5827 25 14.5827Z"
        fill="#1C272A"
      />
    </svg>
  );
};

export default InformationIcon;
