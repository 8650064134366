import { LoadingButton } from "@mui/lab";
import ReturnsLayout from "./ReturnsLayout";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useState } from "react";
import useCustomerOrderInfo from "../../../features/return_management/hooks/useCustomerOrderInfo";
import TrackingPageItemCard from "../components/TrackingPageItemCard";
import { useNavigate, useParams } from "react-router-dom";
import CircularLoader from "../../../loader/circular/CircularLoader";
import { getCurrencySymbol } from "../../../utils/core";
import { useSelector } from "react-redux";

const {
  Typography,
  Box,
  Grid,
  Stack,
  Tooltip,
  IconButton,
} = require("@mui/material");

const TrackingItems = ({ isHeader = true, isReturnHeadColor = false }) => {
  const navigate = useNavigate();
  const { tenant_name } = useParams();
  const [trackingItems, setTrackingItems] = useState([]);
  const [copied, setCopied] = useState(false);
  const {
    customerOrderInfo,
    eligibleLineItems,
    isLoading,
    returnInfo,
    customAuthInfo,
    tenantInfo,
    returnRequestEstimate,
    productImages,
    brandPortalConfig,
    trackingPortalConfig,
    fetchCustomerOrderInfo,
    fetchOrderEligibleLineItems,
    fetchEstimateReturnSummary,
    fetchReturnInformationById,
  } = useCustomerOrderInfo();
  const currency_symbol = getCurrencySymbol(customerOrderInfo.currency);
  let return_base_path =
    customAuthInfo.return_flow === "customer_support"
      ? "returns_support"
      : "returns";
  useEffect(() => {
    if (customAuthInfo?.isLoggedIn && customAuthInfo?.user_info?.order_id) {
      if (returnInfo?._id) {
        fetchCustomerOrderInfo(tenantInfo, {
          order_number: customAuthInfo?.user_info.order_id,
        });
        fetchReturnInformationById(tenantInfo, {
          return_id: returnInfo?._id,
        });
      } else {
        if (customAuthInfo.return_flow === "customer_support") {
          navigate(`/returns_support/${tenant_name}/create-return`);
        } else {
          navigate(`/returns/${tenant_name}/create-return`);
        }
      }
    } else {
      navigate(`/returns/${tenant_name}`);
    }
  }, []);
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      // Get the value of the key we want to group by
      const groupKey = currentValue[key];
      // If the key doesn't exist in the result object, create it and assign an empty array
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      // Push the current value to the group
      result[groupKey].push(currentValue);
      // Return the result object for the next iteration
      return result;
    }, {}); // Initial value of the result object is an empty object
  };
  useEffect(() => {
    if (customerOrderInfo?.order_id) {
      const order_line_items = customerOrderInfo.line_items;
      const groupData = groupBy(order_line_items, "id");
      const returnOrderList = returnInfo?.line_items?.map((item) => {
        const lineItem = groupData[item.line_item_id][0];
        const product_id = lineItem.product_id;
        let image = "/assets/no-image.png";
        if (typeof productImages[product_id] != "undefined") {
          const product_img = productImages[product_id]?.image;
          if (product_img != "" && product_img != null) {
            image = product_img;
          }
        }
        return {
          id: lineItem.id,
          title: lineItem.name,
          description: lineItem.title,
          price: lineItem.price,
          imgUrl: image,
          ...item,
        };
      });
      setTrackingItems(returnOrderList);
    }
  }, [customerOrderInfo?.order_id, returnInfo]);

  const handleCopy = () => {
    if (returnInfo?.return_id) {
      navigator.clipboard
        .writeText(returnInfo.return_id)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  return (
    <>
      <ReturnsLayout isHeader={isHeader} isReturnHeadColor={isReturnHeadColor}>
        {isLoading && <CircularLoader />}
        <Box
          sx={{
            backgroundColor: trackingPortalConfig?.color_theme?.page_bg_color,
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "100%",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "850px",
                alignItems: "center",
                margin: "auto",
                justifyContent: "space-between",
                zIndex: 2,
                mb: "20px",
              }}
            >
              <Typography
                sx={{ color: "#000", fontSize: "20px", fontWeight: "bold" }}
              >
                Track Returns Orders
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography sx={{ color: "#000" }}>
                  Return ID : {returnInfo?.return_id}
                  <Tooltip title={copied ? "Copied!" : "Copy"}>
                    <IconButton onClick={handleCopy} size="small">
                      <ContentCopyIcon
                        fontSize="small"
                        sx={{
                          color: `${brandPortalConfig?.color_theme?.brand_color}`,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ height: "500px", overflowY: "auto" }}>
              {trackingItems.map((data) => (
                <TrackingPageItemCard
                  currency_symbol={currency_symbol}
                  data={data}
                  returnInfo={returnInfo}
                />
              ))}
            </Box>

            <Stack
              direction={"row"}
              justifyContent={"center"}
              gap={2}
              marginTop={2}
            >
              <LoadingButton
                variant="contained"
                onClick={() => {
                  let url = brandPortalConfig?.logo_url;

                  if (
                    url &&
                    !url.startsWith("http://") &&
                    !url.startsWith("https://")
                  ) {
                    url = `https://${url}`;
                  }

                  if (url) {
                    window.open(url, "_blank");
                  } else {
                    navigate("/delivery/dashboard/");
                  }
                }}
                sx={{
                  backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                  width: "130px",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                  },
                }}
              >
                Home
              </LoadingButton>
              <LoadingButton
                varaint="contained"
                onClick={() => {
                  navigate(`/${return_base_path}/${tenant_name}/tracking`);
                }}
                sx={{
                  backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                  color: "#fff",
                  width: "130px",
                  "&:hover": {
                    backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                  },
                }}
              >
                Track Items
              </LoadingButton>
            </Stack>
          </Box>
        </Box>
      </ReturnsLayout>
    </>
  );
};
export default TrackingItems;
