import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Box, Typography } from "@mui/material";
import { setSigninData } from "src/features/signin/signin.action";
import { testConection } from "src/features/onborading/onboardingApi";
import { LoadingButton } from "@mui/lab";

import ModalComponent from "src/components/modal/ModalComponent";
import CircularLoader from "src/loader/circular/CircularLoader";
import InfoLogo from "src/components/info-logo/info-logo";
import palette from "src/theme/palette";

import "./shopifyForm.scss";

const styleInput = {
  input: { color: palette.common.black },
  marginTop: "10px",

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.light",
  },
  "& .MuiInputLabel-formControl": {
    color: "#00000099",
  },
  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
    color: "#00000099",
  },
  "& .MuiFormHelperText-root": {
    textAlign: "left",
    fontSize: "14px",
  },
};

const ShopifyForm = () => {
  const dispatch = useDispatch();
  const signinData = useSelector((state) => state.signin.signinData);
  const {
    managerSignShopifyStoreUrl,
    managerSignShopifyStoreUrlErrors,
    managerSignShopifyStoreUrlNotValid,

    managerSignShopifyApiKey,
    managerSignShopifyApiKeyErrors,

    isShopifyValid,
    isShopifyTested,

    isFromConfig,
  } = signinData;

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [testCon, setTestCon] = useState(false);

  const form = [
    { text: "Log in to your Shopify Store.", inp: false },
    {
      text: `Go to Settings > Apps and sales channel and click on "Develop apps" button.`,
      inp: false,
      extraOptions: false,
    },
    {
      text: `Click on "Create an app" button and enter the name of the App as "BeyondCarts-Integration-App".`,
      inp: false,
      extraOptions: false,
    },
    {
      text: `Navigate to Configuration tab and in "Admin API Integration" block , Click on "Configure" that would open up "Admin API access scopes".`,
      inp: false,
      extraOptions: false,
    },
    {
      text: `On the "Admin API access scopes" page - Select "Write Orders" and "Read Orders" scopes and Save.`,
      inp: false,
      extraOptions: false,
    },
    {
      text: `Install the App and that would give access to "Admin API access token" and click on "reveal token once".`,
      inp: false,
      extraOptions: false,
    },
    {
      text: "Copy the Shopify Store URL and paste it here:",
      inp: true,
      placehold: "https://mystore.shopify.com",
      infoTxt:
        "Enter your Shopify store's URL. This should include the full domain name but not the protocol ( https://).For example, if your store’s web address is 'https://mystore.shopify.com', simply enter 'mystore.shopify.com'. This URL is used to uniquely identify your Shopify store in the integration.",
      extraOptions: false,
    },
    {
      text: "Copy the API Key and paste it here:",
      inp: true,
      placehold: "shpat_KEY *",
      infoTxt:
        "Enter the API Key provided by Shopify. This key allows secure communication between our service and your Shopify store. You can find this key in your Shopify admin under 'Apps' > 'Manage private apps'. Ensure you keep this key confidential to prevent unauthorized access to your store's data.",
      extraOptions: false,
    },
  ];

  const checkUrl = (url) => {
    if (
      !/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
        url
      )
    )
      return true;
    return false;
  };

  const handleTestConnection = async () => {
    if (checkUrl(managerSignShopifyStoreUrl)) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyStoreUrlErrors: true,
          managerSignShopifyStoreUrlNotValid: true,
        })
      );
      return;
    }

    if (!signinData.managerSignShopifyApiKey) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyApiKeyErrors: true,
        })
      );
      return;
    }

    setIsLoading(true);

    testConection({
      action: "shopify",
      access_token: managerSignShopifyApiKey,
      store_url: managerSignShopifyStoreUrl,
    })
      .then((r) => {
        const { statusCode } = r;

        if (statusCode === 200) {
          dispatch(
            setSigninData({
              ...signinData,
              isShopifyValid: true,
              isShopifyTested: true,
            })
          );

          setTestCon("SHOPIFY");
        } else {
          dispatch(
            setSigninData({
              ...signinData,
              isShopifyValid: false,
              isShopifyTested: true,
            })
          );

          setTestCon("SHOPIFY_NOT");
        }

        setIsLoading(false);
      })
      .catch(() => {
        dispatch(
          setSigninData({
            ...signinData,
            isShopifyValid: false,
            isShopifyTested: true,
          })
        );

        setTestCon("NOT_SHOPIFY");
        setIsLoading(false);
      });
  };

  const onValue = (index) => {
    if (index === 6) return managerSignShopifyStoreUrl;
    if (index === 7) return managerSignShopifyApiKey;
  };

  const onInputChange = (value, index) => {
    if (index === 6)
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyStoreUrl: value,
          isShopifyValid: false,
          isShopifyTested: false,
          continueBtn: false,
        })
      );
    else if (index === 7)
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyApiKey: value,
          isShopifyValid: false,
          isShopifyTested: false,
          continueBtn: false,
        })
      );
  };

  const onInputError = (index) => {
    if (index === 6 && managerSignShopifyStoreUrlErrors) return true;
    if (index === 7 && managerSignShopifyApiKeyErrors) return true;
    return false;
  };

  const onFocus = (index) => {
    if (index === 6 && managerSignShopifyStoreUrlErrors)
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyStoreUrlErrors: false,
          managerSignShopifyStoreUrlNotValid: false,
        })
      );
    if (index === 7 && managerSignShopifyApiKeyErrors)
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyApiKeyErrors: false,
        })
      );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(setSigninData({ ...signinData, continueBtn: true }));
  };

  useEffect(() => {
    if (!testCon) return;

    if (isShopifyTested) {
      if (isShopifyValid) {
        dispatch(setSigninData({ ...signinData, continueBtn: true }));
      } else {
        setOpenModal(true);
        setModalText(
          "Your test connection did not succeed with Shopify, affecting our ability to retrieve your order details at this moment. Please fix the connection and try again."
        );
        setTestCon(true);
      }
    }
  }, [testCon]);

  useEffect(() => {
    if (isFromConfig) return;
    if (isShopifyTested)
      dispatch(setSigninData({ ...signinData, continueBtn: true }));
    else dispatch(setSigninData({ ...signinData, continueBtn: false }));
  }, []);

  return (
    <>
      {isLoading && <CircularLoader />}

      <div className="shopify-form-container">
        <img className="manage-img" src="/assets/shopify.png" />

        <div className="shopify-form-step-container">
          {form.map((f, index) => (
            <Fragment key={index}>
              <div className="shopify-form-step">
                <p className="shopify-form-index">{index + 1}</p>
                <p className="shopify-form-txt">{f.text}</p>
              </div>

              {f.inp && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    fullWidth
                    sx={styleInput}
                    label={f.placehold}
                    value={onValue(index)}
                    error={onInputError(index)}
                    helperText={
                      managerSignShopifyStoreUrlErrors &&
                      managerSignShopifyStoreUrlNotValid
                        ? onInputError(index) && "URL is not valid."
                        : onInputError(index) && `${f.placehold} is required.`
                    }
                    onFocus={() => onFocus(index)}
                    onChange={(e) =>{
                     const trimmedValue = e.target.value.trim();
                     onInputChange(trimmedValue, index)}
                    } 
                    inputProps={{
                      maxLength: 50,
                    }}
                  ></TextField>
                  <InfoLogo infoTxt={f.infoTxt}></InfoLogo>
                </Box>
              )}
            </Fragment>
          ))}

          <Typography
            sx={{
              color: palette.common.black,
              fontSize: 18,
              textAlign: "center",
              paddingTop: 1,
            }}
          >
            Kindly test connection to continue with the next step
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LoadingButton
              variant="contained"
              type="submit"
              onClick={() => handleTestConnection()}
              sx={{
                fontSize: "16px !important",
                boxShadow: "none",
                width: "50%",
                margin: "20px auto",
                backgroundColor: isShopifyTested
                  ? isShopifyValid
                    ? palette.success.dark
                    : palette.error.dark
                  : palette.secondary.light,
              }}
            >
              {isShopifyTested
                ? isShopifyValid
                  ? "Connection Successful"
                  : "Unable to Connect"
                : "Test Shopify Connection"}
            </LoadingButton>
          </Box>
        </div>
      </div>

      <ModalComponent
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleBtnClick={handleCloseModal}
        title={`Connection status`}
        description={modalText}
        btnText={"Continue"}
      ></ModalComponent>
    </>
  );
};

export default ShopifyForm;
