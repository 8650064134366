import axios from "axios";
import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendBaseUrl } from "src/utils/backendUrls";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    return decryptData(result);
  }
  return result;
};

export async function getBrandedPortalInfo(tenant_name, userInfo) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}tenant/${tenant_name}/return/brand_portal/info`,
    null,
    headers,
    token
  );
  return result;
}

export async function postBrandedPortalInfo(tenant_name, data, userInfo) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${backendBaseUrl}tenant/${tenant_name}/return/brand_portal/info`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result.data));
}

export async function uploadBrandedPortalImages(data, userInfo) {
  const headers = {};
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const result = await postData(
    `${backendBaseUrl}tenant/s3bucket/generate/presigned_url`,
    headers,
    data,
    {}
  );
  return JSON.parse(extractData(result));
}

export async function uploadToS3(url, file, headers) {
  try {
    const response = await axios.put(url, file, headers);
    return response.data;
  } catch (e) {
    console.error(e, "s3 upload error");
    throw e;
  }
}

export async function generateLabel(data, userInfo, headers = {}) {
  const token = userInfo?.accessToken;

  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }

  let result;
  try {
    result = await postData(
      `${backendBaseUrl}label-generation`,
      headers,
      data,
      token
    );
    return JSON.parse(extractData(result));
  } catch (error) {
    return { error };
  }
}
