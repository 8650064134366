import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import Logo from "src/components/logo";
import { LoginForm } from "src/sections/auth/login";
import SignupForm from "src/sections/auth/signin/signupForm";
import typography from "src/theme/typography";
import CircularLoader from "src/loader/circular/CircularLoader";

const StyledRoot = styled("div")(({ theme, signUp, isOtpView }) => ({
  flex: 1,
  marginBottom: 20,
  display: "flex",
  alignItems: signUp ? (isOtpView ? "center" : "flex-start") : "center",
  justifyContent: "center",
  gap: 20,
  [theme.breakpoints.down("md")]: {
    marginBottom: 40,
    paddingBottom: 30,
    paddingTop: 30,
  },
  [theme.breakpoints.down("xl")]: {
    gap: 0,
  },
}));

const StyledContent = styled("div")(({ theme, isOtpView }) => ({
  display: "flex",
  width: "100%",
  maxWidth: 700,
  marginTop: isOtpView ? "auto" : 0,
  marginBottom: isOtpView ? "auto" : 0,
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(3, 8),
  gap: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(3),
    padding: theme.spacing(0, 2),
  },
}));

export default function LoginPage() {
  const [signUp, setSignUp] = useState(false);
  const [isOtpView, setIsOtpView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFromLogin, setIsFromLogin] = useState(false);

  return (
    <>
      <Helmet>
        <title> Login | BeyondCarts </title>
      </Helmet>

      {isLoading && <CircularLoader />}

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100% !important",
          padding: "0 !important",
          height: "100%",
        }}
      >
        <img
          src="/assets/beyond-carts-logo.svg"
          width={230}
          alt="BeyondCarts logo"
          style={{
            padding: "15px",
          }}
        />
        {/* <Logo
          isLight={false}
          sx={{
            width: "100%",
            padding: 2,
          }}
        /> */}
        <StyledRoot signUp={signUp} isOtpView={isOtpView}>
          <Container
            sx={{
              width: {
                lg: 500,
                xl: 550,
              },
              height: "auto",
              margin: "0 !important",
              padding: "0 !important",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <img src="/assets/images/login-img.svg" alt="login" />
          </Container>

          <StyledContent isOtpView={isOtpView}>
            <h1
              style={{
                ...typography.bigTitle,
                textAlign: "center",
              }}
              className="bigTitle"
            >
              {signUp
                ? isOtpView
                  ? "Confirmation Code"
                  : "Get Started"
                : "Welcome!"}
            </h1>
            {signUp ? (
              <SignupForm
                setSignUp={setSignUp}
                isOtpView={isOtpView}
                setIsOtpView={setIsOtpView}
                isFromLogin={isFromLogin}
              />
            ) : (
              <LoginForm
                setSignUp={setSignUp}
                setIsOtpView={setIsOtpView}
                setIsLoading={setIsLoading}
                setIsFromLogin={setIsFromLogin}
              />
            )}
          </StyledContent>
        </StyledRoot>
      </Container>
    </>
  );
}
