import { useEffect, useState } from "react";
import { Stack, Box, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import { forgotPassword } from "src/features/user/userApi";
import { ErrorMessagesSchema } from "src/utils/errorMessagesSchema";
import { resendOtpCode } from "src/features/onborading/onboardingApi";

const OtpForm = ({ control, email, setError, isError, onBoarding = false }) => {
  const otpLength = 6;
  const secsToResendCode = 30;
  const [timerCount, setTimerCount] = useState(secsToResendCode);

  const handleResendCode = async () => {
    setTimerCount(secsToResendCode);
    try {
      onBoarding
        ? await resendOtpCode({ email: email })
        : await forgotPassword({ email: email });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!timerCount) return;

    const intervalId = setInterval(() => {
      setTimerCount(timerCount - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timerCount]);

  return (
    <>
      {timerCount === 0 ? (
        <Box
          sx={{
            color: "primary.main",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={handleResendCode}
        >
          Click here to resend code
        </Box>
      ) : (
        <Box sx={{ color: "primary.main", textAlign: "center" }}>
          Resend code in {timerCount} secs
        </Box>
      )}
      <Stack
        direction="row"
        paddingTop={1}
        gap={{ xs: 2, sm: 2, md: 3 }}
        className="flexContainer"
      >
        <Controller
          control={control}
          rules={{ validate: (value) => value?.length === otpLength }}
          render={({ field, fieldState }) => (
            <Box>
              <MuiOtpInput
                sx={{
                  gap: { xs: 1, md: 2 },
                  input: {
                    backgroundColor: "#eaeaea",
                    borderRadius: "inherit",
                  },

                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "primary.dark",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "primary.dark",
                    },
                  },
                }}
                {...field}
                length={otpLength}
                onFocus={() => {
                  try {
                    setError(false);
                  } catch (error) {}
                }}
              />
              {fieldState.invalid && (
                <FormHelperText error>
                  {ErrorMessagesSchema.otpCode.invalid}
                </FormHelperText>
              )}
              {isError && (
                <FormHelperText error>
                  {ErrorMessagesSchema.otpCode.wrong}
                </FormHelperText>
              )}
            </Box>
          )}
          name="otp"
        ></Controller>
      </Stack>
    </>
  );
};

export default OtpForm;
