import axios from "axios";
import { deleteToken } from "src/utils/extractUserToken";
import { decryptData } from "./chiper";
import { encConfig } from "./config";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    if (result?.data) return decryptData(result?.data);
  }
  return result;
};

export default async function deleteData(url, headers, token = null) {
  const randomInt = Math.floor(1e7 + Math.random() * 9e7);
  const config = {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Apikey: process.env.REACT_APP_BACKEND_API_KEY,
      request_id: randomInt,
      "Access-Control-Allow-Origin": "*",
    },
  };

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  let result = {};

  try {
    const response = await axios.delete(url, config);
    result = response?.data;
  } catch (e) {
    if((e?.status||e?.response?.status)==401 ||(e?.status||e?.response?.status)==403){
      deleteToken();
      return; 
    }
    let statusMsg = JSON.parse(extractData(e?.response));
    if ([401, 403].includes(parseInt(e?.status||e?.response?.status))) {
      deleteToken();
      return;
    }
    throw new Error(statusMsg.statusMessage || "Something went wrong!");
  }
  return result;
}
