import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMethod: '',
  customerManagedReturn: false,
};

export const returnMethodSlice = createSlice({
  name: 'returnMethod',
  initialState,
  reducers: {
    setSelectedMethod: (state, action) => {
      state.selectedMethod = action.payload;
    },
    setCustomerManagedReturn: (state, action) => {
      state.customerManagedReturn = action.payload;
    },
  },
});

export const selectSelectedMethod = (state) => state.returnMethod.selectedMethod;
export const selectCustomerManagedReturn = (state) => state.returnMethod.customerManagedReturn;
export const { setSelectedMethod, setCustomerManagedReturn } = returnMethodSlice.actions;
export default returnMethodSlice.reducer;
