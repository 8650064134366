import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import deleteData from "src/dataFetcher/deleteDataFetcher";

import { backendBaseUrl, backendUserUrl } from "src/utils/backendUrls";
import { encConfig } from "src/dataFetcher/config";
import { decryptData } from "src/dataFetcher/chiper";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    if (result?.data) return decryptData(result?.data);
  }
  return result;
};

export async function getInvoices(params, headers, userInfo) {
  const tenant = userInfo?.tenant_name;
  const token = userInfo?.accessToken;

  const formatToTwoDecimals = (number) => {
    // Check if the number has decimal places
    if (number % 1 !== 0) {
      // If it has decimal places, round it to two decimal places
      return parseFloat(number.toFixed(2));
    } else {
      // If it's a whole number, add '.00' to the end
      return parseFloat(number + ".00");
    }
  };

  const res = await getData(
    `${backendUserUrl}${tenant}/billing/invoices`,
    params,
    headers,
    token
  );
  if (res.data?.records.length) {
    res.data.records = res.data.records.map((invoice) => ({
      ...invoice,
      price: formatToTwoDecimals(invoice.price),
      gross_total: formatToTwoDecimals(invoice.gross_total),
    }));
  }

  return res;
}

export async function getAllPayments(params = null, headers = null, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  headers.tenant_id = userInfo?.tenant_id;

  const result = await getData(
    `${backendUserUrl}${tenant}/payment_method`,
    params,
    headers,
    token
  );

  return result;
}

export async function getUpcomingPayments(userInfo, payment) {
  const token = userInfo?.accessToken;
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo.user_id,
  };
  const result = await postData(
    `${backendBaseUrl}upcoming-payment`,
    headers,
    {},
    token,
    payment
  );

  return result;
}

export async function createPaymentMethod(data, userInfo, type) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const header = {
    action: type,
    tenant_id: userInfo?.tenant_id,
    tenant_name: tenant,
    user_id: userInfo.user_id,
  };

  const res = await postData(
    `${backendBaseUrl}tenant-cards`,
    header,
    data,
    token
  );
  return JSON.parse(extractData({ data: res }));
}

export async function updatePaymentById(cardId, data, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;

  const header = {
    payment_method_id: cardId,
    action: "update",
    tenant_name: tenant,
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo.user_id,
  };

  return await postData(`${backendBaseUrl}tenant-cards`, header, data, token);
}

export async function deletePaymentById(cardId, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;

  const header = {
    tenant_id: userInfo?.tenant_id,
  };

  return await deleteData(
    `${backendUserUrl}${tenant}/payment_method/${cardId}`,
    header,
    token
  );
}

export async function updatePreferredCardById(cardId, data, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;

  const header = {
    tenant_id: userInfo?.tenant_id,
  };

  return await putData(
    `${backendUserUrl}${tenant}/payment_method/preferred/${cardId}`,
    header,
    data,
    token
  );
}

export async function payPlan(data, userInfo) {
  const token = userInfo?.accessToken;
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo.user_id,
  };
  const res = await postData(`${backendBaseUrl}payNow`, headers, data, token);
  return JSON.parse(extractData({ data: res }));
}

export async function latestPendingInvoices(userInfo) {
  const token = userInfo?.accessToken;
  const headers = {
    tenant_id: userInfo?.tenant_id,
  };
  return await getData(
    `${backendUserUrl}billing/invoice/latest`,
    null,
    headers,
    token
  );
}
