import { Outlet } from "react-router-dom";
import Header from "./header";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { useState } from "react";
import navDetails from "./nav/navDetails";
import NavItems from "src/components/nav-section/NavItems";
import palette from "src/theme/palette";
import {
  getCurrentNavBarState,
  updateMenuState,
} from "src/features/navbar/navbarState";
import appUrls from "src/utils/appUrls";

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  width: "min-content",
  backgroundColor: theme.palette.primary.lighter,
  paddingTop: "0px !important",
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(8.3),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

const drawerWidth = 320;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: theme.palette.primary.lighter,
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function DashboardLayout() {
  const theme = useTheme();
  const open = useAppSelector(getCurrentNavBarState);
  const [openDeliveryManagementMenu, setOpenDeliveryManagementMenu] = useState(
    location.pathname.includes(appUrls.outboundManagement.baseUrl) && open
  );
  const [openReportsMenu, setOpenReportsMenu] = useState(
    location.pathname.includes(appUrls.outboundManagement.reports.baseUrl) &&
      open
  );
  const [openReturnsManagementMenu, setOpenReturnsManagementMenu] = useState(
    location.pathname.includes(appUrls.inboundManagement.baseUrl) && open
  );
  const [openConfigManagementMenu, setOpenConfigManagementMenu] = useState(
    location.pathname.includes(appUrls.configurationManagement.baseUrl) && open
  );
  const [openDeliveryTooltip, setOpenDeliveryTooltip] = useState(false);
  const [openReturnsTooltip, setOpenReturnsTooltip] = useState(false);
  const [openConfigTooltip, setOpenConfigTooltip] = useState(false);

  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();

  const handleDrawerClick = () => {
    dispatch(updateMenuState(!open));

    if (open) {
      // setOpenDeliveryManagementMenu(false);
      // setOpenReportsMenu(false);
      // setOpenReturnsManagementMenu(false);
      setOpenDeliveryTooltip(false);
      setOpenReturnsTooltip(false);
      setOpenConfigTooltip(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        minHeight: "100%",
      }}
    >
      <CssBaseline />
      <AppBar
        position="absolute"
        open={open}
        sx={{
          zIndex: 9,
        }}
      >
        <Toolbar
          sx={{
            paddingLeft: "36px !important",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClick}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
              opacity: 0,
            }}
          >
            <img
              src="/assets/beyond-carts-logo-small.svg"
              alt="BeyondCarts logo"
              width={24}
            />
          </IconButton>
          <Header />
        </Toolbar>
        <Divider />
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          overflow: "visible",
          ".MuiDrawer-paper": {
            backgroundColor: palette.primary.main,
            // position: "relative",
            zIndex: 10,
            overflow: "visible",
            position: "fixed",
            height: "100%",
          },
        }}
      >
        <DrawerHeader
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {open ? (
            <img
              src="/assets/beyond-carts-logo-white.svg"
              alt="BeyondCarts logo"
              width={210}
              height={46}
            />
          ) : (
            <img
              src="/assets/beyond-carts-logo-small.svg"
              alt="BeyondCarts logo"
              width={24}
              height={24}
            />
          )}
        </DrawerHeader>
        <IconButton
          onClick={handleDrawerClick}
          sx={{
            position: "absolute",
            right: -22,
            top: 10,
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <>
              {open ? (
                <ChevronLeftIcon
                  sx={{
                    color: "#fff",
                    backgroundColor: "#3A7DAE",
                    borderRadius: 20,
                    boxShadow: "0px 0px 6px 2px #1C75FD4D",
                    width: 26,
                    height: 26,
                  }}
                />
              ) : (
                <ChevronRightIcon
                  sx={{
                    color: "#fff",
                    backgroundColor: "#3A7DAE",
                    borderRadius: 20,
                    boxShadow: "0px 0px 6px 2px #1C75FD4D",
                    width: 26,
                    height: 26,
                  }}
                />
              )}
            </>
          )}
        </IconButton>
        <Divider
          sx={{
            background: "#fff",
          }}
        />
        <NavItems
          open={open}
          handleDrawerClick={handleDrawerClick}
          data={navDetails(userInfo)}
          openDeliveryManagementMenu={openDeliveryManagementMenu}
          setOpenDeliveryManagementMenu={setOpenDeliveryManagementMenu}
          openReturnsManagementMenu={openReturnsManagementMenu}
          setOpenReturnsManagementMenu={setOpenReturnsManagementMenu}
          openReportsMenu={openReportsMenu}
          setOpenReportsMenu={setOpenReportsMenu}
          openDeliveryTooltip={openDeliveryTooltip}
          setOpenDeliveryTooltip={setOpenDeliveryTooltip}
          openReturnsTooltip={openReturnsTooltip}
          setOpenReturnsTooltip={setOpenReturnsTooltip}
          openConfigTooltip={openConfigTooltip}
          setOpenConfigTooltip={setOpenConfigTooltip}
          openConfigManagementMenu={openConfigManagementMenu}
          setOpenConfigManagementMenu={setOpenConfigManagementMenu}
        />
      </Drawer>
      {/* main content of the app */}
      <Main as="main" className="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
export default DashboardLayout;
