import palette from "src/theme/palette"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from "react-redux";

const { Grid, Box, Typography, Checkbox, Button, IconButton, TextField, Select, MenuItem } = require("@mui/material")

const ProductList = ({ showTrack, showPrice, showCheckBox, showQuantity, data, qty, reason, handleAddQuantity, handleRemoveQuantity, handleReasonChange }) => {
    const productInfo = useSelector(state => state.brandedPortal)
    return <>
        <Grid item xs={12} sx={{ borderBottom: 'solid', borderColor: palette.secondary.lighter, borderWidth: '0.5px', padding: '5px 10px' }}>
            <Grid container>
                <Grid item xs={3}>
                    <img width={100} height={120} style={{ objectFit: 'contain' }} src={data?.image || '/assets/no-image.png'} />
                </Grid>
                <Grid item xs={9} sx={{ margin: 'auto' }}>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography sx={{ color: '#000', fontSize: '14px', fontWeight: 'bold' }}>{data?.name || 'Name'}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, gap: 1 }}>
                                        <Typography sx={{ color: '#0009', fontSize: '13px' }}>Qty</Typography>
                                        <Typography sx={{ color: '#000', fontSize: '13px', fontWeight: 'bold' }}>2</Typography>
                                    </Box>
                                    <Typography sx={{ color: '#000', fontSize: '13px', fontWeight: 'bold', mt: 1 }}>$ {data?.price || 'price'}</Typography>
                                </Box>
                                {/* <Typography sx={{color: '#000' }}>{data.eligible_quantity}</Typography> */}
                                {showCheckBox && <Checkbox defaultChecked />}
                            </Box>
                        </Box>
                        {data?.eligible_quantity ? <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <IconButton onClick={handleRemoveQuantity} sx={{ backgroundColor: productInfo.brandedAssetsState.brand_background_color, width: '30px', height: '30px', borderRadius: '4px', color: 'white' }}>
                                    <RemoveIcon fontSize="small" />
                                </IconButton>
                                <Typography>{qty}/{data.eligible_quantity}</Typography>
                                <IconButton onClick={handleAddQuantity} sx={{ backgroundColor: productInfo.brandedAssetsState.brand_background_color, width: '30px', height: '30px', borderRadius: '4px', color: 'white' }}>
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                sx={{ width: "100%", color: "#000", my: 1 }}
                                value={reason}
                                onChange={handleReasonChange}
                            >
                                <MenuItem value={'UNWANTED'} sx={{ color: "#000" }}>
                                    Unwanted
                                </MenuItem>
                            </Select>
                        </Box>: <Typography sx={{padding: '5px', textWrap: 'nowrap', fontSize: '12px', borderRadius: '20px', color: '#EA1919', backgroundColor: '#fbd1d1'}}>Not eligible to Return</Typography>}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </>
}

export default ProductList