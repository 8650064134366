import axios from "axios";
import { deleteToken } from "src/utils/extractUserToken";
import { encConfig } from "./config";
import { decryptData, encryptData } from "./chiper";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    if (result?.data) return decryptData(result?.data);
  }
  return result;
};

export default async function postData(
  url,
  headers,
  data,
  token = null,
  payment = null
) {
  // random number of 9 digits, not starting by zero
  const randomInt = Math.floor(1e7 + Math.random() * 9e7);
  const config = {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Apikey: process.env.REACT_APP_BACKEND_API_KEY,
      request_id: randomInt,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  let result = {};
  try {
    if (payment) {
      const info = JSON.stringify(
        encConfig.encryption.encrypt && { data: encryptData(data) }
      );
      const res = await axios.post(url, info, config);
      return (result = JSON.parse(extractData(res)));
    } else {
      const info = JSON.stringify(
        encConfig.encryption.encrypt ? { data: encryptData(data) } : data
      );

      const res = await axios.post(url, info, config);
      return res.data;
    }
    // result = response?.data;
  } catch (e) {
    if (parseInt(e.response.status) === 401)
      throw new Error("Invalid credentials");

    if ((e?.response?.status || error?.status) == 403) {
      deleteToken();
      return;
    }
    let statusMsg = JSON.parse(extractData(e?.response));
    if ([403].includes(parseInt(e?.response?.status || e?.status))) {
      deleteToken();
      return;
    }

    if(e?.response?.status==409){
      throw new Error("Sorry. User name already exists");
    }
    throw new Error("Something went wrong!");
  }
  return result;
}
export async function postDataNoEnc(
  url,
  headers,
  data,
  token = null,
  payment = null
) {
  // random number of 9 digits, not starting by zero
  const randomInt = Math.floor(1e7 + Math.random() * 9e7);
  const config = {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Apikey: process.env.REACT_APP_BACKEND_API_KEY,
      request_id: randomInt,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };

  // if (token !== null) {
  //   config.headers.Authorization = `Bearer ${token}`;
  // }
  let result = {};
  try {
    const res = await axios.post(url, data, config);
    return res.data;
  } catch (e) {
    // if (parseInt(e.response.status) === 401)
    //   throw new Error("Invalid credentials");
    //
    // let statusMsg = JSON.parse(extractData(e?.response));
    // if ([403].includes(parseInt(statusMsg.statusCode))) {
    //   deleteToken();
    //   return;
    // }
    // throw new Error("Something went wrong!");
    throw e.response.data;
  }
  // return result;
}
