const PrepaidFreeIcon = ({ color,height,width }) => {
  return (
    <svg
      width={width||"40"}
      height={height||"40"}
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 29.5846V25.418H27.25L34.6458 15.0013L27.25 4.58464H6.41667V10.8346H2.25001V4.58464C2.25001 3.4388 2.65799 2.4579 3.47396 1.64193C4.28994 0.825955 5.27084 0.417969 6.41667 0.417969H27.25C27.9444 0.417969 28.5955 0.574219 29.2031 0.886719C29.8108 1.19922 30.3056 1.63325 30.6875 2.1888L39.75 15.0013L30.6875 27.8138C30.3056 28.3694 29.8108 28.8034 29.2031 29.1159C28.5955 29.4284 27.9444 29.5846 27.25 29.5846H21ZM6.41667 31.668V25.418H0.166672V21.2513H6.41667V15.0013H10.5833V21.2513H16.8333V25.418H10.5833V31.668H6.41667Z"
        fill={color || "#1C272A"}
      />
    </svg>
  );
};

export default PrepaidFreeIcon;
