import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Search from "src/components/search/Search";
import { FormHelperText, Stack, Box } from "@mui/material";
import appUrls from "src/utils/appUrls";

export default function Searchbar() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchValue = e.target.trackingNumber.value;
    if (searchValue)
      navigate(
        `${appUrls.outboundManagement.tracking.absoluteUrl}${e.target.trackingNumber.value}`
      );
    else setError(true);
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      // justifyContent="space-between"
      alignItems="flex-start"
      spacing={{ xs: 2, sm: "13vh", lg: "17vw" }}
      // width={"100%"}
    >
      <Box>
        <Search
          placeholder={"Enter tracking number"}
          onSubmitClicked={handleSubmit}
          onSearchClick={() => {}}
          error={error}
          sx={{
            width: { lg: 350, md: 330, sm: 260, xs: 220 },
          }}
          height={{ xs: 36, lg: 47 }}
          onSearchFocus={() => setError(false)}
        />
        <FormHelperText error={error}>
          {error && "Tracking number is required."}
        </FormHelperText>
      </Box>
    </Stack>
  );
}
