import { styled } from "@mui/material/styles";
import { Typography, Box, Card } from "@mui/material";
import PropTypes from "prop-types";
import typography from "src/theme/typography";
import CustomTooltip from "../tooltip/Tooltip";

AppWidgetSummary.propTypes = {
  item: PropTypes.object.isRequired,
  onClickRow: PropTypes.func,
};

function AppWidgetSummary({ item, handleOnClick }) {
  const StyledIcon = styled("div")(() => ({
    display: "flex",
    borderRadius: "100%",
    alignItems: "center",
    justifyContent: "center",
  }));
  return (
    <CustomTooltip title={item.tooltip}>
    <Card
      sx={{
        py: { lg: 2, sm: 1, xs: 1 },
        px: { lg: 3, sm: 1, xs: 1.5 },
        boxShadow: 0,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        width: { md: "18%" },
        height: { lg: 116, sm: 60, md: 85.53 },
        borderRadius: 1.5,
      }}
      className="metrics-card"
      onClick={() => {
        handleOnClick(item);
      }}
    >
      <Box
        sx={{
          width: { md: 30, xs: 45 },
        }}
      >
        <StyledIcon
          sx={{
            width: { lg: 60, md: 45, sm: 35, xs: 35 },
          }}
        >
          <img src={item.icon} alt="" />
        </StyledIcon>
      </Box>

      <Box
        sx={{
          textAlign: "start",
          paddingLeft: { lg: 6, sm: 1, md: 3 },
          color: "secondary.light",
          maxWidth: 150,
        }}
      >
        <Typography variant="shippingCardValue" sx={{ opacity: 0.7 }}>
          {item.total}
        </Typography>
        <p style={typography.caption} className="caption">
          {item.title}
        </p>
      </Box>
    </Card>
    </CustomTooltip>
  );
}

ShippingMetricsDataCard.propTypes = {
  title: PropTypes.string,
  cardItems: PropTypes.array,
};

export default function ShippingMetricsDataCard({
  title,
  cardItems,
  handleOnClick,
}) {
  const StyledRoot = styled("div")(() => ({
    maxWidth: "100%",
  }));

  return (
    <StyledRoot>
      <Box
        sx={{
          pl: 2,
          py: { xs: 1, md: 4 },
          height: 50,
          display: "flex",
          alignItems: "center",
          color: "common.black",
          bgcolor: "common.white",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <h4 style={typography.h4}>{title}</h4>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          justifyContent: "space-between",
          my: 3,
          gap: 2,
        }}
      >
        {cardItems.map((item, index) => (
          <AppWidgetSummary
            handleOnClick={handleOnClick}
            item={item}
            key={`${item.title}-${index}`}
          />
        ))}
      </Box>
    </StyledRoot>
  );
}
