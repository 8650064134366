import axios from "axios";
import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendBaseUrl } from "src/utils/backendUrls";

export const retrieveLogoUrl = process.env.REACT_APP_BACKEND_RETRIEVE_LOGO_URL;

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    return decryptData(result);
  }
  return result;
};

export async function getEmailTemplatesList(userInfo) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/email_template/info`,
    null,
    headers,
    token
  );
  return result;
}

export async function putEmailTemplatesList(data, userInfo) {
  const url = backendBaseUrl;
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${url}tenant/${userInfo?.tenant_name}/return/email_template/info`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result.data));
}

export async function getEmailTemplatesConfig(userInfo) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/rules/info/email_template`,
    null,
    headers,
    token
  );
  return result;
}

export async function uploadEmailTemplateLogo(userInfo, data) {
  const url = backendBaseUrl;
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await postData(
    `${url}tenant/s3bucket/generate/presigned_url`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result));
}

export async function uploadToS3(url, file, headers) {
  try {
    const response = await axios.put(url, file, headers);
    return response.data;
  } catch (e) {
    console.error(e, "s3 upload error");
    throw e;
  }
}

export async function updateEmailTemplateConfig(data, userInfo) {
  const url = backendBaseUrl;
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${url}tenant/${userInfo?.tenant_name}/return/rules/info`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result));
}

export async function sendTestEmail(data, userInfo) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    Authorization: `Bearer ${userInfo?.accessToken}`,
  };
  const res = await axios.post(
    `https://g81k5i32za.execute-api.us-east-2.amazonaws.com/v1/notification/api/V1/notification/send_raw_content_mail`,
    data,
    { headers }
  );
  return res.data;
}

export async function postEnableDisableEmailTemplate(data, userInfo) {
  const url = backendBaseUrl;
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await postData(
    `${url}tenant/${userInfo?.tenant_name}/return/email_template/info`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result));
}
