import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

const CustomTextField = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  adornmentText,
  inputProps,
  inputType = "number",
  maxLength=5,
  className
}) => {
  
  const handleChange = (e) => {
    if (inputType === "number" && maxLength && e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }
    onChange(e);
  };

  return (
    <TextField
      label={label}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      className={className}
      error={!!error}
      helperText={helperText}
      variant="outlined"
      fullWidth
      type={inputType}
      sx={{
        "& .MuiInputBase-input": {
          fontSize: 14,
        },
        "& .MuiFormLabel-root": {
          fontSize: 14,
          color: "#AAAAAA",
        },
        "& .MuiOutlinedInput-root": {
          paddingRight: 0,
        },
      }}
      inputProps={{
        ...inputProps,
        fontSize: 12,
        maxLength,
      }}
      InputProps={{
        endAdornment: adornmentText ? (
          <InputAdornment
          sx={{
            "& .MuiTypography-root": {
              fontSize: 14,
              color: "#AAAAAA",
            },
            backgroundColor: (theme) => theme.palette.divider,
            minHeight: "100%",
            padding: "26.5px 14px",
            borderTopRightRadius: (theme) =>
              theme.shape.borderRadius + "px",
            borderBottomRightRadius: (theme) =>
              theme.shape.borderRadius + "px",
          }}
          position="end"
          >
            {adornmentText}
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default CustomTextField;
