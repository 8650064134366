import { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  Card,
  Grid,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Checkbox,
  Button,
  Stack,
  IconButton,
  Collapse,
  Typography,
  FormHelperText,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { titleCase, removeTitleCase, removeItemOnce } from "src/utils/core";
import Search from "../search/Search";
import Iconify from "../iconify/Iconify";
import DateRangePicker from "../date-picker/DateRangePicker";
import MultipleSelect from "../select/MultipleSelect";
import SelectInput from "../select/SelectInput";
import { useLocation } from "react-router-dom";
import typography from "src/theme/typography";
import UsersTableRowDetails from "./UsersTableRowDetails";
import TrackingTableRowDetails from "./TrackingTableRowDetails";
import AdvancedFilter from "./AdvancedFilter";
import AddUserModal from "../modal/AddUserModal";
import palette from "src/theme/palette";
import { Fragment } from "react";
import { isMoment } from "moment";
import ModalComponent from "../modal/ModalComponent";

const tableBgColor = palette.primary.lighter;

// Client-side sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator, isSortable) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (isSortable) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

// Table head
function SmartTabledHead(props) {
  const {
    isUsersPage,
    isBillingPage,
    isMailScans,
    onSelectAllClick,
    order,
    orderBy,
    isRowSelectable,
    isAction,
    visibleRows,
    numSelected,
    selected,
    rowCount,
    onRequestSort,
    columnHeader,
    defaultColumn,
    onRefreshClicked,
    sortOrder,
    isChecked,
  } = props;
  const { pathname } = useLocation();

  //function for refresh all button
  const handleRefreshAll = (e) => {
    if (selected.length > 0) {
      const filteredSelected = selected.filter((trackingNumber) => {
        const selectedItem = visibleRows.find(
          (item) => item.tracking_number === trackingNumber
        );
        return (
          selectedItem &&
          !(
            selectedItem.scanned_status === "Delivered" ||
            selectedItem.scanned_status === "Un Scanned Exit met"
          )
        );
      });
      onRefreshClicked(e, filteredSelected, true);
    } else {
      const filteredRows = visibleRows.filter(
        (item) =>
          !(
            item.scanned_status === "Delivered" ||
            item.scanned_status === "Un Scanned Exit met"
          )
      );
      const filteredTrackingNumbers = filteredRows.map(
        (item) => item.tracking_number
      );
      onRefreshClicked(e, filteredTrackingNumbers, true);
    }
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    sortOrder(property, order);
  };

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    "& .MuiTableRow-head > .MuiTableCell-head": {
      color: theme.palette.common.black,
    },
    "& .MuiTableRow-head > .MuiTableCell-head> .MuiTableSortLabel-root:hover": {
      color: theme.palette.common.black,
    },
  }));

  // styles for the head table cells
  const StyledTableCell = styled(TableCell)(() => ({
    // minWidth: "220px",
    fontFamily: typography.fontFamilySemiBold,
    fontSize: { xs: 14, sm: 14, lg: 16 },
    textAlign: "center",
  }));

  return (
    <StyledTableHead>
      <TableRow>
        {!isUsersPage && !isBillingPage && !isMailScans && (
          <StyledTableCell padding="checkbox" sx={{ minWidth: "auto" }}>
            <Checkbox
              color="primary"
              value={isChecked}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </StyledTableCell>
        )}
        {isRowSelectable ? (
          <StyledTableCell
            key={`${defaultColumn}`}
            padding="none"
            sortDirection={orderBy == { defaultColumn } ? order : false}
          >
            <TableSortLabel
              active={false}
              direction={orderBy == { defaultColumn } ? order : "asc"}
              onClick={createSortHandler(defaultColumn)}
            >
              <p
                style={{
                  display: "inline-block",
                  textWrap: "nowrap",
                }}
              >
                {titleCase(defaultColumn)}
              </p>
            </TableSortLabel>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            key={`${defaultColumn}1`}
            // align="left"
            padding="none"
            sx={{ paddingLeft: 5 }}
            // sx={{ paddingLeft: 5, width: 200 }}
            sortDirection={orderBy == { defaultColumn } ? order : false}
          >
            <TableSortLabel
              active={false}
              direction={orderBy == { defaultColumn } ? order : "asc"}
              onClick={createSortHandler(defaultColumn)}
              // sx={{ width: 160 }}
            >
              <p
                style={{
                  display: "inline-block",
                  textWrap: "nowrap",
                }}
              >
                {titleCase(defaultColumn)}
              </p>
            </TableSortLabel>
          </StyledTableCell>
        )}
        {columnHeader.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            // align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={false}
              // sx={{ width: '200px' }}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <p
                style={{
                  display: "inline-block",
                  textWrap: "nowrap",
                }}
              >
                {headCell.label === "Price" ||
                headCell.label === "Tax" ||
                headCell.label === "Gross Total"
                  ? headCell.label + " ($)"
                  : headCell.label === "No Orders"
                  ? "Order Count"
                  : headCell.label === "Price Per Order"
                  ? "Price Per Order"
                  : headCell.label.replace("Per", "%")}
              </p>
            </TableSortLabel>
          </StyledTableCell>
        ))}
        {isAction && !isUsersPage && !isBillingPage && (
          <StyledTableCell
            key={defaultColumn}
            // align="right"
            padding="normal"
            sx={{
              position: "sticky",
              right: 0,
              fontFamily: typography.fontFamilySemiBold,
              fontSize: { xs: 14, lg: 16 },
              paddingRight: { lg: 3 },
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => handleRefreshAll(e)}
              sx={{
                width: "129px",
                height: "45px",
                // color: 'primary.dark',
                display: "inline-flex",
                alignItems: "center",
                gap: "10px",
                borderRadius: "12px",
                // border: '1px solid primary.main',
              }}
            >
              Refresh All
              <img
                width="18px"
                src="/assets/icons/table/refresh_green.svg"
                alt=""
              />
            </Button>
          </StyledTableCell>
        )}
      </TableRow>
    </StyledTableHead>
  );
}

SmartTabledHead.propTypes = {
  isUsersPage: PropTypes.bool,
  isBillingPage: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnHeader: PropTypes.array.isRequired,
  defaultColumn: PropTypes.string.isRequired,
  isRowSelectable: PropTypes.bool,
  isAction: PropTypes.bool,
  onRefreshClicked: PropTypes.func,
  sortOrder: PropTypes.func,
  trackingPage: PropTypes.bool,
};

// Table Toolbar
function SmartTabledToolbar(props) {
  const {
    placeholder,
    numSelected,
    setIsRefresh,
    selected,
    onhandeSubmit,
    searchKey,
    exportCSV,
    onDateChange,
    columnsDetails,
    selectedColumns,
    handleColumnSubmit,
    isSearch,
    isAdvancedFilter,
    isUsersPage,
    isBillingPage,
    onSearchClick,
    startDate,
    endDate,
    onClickAdvancedFilter,
    showAdvancedFilter,
    isGridSelection,
    dateTypeList,
    onChangeAdvancedForm,
    advancedFormData,
    exportButtonClicked,
    trackingPage,
    userSelectedColumns,
    searchError,
    setSearchError,
  } = props;

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    marginBottom: 30,
    marginTop: 20,
  }));

  function generateFileName() {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const month = String(currentTime.getMonth() + 1).padStart(2, "0"); // Add 1 to the month since it's zero-based
    const day = String(currentTime.getDate()).padStart(2, "0");
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentTime.getSeconds()).padStart(2, "0");

    return `Beyond_carts_${year}-${month}-${day}_${hours}_${minutes}_${seconds}.csv`;
  }

  const filename = generateFileName();

  const [isOpenAddUserModal, setIsOpenAddUserModal] = useState(false);
  const [successfulAddUser, setSuccessfulAddUser] = useState(false);

  const handleCloseModal = () => {
    setIsOpenAddUserModal(false);
    setIsRefresh(true);
  };

  const handleContinueBtn = () => {
    setSuccessfulAddUser(false);
    handleCloseModal();
  };

  return (
    <StyledToolbar>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        rowSpacing={{ lg: 2, md: 2, sm: 1, xs: 1 }}
        justifyContent={{ lg: "space-between" }}
      >
        {isSearch && (
          <Grid item xs="auto">
            <Search
              placeholder={placeholder}
              size="small"
              onSubmitClicked={onhandeSubmit}
              searchValue={searchKey}
              onSearchClick={onSearchClick}
              error={searchError}
              onSearchFocus={() => setSearchError(false)}
            />
            <FormHelperText error={searchError}>
              {searchError && "Tracking number is required."}
            </FormHelperText>
          </Grid>
        )}

        {isAdvancedFilter && (
          <Grid item xs="auto">
            {/* <Grid item xs="auto" sx={{ marginRight: { xl: '120px', lg: '10px' } }}> */}
            <Button
              variant="contained"
              className={showAdvancedFilter ? "btn-active" : ""}
              onClick={onClickAdvancedFilter}
              type="button"
              sx={{
                boxShadow: "none",
                color: "secondary.main",
                border: "1px rgba(128, 128, 128, 0.32) solid",
                height: { xs: 36, sm: 36, md: 36, lg: 40 },
                width: { lg: 190, sm: 160, xs: 160, md: 170 },
                fontFamily: typography.fontFamilyRegular,
                letterSpacing: "0.15px",
                lineHeight: "24px",
                fontSize: { xs: 14, md: 16 },
                backgroundColor: "common.white",
                "&.btn-active, :hover": {
                  color: "common.white",
                  backgroundColor: "secondary.main",
                },
              }}
              startIcon={<Iconify icon="material-symbols:instant-mix" />}
            >
              Advanced Filter
            </Button>
          </Grid>
        )}

        {isUsersPage && (
          <Grid item xs="auto">
            <Button
              variant="contained"
              // disabled={columnsDetails.length === 0}
              sx={{
                boxShadow: "none",
                backgroundColor: "secondary.main",
                width: { lg: 140, sm: 120, xs: 120, md: 140 },
                height: { xs: 36, sm: 36, md: 36, lg: 40 },
                fontSize: { md: 16, xs: 14 },
                letterSpacing: "0.1px",
              }}
              onClick={() => {
                // adds a new user
                setIsOpenAddUserModal(true);
              }}
              endIcon={<Iconify icon="ri:add-fill" />}
            >
              Add User
            </Button>
            {isOpenAddUserModal && (
              <AddUserModal
                openModal={isOpenAddUserModal}
                setOpenModal={setIsOpenAddUserModal}
                handleCloseModal={handleCloseModal}
                setSuccessfulAddUser={setSuccessfulAddUser}
              />
            )}
            {successfulAddUser && (
              <ModalComponent
                openModal={successfulAddUser}
                handleCloseModal={handleContinueBtn}
                title={"User created successfully"}
                description={
                  "An email has been sent to the user with their credentials."
                }
                btnText={"Continue"}
                handleBtnClick={handleContinueBtn}
              />
            )}
          </Grid>
        )}

        {trackingPage && (
          <Grid item xs="auto">
            {/* <Grid item sx={{ width: '200px', marginLeft: { md: '-14px', lg: '100px', xl: '20px' } }}> */}
            <MultipleSelect
              columnsDetails={columnsDetails}
              selectedColumns={userSelectedColumns}
              handleColumnSubmit={handleColumnSubmit}
            />
          </Grid>
        )}

        {isGridSelection && (
          <SelectInput
            sx={{
              marginTop: 0,
              height: { xs: 36, sm: 36, md: 36, lg: 40 },
              "&  .MuiFormControl-root": {
                width: { lg: 256, md: 200, sm: 150 },
              },
              "&  .MuiFormControl-root > .MuiInputBase-root": {
                height: { lg: 40, md: 33, sm: 33 },
              },
            }}
            name="report_type"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
            listItem={dateTypeList}
          />
        )}

        {isUsersPage && (
          <Grid item xs="auto">
            <MultipleSelect
              isUsersPage={isUsersPage}
              columnsDetails={columnsDetails}
              selectedColumns={userSelectedColumns}
              handleColumnSubmit={handleColumnSubmit}
            />
          </Grid>
        )}

        {!trackingPage && !isUsersPage && (
          <Grid item xs="auto">
            <MultipleSelect
              columnsDetails={columnsDetails}
              selectedColumns={userSelectedColumns}
              handleColumnSubmit={handleColumnSubmit}
            />
          </Grid>
        )}

        {isUsersPage || isBillingPage ? null : (
          <Grid item xs="auto">
            <DateRangePicker
              onDateChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
        )}

        <Grid item xs="auto">
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <CSVLink
              style={{
                color: "#FFF",
                textDecoration: "none",
                display: "flex",
              }}
              data={exportCSV}
              filename={filename}
              onClick={() => {
                if (columnsDetails.length === 0) return false;
                else return true;
              }}
            >
              <Button
                variant="contained"
                disabled={columnsDetails.length === 0}
                sx={{
                  boxShadow: "none",
                  backgroundColor: "secondary.main",
                  width: {
                    lg: 130,
                    sm: 110,
                    xs: 110,
                    md: 120,
                  },
                  height: {
                    xs: 36,
                    sm: 36,
                    md: 36,
                    lg: 40,
                  },
                  fontSize: {
                    md: 14,
                    lg: 16,
                    sm: 14,
                    xs: 14,
                  },
                  letterSpacing: "0.1px",
                }}
                startIcon={<Iconify icon="ic:outline-sim-card-download" />}
              >
                Export
              </Button>
            </CSVLink>
            {/* <Button
							variant="contained"
							disabled={columnsDetails.length === 0}
							sx={{
								boxShadow: "none",
								backgroundColor: "secondary.main",
								width: {
									lg: 130,
									sm: 110,
									xs: 110,
									md: 120,
								},
								height: { xs: 36, sm: 36, md: 36, lg: 40 },
								fontSize: {
									md: 14,
									lg: 16,
									sm: 14,
									xs: 14,
								},
								letterSpacing: "0.1px",
							}}
							onClick={() => {
								exportButtonClicked();
							}}
							startIcon={
								<Iconify icon="ic:outline-sim-card-download" />
							}
						>
							Export
						</Button> */}
            {/* ) */}

            {/* : columnsDetails.length !== 0 ? (
							<CSVLink
								style={{
									color: "#FFF",
									textDecoration: "none",
									display: "flex",
								}}
								data={exportCSV}
								filename={filename}
							>
								<Button
									variant="contained"
									sx={{
										boxShadow: "none",
										backgroundColor: "secondary.main",
										width: {
											lg: 130,
											sm: 110,
											xs: 110,
											md: 120,
										},
										height: {
											xs: 36,
											sm: 36,
											md: 36,
											lg: 40,
										},
										fontSize: {
											md: 14,
											lg: 16,
											sm: 14,
											xs: 14,
										},
										letterSpacing: "0.1px",
									}}
									startIcon={
										<Iconify icon="ic:outline-sim-card-download" />
									}
								>
									Export
								</Button>
							</CSVLink>
						) : (
							<Button
								variant="contained"
								disabled={columnsDetails.length === 0}
								sx={{
									boxShadow: "none",
									backgroundColor: "secondary.main",
									width: {
										lg: 130,
										sm: 110,
										xs: 110,
										md: 120,
									},
									height: { xs: 36, sm: 36, md: 36, lg: 40 },
									fontSize: {
										md: 14,
										lg: 16,
										sm: 14,
										xs: 14,
									},
									letterSpacing: "0.1px",
								}}
								startIcon={
									<Iconify icon="ic:outline-sim-card-download" />
								}
							>
								Export
							</Button>
						) */}
            {/* } */}
          </Stack>
        </Grid>
      </Grid>
    </StyledToolbar>
  );
}

SmartTabledToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array,
  onhandeSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  searchKey: PropTypes.string,
  setIsRefresh: PropTypes.func,
  exportCSV: PropTypes.array,
  onDateChange: PropTypes.func,
  columnsDetails: PropTypes.array,
  selectedColumns: PropTypes.array,
  handleColumnChange: PropTypes.func,
  isSearch: PropTypes.bool,
  isAdvancedFilter: PropTypes.bool,
  isUsersPage: PropTypes.bool,
  isBillingPage: PropTypes.bool,
  onSearchClick: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onClickAdvancedFilter: PropTypes.func,
  showAdvancedFilter: PropTypes.bool,
  isGridSelection: PropTypes.bool,
  dateTypeList: PropTypes.array,
  onChangeAdvancedForm: PropTypes.func,
  advancedFormData: PropTypes.object,
  trackingPage: PropTypes.bool,
  userSelectedColumns: PropTypes.array,
  searchError: PropTypes.bool,
  setSearchError: PropTypes.func,
};

// status filter
function StatusFilterMenu({
  active,
  onStatusChange,
  originalRows,
  statusList,
  statusColumn,
  statusAllData,
}) {
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    color: theme.palette.text.light,
    fontWeight: 500,
  }));
  const rawStatusList =
    statusList.length > 0
      ? statusList
      : originalRows.map((el) => el[statusColumn]);
  if (statusAllData !== "") {
    rawStatusList.unshift(statusAllData);
  }
  const finalStatusList = [...new Set(rawStatusList)];
  const buttonList = [];
  finalStatusList.forEach((item) =>
    buttonList.push(
      <StyledButton
        key={item === "Total in Database" ? "Total Shipments" : item}
        name={item === "Total in Database" ? "Total Shipments" : item}
        className={active === item && "active"}
        onClick={onStatusChange}
        sx={{
          "&.active": {
            borderBottom: "3px solid",
            borderColor: "primary.dark",
            color: "primary.dark",
            lineHeight: "24px",
          },
          fontSize: { lg: 16, md: 16, sm: 14 },
          fontFamily: typography.fontFamilyRegular,
        }}
      >
        {item === "Total in Database" ? "Total Shipments" : item}
      </StyledButton>
    )
  );
  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{
        borderBottom: 1,
        borderStyle: "line",
        borderColor: "secondary.gray",
        marginBottom: 2,
      }}
    >
      {buttonList}
    </Stack>
  );
}

StatusFilterMenu.propTypes = {
  active: PropTypes.string,
  onStatusChange: PropTypes.func,
  originalRows: PropTypes.array,
  statusList: PropTypes.array,
  statusColumn: PropTypes.string,
  statusAllData: PropTypes.string,
};

// main table
export default function SmartTable({
  columnsToAvoid,
  placeholexpandedRowarrayder,
  originalRows,
  placeholder,
  isSearch,
  defaultColumn,
  statusColumn,
  isStatusMenu,
  isRowSelectable,
  isAction,
  statusList,
  statusAllData,
  getDateRange,
  onRefreshClicked,
  onExpandhandler,
  setIsRefresh,
  expandedRow,
  isAdvancedFilter,
  isUsersPage,
  isBillingPage,
  totelItemCount,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  onhandeSeachSubmit,
  onBulkOrderSearch,
  searchKey,
  onSearchClick,
  startDate,
  endDate,
  onChangeAdvancedForm,
  advancedFilterUsersSearchData,
  advancedFilterInvoicesSearchData,
  advancedFormData,
  carrierSelect,
  statusSelect,
  scannedStatus,
  invoicesStatus,
  firstScanEmailStatistics,
  onSubmitAdvancedFilter,
  exapndRowIntputData,
  usersList,
  trackingInfoRef,
  showAdvancedFilter,
  onClickAdvancedFilter,
  onStatusChange,
  statusToShow,
  isGridSelection,
  dateTypeList,
  exportButtonClicked,
  trackingPage,
  userSelectedColumns,
  handleColumnSubmit,
  columnsToRemove,
  sortOrder,
  searchError,
  setSearchError,
  isMailScans,
  alertStatus,
}) {
  //----------------------------------------------------------------------------
  const { pathname } = useLocation();

  const [rows, setRows] = useState([]);
  const [columnsToShow, setColumsToShow] = useState([]);
  // const [selectedColumns, setSelectedColumns] = useState(userSelectedColumns);
  const [order, setOrder] = useState("asc");
  const [isSortable, setIsSortable] = useState(false);
  const [orderBy, setOrderBy] = useState(defaultColumn);
  const [selected, setSelected] = useState([]);
  const [reportToExport, setReportToExport] = useState(rows);
  const [rawColumnList, setRawCloumnList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    setRows(originalRows);
    setReportToExport(filteredReportsToExport(originalRows));

    if (userSelectedColumns.length > 0) {
      setColumsToShow(userSelectedColumns);
    } else if (originalRows.length > 0) {
      const filteredCloumns = Object.keys(originalRows[0]).filter(
        (name) => !columnsToRemove?.includes(name)
      );
      setColumsToShow(filteredCloumns);
    } else {
      setColumsToShow([]);
    }
    setRawCloumnList(
      originalRows.length > 0 ? Object.keys(originalRows[0]) : []
    );
    setIsSortable(false);

    return () => abortController.abort();
  }, [originalRows, userSelectedColumns]);

  // Styles
  const StyledTableBody = styled(TableBody)(({ theme }) => ({
    color: theme.palette.common.black,
    backgroundColor: tableBgColor,
    // fontSize: { xs: 12, sm: 14, lg: 16 },
    fontFamily: typography.fontFamilyRegular,
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.common.black,
    // fontSize: { xs: 12, sm: 14, lg: 16 },
    fontFamily: typography.fontFamilyRegular,
  }));

  const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    backgroundColor: tableBgColor,

    color: theme.palette.common.black,
    "& .MuiInputBase-root": {
      border: "1px solid #757575",
      borderRadius: 4,
    },
    "& .MuiPaper-root": {
      color: theme.palette.common.black,
    },
  }));

  // Create columns from rows
  const columns = [];

  columnsToShow.forEach((element) => {
    if (!columnsToAvoid.includes(element)) {
      if (element !== defaultColumn) {
        const obj = {};
        obj.id = element;
        obj.label = titleCase(element);
        columns.push(obj);
      }
    }
  });
  // columns for toolbar
  const toolbarColumns = [];
  rawColumnList.sort().forEach((element) => {
    if (element !== defaultColumn) {
      const obj = {};
      obj.id = element;
      obj.label = titleCase(element);
      toolbarColumns.push(obj);
    }
  });

  const handleRequestSort = (event, property) => {
    setIsSortable(true);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Select single row
  // TODO: DElete this for users
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setIsChecked(!isChecked);
      const newSelected = rows.map((n) => n[defaultColumn]);
      const recordsToExport = [];
      newSelected.forEach((item) => {
        const result = originalRows.filter(
          (obj) => obj[defaultColumn] === item
        );
        recordsToExport.push(result[0]);
      });
      setSelected(newSelected);
      setReportToExport(filteredReportsToExport(recordsToExport));
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    const recordsToExport = [];
    newSelected.forEach((item) => {
      const result = originalRows.filter((obj) => obj[defaultColumn] === item);
      if (result.length !== 0) recordsToExport.push(result[0]);
    });

    setSelected(newSelected);
    setReportToExport(filteredReportsToExport(recordsToExport));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    setIsChecked(false);
    setSelected([]);
  }, [pathname]);

  const filteredReportsToExport = (reportToExportArr) => {
    if (!reportToExportArr || !reportToExportArr.length)
      return reportToExportArr;

    const newReports = reportToExportArr.map((item) => {
      const newItem = {};
      for (const [key, value] of Object.entries(item)) {
        // Capitalize the first letter of each key, keep underscores
        const capitalizedKey = key.replace(/^./, (match) =>
          match.toUpperCase()
        );
        newItem[capitalizedKey] = value;
      }
      return newItem;
    });

    return newReports;
  };

  // useEffect(() => {
  //   if (!reportToExport) return;
  //   if (!reportToExport[0]?.id) return;
  //   const newReports = reportToExport.map(({ id, ...rest }) => ({
  //     ...rest,
  //   }));
  //   setReportToExport(newReports);
  // }, [reportToExport]);

  // Rows to show on table
  const visibleRows = trackingPage
    ? rows
    : useMemo(
        () =>
          stableSort(rows, getComparator(order, orderBy), isSortable).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        [order, orderBy, page, rowsPerPage, rows]
      );
  return (
    <>
      <SmartTabledToolbar
        key="TableToolBar"
        setIsRefresh={setIsRefresh}
        numSelected={selected.length}
        placeholder={placeholder}
        selected={selected}
        originalRows={originalRows}
        onhandeSubmit={onhandeSeachSubmit}
        searchKey={searchKey}
        exportCSV={reportToExport}
        onDateChange={getDateRange}
        columnsDetails={removeItemOnce(rawColumnList, defaultColumn)}
        handleColumnSubmit={handleColumnSubmit}
        isSearch={isSearch}
        isAdvancedFilter={isAdvancedFilter}
        isUsersPage={isUsersPage}
        isBillingPage={isBillingPage}
        onSearchClick={onSearchClick}
        startDate={startDate}
        endDate={endDate}
        onClickAdvancedFilter={onClickAdvancedFilter}
        showAdvancedFilter={showAdvancedFilter}
        isGridSelection={isGridSelection}
        dateTypeList={dateTypeList}
        onChangeAdvancedForm={onChangeAdvancedForm}
        advancedFormData={advancedFormData}
        exportButtonClicked={exportButtonClicked}
        trackingPage={trackingPage}
        userSelectedColumns={userSelectedColumns}
        searchError={searchError}
        setSearchError={setSearchError}
        alertStatus={alertStatus}
      />
      {showAdvancedFilter && (
        <AdvancedFilter
          advancedFilterUsersSearchData={advancedFilterUsersSearchData}
          advancedFilterInvoicesSearchData={advancedFilterInvoicesSearchData}
          onChangeAdvancedForm={onChangeAdvancedForm}
          advancedFormData={advancedFormData}
          carrierSelect={carrierSelect}
          statusSelect={statusSelect}
          scannedStatus={scannedStatus}
          invoicesStatus={invoicesStatus}
          firstScanEmailStatistics={firstScanEmailStatistics}
          onSubmitAdvancedFilter={onSubmitAdvancedFilter}
          alertStatus={alertStatus}
        />
      )}
      {statusColumn && isStatusMenu && (
        <StatusFilterMenu
          key="StatusFilterMenu"
          active={statusToShow}
          onStatusChange={onStatusChange}
          originalRows={originalRows}
          statusList={statusList}
          statusColumn={statusColumn}
          statusAllData={statusAllData}
        />
      )}
      {rows.length > 0 ? (
        <Card
          sx={{
            width: "100%",
            borderRadius: 2,
            zIndex: 10,
          }}
        >
          <Paper sx={{ width: "100%", boxShadow: "none" }}>
            <TableContainer sx={{ width: "auto" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <SmartTabledHead
                  isMailScans={isMailScans}
                  isUsersPage={isUsersPage}
                  isBillingPage={isBillingPage}
                  numSelected={selected.length}
                  selected={selected}
                  order={order}
                  visibleRows={visibleRows}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  columnHeader={columns}
                  defaultColumn={defaultColumn}
                  isRowSelectable={isRowSelectable}
                  isAction={isAction}
                  onRefreshClicked={onRefreshClicked}
                  sortOrder={sortOrder}
                />
                <StyledTableBody className="main">
                  {visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row[defaultColumn]);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Fragment key={row[isUsersPage ? "id" : defaultColumn]}>
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                          // onClick={(event) => onExpandhandler(event, row[defaultColumn], index)}
                        >
                          {isRowSelectable ? (
                            <>
                              {!isUsersPage &&
                                !isMailScans &&
                                !isBillingPage && (
                                  <StyledTableCell
                                    padding="checkbox"
                                    onClick={(event) =>
                                      handleClick(event, row[defaultColumn])
                                    }
                                  >
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </StyledTableCell>
                                )}

                              <StyledTableCell
                                onClick={(event) =>
                                  onExpandhandler(
                                    event,
                                    isUsersPage
                                      ? row["id"]
                                      : row[defaultColumn],
                                    index
                                  )
                                }
                                component="th"
                                id={labelId}
                                scope="row"
                                padding={"checkbox"}
                                sx={{
                                  textAlign: "left",
                                  color: "secondary.main",
                                  fontSize: {
                                    xs: 14,
                                    lg: 16,
                                  },
                                }}
                              >
                                <p
                                  style={{
                                    display: "inline-block",
                                    textWrap: "nowrap",
                                  }}
                                >
                                  {row[defaultColumn]}
                                </p>
                              </StyledTableCell>
                            </>
                          ) : (
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding={!isUsersPage ? "checkbox" : "none"}
                              // align="left"
                              sx={{
                                color: "secondary.main",
                                paddingLeft: 5,
                                fontSize: {
                                  xs: 14,
                                  lg: 16,
                                },
                              }}
                            >
                              <p
                                style={{
                                  display: "inline-block",
                                  textWrap: "nowrap",
                                }}
                              >
                                {row[defaultColumn]}
                              </p>
                            </StyledTableCell>
                          )}

                          {columnsToShow.map((item) =>
                            item !== defaultColumn &&
                            !columnsToAvoid.includes(item) ? (
                              <StyledTableCell
                                onClick={(event) =>
                                  onExpandhandler(
                                    event,
                                    isUsersPage
                                      ? row["id"]
                                      : row[defaultColumn],
                                    index
                                  )
                                }
                                ref={(e) =>
                                  trackingInfoRef
                                    ? (trackingInfoRef.current[index] = e)
                                    : null
                                }
                                key={item}
                                sx={
                                  item === statusColumn
                                    ? {
                                        color: `status.${
                                          row[item] &&
                                          removeTitleCase(row[item])
                                        }`,
                                        fontWeight: 600,
                                        width: 300,
                                        textAlign: "center",
                                        fontSize: {
                                          xs: 14,
                                          lg: 16,
                                        },
                                      }
                                    : {
                                        textAlign: "center",
                                        fontSize: {
                                          xs: 14,
                                          lg: 16,
                                        },
                                      }
                                }
                                // align="left"
                              >
                                <p
                                  style={{
                                    display: "inline-block",
                                    textWrap: "nowrap",
                                  }}
                                >
                                  {isMoment(row[item])
                                    ? row[item].format("MMM DD, YYYY")
                                    : row[item]}
                                  {/* {row[item] + "?" + isMoment(row[item])} */}
                                </p>
                              </StyledTableCell>
                            ) : null
                          )}

                          {isAction && !isBillingPage && (
                            <StyledTableCell
                              hover
                              align="center"
                              sx={{
                                width: "169px",
                                height: "62px",
                                position: "sticky",
                                right: 0,
                                backgroundColor: "#FAFAFA",
                              }}
                            >
                              <IconButton
                                size="small"
                                color="secondary"
                                disabled={
                                  row[statusColumn] == "Delivered" ||
                                  row["scanned_status"] === "Delivered" ||
                                  row["scanned_status"] ===
                                    "Un Scanned Exit met"
                                }
                                sx={{
                                  opacity:
                                    row[statusColumn] == "Delivered" ||
                                    row["scanned_status"] == "Delivered" ||
                                    row["scanned_status"] ===
                                      "Un Scanned Exit met"
                                      ? 0.3
                                      : 1,
                                }}
                                onClick={(event) => {
                                  onRefreshClicked(
                                    event,
                                    row[defaultColumn],
                                    false
                                  );
                                }}
                                aria-label="refresh"
                              >
                                <img
                                  width="18px"
                                  src="/assets/icons/table/refresh.svg"
                                  alt=""
                                />
                              </IconButton>
                            </StyledTableCell>
                          )}
                        </TableRow>

                        {isUsersPage ? (
                          <TableRow key={`${expandedRow}-expand-column`}>
                            <TableCell
                              xs="auto"
                              colSpan={24}
                              sx={{
                                padding: 0,
                                border: "0px",
                              }}
                            >
                              <Collapse
                                in={
                                  expandedRow ===
                                  row[isUsersPage ? "id" : defaultColumn]
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                <UsersTableRowDetails
                                  usersList={usersList}
                                  inputData={exapndRowIntputData}
                                  onExpandhandler={onExpandhandler}
                                  setIsRefresh={setIsRefresh}
                                />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow key={`${expandedRow}-expand-column`}>
                            <TableCell
                              xs="auto"
                              colSpan={24}
                              sx={{
                                padding: 0,
                                border: "0px",
                              }}
                            >
                              <Collapse
                                in={
                                  expandedRow ===
                                  row[isUsersPage ? "id" : defaultColumn]
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                <TrackingTableRowDetails
                                  inputData={exapndRowIntputData}
                                  onExpandhandler={onExpandhandler}
                                  // setIsRefresh={
                                  // 	setIsRefresh
                                  // }
                                />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })}
                </StyledTableBody>
              </Table>
            </TableContainer>
            <StyledTablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={totelItemCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Card>
      ) : (
        <Card
          sx={{
            width: "100%",
            boxShadow: "none",
            minHeight: 500,
            display: "flex",
            borderRadius: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography variant="h5" sx={{ color: "primary.main" }}>
              No data found
            </Typography>
          </Stack>
        </Card>
      )}
    </>
  );
}

SmartTable.propTypes = {
  columnsToAvoid: PropTypes.array,
  placeholder: PropTypes.string,
  originalRows: PropTypes.array,
  isSearch: PropTypes.bool,
  defaultColumn: PropTypes.string,
  statusColumn: PropTypes.string,
  statusList: PropTypes.array,
  statusAllData: PropTypes.string,
  getDateRange: PropTypes.func,
  onRefreshClicked: PropTypes.func,
  onExpandhandler: PropTypes.func,
  setIsRefresh: PropTypes.func,
  expandedRow: PropTypes.string,
  isAdvancedFilter: PropTypes.bool,
  isUsersPage: PropTypes.bool,
  isBillingPage: PropTypes.bool,
  totelItemCount: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onhandeSeachSubmit: PropTypes.func,
  searchKey: PropTypes.string,
  onSearchClick: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChangeAdvancedForm: PropTypes.func,
  advancedFormData: PropTypes.object,
  carrierSelect: PropTypes.array,
  statusSelect: PropTypes.array,
  scannedStatus: PropTypes.array,
  invoicesStatus: PropTypes.array,
  firstScanEmailStatistics: PropTypes.array,
  onSubmitAdvancedFilter: PropTypes.func,
  exapndRowIntputData: PropTypes.object,
  usersList: PropTypes.array,
  trackingInfoRef: PropTypes.object,
  isStatusMenu: PropTypes.bool,
  isRowSelectable: PropTypes.bool,
  isAction: PropTypes.bool,
  trackingPageshowAdvancedFilter: PropTypes.bool,
  advancedFilterUsersSearchData: PropTypes.object,
  advancedFilterInvoicesSearchData: PropTypes.object,
  onClickAdvancedFilter: PropTypes.func,
  onStatusChange: PropTypes.func,
  statusToShow: PropTypes.string,
  isGridSelection: PropTypes.bool,
  dateTypeList: PropTypes.array,
  exportButtonClicked: PropTypes.func,
  trackingPage: PropTypes.bool,
  userSelectedColumns: PropTypes.array,
  handleColumnSubmit: PropTypes.func,
  columnsToRemove: PropTypes.array,
  sortOrder: PropTypes.func,
  searchError: PropTypes.bool,
  setSearchError: PropTypes.func,
};

SmartTable.defaultProps = {
  placeholder: "Enter tracking number",
  columnsToAvoid: [],
  searchError: false,
  trackingPage: false,
  exapndRowIntputData: {},
  usersList: [],
  sortOrder: () => {},
  setSearchError: () => {},
  onExpandhandler: () => {},
};
