import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import typography from "src/theme/typography";
import { LoadingButton } from "@mui/lab";
import palette from "src/theme/palette";

// ----------------------------------------------------------------------
const StyledContent = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function Page404() {
  return (
    <>
      <Helmet>
        <title> 404 Page | BeyondCarts </title>
      </Helmet>

      <img
        src="/assets/beyond-carts-logo.svg"
        alt="logo"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "48px",
          display: "block",
          margin: "30px 0 0 30px",
        }}
      />

      <StyledContent sx={{ gap: 2, textAlign: "center", alignItems: "center", backgroundColor: '#fff' }}>
        <h1 style={typography.h1}>Sorry, page not found!</h1>

        <Typography sx={{ color: "text.secondary", fontSize: 18, maxWidth: 550 }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
          mistyped the URL? Be sure to check your spelling.
        </Typography>

        <Box
          component="img"
          src="/assets/illustrations/illustration_404.svg"
          sx={{ height: 260, mx: "auto", my: 2 }}
        />

        <LoadingButton
          to="/"
          component={RouterLink}
          size="large"
          type="submit"
          variant="contained"
          sx={{
            fontSize: 17,
            marginTop: 1.8,
            width: { xs: 180, md: 220 },
            boxShadow: "none",
            backgroundColor: palette.secondary.main,
            color: "common.white",
          }}
        >
          Back Home
        </LoadingButton>
      </StyledContent>
    </>
  );
}
