export const LABELS = {
  NO_SELECTED: "",
  TWO_WAY: "2_way_label",
  PREPAID_LABEL: "prepaid_free_label",
  CUSTOMER_MANAGED: "customer_managed_returns",
};

export const LABEL_PROCESS_TYPE = {
  TWO_WAY_LABEL: '2_way_label',
  PREPAID_FREE_LABEL: 'free_label',
  PREPAID_PAID_LABEL: 'paid_label',
  OWN_LABEL: 'own_label',
  prepaid_free_label_2:"prepaid_free_label"
}