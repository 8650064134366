import { Box, Pagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationsItemList from "./NotificationsItemList";
import { Helmet } from "react-helmet-async";
import { getNotifications } from "src/features/tracking/trackingApi";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import CircularLoader from "src/loader/circular/CircularLoader";

const NotificationsPage = () => {
  const userInfo = useAppSelector(getUserInfo);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [notificationsData, setNotificationsData] = useState([
    {
      message: "",
      timeAgo: "",
      status: "",
    },
  ]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const calculatePageCount = (total) => {
    return Math.ceil(total / 10);
  };

  const getNotificationsData = async () => {
    setIsLoading(true);
    try {
      const response = await getNotifications(
        {
          page_size: 10,
          current_page: page,
        },
        userInfo
      );
      if (response.statusCode === 200) {
        setTotalCount(response?.data?.items.length);
        setNotificationsData(response?.data?.items);
        if (page === 1)
          setPageCount(calculatePageCount(response?.data?.total_count));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getNotificationsData();
  }, [page]);

  return (
    <>
      <Helmet>
        <title> Notifications | BeyondCarts </title>
      </Helmet>

      {isLoading && <CircularLoader />}
      {!totalCount ? (
        <Typography
          variant="h5"
          sx={{
            color: "primary.main",
            height: "calc(100vh - 130px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No data found
        </Typography>
      ) : (
        <Box
          sx={{
            paddingTop: 1,
          }}
        >
          {notificationsData.map((el) => (
            <NotificationsItemList
              key={el?.timeAgo}
              message={el?.message}
              timeAgo={el?.timestamp}
              status={el?.status}
            />
          ))}
          <Box sx={{ paddingY: "20px", float: "right" }}>
            <Pagination
              color="primary"
              count={pageCount}
              page={page}
              onChange={handleChange}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default NotificationsPage;
