import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#ffffff80",
  400: "#C4CDD5",
  500: "#808080",
  600: "#727272",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const PRIMARY = {
  lighter: "#FAFAFA",
  light: "#479DA6",
  main: "#256FA5",
  dark: "#005072", // hover color for btns, for input fileds, selects
  darker: "#03416F",
  contrastText: "#fff",
  lightbg: "#DADADA",
  lightIcon: "#838383",
};

const SECONDARY = {
  lighter: alpha("#B7B7B7", 0.2),
  light: "#143F67",
  main: "#002F43", // bg color for btns
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
  gray: "#D6D6D6",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
  button: "#D3D3D3",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  red: "#FF0000",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#ffe3e3",
  light: "#FFA48D",
  main: "#FF1616",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const STATUS = {
  alert: "#BE4141",
  in_transit: "#BC8524",
  delivered: "#02714B",
  "pre-shipment": "#D20097",
  out_for_delivery: "#20B9CB",
  returned: "#F1502D",
  delivery_attempt: "#00A3FF",
  available_for_pickup: "#85CA53",
  available_for_agent_pickup: "#1B83B8",
  delivered_to_agent: "#621946",
  unknown: "#584A55",
  forwarded: "#8D47C4",
};

const COMMON = {
  black: "#000",
  white: "#fff",
};

const palette = {
  common: COMMON,
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  contrastText: PRIMARY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: "#000",
    secondary: GREY[500],
    alternative: GREY[300],
    disabled: GREY[500],
    alternative2: GREY[400],
    light: "#878484",
    active: "#fff",
  },
  background: {
    paper: "#fff",
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[0], 0.05),
    selected: alpha(GREY[0], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  status: STATUS,
};

export default palette;
