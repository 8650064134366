import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ResetPassword from "src/components/reset-password/ResetPassword";
import { useAppDispatch } from "src/hooks/hooks";
import { login } from "src/features/user/userState";
import CircularLoader from "src/loader/circular/CircularLoader";
import { storeCredentialsInCookies } from "src/utils/cookiesTracker";
import { useForm } from "react-hook-form";
import { getToken } from "src/utils/extractUserToken";
import appUrls from "src/utils/appUrls";

const ResetPasswordDone = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedToken, setFetchedToken] = useState(null);
  const {
    state: { obj },
  } = useLocation();
  const credentials = {
    email: obj?.email,
    password: obj?.new_password,
  };

  const onSubmit = async () => {
    setIsLoading(true);
    dispatch(login(credentials))
      .then((action) => {
        const { email, password } = action.meta.arg;
        storeCredentialsInCookies(email, password);
        setFetchedToken(getToken());
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (fetchedToken) {
      navigate(appUrls.outboundManagement.dashboard.absoluteUrl);
    }
  }, [fetchedToken]);

  return (
    <>
      <Helmet>
        <title> Reset Password | BeyondCarts </title>
      </Helmet>

      {isLoading && <CircularLoader />}

      <ResetPassword
        title={"Your password has been changed!"}
        style={{
          title: { color: "$primary-color", textAlign: "center" },
        }}
        btnText={"Continue"}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ResetPasswordDone;
