import { Typography, Grid, Box, OutlinedInput } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { handlePhoneChange } from 'src/utils/formatPhone';
import CustomPolicyIcon from "./CustomPolicyIcon";
import { useDispatch } from "react-redux";
import { returnPolicyUpload } from "src/features/brandedPortal/brandedPortal";

const BrandPolicy = ({data, validateUrl, errors, setErrors}) => {
    const [phoneNumber, setPhoneNumber] = useState(data?.phone_no);
    const [email, setEmail] = useState(data?.email_id);
    const [text, setText] = useState(data?.link_text);
    const [linkurl, setLinkUrl] = useState(data?.link_url);
    const [selectedOption, setSelectedOption] = useState(data?.is_custom);
    const [instruction, setInstruction] = useState(data?.custom_content);

    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["code-block"],
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    ];

    const dispatch = useDispatch();
    const policyData = {
        phone_no: phoneNumber,
        email_id: email,
        link_text: text,
        link_url: linkurl,
        is_custom: selectedOption,
        custom_content: instruction,
    }
    useEffect(() => {
        dispatch(
            returnPolicyUpload(policyData)
        );
    }, [phoneNumber, email, text, linkurl, selectedOption, instruction])

    const module = {
        toolbar: toolbarOptions,
    };

    const handleSelection = (option) => {
        setSelectedOption(option);
    };

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmail(value);
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value === '') {
            setErrors(prev => ({ ...prev, email: null }));
          } else if (emailPattern.test(value)) {
            setErrors(prev => ({ ...prev, email: null }));
          } else {
            setErrors(prev => ({ ...prev, email: 'Invalid email format' }));
          }
      };

      const handleURLChange = (e, name, state) => {
        const { value } = e.target;
        state(value);
        validateUrl(name, value);
      };
      const handleNumberError = (number) => {
        const contact = number.split(" ").slice(1);
        const combinedLength = contact.join("").length;
      
        if (combinedLength === 0) {
          setErrors(prev => ({ ...prev, phoneNumber: null }));
        } else if (combinedLength < 10) {
          setErrors(prev => ({ ...prev, phoneNumber: 'Phone number must be at least 10 digits' }));
        } else {
          setErrors(prev => ({ ...prev, phoneNumber: null }));
        }
      };
    return (
        <>
            <Typography sx={{ fontSize: '18px', mb: 2 }}>
                Set up your return policy by providing a web link or defining a custom policy for displaying in your returns portal.
            </Typography>
            <Grid container spacing={3} sx={{ borderBottom: '2px solid #E9E9E9', pb:4 }}>
                <Grid item xs={3}>
                    <Typography sx={{ fontSize: '18px', mb: 1 }}>Return policy</Typography>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>
                        Enter the URL and link text for your return policy portal to redirect customers
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                        <Box
                            sx={{
                                width: '170px',
                                height: '100px',
                                borderRadius: '12px',
                                backgroundColor: !selectedOption ? '#002F43' : 'white',
                                border: '1px solid',
                                borderColor: !selectedOption ? 'transparent' : '#AAAAAA',
                                cursor: 'pointer',
                                position:' relative',
                            }}
                            onClick={() => handleSelection(false)}
                        >
                            {!selectedOption ? <img style={{position: 'absolute', top: '8px', right: '8px'}} src='/assets/circleselect.svg' /> : '' }
                            <Box sx={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <LaunchIcon sx={{ color: !selectedOption ? 'white' : '#002F43' }} />
                                <Typography sx={{ color: !selectedOption ? 'white' : '#002F43', fontSize: '14px', mt: 1 }}>
                                    Webpage redirect
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '170px',
                                height: '100px',
                                borderRadius: '12px',
                                backgroundColor: selectedOption ? '#002F43' : 'white',
                                border: '1px solid',
                                borderColor: selectedOption ? 'transparent' : '#AAAAAA',
                                cursor: 'pointer',
                                position: 'relative'
                            }}
                            onClick={() => handleSelection(true)}
                        >
                            {selectedOption ? <img style={{position: 'absolute', top: '8px', right: '8px'}} src='/assets/circleselect.svg' /> : '' }
                            <Box sx={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <CustomPolicyIcon color={selectedOption ? 'white' : '#002F43'} />
                                <Typography sx={{ color: selectedOption ? 'white' : '#002F43', fontSize: '14px', mt: 1 }}>
                                    Custom return policy
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Typography sx={{ mt: 3, mb: 1 }}>Link text</Typography>
                    <OutlinedInput
                        size="small"
                        placeholder="Return policy"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        id="outlined-adornment-weight"
                        sx={{ color: "#000", width: '360px' }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{ "aria-label": "weight" }}
                    />
                    {!selectedOption && (
                        <Box sx={{position: 'relative', mb: 8}}>
                            <Typography sx={{ mt: 2, mb: 1 }}>Link URL</Typography>
                            <OutlinedInput
                                size="small"
                                value={linkurl}
                                placeholder="yoursite.com/policy"
                                id="outlined-adornment-weight"
                                onChange={(e) => handleURLChange(e, 'linkurl', setLinkUrl)}
                                sx={{ color: "#000", width: '360px' }}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{ "aria-label": "weight" }}
                                error={errors?.linkurl}
                            />
                            {errors?.linkurl && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom: '-25px' }}>{errors?.linkurl}</Typography>}
                        </Box>
                    )}
                    {selectedOption && (<>
                        <ReactQuill
                            modules={module}
                            theme="snow"
                            style={{ marginTop: '20px' }}
                            value={instruction}
                            onChange={setInstruction}
                        />
                        <style>
                            {`
                            .ql-container {
                                height: calc(100% - 42px);
                                min-height: 158px;
                                max-height: 158px;
                            }
                            `}
                        </style>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ py: 3 }}>
                <Grid item xs={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}>Customer support</Typography>
                    <Typography sx={{ color: '#AAAAAA', fontSize: '14px' }}>
                        Provide your customer support email and phone number for seamless assistance.
                    </Typography>
                </Grid>
                <Grid item xs={9} sx={{ margin: 'auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                        <Box sx={{ position: "relative" }}>
                            <Typography sx={{ mb: 1 }}>Email</Typography>
                            <OutlinedInput
                                size='small'
                                sx={{ color: "#000", width: '300px' }}
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="customersupport@merchant.com"
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{ "aria-label": "weight" }}
                                error={errors?.email}
                            />
                            {errors?.email && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-25px' }}>{errors?.email}</Typography>}
                        </Box>
                        <Box sx={{position: 'relative'}}>
                            <Typography sx={{ mb: 1 }}>Phone</Typography>
                            <MuiTelInput
                                size="small"
                                defaultCountry="US"
                                forceCallingCode={true}
                                value={phoneNumber}
                                placeholder="(xxx) xxx-xxxx"
                                onChange={(newValue) => [handlePhoneChange(newValue, setPhoneNumber), handleNumberError(newValue)]}
                            />
                            {errors?.phoneNumber && <Typography sx={{ color: 'red', fontSize: '12px', position: 'absolute', bottom:'-25px' }}>{errors?.phoneNumber}</Typography>}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default BrandPolicy