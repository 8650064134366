import { useEffect, useState,useRef } from "react";
import { getBoxConfigDetails, generateLabelApi } from "./ReturnsApi";
import { LABEL_PROCESS_TYPE } from "src/pages/ReturnConfiguration/ReturnMethod/Labels";
import {
  Button,
  Box,
  Dialog,
  IconButton,
  Typography,
  Grid,
  Stack,
  Divider,
} from "@mui/material";
import CustomTextField from "./common/CustomTextField";
import CustomSelect from "./common/CustomSelect";
import "./style/styles.css";
import { LoadingButton } from "@mui/lab";
import palette from "src/theme/palette";
import CloseIcon from "@mui/icons-material/Close";
import CircularLoader from "src/loader/circular/CircularLoader";
import typography from "src/theme/typography";
import { selectSelectedMethod } from "src/features/returns/returnMethodSlice";
import { selectLabelSizeOption } from "src/features/returns/stepperFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "src/features/user/userState";
import useCustomerOrderInfo from "src/features/return_management/hooks/useCustomerOrderInfo";
import { generateLabel } from "../../features/brandedPortal/brandedPortalApi";

const BoxConfigDialog = ({
  returnDetails,
  userInfo,
  isAcceptAction = false,
  handleLabelGenerateSubmit,
  onLabelGenerated,
  setIsAcceptAction,
  returnRuleData,
  customerLineItem,
  labelButton,
  customerOrderInfo,
  ShipmentData,
  labelInfo = false,
}) => {
  const [boxConfigData, setBoxConfigData] = useState(null || []);
  const [productItems, setProductItems] = useState([]);
  const [cxproductItems, setCXProductItems] = useState(customerLineItem || []);
  const [mailClass, setMailClass] = useState(" ");
  const [mailConfigData, setmailConfigData] = useState(null || []);
  const [wholeconfigData, setWholeconfigData] = useState([]);
  const [isMainDialogOpen, setMainDialogOpen] = useState(true);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [isCustomBoxDialogOpen, setCustomBoxDialogOpen] = useState(false);
  const [boxConfig, setBoxConfig] = useState("");
  const [boxObject, setBoxObject] = useState(null || {});
  const [isLoading, setIsLoading] = useState(false);
  const [labelMake, setLabelMake] = useState(" ");
  const [labelImage, setLabelImage] = useState(null);
  const [isLabelDialogOpen, setIsLabelDialogOpen] = useState(false);
  const [customBoxAdded, setCustomBoxAdded] = useState(false);
  const selectedMethod = useSelector(selectSelectedMethod);
  const labelSelectedMethod = useSelector(selectLabelSizeOption);
  const [errors, setErrors] = useState({});
  const [isGenLabelConfirmation, setIsGenLabelConfirmation] = useState(false);
  const prevBoxConfigRef = useRef(boxConfig);

  const [successfullLabelGenModal, setSuccessfullLabelGenModal] =
    useState(false);
  const [unsuccessfullLabelGenModal, setUnsuccessfullLabelGenModal] =
    useState(false);
  const [labelGenStatusText, setLabelGenStatusText] = useState("");

  const [boxData, setBoxData] = useState({
    length: "",
    width: "",
    height: "",
    box_weight: "",
  });

  const labelMakeOptions = [
    { label: "Free Label", value: "PREPAID_FREE_LABEL" },
    { label: "Paid Label", value: "PREPAID_PAID_LABEL" },
  ];
  useEffect(() => {
    if (isAcceptAction) {
      handleOpenMainDialog();
    }
  }, [isAcceptAction]);
  const handleChange = (field, value) => {
    setBoxData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleGenerateLabel = async () => {
    try {
      setIsLoading(true);
      const mailClassFormat = mailClass?.toUpperCase().replace(/\s+/g, "_");
      const setLabelMakeFormat = LABEL_PROCESS_TYPE[labelMake];
      const packageLength = Number(boxObject?.length || 0);
      const packageWidth = Number(boxObject?.width || 0);
      const packageHeight = Number(boxObject?.height || 0);
      const packageWeight = Number(boxObject?.box_weight || 0);
      const payload = {
        mailClass: mailClassFormat,
        weight: packageWeight,
        length: packageLength,
        width: packageWidth,
        height: packageHeight,
        label_size: 1,
        order_number: returnDetails?.order_number,
        tenant_id: userInfo?.tenant_id,
        label_type: setLabelMakeFormat,

        shipping_address: {
          firstName: returnDetails?.shipping_address?.first_name,
          lastName: returnDetails?.shipping_address?.last_name,
          streetAddress: returnDetails?.shipping_address?.address1,
          city: returnDetails?.shipping_address?.city,
          state: returnDetails?.shipping_address?.province,
          ZIPCode: returnDetails?.shipping_address?.zip,
        },
      };

      let response = await generateLabel(payload, userInfo, {
        return_id: returnDetails?._id || "",
      });

      if (response.error) {
        setUnsuccessfullLabelGenModal(true);
        setLabelGenStatusText(
          "There was an error generating the label. Please try again."
        );
        setIsGenLabelConfirmation(false);
        setPreviewDialogOpen(false);

        // TODO: LOGIC
      } else if (response?.statusCode == 200) {
        if (response?.data?.label) {
          setLabelImage(response?.data?.label);
        }
        setSuccessfullLabelGenModal(true);
        setPreviewDialogOpen(false);
        setMainDialogOpen(false);
        const sendtoparent = {
          label_type: setLabelMakeFormat,
          label_path: response?.data?.label,
          tracking_number: response?.data?.trackingNumber,
          label_fees: response?.data?.postage,
        };
        setIsGenLabelConfirmation(false);
        setPreviewDialogOpen(false);
        await onLabelGenerated(sendtoparent);

        //await handleLabelGenerateSubmit({isLabelGenerate:true});
      } else {
        //  alert('Test')
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error generating label:", error);
      setIsLoading(false);
      setPreviewDialogOpen(false);
      setIsGenLabelConfirmation(false);
    }
  };
  const handleCloseLabelDialog = () => {
    setIsLabelDialogOpen(false);
  };

  const validateBoxData = (data, fieldToValidate = null) => {
    let validationErrors = {};
    const isValidDecimal = (value) => /^\d+(\.\d{1,2})?$/.test(value);

    const isValidWeight = (value) => isValidDecimal(value) && value <= 70;

    const fieldsToValidate = fieldToValidate
      ? [fieldToValidate]
      : ["length", "width", "height", "box_weight"];

    fieldsToValidate.forEach((field) => {
      switch (field) {
        case "length":
          if (!data.length) {
            validationErrors.length = "Length is required";
          } else if (!isValidDecimal(data.length)) {
            validationErrors.length =
              "Invalid length, should be a number with up to 2 decimal places";
          }
          break;
        case "width":
          if (!data.width) {
            validationErrors.width = "Width is required";
          } else if (!isValidDecimal(data.width)) {
            validationErrors.width =
              "Invalid width, should be a number with up to 2 decimal places";
          }
          break;
        case "height":
          if (!data.height) {
            validationErrors.height = "Height is required";
          } else if (!isValidDecimal(data.height)) {
            validationErrors.height =
              "Invalid height, should be a number with up to 2 decimal places";
          }
          break;
        case "box_weight":
          if (!data.box_weight) {
            validationErrors.box_weight = "Box weight is required";
          } else if (!isValidWeight(data.box_weight)) {
            validationErrors.box_weight =
              "Invalid box weight, should be a number below 70 lbs";
          }
          break;
        default:
          break;
      }
    });

    return validationErrors;
  };
  const renderBoxText = (boxConfig) => {
    const hasDimensions = boxConfig.includes("(L)") && 
                          boxConfig.includes("(W)") && 
                          boxConfig.includes("(H)") && 
                          boxConfig.includes("(Wt)");
  
    return hasDimensions ? "Custom Box" : boxConfig;
  };
  const handleBlur = (field) => {
    const validationErrors = validateBoxData(boxData, field);
    setErrors((prevErrors) => {
      if (validationErrors[field]) {
        return { ...prevErrors, [field]: validationErrors[field] };
      } else {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[field];
        return updatedErrors;
      }
    });
  };

  const handleAddCustomBox = () => {
    const validationErrors = validateBoxData(boxData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    const newBox = {
      // config_name: 'Custom Box',
      config_name: `${Number(boxData?.length || 0)}"(L) x ${Number(
        boxData?.width || 0
      )}"(W) x ${Number(boxData?.height || 0)}"(H) x ${Number(
        boxData?.box_weight || 0
      )} lbs(Wt)`,
      length: boxData.length,
      width: boxData.width,
      height: boxData.height,
      box_weight: boxData.box_weight,
    };
    setBoxConfigData((prevConfigData) => [
      ...prevConfigData,
      {
        ...newBox,
        value: newBox?.config_name,
        label: newBox?.config_name,
        tooltip: newBox?.config_name,
      },
    ]);
    setBoxConfig(newBox.config_name);
    setBoxObject(newBox);
    setCustomBoxAdded(true);
    handleCloseCustomBoxDialog();
  };
  const handleEditCustomBox = () => {
    const validationErrors = validateBoxData(boxData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    if (customBoxAdded) {
      const lastIndex = boxConfigData.length - 1;
      const updatedBox = {
        config_name: `${Number(boxData.length || 0)}"(L) x ${Number(
          boxData.width || 0
        )}"(W) x ${Number(boxData.height || 0)}"(H) x ${Number(
          boxData.box_weight || 0
        )} lbs(Wt)`,
        length: boxData.length,
        width: boxData.width,
        height: boxData.height,
        box_weight: boxData.box_weight,
      };

      setBoxConfigData((prevConfigData) => {
        const updatedData = [...prevConfigData];
        updatedData[lastIndex] = {
          ...updatedBox,
          value: updatedBox.config_name,
          label: updatedBox.config_name,
          tooltip: updatedBox?.config_name,
        };
        return updatedData;
      });

      setBoxConfig(updatedBox.config_name);
      setBoxObject(updatedBox);
      setErrors({});
      handleCloseCustomBoxDialog();
    }
  };

  const EditCustomBoxData = () => {
    if (customBoxAdded) {
      const lastBox = boxConfigData[boxConfigData.length - 1];
      setBoxData({
        length: lastBox.length,
        width: lastBox.width,
        height: lastBox.height,
        box_weight: lastBox.box_weight,
      });
    }
    setCustomBoxDialogOpen(true);
  };
  const fetchBoxConfigDetails = async () => {
    setIsLoading(true);
    try {
      const data = await getBoxConfigDetails(
        returnDetails?.order_number,
        userInfo
      );
      const wholeData = data?.data;
      setWholeconfigData(data?.data);
      wholeData && setProductItems(wholeData?.line_items);
      // !wholeData && setProductItems(customerLineItem);
      const boxConfigOptions =
        wholeData?.box_config?.map((box) => ({
          tooltip: `${Number(box?.length || 0)}"(L) x ${Number(
            box?.width || 0
          )}"(W) x ${Number(box?.height || 0)}"(H) x ${Number(
            box?.box_weight || 0
          )} lbs(Wt)`,
          value: box?.config_name,
          label: box?.config_name,
          ...box,
        })) || [];
      setBoxConfigData(boxConfigOptions);
      // await setmailConfigData(data?.data?.mail_config.map(mail => ({
      //     value: mail,
      //     mail
      // })))
      const defaultBox =
        boxConfigOptions.find(
          (box) => box.config_name === "Small Flat Rate Box"
        ) || boxConfigOptions[0];

      setBoxObject(defaultBox);
      setBoxConfig(defaultBox?.config_name || "Small flat rate box");
      // prevBoxConfigRef.current = defaultBox?.config_name ; 
      // setBoxData({
      //     length: defaultBox?.length || '',
      //     width: defaultBox?.width || '',
      //     height: defaultBox?.height || '',
      //     box_weight: defaultBox?.box_weight || '',
      // });

      const mailConfigOptions =
        wholeData?.mail_config?.map((mail) => ({
          value: mail,
          label: mail
            .toLowerCase()
            .replace(/[_-]/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        })) || [];

      setmailConfigData(mailConfigOptions);
      if (mailConfigOptions.length > 0) {
        setMailClass(mailConfigOptions[0].value);
      }
      setLabelMake(labelMakeOptions[0]?.value);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching box config details:", error);
    }
  };
  const handleOpenMainDialog = async () => {
    await fetchBoxConfigDetails();
    setMainDialogOpen(true);
    setCustomBoxAdded(false);
  };

  const handleCloseMainDialog = () => {
    setMainDialogOpen(false);
    setIsAcceptAction(false);
  };
  const handlepreviewDialog = () => setPreviewDialogOpen(true);

  const handleOpenCustomBoxDialog = () => setCustomBoxDialogOpen(true);
  const handleCloseCustomBoxDialog = () => {
    setCustomBoxDialogOpen(false);
    setBoxData({ length: "", width: "", height: "", box_weight: "" });
    setErrors({});
  };
  const isBoxDataEmpty = (boxData) => {
    return Object.values(boxData).every(value => value === "");
  };
  const handleBoxConfigChange = (event) => {
    const isEmpty = isBoxDataEmpty(boxConfig);
    prevBoxConfigRef.current = boxConfig ;
    const res=(event.target.value !== undefined && !isEmpty) ?event.target.value:prevBoxConfigRef.current
    setBoxConfig(res);

    const selectedBoxObject = boxConfigData?.find(
      (box) => box?.config_name === res
    );
    setBoxObject(selectedBoxObject);
  };
  const handleMailClassChange = (event) => setMailClass(event.target.value);
  const handleLabelMakeChange = (event) => setLabelMake(event.target.value);
  const isDecimal = (value) => {
    return value.includes('.') && !isNaN(value);
  };
  return (
    <>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Dialog
          open={isMainDialogOpen}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              return;
            }
            handleCloseMainDialog();
          }}
          PaperProps={{
            sx: {
              width: "700px",
              borderRadius: "12px 12px 12px 12px",
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "6px",
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "#1C272A",
                borderRadius: "10px",
              },
              "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#333",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0",
              },
              "::-webkit-scrollbar-button": {
                display: "none",
              },
            },
          }}
        >
          <Box padding={4} paddingTop={6} position={"relative"}>
            <IconButton
              onClick={handleCloseMainDialog}
              aria-label="close"
              sx={{
                position: "absolute",
                top: { xs: 5, md: 10 },
                right: { xs: 5, md: 10 },
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>

            <Stack direction="row" gap={2} width={"100%"}>
              <Stack flex={1} gap={1}>
                <Typography>Box Config</Typography>
                <CustomSelect
                  options={boxConfigData}
                  value={boxConfig}
                  onChange={handleBoxConfigChange}
                  onAddCustomBox={handleOpenCustomBoxDialog}
                  onEditCustomBox={EditCustomBoxData}
                  customBoxAdded={customBoxAdded}
                  showTooltip={true}
                  showAddCustomBox={true}
                  width="100%"
                  height="42px"
                />
              </Stack>

              <Stack flex={1} gap={1}>
                <Typography>Mail Class</Typography>
                <CustomSelect
                  options={mailConfigData}
                  value={mailClass}
                  onChange={handleMailClassChange}
                  showTooltip={false}
                  showAddCustomBox={false}
                  width="100%"
                  height="42px"
                />
              </Stack>

              <Stack flex={1} gap={1}>
                <Typography>Make the Label</Typography>
                <CustomSelect
                  options={labelMakeOptions}
                  value={labelMake}
                  onChange={handleLabelMakeChange}
                  showTooltip={false}
                  showAddCustomBox={false}
                  width="100%"
                  height="42px"
                />
              </Stack>

              <Dialog
                open={isCustomBoxDialogOpen}
                // onClose={handleCloseCustomBoxDialog}
                //  fullWidth maxWidth="xs"
                onClose={(event, reason) => {
                  if (reason === "backdropClick") {
                    return;
                  }
                }}
              >
                <Box
                  p={2}
                  PaperProps={{
                    sx: {
                      width: "120px",
                      height: "42px",
                    },
                  }}
                >
                  <IconButton
                    onClick={handleCloseCustomBoxDialog}
                    style={{ float: "right" }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" p={2} textAlign="center">
                    Custom box config
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <CustomTextField
                        label="Length"
                        value={boxData.length}
                        onChange={(e) => handleChange("length", e.target.value)}
                        onBlur={() => handleBlur("length")}
                        error={errors?.length}
                        helperText={errors?.length}
                        adornmentText="In"
                        className="boxfont"
                        maxLength={isDecimal(boxData.length)?4:3}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomTextField
                        label="Width"
                        value={boxData.width}
                        onChange={(e) => handleChange("width", e.target.value)}
                        onBlur={() => handleBlur("width")}
                        error={errors?.width}
                        helperText={errors?.width}
                        adornmentText="In"
                        maxLength={isDecimal(boxData.width)?4:3}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomTextField
                        label="Height"
                        value={boxData.height}
                        onChange={(e) => handleChange("height", e.target.value)}
                        onBlur={() => handleBlur("height")}
                        error={errors?.height}
                        helperText={errors?.height}
                        adornmentText="In"
                        maxLength={isDecimal(boxData.height)?4:3}


                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomTextField
                        label="Box weight"
                        value={boxData.box_weight}
                        onChange={(e) =>
                          handleChange("box_weight", e.target.value)
                        }
                        onBlur={() => handleBlur("box_weight")}
                        error={errors?.box_weight}
                        helperText={errors?.box_weight}
                        maxLength={3}
                        adornmentText="Lbs"

                      />
                    </Grid>
                  </Grid>
                  <Stack
                    display={"flex"}
                    alignItems={"center"}
                    direction={"row"}
                    justifyContent={"center"}
                    gap={2}
                    p={2}
                  >
                    <Stack>
                      <LoadingButton
                        variant="contained"
                        sx={{
                          width: "130px",
                          backgroundColor: palette.secondary.main,
                        }}
                        onClick={
                          customBoxAdded
                            ? handleEditCustomBox
                            : handleAddCustomBox
                        }
                      >
                        {customBoxAdded ? "Edit" : "Add"}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Box>
              </Dialog>
            </Stack>

            <Box paddingTop={3}>
              <Box>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={6}>
                    <Typography
                      fontFamily={typography.fontFamilySemiBold}
                      textTransform="capitalize"
                    >{`Product items (${productItems?.length})`}</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography
                      fontFamily={typography.fontFamilySemiBold}
                      textTransform="capitalize"
                      textAlign="right"
                    >
                      {"Weight"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {wholeconfigData &&
                productItems &&
                productItems?.map((item, index) => (
                  <>
                    <Box key={index}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        py={1}
                      >
                        <Grid item xs={6}>
                          <Typography className="productitems">
                            {item?.product_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography className="productitemsweight">{`${item?.lbsConverted} lbs`}</Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      py={1}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      <Grid item>
                        <Typography className="totaltext" sx={{ pr: 5 }}>
                          Product weight total
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="productitemsweight">
                          {/* {`${wholeconfigData?.total_weight} lbs`} */}
                          {`${Number(wholeconfigData?.total_weight || 0)} lbs`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ))}
              {customerLineItem &&
                cxproductItems &&
                cxproductItems?.map((item, index) => (
                  <>
                    <Box key={index}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        py={1}
                      >
                        <Grid item xs={6}>
                          <Typography className="productitems">
                            {item?.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography className="productitemsweight">{`${item?.lbsConverted} lbs`}</Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      py={1}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      <Grid item>
                        <Typography className="totaltext" sx={{ pr: 5 }}>
                          Product weight total
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="productitemsweight">
                          {/* {`${wholeconfigData?.total_weight} lbs`} */}
                          {`${Number(item?.lbsConverted || 0)} lbs`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ))}

              <Stack
                display={"flex"}
                alignItems={"center"}
                direction={"row"}
                justifyContent={"center"}
                paddingTop={2}
              >
                <LoadingButton
                  variant="contained"
                  sx={{
                    width: "130px",
                    height: "40px",
                    backgroundColor: palette.secondary.main,
                  }}
                  onClick={handlepreviewDialog}
                >
                  Preview
                </LoadingButton>
              </Stack>
            </Box>
          </Box>
        </Dialog>
      )}
      <Dialog
        open={previewDialogOpen}
        fullWidth
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return;
          }
        }}
      >
        <Box p={3}>
          <Typography className="previewDialogTitle" component="h2">
            This input will determine the price of the label. Proceed with label
            generation?
          </Typography>
          <Box>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              py={1}
            >
              <Grid item xs={6}>
                <Typography className="productitems">Box config</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography className="productitemsweight">
                {renderBoxText(boxConfig)}
                  <br />
                </Typography>
                <Typography className="productitemsweight">
                  {/* {boxConfigData?.filter((Boxname)=> Boxname==boxConfig  && console.log("yyyy",Boxname)
                  )} */}
                  {`${Number(boxObject?.length || 0)}"(L) x ${Number(
                    boxObject?.width || 0
                  )}"(W) x ${Number(boxObject?.height || 0)}"(H) x ${Number(
                    boxObject?.box_weight || 0
                  )} lbs(Wt)`}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              py={1}
            >
              <Grid item xs={6}>
                <Typography className="productitems">Mail class </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography className="productitemsweight">
                  {mailClass.toLowerCase().replace(/[_-]/g, " ")}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              py={1}
            >
              <Grid item xs={6}>
                <Typography className="productitems">Make the label</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography className="productitemsweight">
                  {labelMake.toLowerCase().replace(/[_-]/g, " ")}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Box>
          <Box mt={3}>
            <Box>
              <Box>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={6}>
                    <Typography
                      fontFamily={typography.fontFamilySemiBold}
                      textTransform="capitalize"
                      textAlign="left"
                    >
                      Product items
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography
                      fontFamily={typography.fontFamilySemiBold}
                      textTransform="capitalize"
                      textAlign="right"
                    >
                      {"Weight"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {productItems.map((item, index) => (
                <Box key={index}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    py={1}
                  >
                    <Grid item xs={6}>
                      <Typography className="productitems">
                        {item?.product_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <Typography className="productitemsweight">{`${item?.lbsConverted} lbs`}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Box>
              ))}

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ justifyContent: "flex-end" }}
              >
                <Grid item>
                  <Typography className="totaltextPreview" sx={{ pr: 5 }}>
                    Product weight total
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="productitemsweight">
                    {`${Number(wholeconfigData?.total_weight || 0)} lbs`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ justifyContent: "flex-end" }}
              >
                <Grid item>
                  <Typography className="totaltextPreview" sx={{ pr: 2 }}>
                    Box weight
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="productitemsweight">
                    {/* {boxObject?.box_weight} lbs */}
                    {`${Number(boxObject?.box_weight || 0)} lbs`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ justifyContent: "flex-end" }}
              >
                <Grid item>
                  <Typography className="totaltextPreview" sx={{ pr: 2 }}>
                    <strong>Total weight </strong>{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="productitemsweight">
                    {/* <strong>{wholeconfigData?.total_weight + boxObject?.box_weight} lbs</strong> */}
                    <strong>{`${Math.abs(
                      Number(wholeconfigData?.total_weight || 0) +
                        Number(boxObject?.box_weight || 0)
                    )} lbs`}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Stack
            direction={"row"}
            gap={2}
            width={"100%"}
            justifyContent="center"
            p={2}
          >
            <Button
              variant="contained"
              disableElevation
              sx={{
                width: "120px",
                boxShadow: "none",
                backgroundColor: "grey.600",
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
                "&:hover": {
                  backgroundColor: "primary.lightIcon",
                },
              }}
              onClick={() => setPreviewDialogOpen(false)}
            >
              {"Cancel"}
            </Button>

            <Button
              // disabled={!productItems||!customerLineItem}
              variant="contained"
              disableElevation
              type="submit"
              onClick={() => setIsGenLabelConfirmation(true)}
              sx={{
                width: "160px",
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
              }}
            >
              {labelButton}
            </Button>
          </Stack>
        </Box>
      </Dialog>
      <Dialog
        open={isLabelDialogOpen}
        onClose={handleCloseLabelDialog}
        sx={{
          width: "662px",
          height: "435px",
        }}
      >
        <Box p={2} display="flex" justifyContent="center" alignItems="center">
          {labelImage ? (
            // <img src={`https://stage-beyondcarts.s3.us-west-2.amazonaws.com/${labelImage}`} alt="LabelImage" style={{ width: "100%" }} />
            <img
              src="https://stage-beyondcarts.s3.us-west-2.amazonaws.com/uploads/logo/BeyondCart_Email_logo.png"
              alt="LabelImage"
              style={{ width: "100%" }}
            />
          ) : (
            <Typography>No label image available</Typography>
          )}
        </Box>
        <Button
          variant="contained"
          // fullWidth
          ma
          sx={{ boxShadow: "none", backgroundColor: "secondary.main" }}
          onClick={handleCloseLabelDialog}
        >
          close
        </Button>
      </Dialog>
      <Dialog
        open={isGenLabelConfirmation}
        onClose={() => setIsGenLabelConfirmation(!isGenLabelConfirmation)}
      >
        <Box sx={{ py: 4, px: 8 }}>
          <Box>
            <Typography>
              Are you sure you want to generate the label?
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"} gap={2} mt={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setIsGenLabelConfirmation(!isGenLabelConfirmation)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ boxShadow: "none", backgroundColor: "secondary.main" }}
              fullWidth
              onClick={handleGenerateLabel}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* label gen statuses */}
      <Dialog
        open={successfullLabelGenModal}
        onClose={() => setSuccessfullLabelGenModal(false)}
      >
        <Box sx={{ py: 4, px: 8 }}>
          <Box>
            <Typography>{labelGenStatusText}</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"} gap={2} mt={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                setSuccessfullLabelGenModal(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={unsuccessfullLabelGenModal}
        onClose={() => setUnsuccessfullLabelGenModal(false)}
      >
        <Box sx={{ py: 4, px: 8 }}>
          <Box>
            <Typography>{labelGenStatusText}</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"} gap={2} mt={3}>
            <LoadingButton
              variant="contained"
              sx={{
                width: "130px",
                height: "40px",
                backgroundColor: palette.secondary.main,
              }}
              onClick={() => setUnsuccessfullLabelGenModal(false)}
            >
              Try Again
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default BoxConfigDialog;
