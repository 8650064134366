import {
  Stack,
  Box,
  Modal,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getTenantUser } from "src/features/usersPage/usersApi";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import {
  getUserInfo,
  logoutUser,
  updateUserInfo,
} from "src/features/user/userState";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import { updatePassword } from "src/features/user/userApi";
import { passwordValidator } from "src/utils/validators";
import { ErrorMessagesSchema } from "src/utils/errorMessagesSchema";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { handlePhoneChange } from "src/utils/formatPhone";

const popUpModalStyle = {
  display: "flex",
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  width: { xs: "80%", sm: "75%", lg: "40%", md: "50%" },
  maxWidth: "800px",
  background: "#FFF",
  overflow: "auto",
  overflow: "auto-y",
  overflowX: "hidden",
  outline: "none",
};

const styleInput = {
  input: { color: palette.common.black },
  width: "100%",
  // width: { lg: 440, md: 400, sm: 400 },
  marginTop: "10px",
  "& .MuiFormHelperText-root": {
    textAlign: "left",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "primary.dark",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.dark",
    },
  },
};

const ProfileModal = ({ openModal, setOpenModal }) => {
  const userInfo = useAppSelector(getUserInfo);

  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openPasswordHasChanged, setOpenPasswordHasChanged] = useState(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const headers = {
    tenant_id: userInfo.tenant_id,
    user_id: userInfo.user_id,
  };

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: async () => {
      try {
        dispatch(incrementAwaitingApiCounter());
        const data = await getTenantUser(
          null,
          headers,
          userInfo.user_id,
          userInfo
        );
        dispatch(decrementAwaitingApiCounter());
        setIsLoading(false);
        return {
          first_name: data?.data?.first_name,
          last_name: data?.data?.last_name,
          user_name: data?.data?.user_name,
          phone_number: setPhoneNumber(data?.data?.phone_number || ""),
        };
      } catch (error) {
        console.log(error);
      }
    },
  });

  const passwordInput = watch("new_password");
  const confirmPasswordInput = watch("confirm_password");

  useEffect(() => {
    if (errors.new_password && passwordInput) clearErrors("new_password");
  }, [passwordInput]);

  useEffect(() => {
    setPasswordError(false);
    if (errors.confirm_password && confirmPasswordInput)
      clearErrors("confirm_password");
  }, [confirmPasswordInput]);

  // updates the users info calling the corresponding api
  const onSubmit = async (data) => {
    const body = {
      ...data,
      phone_number: phoneNumber,
    };

    dispatch(incrementAwaitingApiCounter());
    dispatch(updateUserInfo(body))
      .then((action) => {
        setIsError(false);
        dispatch(decrementAwaitingApiCounter());
        setSuccessfulUpdate(true);
      })
      .catch((err) => {
        dispatch(decrementAwaitingApiCounter());
        setIsError(true);
        setErrorMessage("There has been an unexpected error. Try again");
      });
  };

  const onChangePasswordSubmit = async (data) => {
    if (data?.new_password !== data?.confirm_password) {
      setPasswordError(true);
      setIsSubmited(true);
      setErrorMessage(`${ErrorMessagesSchema.passwordValidation.dontMatch}`);
      return;
    }
    if (!passwordValidator(data?.new_password)) {
      setPasswordError(true);
      setIsSubmited(true);
      setErrorMessage(`${ErrorMessagesSchema.passwordValidation.requirements}`);
      return;
    }

    dispatch(incrementAwaitingApiCounter());

    const obj = {
      password: data?.new_password,
      confirm_password: data?.confirm_password,
    };

    let response;

    try {
      response = await updatePassword(headers, obj, userInfo);
      dispatch(decrementAwaitingApiCounter());

      if (
        response?.data?.statusMessage?.startsWith(
          "Password does not conform to policy"
        )
      ) {
        setIsSubmited(true);
        setIsError(true);
        setErrorMessage(
          `${ErrorMessagesSchema.passwordValidation.requirements}`
        );
      } else if (
        response?.data?.statusMessage?.startsWith("Attempt limit exceeded")
      ) {
        setIsSubmited(true);
        setIsError(true);
        setErrorMessage(
          `${ErrorMessagesSchema.passwordValidation.attemptLimit}`
        );
      } else {
        setIsSubmited(true);
        setIsError(true);
        setErrorMessage("There has been an unexpected error. Try again");
      }

      setIsError(false);
      setIsSubmited(true);
      setOpenPasswordHasChanged(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={popUpModalStyle}>
          {!isLoading &&
            !openChangePassword &&
            !openPasswordHasChanged &&
            !successfulUpdate && (
              <Stack
                gap={{ xs: 4, md: 6 }}
                direction="column"
                sx={{
                  width: "100%",
                  color: "common.black",
                  paddingY: { xs: 5, md: 7 },
                  paddingX: { xs: 3, md: 8 },
                  fontFamily: typography.fontFamilyRegular,
                  backgroundColor: "white",
                }}
              >
                <IconButton
                  aria-label="close"
                  sx={{
                    position: "fixed",
                    top: { xs: 5, md: 25 },
                    right: { xs: 5, md: 25 },
                    zIndex: 2,
                  }}
                  onClick={handleCloseModal}
                >
                  <img
                    width="34px"
                    src="/assets/icons/table/close.svg"
                    alt="X"
                  />
                </IconButton>
                <FormControl
                  component="form"
                  className="form"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{
                    gap: "2vh !important",
                  }}
                >
                  <Stack gap={2}>
                    <h2 style={typography.h3}>My Account</h2>
                    <h4 style={typography.h4}>Basic Information</h4>
                  </Stack>

                  <Stack gap={1}>
                    <TextField
                      sx={styleInput}
                      label="First Name"
                      name="first_name"
                      {...register("first_name", {
                        required: "First Name is required",
                        validate: (value) => !/[^A-Za-z0-9 _]+/.test(value),
                      })}
                      error={!!errors.first_name}
                      helperText={
                        errors.first_name && errors.first_name.message
                      }
                      inputProps={{ maxLength: 40 }}
                    />

                    <TextField
                      sx={styleInput}
                      label="Last Name"
                      name="last_name"
                      {...register("last_name", {
                        required: "Last Name is required",
                        validate: (value) => !/[^A-Za-z0-9 _]+/.test(value),
                      })}
                      error={!!errors.last_name}
                      helperText={errors.last_name && errors.last_name.message}
                      inputProps={{ maxLength: 40 }}
                    />

                    <MuiTelInput
                      sx={styleInput}
                      defaultCountry="US"
                      forceCallingCode={true}
                      label="Phone Number"
                      name="phone_number"
                      value={phoneNumber}
                      {...register("phone_number", {
                        required: "Phone number is required.",
                      })}
                      error={!!errors.phone_number}
                      helperText={
                        errors.phone_number && errors.phone_number.message
                      }
                      onChange={(newValue) =>
                        handlePhoneChange(newValue, setPhoneNumber)
                      }
                    />

                    <TextField
                      sx={styleInput}
                      label="Email Address"
                      disabled
                      name="user_name"
                      {...register("user_name")}
                      error={!!errors.user_name}
                      helperText={
                        (errors.user_name && errors.user_name.message) ||
                        errorMessage
                      }
                    />
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{
                        marginTop: { xs: 1, md: 2 },
                        boxShadow: "none",
                        backgroundColor: palette.secondary.main,
                      }}
                    >
                      Update
                    </Button>
                    {/* </Box> */}
                  </Stack>
                </FormControl>

                <Stack>
                  <h4
                    style={{
                      ...typography.h4,
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Security Information
                  </h4>
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: 2,
                      boxShadow: "none",
                      backgroundColor: palette.secondary.main,
                    }}
                    onClick={() => setOpenChangePassword(true)}
                  >
                    Change Password
                  </Button>
                </Stack>
              </Stack>
            )}

          {successfulUpdate && (
            <Stack
              gap={6}
              direction="column"
              sx={{
                width: "100%",
                color: "common.black",
                paddingY: { xs: 5, md: 7 },
                paddingX: { xs: 3, md: 8 },
                fontFamily: typography.fontFamilyRegular,
                backgroundColor: "white",
              }}
            >
              <IconButton
                aria-label="close"
                sx={{
                  position: "fixed",
                  top: { xs: 5, md: 25 },
                  right: { xs: 5, md: 25 },
                  zIndex: 2,
                }}
                onClick={handleCloseModal}
              >
                <img width="32px" src="/assets/icons/table/close.svg" alt="X" />
              </IconButton>
              <Stack alignItems={"center"} gap={4}>
                <h3 style={{ ...typography.h3, textAlign: "center" }}>
                  Profile updated successfully
                </h3>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    backgroundColor: palette.secondary.main,
                    width: {
                      lg: 330,
                      md: 330,
                      xs: "100%",
                    },
                  }}
                  onClick={() => setSuccessfulUpdate(false)}
                >
                  Back to Profile
                </Button>
              </Stack>
            </Stack>
          )}

          {openChangePassword && !openPasswordHasChanged && (
            <Stack
              gap={6}
              direction={"column"}
              sx={{
                width: "100%",
                color: "common.black",
                paddingY: { xs: 5, md: 7 },
                paddingX: { xs: 3, md: 8 },
                fontFamily: typography.fontFamilyRegular,
                backgroundColor: "white",
              }}
            >
              <IconButton
                aria-label="close"
                sx={{
                  position: "fixed",
                  top: { xs: 5, md: 25 },
                  right: { xs: 5, md: 25 },
                  zIndex: 2,
                }}
                onClick={handleCloseModal}
              >
                <img width="34px" src="/assets/icons/table/close.svg" alt="X" />
              </IconButton>
              <FormControl
                component="form"
                className="form"
                onSubmit={handleSubmit(onChangePasswordSubmit)}
              >
                <Stack spacing={2} alignItems={"center"}>
                  <h3
                    style={{
                      ...typography.h3,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Change your password
                  </h3>
                </Stack>
                <Stack spacing={2} alignItems={"center"}>
                  <TextField
                    sx={styleInput}
                    label="New Password"
                    name="new_password"
                    type={showPassword ? "text" : "password"}
                    {...register("new_password", {
                      required: `${ErrorMessagesSchema.newPassword.required}`,
                    })}
                    error={!!errors.new_password}
                    aria-invalid={!!errors.new_password}
                    helperText={
                      errors.new_password && errors.new_password.message
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    label="Confirm Password"
                    name="confirm_password"
                    sx={styleInput}
                    type={showPassword ? "text" : "password"}
                    {...register("confirm_password", {
                      required: `${ErrorMessagesSchema.confirmPassword.required}`,
                    })}
                    error={!!errors.confirm_password || passwordError}
                    aria-invalid={!!errors.confirm_password}
                    helperText={
                      errors.confirm_password
                        ? errors.confirm_password.message
                        : passwordError
                        ? errorMessage
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: 3,
                      boxShadow: "none",
                      backgroundColor: palette.secondary.main,
                      width: {
                        lg: 330,
                        md: 330,
                        xs: "100%",
                      },
                    }}
                  >
                    Change Password
                  </Button>
                </Stack>
              </FormControl>
            </Stack>
          )}

          {openPasswordHasChanged && (
            <Stack
              gap={3}
              alignItems={"center"}
              sx={{
                width: "100%",
                color: "common.black",
                paddingY: { xs: 5, md: 7 },
                paddingX: { xs: 3, md: 8 },
                fontFamily: typography.fontFamilyRegular,
                backgroundColor: "white",
              }}
            >
              <IconButton
                aria-label="close"
                sx={{
                  position: "fixed",
                  top: { xs: 5, md: 25 },
                  right: { xs: 5, md: 25 },
                  zIndex: 2,
                }}
                onClick={handleCloseModal}
              >
                <img width="34px" src="/assets/icons/table/close.svg" alt="X" />
              </IconButton>
              <Stack spacing={2}>
                <h3
                  style={{
                    ...typography.h3,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Your Password has been changed!
                </h3>
              </Stack>
              <Button
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  marginTop: { md: 3 },
                  boxShadow: "none",
                  backgroundColor: palette.secondary.main,
                  width: {
                    lg: 300,
                    md: 300,
                    xs: "100%",
                  },
                }}
                onClick={() => {
                  dispatch(logoutUser());
                  navigate("/login");
                }}
              >
                Back to Login
              </Button>
            </Stack>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ProfileModal;
