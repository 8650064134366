import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material"
import React, { useState } from "react"

const AdvancedFilter = ({getFilterData}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');

    const handleContinue = () => {
        const formData = {
            first_name: firstName,
            last_name: lastName,
            customer_email: emailId,
            tracking_no: trackingNumber
        };

        const filteredFormData = Object.fromEntries(
            Object.entries(formData).filter(([key, value]) => value)
        );

        getFilterData(filteredFormData)
    }

    return (
        <Box sx={{ mb: 5 }}>
            <Grid container sx={{ margin: '10px 0', borderTop: '1px solid #ddd' }}>
                <Grid item lg={3} sx={{ padding: '20px 20px 20px 0' }}>
                    <Typography sx={{ color: '#000', fontWeight: 'bold', fontSize: '13px', mb: 1 }}>First Name</Typography>
                    <OutlinedInput
                        size='small'
                        sx={{ color: '#000' }}
                        fullWidth={true}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item lg={3} sx={{ padding: '20px 20px 20px 0' }}>
                    <Typography sx={{ color: '#000', fontWeight: 'bold', fontSize: '13px', mb: 1 }}>Last Name</Typography>
                    <OutlinedInput
                        size='small'
                        sx={{ color: '#000' }}
                        fullWidth={true}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item lg={3} sx={{ padding: '20px 20px 20px 0' }}>
                    <Typography sx={{ color: '#000', fontWeight: 'bold', fontSize: '13px', mb: 1 }}>Email</Typography>
                    <OutlinedInput
                        size='small'
                        sx={{ color: '#000' }}
                        fullWidth={true}
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                    />
                </Grid>
                <Grid item lg={3} sx={{ padding: '20px 20px 20px 0' }}>
                    <Typography sx={{ color: '#000', fontWeight: 'bold', fontSize: '13px', mb: 1 }}>Tracking Number</Typography>
                    <OutlinedInput
                        size='small'
                        sx={{ color: '#000' }}
                        fullWidth={true}
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Box sx={{ mt: 1, textAlign: 'end', paddingRight: '20px' }}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: 'secondary.main', boxShadow: 'none' }}
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    )
}

export default AdvancedFilter
