import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  Typography,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Switch,
} from "@mui/material";
import { getUserInfo, updateUserInfo } from "src/features/user/userState";
import { setSigninData } from "src/features/signin/signin.action";
import { useAppSelector } from "src/hooks/hooks";
import { getTenant } from "src/features/usersPage/usersApi";
import {
  textStyle,
  styleInput,
  months,
  years,
  capitalize,
  cc_format,
  showBrandLogo,
  creditCardType,
} from "src/pages/billing/cardsComponent/cardsUtils";
import { getAllPayments } from "src/features/billing/billingApi";
import { LoadingButton } from "@mui/lab";
import { updateTenantInfo } from "src/features/config/configApi";
import {
  createPaymentMethod,
  updatePaymentById,
  updatePreferredCardById,
} from "src/features/billing/billingApi";

import CountrySelect from "src/sections/auth/signin/formsContainer/billing/countrySelect";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import CircularLoader from "src/loader/circular/CircularLoader";
import ModalComponent from "src/components/modal/ModalComponent";
import planNames from "src/utils/planNames";
import typography from "src/theme/typography";

import palette from "src/theme/palette";

import "./upgradePlan.scss";
import { getPlansInfo } from "src/features/plans/plansState";
import appUrls from "src/utils/appUrls";

const pStyle = { color: palette.common.black, fontSize: 18 };

const UpgradePlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const signinData = useSelector((state) => state.signin.signinData);

  const {
    cityError,
    zipcodeError,
    stateError,
    state,
    cardName,
    cardNameError,
    creditCardError,
    creditCardMessage,
    cvvError,
    expirationError,
    billingAddrError,
    billingAddr,
    carrier,
    cvv,
    expirationMonth,
    expirationYear,
    city,
    country,
    creditCard,
    email,
    fullName,
    lastName,
    manager,
    name,
    phone,
    company,
    zipcode,
  } = signinData || {};

  const [creditCardSelected, setCreditCardSelected] = useState();
  const [cardTokenId, setCardTokenId] = useState("");
  const [carrierUSPSImg, setCarrierUSPSImg] = useState("");
  const [carrierUPSImg, setCarrierUPSImg] = useState("");
  const [managerImg, setManagerImg] = useState("");
  const [formatedCreditCard, setFormatedCreditCard] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);
  const [date, setDate] = useState("");
  const [creditLogo, setCreditLogo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userBillingInfo, setUserBillingInfo] = useState({});

  const plansInfo = useAppSelector(getPlansInfo);
  const isFreeExpired =
    userInfo?.is_plan_expired &&
    userInfo?.plan_id === plansInfo[planNames.free]?._id;

  const [paymentData, setPaymentData] = useState([]);
  const [additionalData, setAdditionalData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false);
  const [openModalCardAdded, setOpenModalCardAdded] = useState(false);
  const [cardModalOpen, setCardModalOpen] = useState(false);

  const [isCreateSuccess, setIsCreateSuccess] = useState(false);
  const [isOnChangePref, setIsOnChangePref] = useState(false);

  const formatPhone = (phone) => {
    return phone;
  };

  const handleCardChange = (e) => {
    const valueParsed = e.target.value.replaceAll(" ", "");
    const valueInt = parseInt(valueParsed) || "";
    const value = valueInt.toString();

    if (value.length > 16) return;

    setFormatedCreditCard(cc_format(value));
    creditCardType(value, setCreditLogo);
    dispatch(setSigninData({ ...signinData, creditCard: value }));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCardModalOpen(false);
    setCreditCardSelected(null);
  };

  const handleCloseModalUpgrade = () => {
    setOpenModalUpgrade(false);
    getTenant(userInfo)
      .then(({ data }) => {
        dispatch(updateUserInfo(data))
          .then(() => {})
          .catch((err) => {
            setOpenModalUpgrade(false);
          });
      })
      .catch((err) => console.log(err));
  };

  const handleCloseModalCardAdded = () => {
    setOpenModalCardAdded(false);
    setIsModalActive(false);
    setCardModalOpen(false);
  };

  const handleCardAdd = () => {
    setCardModalOpen(true);
    setCreditCardSelected(null);
    setCreditLogo(null);
    setFormatedCreditCard("");
    dispatch(
      setSigninData({
        ...signinData,
        cardName: "",
        cvv: "",
        expirationMonth: "",
        expirationYear: "",
        billingAddr: "",
        city: "",
        zipcode: "",
        state: "",
        country: "",
      })
    );
  };

  const handleCardEdit = (p) => {
    setCardModalOpen(true);
    setCreditCardSelected(p);
    setFormatedCreditCard("**** **** **** " + p?.card_no);
    setCardTokenId(p?.card_token_id);
    dispatch(
      setSigninData({
        ...signinData,
        cardName: p?.name,
        cvv: p?.cvv,
        expirationMonth: p?.exp_month,
        expirationYear: p?.exp_year,
        billingAddr: p?.billing.address,
        city: p?.billing.city,
        zipcode: p?.billing.zip_code,
        state: p?.billing.state,
        country: p?.billing.country,
      })
    );
  };

  const handlePlanUpgrade = () => {
    const data = {
      shipping_volume: userInfo.shipping_volume,
      contact_info: {
        email: email,
      },
      plan_id: plansInfo[planNames.premium]?._id,
      plan_name: planNames.premium,
      switched_to_premium: true,
      status: "active",
    };

    updateTenantInfo(data, userInfo)
      .then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
          setOpenModalUpgrade(true);
        }
      })
      .catch((e) => {});
  };

  const handleCvvChange = (e) => {
    const value = e.target.value;
    if (value.length > 3) return;

    dispatch(setSigninData({ ...signinData, cvv: value }));
  };

  const handleIsPreferredChange = () => {
    if (creditCardSelected.is_preferred) {
      setIsOnChangePref(false);
      return;
    }

    setIsLoading(true);

    updatePreferredCardById(
      creditCardSelected._id,
      { is_preferred: !creditCardSelected.is_preferred },
      userInfo
    )
      .then(({ data }) => {
        const { statusCode } = data;

        if (statusCode === 200)
          getAllPayments(null, {}, userInfo).then((r) => {
            const { data } = r;
            setPaymentData(data);
          });
        setIsOnChangePref(false);
        setIsLoading(false);
        handleCloseModal();
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const checkBillingData = () => {
    if (!creditCardSelected) {
      if (!cardName) {
        dispatch(setSigninData({ ...signinData, cardNameError: true }));
        return true;
      }

      if (!creditCard || creditCard.length < 15 || creditCard.length > 16) {
        dispatch(
          setSigninData({
            ...signinData,
            creditCardError: true,
            creditCardMessage: "Credit Card is required.",
          })
        );
        return true;
      }

      if (!cvv || cvv.length < 3) {
        dispatch(setSigninData({ ...signinData, cvvError: true }));
        return true;
      }

      if (!expirationMonth) {
        dispatch(setSigninData({ ...signinData, expirationError: true }));
        return true;
      }

      if (!expirationYear) {
        dispatch(setSigninData({ ...signinData, expirationError: true }));
        return true;
      }

      if (!billingAddr) {
        dispatch(setSigninData({ ...signinData, billingAddrError: true }));
        return true;
      }

      if (!city) {
        dispatch(setSigninData({ ...signinData, cityError: true }));
        return true;
      }

      if (!zipcode) {
        dispatch(setSigninData({ ...signinData, zipcodeError: true }));
        return true;
      }

      if (!state) {
        dispatch(setSigninData({ ...signinData, stateError: true }));
        return true;
      }

      if (!country) {
        dispatch(setSigninData({ ...signinData, countryError: true }));
        return true;
      }

      const data = {
        name: cardName,
        card_no: creditCard,
        cvv: cvv,
        exp_month: expirationMonth,
        exp_year: expirationYear,
        is_preferred: false,
        billing: {
          address: billingAddr,
          city: city,
          zip_code: zipcode,
          state: state,
          country: country,
        },
      };

      setIsLoading(true);

      createPaymentMethod(data, userInfo, "create")
        .then((r) => {
          const { statusCode } = r;
          if (statusCode === 200) {
            setIsCreateSuccess(true);
            setOpenModalCardAdded(true);

            getAllPayments(null, {}, userInfo).then((r) => {
              const { data } = r;
              const preferredPaymentCardInfo = data.filter(
                (el) => el.is_preferred
              )[0];

              let tempData = {};

              tempData.billingAddr = preferredPaymentCardInfo.billing.address;
              tempData.city = preferredPaymentCardInfo.billing.city;
              tempData.country = preferredPaymentCardInfo.billing.country;
              tempData.zipcode = preferredPaymentCardInfo.billing.zip_code;
              tempData.creditCard = preferredPaymentCardInfo.card_no;
              tempData.fullName = preferredPaymentCardInfo.name;

              setAdditionalData((prev) => ({ ...prev, ...tempData }));

              setPaymentData(data);
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          if (e.message.includes("Your card number is incorrect")) {
            dispatch(
              setSigninData({
                ...signinData,
                creditCardError: true,
                creditCardMessage: "Your card number is incorrect.",
              })
            );
          }
          setIsLoading(false);
        });
    } else {
      if (!cardName) {
        dispatch(setSigninData({ ...signinData, cardNameError: true }));
        return true;
      }

      if (!cvv || cvv.length < 3) {
        dispatch(setSigninData({ ...signinData, cvvError: true }));
        return true;
      }

      if (!expirationMonth) {
        dispatch(setSigninData({ ...signinData, expirationError: true }));
        return true;
      }

      if (!expirationYear) {
        dispatch(setSigninData({ ...signinData, expirationError: true }));
        return true;
      }

      if (!billingAddr) {
        dispatch(setSigninData({ ...signinData, billingAddrError: true }));
        return true;
      }

      if (!city) {
        dispatch(setSigninData({ ...signinData, cityError: true }));
        return true;
      }

      if (!zipcode) {
        dispatch(setSigninData({ ...signinData, zipcodeError: true }));
        return true;
      }

      if (!state) {
        dispatch(setSigninData({ ...signinData, stateError: true }));
        return true;
      }

      if (!country) {
        dispatch(setSigninData({ ...signinData, countryError: true }));
        return true;
      }

      const data = {
        name: cardName,
        cvv: cvv,
        exp_month: expirationMonth,
        exp_year: expirationYear,
        is_preferred: creditCardSelected?.is_preferred || false,
        billing: {
          address: billingAddr,
          city: city,
          zip_code: zipcode,
          state: state,
          country: country,
        },
        paymentId: cardTokenId,
      };

      setIsLoading(true);

      updatePaymentById(creditCardSelected._id, data, userInfo)
        .then((r) => {
          const { statusCode } = r;

          if (statusCode === 200) {
            setIsCreateSuccess(false);
            setOpenModalCardAdded(true);
            getAllPayments(null, {}, userInfo).then((r) => {
              const { data } = r;
              const preferredPaymentCardInfo = data.filter(
                (el) => el.is_preferred
              )[0];

              let tempData = {};

              tempData.billingAddr = preferredPaymentCardInfo.billing.address;
              tempData.city = preferredPaymentCardInfo.billing.city;
              tempData.country = preferredPaymentCardInfo.billing.country;
              tempData.zipcode = preferredPaymentCardInfo.billing.zip_code;
              tempData.creditCard = preferredPaymentCardInfo.card_no;
              tempData.fullName = preferredPaymentCardInfo.name;

              setAdditionalData((prev) => ({ ...prev, ...tempData }));

              setPaymentData(data);
            });
          }

          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isFreeExpired) {
      let tempData = {};

      getTenant(userInfo)
        .then((r) => {
          const { statusCode, data } = r;
          if (statusCode === 200) {
            tempData.order_management_name = Object.keys(
              data.order_management
            )[0];

            tempData.carrier = data.carrier;

            setAdditionalData((prev) => ({ ...prev, ...tempData }));
          }
        })
        .catch((e) => console.log(e));

      // get the payment method
      getAllPayments(null, {}, userInfo).then((r) => {
        const { data } = r;

        if (data.length === 1) {
          tempData.billingAddr = data[0].billing.address;
          tempData.city = data[0].billing.city;
          tempData.country = data[0].billing.country;
          tempData.zipcode = data[0].billing.zip_code;
          tempData.creditCard = data[0].card_no;
          tempData.fullName = data[0].name;
        } else {
          const preferredPaymentCardInfo = data.filter(
            (el) => el.is_preferred
          )[0];

          tempData.billingAddr = preferredPaymentCardInfo?.billing.address;
          tempData.city = preferredPaymentCardInfo?.billing.city;
          tempData.country = preferredPaymentCardInfo?.billing.country;
          tempData.zipcode = preferredPaymentCardInfo?.billing.zip_code;
          tempData.creditCard = preferredPaymentCardInfo?.card_no;
          tempData.fullName = preferredPaymentCardInfo?.name;
        }

        setAdditionalData((prev) => ({ ...prev, ...tempData }));

        setPaymentData(data);
      });

      // update userInfo
      getTenant(userInfo)
        .then((r) => {
          const { statusCode, data } = r;
          if (statusCode === 200) {
            dispatch(
              updateUserInfo({
                shipping_volume: data?.shipping_volume,
              })
            ).then(() => setOpenModal(true));
          }
        })
        .catch((e) => console.log(e));
    } else {
      if (!signinData?.user_id) {
        navigate(appUrls.login);
        return;
      }
    }

    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setDate(lastDayOfMonth.toDateString());
  }, []);

  useEffect(() => {
    if (isFreeExpired) {
      setUserBillingInfo({
        fullName: userInfo?.first_name + " " + userInfo?.last_name,
        phone: userInfo?.phone_number,
        billingAddr: additionalData.billingAddr,
        zipcode: additionalData.zipcode,
        city: additionalData?.city,
        country: additionalData?.country,
      });
      dispatch(
        setSigninData({
          ...signinData,
          name: userInfo?.first_name,
          lastName: userInfo?.last_name,
          email: userInfo?.email,
          phone: userInfo?.phone_number,
          company: userInfo?.company_name,
          user_id: userInfo?.user_id,
          manager: additionalData?.order_management_name,
          carrier: additionalData?.carrier,
          billingAddr: additionalData.billingAddr,
          city: additionalData?.city,
          country: additionalData?.country,
          creditCard: additionalData.creditCard,
          fullName: additionalData.fullName,
          zipcode: additionalData.zipcode,
        })
      );
    }
  }, [additionalData]);

  useEffect(() => {
    if (carrier?.usps) setCarrierUSPSImg("/assets/usps.png");
    if (carrier?.ups) setCarrierUPSImg("/assets/ups.png");

    if (manager === "ship_station") setManagerImg("/assets/shipStation.png");
    if (manager === "shopify") setManagerImg("/assets/shopify.png");
    if (manager === "magento") setManagerImg("/assets/magento.png");
  }, [signinData]);

  return (
    <>
      {isLoading && <CircularLoader />}

      <Box
        sx={{
          display: "flex",
          paddingLeft: "30px",
          paddingRight: "30px",
          paddingTop: "30px",
          justifyContent: "space-between",
        }}
      >
        <img
          src="/assets/beyond-carts-logo.svg"
          alt="logo"
          style={{
            height: "48px",
            display: "block",
            cursor: "pointer",
          }}
          onClick={() => navigate(appUrls.login)}
        />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </Box>

      <Box className="checkout-container">
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Upgrade to Premium
        </Typography>

        <Box className="checkout-cards-container">
          <Box className="checkout-card">
            <Box className="section-container">
              <Typography variant="h4">Your Information</Typography>

              <Box className="section-info-container">
                <img
                  src="/assets/user.svg"
                  alt="logo"
                  style={{ height: "30px", display: "block" }}
                ></img>
                <Typography sx={pStyle}>
                  {name} {lastName}
                </Typography>
              </Box>

              <Box className="section-info-container">
                <img
                  src="/assets/phone.svg"
                  alt="logo"
                  style={{ height: "30px", display: "block" }}
                ></img>
                <Typography sx={pStyle}>{formatPhone(phone)}</Typography>
              </Box>

              <Box className="section-info-container">
                <img
                  src="/assets/email.svg"
                  alt="logo"
                  style={{ height: "30px", display: "block" }}
                ></img>
                <Typography sx={pStyle}>{email}</Typography>
              </Box>

              <Box className="section-info-container">
                <img
                  src="/assets/company.svg"
                  alt="logo"
                  style={{ height: "30px", display: "block" }}
                ></img>
                <Typography sx={pStyle}>{company}</Typography>
              </Box>
            </Box>

            <Box className="section-container">
              <Typography variant="h4">
                Shipping Fulfiller and Order Management
              </Typography>

              <Box className="checkout-section-img-container">
                <img src={carrierUSPSImg}></img>
                <img src={carrierUPSImg}></img>
                <img src={managerImg}></img>
              </Box>
            </Box>
          </Box>

          <Box
            className="checkout-card"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box className="section-container">
                <Typography variant="h4">Billing Address</Typography>
                <Typography sx={pStyle}>{userBillingInfo.fullName}</Typography>
                <Typography sx={pStyle}>
                  {userBillingInfo.billingAddr}
                </Typography>
                <Typography sx={pStyle}>
                  {userBillingInfo.city}, {userBillingInfo.zipcode}
                </Typography>
                <Typography sx={pStyle}>{userBillingInfo.country}</Typography>
                <Typography sx={pStyle}>
                  {formatPhone(userBillingInfo.phone)}
                </Typography>
              </Box>

              <Box className="section-container">
                <Typography variant="h4">Payment</Typography>

                {isFreeExpired ? (
                  <>
                    {paymentData.map((p, i) => {
                      return (
                        <Box
                          className="billing-invoices-payment-container"
                          key={i}
                        >
                          <Box className="billing-invoices-payment-card-name">
                            <Typography
                              sx={{
                                ...textStyle,
                                paddingLeft: 0.7,
                                marginTop: 0,
                              }}
                            >
                              {p.name}
                            </Typography>

                            {p.is_preferred && (
                              <Stack
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                              >
                                <Typography
                                  sx={{
                                    color: "#299D4A",
                                    fontFamily: typography.fontFamilySemiBold,
                                    paddingRight: 1,
                                  }}
                                >
                                  Default Card
                                </Typography>
                                <img src="/assets/preferredLogo.svg"></img>
                              </Stack>
                            )}
                          </Box>

                          <Box className="billing-invoices-payment-card-info">
                            <Stack
                              flexDirection={"row"}
                              width={"100%"}
                              justifyContent={"space-between"}
                            >
                              <img
                                style={{ height: "30px" }}
                                src={showBrandLogo(p.card_type)}
                              />
                              <Typography
                                sx={{
                                  ...textStyle,
                                  paddingLeft: 0.7,
                                  marginTop: 0,
                                }}
                              >
                                {capitalize(p.card_type)} Card ending in{" "}
                                {p.card_no}
                              </Typography>

                              <Box>
                                <Typography
                                  sx={{
                                    cursor: "pointer",
                                    ...textStyle,
                                    marginTop: 0,
                                  }}
                                  onClick={() => handleCardEdit(p)}
                                >
                                  <img
                                    title="Edit Card"
                                    style={{
                                      cursor: "pointer",
                                      height: "26px",
                                      margin: "auto",
                                    }}
                                    src="/assets/edit.svg"
                                  />
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                        </Box>
                      );
                    })}

                    <Box className="billing-invoices-add-payments-container">
                      <Box
                        className="billing-invoices-add-payments"
                        onClick={handleCardAdd}
                      >
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            textAlign: "center",
                            paddingTop: "7px",
                            paddingBottom: "7px",
                          }}
                        >
                          <img
                            title="Add Card"
                            style={{
                              height: "16px",
                              margin: "auto",
                            }}
                            src="/assets/add.svg"
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Typography sx={pStyle}>
                    Card ending in {creditCard?.slice(12, 16)}
                  </Typography>
                )}
              </Box>
            </Box>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handlePlanUpgrade}
              sx={{
                fontSize: "18px !important",
                marginTop: 3,
                width: { lg: 280, sm: 444, md: 444, xs: 150 },
                height: { lg: 52 },
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
              }}
            >
              Upgrade Plan
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      {cardModalOpen && (
        <Box className="billing-invoices-add-payments-modal">
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: { sm: 15, lg: 34 },
              right: { lg: 29, md: 30, sm: 10 },
              zIndex: 2,
            }}
            onClick={() => {
              setCardModalOpen(false);
              setCreditCardSelected(null);
            }}
          >
            <img width="34px" src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>

          <Typography variant="h3">Payment Information</Typography>

          <Typography sx={{ ...textStyle, textAlign: "center" }}>
            {creditCardSelected
              ? "Change a payment method below."
              : "Add a payment method."}
          </Typography>

          {creditCardSelected && (
            <Box className="billing-invoices-add-payments-modal-card-container">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <img
                    src={showBrandLogo(creditCardSelected.card_type)}
                    style={{ height: "30px" }}
                  ></img>
                  <Typography sx={textStyle}>
                    {capitalize(creditCardSelected.card_type)} Card ending with{" "}
                    {creditCardSelected.card_no}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    checked={creditCardSelected.is_preferred}
                    onChange={() => setIsOnChangePref(true)}
                  />
                  <Typography>Default Card</Typography>
                </Box>
              </Box>
            </Box>
          )}

          <TextField
            sx={styleInput}
            label="Name of Card *"
            error={cardNameError}
            helperText={cardNameError && "Name of Card is required."}
            value={cardName}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  cardNameError: false,
                })
              )
            }
            onChange={(e) => {
              if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
              dispatch(
                setSigninData({
                  ...signinData,
                  cardName: e.target.value,
                })
              );
            }}
            inputProps={{ maxLength: 40 }}
          ></TextField>

          <TextField
            sx={styleInput}
            label="Credit Card *"
            disabled={creditCardSelected}
            value={formatedCreditCard}
            error={creditCardError}
            helperText={creditCardError && creditCardMessage}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  creditCardError: false,
                })
              )
            }
            onChange={handleCardChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <img
                      style={{
                        height: "25px",
                        cursor: "default",
                        marginRight: "20px",
                      }}
                      src={creditLogo}
                    ></img>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>

          <Box className="billing-invoices-add-payments-modal-inp-container">
            <TextField
              sx={styleInput}
              type="number"
              label="CVV *"
              value={cvv}
              error={cvvError}
              helperText={cvvError && "CVV is required."}
              onFocus={() =>
                dispatch(
                  setSigninData({
                    ...signinData,
                    cvvError: false,
                  })
                )
              }
              onChange={handleCvvChange}
            ></TextField>

            <FormControl fullWidth>
              <InputLabel
                id="credit-month-select-label"
                sx={{ marginTop: "10px" }}
              >
                Month *
              </InputLabel>
              <Select
                labelId="credit-month-select-label"
                defaultValue={""}
                value={expirationMonth}
                label="Month"
                onChange={(e) => {
                  dispatch(
                    setSigninData({
                      ...signinData,
                      expirationMonth: e.target.value,
                    })
                  );
                }}
                sx={{
                  ...styleInput,
                  color: palette.common.black,
                }}
                error={expirationError}
                onFocus={() =>
                  dispatch(
                    setSigninData({
                      ...signinData,
                      expirationError: false,
                    })
                  )
                }
              >
                {months.map((m, index) => (
                  <MenuItem
                    value={index + 1}
                    sx={{ color: palette.common.black }}
                  >
                    {m}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={expirationError}>
                {expirationError && "Month is required."}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel
                id="credit-year-select-label"
                sx={{ marginTop: "10px" }}
              >
                Year *
              </InputLabel>
              <Select
                labelId="credit-year-select-label"
                defaultValue={""}
                value={expirationYear}
                label="Year"
                onChange={(e) => {
                  dispatch(
                    setSigninData({
                      ...signinData,
                      expirationYear: e.target.value,
                    })
                  );
                }}
                sx={{
                  ...styleInput,
                  color: palette.common.black,
                }}
                error={expirationError}
                onFocus={() =>
                  dispatch(
                    setSigninData({
                      ...signinData,
                      expirationError: false,
                    })
                  )
                }
              >
                {years.map((m) => (
                  <MenuItem value={m} sx={{ color: palette.common.black }}>
                    {m}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={expirationError}>
                {expirationError && "Year is required."}
              </FormHelperText>
            </FormControl>
          </Box>

          <TextField
            sx={styleInput}
            label="Billing Address *"
            error={billingAddrError}
            helperText={billingAddrError && "Billing Address is required."}
            value={billingAddr}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  billingAddrError: false,
                })
              )
            }
            onChange={(e) => {
              if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
              dispatch(
                setSigninData({
                  ...signinData,
                  billingAddr: e.target.value,
                })
              );
            }}
            inputProps={{ maxLength: 40 }}
          ></TextField>

          <Box className="billing-invoices-add-payments-modal-inp-container">
            <TextField
              sx={styleInput}
              label="City *"
              error={cityError}
              helperText={cityError && "City is required."}
              value={city}
              onFocus={() =>
                dispatch(
                  setSigninData({
                    ...signinData,
                    cityError: false,
                  })
                )
              }
              onChange={(e) => {
                if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
                dispatch(
                  setSigninData({
                    ...signinData,
                    city: e.target.value,
                  })
                );
              }}
              inputProps={{ maxLength: 40 }}
            ></TextField>

            <TextField
              sx={styleInput}
              label="Zipcode *"
              error={zipcodeError}
              helperText={zipcodeError && "Zipcode is required."}
              value={zipcode}
              onFocus={() =>
                dispatch(
                  setSigninData({
                    ...signinData,
                    zipcodeError: false,
                  })
                )
              }
              onChange={(e) => {
                if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
                dispatch(
                  setSigninData({
                    ...signinData,
                    zipcode: e.target.value,
                  })
                );
              }}
              inputProps={{ maxLength: 10 }}
            ></TextField>
          </Box>

          <TextField
            sx={styleInput}
            label="State *"
            value={state}
            error={stateError}
            helperText={stateError && "State is required."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  stateError: false,
                })
              )
            }
            onChange={(e) => {
              if (/[^A-Za-z0-9 _]+/.test(e.target.value)) return;
              dispatch(
                setSigninData({
                  ...signinData,
                  state: e.target.value,
                })
              );
            }}
            inputProps={{ maxLength: 40 }}
          ></TextField>

          <CountrySelect sx={styleInput} />

          <Box className="billing-invoices-add-payments-modal-btns-container">
            <Button
              size="large"
              type="button"
              variant="contained"
              sx={{
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
                paddingY: 1.3,
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={checkBillingData}
            >
              {creditCardSelected ? "Update card" : "Create card"}
            </Button>
          </Box>
        </Box>
      )}

      <ModalComponent
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleBtnClick={handleCloseModal}
        title={`Your ${userInfo.plan_name} has expired`}
        description={"You need to upgrade to a premium plan."}
        btnText={"Continue"}
      />

      <ModalComponent
        openModal={openModalUpgrade}
        handleCloseModal={handleCloseModalUpgrade}
        handleBtnClick={handleCloseModalUpgrade}
        title={"Upgrade to Premium successful"}
        description={"You upgraded to a premium plan."}
        btnText={"Continue"}
      />

      <ModalComponent
        openModal={openModalCardAdded}
        handleCloseModal={handleCloseModalCardAdded}
        handleBtnClick={handleCloseModalCardAdded}
        title={"Payment Information"}
        description={
          isCreateSuccess
            ? "Card created succesfully."
            : "Card updated succesfully."
        }
        btnText={"Continue"}
      />

      {isOnChangePref && (
        <Box className="billing-invoices-success-payments-modal">
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: { sm: 15, lg: 34 },
              right: { lg: 29, md: 30, sm: 10 },
              zIndex: 2,
            }}
            onClick={() => {
              setCardModalOpen(false);
              setIsOnChangePref(false);
            }}
          >
            <img width="34px" src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>

          <Box>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Payment Information
            </Typography>
            <Typography sx={textStyle}>
              {creditCardSelected.is_preferred
                ? "Atleast 1 card needs to be preferred, please make the other card preferred to remove this as preferred card."
                : "Are you sure you want to set this card as preferred?"}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              columnGap: "30px",
              marginTop: "30px",
            }}
          >
            <Button
              size="large"
              type="button"
              variant="contained"
              sx={{
                boxShadow: "none",
                width: "200px",
                backgroundColor: palette.secondary.main,
                paddingY: 1.3,
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={handleIsPreferredChange}
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default UpgradePlan;
