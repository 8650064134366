import { deleteToken } from "src/utils/extractUserToken";

import { backendTrackingUrl } from "src/utils/backendUrls";
import getData from "src/dataFetcher/getDataFetcher";
import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    if (result?.data) return decryptData(result?.data);
  }
  return result;
};

export const fetchScanData = async (startDate, endDate, token, headers) => {
  let url = `${backendTrackingUrl}tracking/statistics`;
  let params = null;

  if (startDate !== null && endDate !== null) {
    params = { date_range: `${startDate}-${endDate}` };
  }

  try {
    const data = await getData(url, params, headers, token);
    return data;
  } catch (error) {}
};

export const fetchShippingStatisticData = async (
  startDate,
  endDate,
  token,
  headers
) => {
  let url = `${backendTrackingUrl}tracking/statistics_live`;
  let params = null;

  if (startDate !== null && endDate !== null) {
    params = { date_range: `${startDate}-${endDate}` };
  }

  try {
    const data = await getData(url, params, headers, token);
    return data;
  } catch (error) {}
};
