import {
  Backdrop,
  CircularProgress,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Iconify from "src/components/iconify";
import ResetPassword from "src/components/reset-password/ResetPassword";
import timer from "src/utils/timer";
import OtpForm from "./OtpForm";
import { resetPassword } from "src/features/user/userApi";
import { passwordValidator } from "src/utils/validators";
import { ErrorMessagesSchema } from "src/utils/errorMessagesSchema";
import CircularLoader from "src/loader/circular/CircularLoader";

const inputStyles = {
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "primary.dark",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.dark",
    },
  },
};

const ResetPasswordStep2Page = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const {
    state: { email },
  } = useLocation();

  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const passwordInput = watch("new_password");
  const confirmPasswordInput = watch("confirm_password");

  useEffect(() => {
    if (errors.new_password && passwordInput) clearErrors("new_password");
  }, [passwordInput]);

  useEffect(() => {
    setPasswordError(false);
    if (errors.confirm_password && confirmPasswordInput)
      clearErrors("confirm_password");
  }, [confirmPasswordInput]);

  const onSubmit = async (data) => {
    if (data.new_password !== data.confirm_password) {
      setPasswordError(true);
      setIsSubmited(true);
      setErrorMessage(`${ErrorMessagesSchema.passwordValidation.dontMatch}`);
      return;
    }

    if (!passwordValidator(data?.new_password)) {
      setPasswordError(true);
      setIsSubmited(true);
      setErrorMessage(`${ErrorMessagesSchema.passwordValidation.requirements}`);
      return;
    }

    setIsLoading(true);

    const obj = {
      code: data?.otp,
      new_password: data?.new_password,
      email,
    };

    try {
      const response = await resetPassword(obj);
      setIsLoading(false);

      if (
        response?.data?.statusMessage?.startsWith(
          "Password does not conform to policy"
        )
      ) {
        setIsSubmited(true);
        setErrorMessage(
          `${ErrorMessagesSchema.passwordValidation.requirements}`
        );
      } else if (
        response?.data?.statusMessage?.startsWith("Attempt limit exceeded")
      ) {
        setIsSubmited(true);
        setErrorMessage(
          `${ErrorMessagesSchema.passwordValidation.attemptLimit}`
        );
      }
      // password changed successfully
      else if (response?.data?.statusMessage === "Password Changed") {
        setErrorMessage("");
        setIsLoading(true);
        await timer(1000);
        navigate("/reset-password/step-3");
      }
      // password changed successfully
      else if (response.statusMessage === "Succesfully sent notification.") {
        setErrorMessage("");
        setIsLoading(true);
        await timer(1000);
        navigate("/reset-password/step-3");
      }
      // else {
      // 	setIsSubmited(true);
      // 	// setErrorMessage(error?.message);
      // }
    } catch (error) {
      if (error.message.includes("Invalid"))
        setErrorMessage(
          "Invalid verification code provided, please try again."
        );
      else {
        setErrorMessage(
          "There has been an unexpected error, please try again."
        );
      }
      setIsSubmited(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> Reset Password | BeyondCarts </title>
      </Helmet>

      {isLoading && <CircularLoader />}

      <ResetPassword
        title={"Reset your password"}
        description={`Enter in the code that was sent to ${email}`}
        btnText={"Reset Password"}
        btnText2={"Back to log in"}
        handleSubmit={handleSubmit}
        setIsSubmited={setIsSubmited}
        onSubmit={onSubmit}
      >
        <OtpForm
          control={control}
          email={email}
          // isError={errorMessage.includes("Invalid") ? true : false}
        />
        <Stack gap={1.5}>
          <TextField
            label="New Password"
            name="new_password"
            type={showPassword ? "text" : "password"}
            {...register("new_password", {
              required: `${ErrorMessagesSchema.newPassword.required}`,
            })}
            error={!!errors.new_password}
            aria-invalid={!!errors.new_password}
            helperText={errors.new_password && errors.new_password.message}
            sx={inputStyles}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Confirm Password"
            name="confirm_password"
            type={showPassword ? "text" : "password"}
            {...register("confirm_password", {
              required: `${ErrorMessagesSchema.confirmPassword.required}`,
            })}
            error={!!errors.confirm_password || passwordError}
            aria-invalid={!!errors.confirm_password}
            helperText={
              errors.confirm_password
                ? errors.confirm_password.message
                : passwordError
                ? errorMessage
                : ""
            }
            sx={inputStyles}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        {errorMessage.includes("Invalid") &&
          <p className="errorMessageText">{errorMessage}</p>
        }
      </ResetPassword>
    </>
  );
};

export default ResetPasswordStep2Page;
