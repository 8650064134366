import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";

import { setSigninData } from "src/features/signin/signin.action";
import typography from "src/theme/typography";

import palette from "src/theme/palette";

import "./manage.scss";

const checkedLogoStyle = {
  position: "absolute",
  top: "-15px",
  right: "-25px",
  zIndex: "2",
};

const selectedStyle = {
  backgroundColor: "#dee2e6",
  border: "1px solid #256fa5",
  transition: "all 0.5s ease",
};

const ManageForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signinData = useSelector((state) => state.signin.signinData);
  const { isFromConfig } = signinData || {};

  const { manageError, manager } = signinData || {};

  const [isShopifySelected, setIsShopifySelected] = useState(false);
  const [isMagentoSelected, setIsMagentoSelected] = useState(false);
  const [isShipStationSelected, setIsShipStationSelected] = useState(false);

  const handleSelectManagment = (managment) => {
    switch (managment) {
      case "shopify":
        if (isShopifySelected) {
          setIsShopifySelected(false);
          dispatch(setSigninData({ ...signinData, manager: "" }));
        } else {
          setIsShopifySelected(true);
          setIsMagentoSelected(false);
          setIsShipStationSelected(false);

          dispatch(
            setSigninData({
              ...signinData,
              manager: managment,
              manageError: false,
            })
          );
        }

        break;

      case "magento":
        if (isMagentoSelected) {
          setIsMagentoSelected(false);
          dispatch(setSigninData({ ...signinData, manager: "" }));
        } else {
          setIsMagentoSelected(true);
          setIsShopifySelected(false);
          setIsShipStationSelected(false);

          dispatch(
            setSigninData({
              ...signinData,
              manager: managment,
              manageError: false,
            })
          );
        }

        break;

      case "shipstation":
        if (isShipStationSelected) {
          setIsShipStationSelected(false);
          dispatch(setSigninData({ ...signinData, manager: "" }));
        } else {
          setIsShipStationSelected(true);
          setIsShopifySelected(false);
          setIsMagentoSelected(false);
          dispatch(
            setSigninData({
              ...signinData,
              manager: managment,
              manageError: false,
            })
          );
        }

        break;
    }
  };

  useEffect(() => {
    if (!signinData?.user_id) {
      navigate("/login");
      return;
    }

    if (manager === "shopify") setIsShopifySelected(true);
    if (manager === "magento") setIsMagentoSelected(true);
    if (manager === "shipstation") setIsShipStationSelected(true);

    if (isFromConfig) return;

    dispatch(
      setSigninData({
        ...signinData,
        continueBtn: true,
      })
    );
  }, []);

  useEffect(() => {
    if (!manager) return;
    else if (
      Object.keys(manager).filter((key) => !!manager[key]).length === 0
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          manageError: true,
          continueBtn: false,
        })
      );
    } else
      dispatch(
        setSigninData({
          ...signinData,
          continueBtn: true,
        })
      );
  }, [manager]);

  return (
    <div className="manage-container">
      <h1 style={{ ...typography.h1, textAlign: "center" }}>
        What do you use to manage <br></br> your orders?
      </h1>
      <Typography
        sx={{
          color: palette.common.black,
          fontSize: { sx: 16, md: 18 },
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        We recognize the significance of managing your orders efficiently and
        accurately. Please choose your preferred order management system from
        the options below. This will enable you to monitor your orders in
        real-time, receive updates on their status, and get alerts for any
        changes or issues. Stay on top of your order management to ensure smooth
        operations and customer satisfaction.
      </Typography>

      <Box className="manage-img-container">
        <Box style={{ position: "relative" }}>
          <img
            className="manage-img"
            src="/assets/shopify.png"
            style={isShopifySelected ? selectedStyle : { width: "100%" }}
            onClick={() => handleSelectManagment("shopify")}
          />
          {isShopifySelected && (
            <img src="/assets/checkLogo.svg" style={checkedLogoStyle} />
          )}
        </Box>

        <Box style={{ position: "relative" }}>
          <img
            className="manage-img"
            src="/assets/magento.png"
            style={isMagentoSelected ? selectedStyle : null}
            onClick={() => handleSelectManagment("magento")}
          />
          {isMagentoSelected && (
            <img src="/assets/checkLogo.svg" style={checkedLogoStyle} />
          )}
        </Box>

        <Box style={{ position: "relative" }}>
          <img
            className="manage-img manage-img2"
            src="/assets/shipStation.png"
            style={isShipStationSelected ? selectedStyle : null}
            onClick={() => handleSelectManagment("shipstation")}
          />
          {isShipStationSelected && (
            <img src="/assets/checkLogo.svg" style={checkedLogoStyle} />
          )}
        </Box>
      </Box>

      {manageError && (
        <Typography
          sx={{
            color: palette.error.main,
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          You need to select 1 manager.
        </Typography>
      )}
    </div>
  );
};

export default ManageForm;
