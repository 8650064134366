import {
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  OutlinedInput,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ReturnsLayout from "./ReturnsLayout";
import ReturnItemCard from "./ReturnItemCard";
import { useEffect, useState } from "react";
import typography from "src/theme/typography";
import { LoadingButton } from "@mui/lab";
import Tooltip from "@mui/material/Tooltip";
import { createReturnRequest } from "../../../features/return_management/order/customerBrandPortalApi";
import useCustomerOrderInfo from "../../../features/return_management/hooks/useCustomerOrderInfo";
import CustomerModal from "../components/CreateReturnSuccessModal";
import { useNavigate, useParams } from "react-router-dom";
import CircularLoader from "../../../loader/circular/CircularLoader";
import { getCurrencySymbol } from "../../../utils/core";
import { useAppSelector } from "src/hooks/hooks";
import BoxConfigDialog from "src/pages/returns/BoxConfigDialog";
import { getUserInfo } from "src/features/user/userState";
import {
  LABEL_PROCESS_TYPE,
  LABELS,
} from "src/pages/ReturnConfiguration/ReturnMethod/Labels";
import "src/pages/returns/style/styles.css";
import InformationIcon from "src/pages/ReturnConfiguration/ReturnMethod/InformationIcon";
import { getSpecificReturnConfig } from "../../../features/returnConfiguration/returnRulesApi";

const ReturnsHome = ({ isHeader = true, isReturnHeadColor = false }) => {
  const navigate = useNavigate();
  const { tenant_name } = useParams();

  const isCustomerManagedReturn = useAppSelector(
    (state) => state.returnMethod.customerManagedReturn
  );
  const selectedReturnMethod = useAppSelector(
    (state) => state.returnMethod.selectedMethod
  );

  const carriers = ["UPS", "USPS", "Fedex", "DHL"];
  const [buyMyOwnLabelSelected, setBuyMyOwnLabelSelected] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isOneItemSelected, setIsOneItemSelected] = useState(false);
  const [checkoutItems, setCheckoutItems] = useState([]);
  const [itemCommentsReason, setItemCommentsReason] = useState({});
  const [eligibleItems, setEligibleItems] = useState({});
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [isCustomLoading, setCustomLoading] = useState(false);
  const [isReturnSuccessModal, setIsReturnSuccessModal] = useState(false);
  const [returnId, setReturnId] = useState(null);
  const [selectedRadioValue, setSelectedRadioValue] = useState(null);
  const [showcarrier, setShowcarrier] = useState(false);
  const [isAcceptAction, setIsAcceptAction] = useState(false);
  const [ShipmentData, setShipmentData] = useState(null);
  const [ShowReturnButton, setshowReturnButton] = useState(false);
  const [labelInfo, setLabelInfo] = useState(false);

  const [labelType, setLabelType] = useState(null);
  const {
    customerOrderInfo,
    eligibleLineItems,
    isLoading,
    returnRequestEstimate,
    customAuthInfo,
    returnInfo,
    brandPortalConfig,
    productImages,
    tenantInfo,
    fetchCustomerOrderInfo,
    fetchOrderEligibleLineItems,
    fetchEstimateReturnRequestSummary,
    fetchReturnInformationById,
    fetchProductImages,
    resetBrandPortalInfo,
  } = useCustomerOrderInfo();
  const userInfo = useAppSelector(getUserInfo);
  const updatedUserInfo = {
    ...userInfo,
    order_number: customAuthInfo?.user_info?.order_number,
  };
  const currency_symbol = getCurrencySymbol(customerOrderInfo?.currency);
  let return_base_path =
    customAuthInfo.return_flow === "customer_support"
      ? "returns_support"
      : "returns";
  const [labelPayload, setLabelPayload] = useState(null);

  const handleLabelGenerated = (payload) => {
    setLabelPayload(payload);
    setSelectValue("usps");
    setLabelType(payload.label_type);
    setshowReturnButton(true);
  };
  const [customerLineItem, setCustomerLineItem] = useState(
    customerOrderInfo?.line_items
  );

  const handleSubmit = ({ isLabelGenerate = false }) => {
    setshowReturnButton(isLabelGenerate || true);

    if (labelPayload) {
      console.log("Creating return with payload:", labelPayload);
    } else {
      console.log("No label generated yet!");
    }
    setIsAcceptAction(false);
  };

  useEffect(() => {
    fetchProductImages(tenantInfo);
    if (customAuthInfo?.user_info?.order_id) {
      if (returnInfo?._id) {
        if (customAuthInfo.return_flow === "customer_support") {
          navigate(`/returns_support/${tenant_name}/tracking-items`);
        } else {
          navigate(`/returns/${tenant_name}/tracking-items`);
        }
      } else {
        fetchCustomerOrderInfo(tenantInfo, {
          order_number: customAuthInfo?.user_info.order_id,
        });
      }
    } else {
      navigate(`/returns/${tenant_name}`);
    }
  }, []);
  useEffect(() => {
    if (customerOrderInfo?.order_id) {
      fetchOrderEligibleLineItems(tenantInfo, {
        order_id: customerOrderInfo?.order_id,
        tracking_number: customerOrderInfo?.fulfillments?.tracking_number,
      });
    }
  }, [customerOrderInfo?.order_id]);

  useEffect(() => {
    const lineItemsMap = Object.fromEntries(
      eligibleLineItems.map((item) => [item.line_item_id, item])
    );
    setEligibleItems(lineItemsMap);
    if (customerOrderInfo?.line_items) {
      const returnOrderList = customerOrderInfo.line_items.map((item) => {
        const lineItem = lineItemsMap[item.id];
        const product_id = item?.product_id;
        let image = "/assets/no-image.png";
        if (
          typeof productImages != "undefined" &&
          typeof productImages[product_id] != "undefined"
        ) {
          const product_img = productImages[product_id]?.image;
          if (product_img !== "" && product_img != null) {
            image = product_img;
          }
        }
        return {
          id: item.id,
          title: item.name,
          description: item.title,
          quantity: lineItem ? lineItem.quantity : item.quantity,
          price: item.price,
          imgUrl: image,
          eligibleForReturn: !!lineItem,
        };
      });
      returnOrderList.sort((a, b) => b.eligibleForReturn - a.eligibleForReturn);
      setOrderLineItems(returnOrderList);
    }
  }, [eligibleLineItems, customerOrderInfo, productImages]);

  useEffect(() => {
    if (checkoutItems.length === 0) {
      setIsOneItemSelected(false);
    } else {
      let line_items = [];
      checkoutItems.forEach(function (item) {
        line_items.push({
          ...eligibleItems[item.id],
          quantity: item.quantity,
        });
      });
      let payload = {
        order_id: customerOrderInfo?.order_id,
        line_items: line_items,
      };
      fetchEstimateReturnRequestSummary(tenantInfo, payload);
    }
  }, [checkoutItems]);
  const findItem = (array, key, value) => {
    return array.find((item) => item[key] === value);
  };
  const addItemToCheckout = (itemToAdd) => {
    const item = itemToAdd;
    const foundItem = findItem(checkoutItems, "id", item.id);
    if (!foundItem?.id) {
      setIsOneItemSelected(true);
      setCheckoutItems((prev) => [...prev, itemToAdd]);
    } else {
      if (foundItem?.quantity != item.quantity) {
        const updatedItems = checkoutItems.map((checkoutItem) =>
          checkoutItem.id === item.id
            ? { ...checkoutItem, quantity: item.quantity } // Update quantity
            : checkoutItem
        );
        setCheckoutItems(updatedItems);
      }
    }
  };
  const updateReasonComment = (data) => {
    setItemCommentsReason((prev) => ({
      ...prev,
      [data.line_item_id]: data,
    }));
  };

  const deleteItemToCheckout = (itemToDelete) => {
    setCheckoutItems((prev) =>
      prev.filter((el) => el?.id !== itemToDelete?.id)
    );
  };

  const createReturnRequestSubmit = async () => {
    setCustomLoading(true);
    let line_items = [];
    checkoutItems.forEach((item) => {
      const line_item_id = item.id;
      const item_reason = itemCommentsReason[line_item_id];
      const correspondingItem = customerOrderInfo.line_items.find(
        (data) => data.id === line_item_id
      );
      if (correspondingItem) {
        line_items.push({
          ...eligibleItems[line_item_id],
          product_name: correspondingItem.name,
          sku: correspondingItem.sku,
          price: correspondingItem.price,
          reason: item_reason.reason,
          quantity: item.quantity,
          return_reason: "UNWANTED",
          customer_notes: item_reason.comments,
          weight: correspondingItem?.weight,
          weight_unit: correspondingItem?.weight_unit,
        });
      }
    });
    let payload = {
      order_id: customerOrderInfo.order_id,
      line_items: line_items,
      return_flow: customAuthInfo?.return_flow,
      order_number: customAuthInfo?.user_info?.order_number,
      label_type: labelType,
    };
    if (labelType == LABEL_PROCESS_TYPE.OWN_LABEL) {
      payload["label_info"] = {
        carrier: selectValue,
        tracking_number: null,
        label_path: null,
      };
    } else if (
      labelType == LABEL_PROCESS_TYPE.PREPAID_FREE_LABEL ||
      labelType == LABEL_PROCESS_TYPE.PREPAID_PAID_LABEL
    ) {
      payload["label_info"] = {
        carrier: selectValue,
        tracking_number: labelPayload?.tracking_number,
        label_path: labelPayload?.label_path,
      };
    }

    try {
      const response = await createReturnRequest(tenantInfo, payload);
      const return_id = response.data.return_id;
      setIsReturnSuccessModal(true);
      setReturnId(return_id);
      fetchReturnInformationById(tenantInfo, {
        return_id: return_id,
      });
    } catch (e) {}
    setCustomLoading(false);
  };

  function getNumberValue(value) {
    return !isNaN(Math.abs(value)) ? Math.abs(value).toFixed(2) : 0.0;
  }

  const handleCloseSuccessModal = () => {
    if (customAuthInfo.return_flow === "customer_support") {
      navigate(`/return-refund/manage-returns/`);
    } else {
      setIsReturnSuccessModal(false);
      navigate(`/${return_base_path}/${tenant_name}/tracking-items`);
    }
  };

  function calculateEstimateRefund(value) {
    if (value != null) {
      const item_subtotal = Math.abs(value.return_items_amount);
      const tax_amount = Math.abs(value.tax_amount);
      const shipping = 0;
      const total = (item_subtotal + tax_amount + shipping).toFixed(2);
      return isNaN(total) ? "--" : total;
    } else {
      return "--";
    }
  }

  const handleRadioChange = async (event) => {
    const selectedradiolabel = event.target.value;
    setSelectedRadioValue(event.target.value);
    if (selectedradiolabel == LABELS.PREPAID_LABEL) {
      setLabelType(LABEL_PROCESS_TYPE.PREPAID_PAID_LABEL);
      setshowReturnButton(false);
    } else if (selectedradiolabel == LABELS.TWO_WAY) {
      setLabelType(LABEL_PROCESS_TYPE.TWO_WAY_LABEL);
      setshowReturnButton(true);
    } else if (selectedradiolabel == "customer_managed_return") {
      setLabelType(LABEL_PROCESS_TYPE.OWN_LABEL);
      setOpenDialog(true);
    }
  };
  const handleOkayClick = () => {
    if (showcarrier) {
      setLabelType(LABEL_PROCESS_TYPE.OWN_LABEL);
      createReturnRequestSubmit();
    } else {
      setOpenDialog(true);
    }
  };
  const handleGenerateLabel = () => {
    const shipping_details = customerOrderInfo?.shipping_details;
    const shipping_address = {
      first_name: shipping_details.first_name,
      last_name: shipping_details.last_name,
      address1: shipping_details.address1,
      city: shipping_details.city,
      country_code: shipping_details.country_code,
      zip: shipping_details.zip,
    };
    setShipmentData(shipping_address);
    setLabelInfo(true);
    setIsAcceptAction(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOkayDialog = () => {
    setShowcarrier(true);
    // setIsAcceptAction(true)
    setOpenDialog(false);
  };

  return (
    <ReturnsLayout
      isHeader={isHeader}
      isReturnHeadColor={isReturnHeadColor}
      isCx
    >
      {(isLoading || isCustomLoading) && <CircularLoader />}
      {/* body */}
      {returnId != null && (
        <CustomerModal
          isOpen={isReturnSuccessModal}
          handleClose={handleCloseSuccessModal}
        ></CustomerModal>
      )}
      <Stack
        sx={{
          backgroundColor: brandPortalConfig?.color_theme?.page_bg_color,
          minHeight: "83.5vh",
        }}
        zIndex={1}
        direction={"row"}
        width={"100%"}
        height={"100%"}
        paddingX={isHeader ? 8 : 0}
        paddingY={isHeader ? 8 : 0}
        gap={6}
      >
        {/* left section */}
        <Stack flex={1} width={"100%"} gap={3}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontSize={24}
              fontFamily={typography.fontFamilySemiBold}
            >
              Total Items ({orderLineItems.length})
            </Typography>
            <Stack
              direction={"row"}
              gap={1.2}
              padding={"8px 14px"}
              bgcolor={"#FBAC64"}
              borderRadius={"10px"}
              fontSize={14}
            >
              <img src="/assets/warning.svg" alt="warning icon" width={18} />
              <p>
                Note: You can initiate only one return request per Order Number.
              </p>
            </Stack>
          </Stack>
          <Stack gap={3} sx={{ scrollbarWidth: "thin" }}>
            {/* map card elements */}
            {orderLineItems.map((dataEL, index) => (
              <ReturnItemCard
                key={index}
                data={dataEL}
                max_quantity={dataEL.quantity}
                currency_symbol={currency_symbol}
                cbReasonCommentUpdate={updateReasonComment}
                addItemToCheckout={addItemToCheckout}
                deleteItemToCheckout={deleteItemToCheckout}
              />
            ))}
          </Stack>
        </Stack>

        {/* right section */}
        <Stack width={"30%"} gap={3}>
          <Stack gap={2}>
            <Typography
              fontSize={24}
              fontFamily={typography.fontFamilySemiBold}
            >
              Estimated Refund ({checkoutItems.length})
            </Typography>

            {!isOneItemSelected ? (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                boxShadow={"0px 9px 30px 0px #00000014"}
                height={150}
                color={"#AAAAAA"}
                fontSize={18}
                backgroundColor="white"
                fontFamily={typography.fontFamilySemiBold}
              >
                Select an item and return reason
              </Stack>
            ) : (
              <Stack
                boxShadow={"0px 9px 30px 0px #00000014"}
                backgroundColor="white"
                gap={1.5}
                padding={3}
              >
                {checkoutItems?.map((el) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Tooltip title={el?.title} arrow>
                      <p
                        style={{
                          fontFamily: typography.fontFamilySemiBold,
                          flexGrow: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          margin: 0,
                        }}
                      >
                        {el?.title}
                      </p>
                    </Tooltip>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <p
                        style={{
                          fontFamily: typography.fontFamilySemiBold,
                          margin: 0,
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>{currency_symbol}</span>
                        <span>{el?.price}&nbsp;&nbsp;</span>
                      </p>
                      <p
                        style={{
                          color: "#AAAAAAAA",
                          fontSize: 14,
                          whiteSpace: "nowrap",
                          margin: 0,
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        Qty : {el?.quantity}
                      </p>
                    </Stack>
                  </Stack>
                ))}

                <Divider />

                <Stack gap={2}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <p
                      style={{
                        fontFamily: typography.fontFamilySemiBold,
                        color: "#AAAAAAAA",
                      }}
                    >
                      Refund Subtotal
                    </p>
                    <p
                      style={{
                        fontFamily: typography.fontFamilySemiBold,
                      }}
                    >
                      {currency_symbol}{" "}
                      {getNumberValue(
                        returnRequestEstimate?.return_items_amount
                      )}
                    </p>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <p
                      style={{
                        fontFamily: typography.fontFamilySemiBold,
                        color: "#AAAAAAAA",
                      }}
                    >
                      Tax
                    </p>
                    <p
                      style={{
                        fontFamily: typography.fontFamilySemiBold,
                      }}
                    >
                      {currency_symbol}{" "}
                      {getNumberValue(returnRequestEstimate?.tax_amount)}
                    </p>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <p
                      style={{
                        fontFamily: typography.fontFamilySemiBold,
                        color: "#AAAAAAAA",
                      }}
                    >
                      Refund shipping fee
                    </p>
                    <p
                      style={{
                        fontFamily: typography.fontFamilySemiBold,
                      }}
                    >
                      {currency_symbol}{" "}
                      {getNumberValue(returnRequestEstimate?.shipping_amount)}
                    </p>
                  </Stack>
                </Stack>

                <Divider />

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  color={`${brandPortalConfig?.color_theme?.brand_color}`}
                  fontFamily={typography.fontFamilySemiBold}
                >
                  <p>Total Estimated refund</p>
                  <p>
                    {currency_symbol}{" "}
                    {getNumberValue(returnRequestEstimate?.sub_total)}
                  </p>
                </Stack>
              </Stack>
            )}
          </Stack>
          {
            // customAuthInfo.return_flow==="customer_support" &&
            isOneItemSelected &&
              customAuthInfo.return_flow !== "customer_support" && (
                <>
                  {tenantInfo?.is_customer_manage_return && (
                    <Stack gap={2}>
                      <Typography
                        fontSize={24}
                        fontFamily={typography.fontFamilySemiBold}
                      >
                        Return method
                      </Typography>
                      <Stack
                        boxShadow={"0px 9px 30px 0px #00000014"}
                        backgroundColor="white"
                        gap={1.5}
                        padding={3}
                        paddingTop={1.5}
                        paddingRight={1.5}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            fontFamily={typography.fontFamilySemiBold}
                          >
                            Buy my own label
                          </Typography>
                          <Checkbox
                            checked={buyMyOwnLabelSelected}
                            onChange={(event) => {
                              setBuyMyOwnLabelSelected(event.target.checked);
                              setLabelType(
                                event.target.checked
                                  ? LABEL_PROCESS_TYPE.OWN_LABEL
                                  : null
                              );
                            }}
                          />
                        </Stack>
                        {buyMyOwnLabelSelected && (
                          <Stack paddingRight={1.5} gap={3.5}>
                            <Typography lineHeight={"19.2px"}>
                              Please choose one of the below carrier to send it
                              back to us for a smooth return process.
                            </Typography>
                            <FormControl size="small" fullWidth>
                              <InputLabel size="small" id="select-label">
                                Select carrier
                              </InputLabel>
                              <Select
                                disabled={!buyMyOwnLabelSelected}
                                size="small"
                                labelId="select-label"
                                id="select-label"
                                label="Select a return reason"
                                value={selectValue}
                                onChange={(event) =>
                                  setSelectValue(event.target.value)
                                }
                                sx={{
                                  color: "common.black",
                                }}
                              >
                                {carriers?.map((el) => (
                                  <MenuItem
                                    value={el}
                                    sx={{
                                      ":hover": { backgroundColor: "#E9E9E9" },
                                    }}
                                  >
                                    {el}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  )}
                  {/* //check below button should come when no customer support so please keep as common outside */}
                  {isOneItemSelected && (
                    <Stack direction={"row"} justifyContent={"center"} gap={2}>
                      <LoadingButton
                        onClick={() => {
                          let url = brandPortalConfig?.logo_url;
                          if (url) {
                            if (
                              !url.startsWith("http://") &&
                              !url.startsWith("https://")
                            ) {
                              url = `https://${url}`;
                            }
                            window.location.href = url;
                          } else navigate(`/return-refund/manage-returns/`);
                        }}
                        variant="outlined"
                        sx={{
                          color: `${brandPortalConfig?.color_theme?.brand_color}`,
                          borderColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                          width: "30%",
                          padding: 0.8,
                          "&:hover": {
                            borderColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                          },
                        }}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        onClick={createReturnRequestSubmit}
                        sx={{
                          backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                          color: "#fff",
                          width: "30%",
                          "&:hover": {
                            backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                          },
                        }}
                        disabled={
                          tenantInfo?.is_customer_manage_return &&
                          tenantInfo.label_name == "" &&
                          !buyMyOwnLabelSelected
                        }
                      >
                        Create Return
                      </LoadingButton>
                    </Stack>
                  )}
                </>
              )
          }

          {/* ----------for prepaid_free_label---- */}
          {isOneItemSelected &&
            customAuthInfo.return_flow === "customer_support" && (
              <>
                <Stack gap={2}>
                  <Typography
                    fontSize={24}
                    fontFamily={typography.fontFamilySemiBold}
                  >
                    Return method
                  </Typography>
                  <Stack
                    boxShadow={"0px 9px 30px 0px #00000014"}
                    backgroundColor="white"
                    gap={1.5}
                    className="padding-space"
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <div style={{ width: "100%", marginRight: "20px" }}>
                        <RadioGroup
                          aria-label="return-method"
                          name="return-method"
                          value={selectedRadioValue}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value={`${tenantInfo?.label_name}`}
                            control={<Radio />}
                            label={`${
                              tenantInfo?.label_name === "prepaid_free_label"
                                ? "Prepaid Label"
                                : "Two Way Delivery Label"
                            }`}
                            labelPlacement="start"
                            className="radiotext"
                          />
                          {tenantInfo?.is_customer_manage_return == true && (
                            <FormControlLabel
                              value="customer_managed_return"
                              control={<Radio />}
                              label="Customer Managed Returns"
                              labelPlacement="start"
                              className="radiotext"
                            />
                          )}
                        </RadioGroup>

                        <Dialog
                          open={openDialog}
                          onClose={handleCloseDialog}
                          sx={{ borderRadius: "12px" }}
                        >
                          <div style={{ padding: "10px" }}>
                            <DialogTitle
                              sx={{
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              <InformationIcon />
                            </DialogTitle>
                            <DialogContent>
                              <Typography
                                fontFamily={typography.fontFamilyRegular}
                                sx={{
                                  color: "#1C272A",
                                  fontSize: typography.body1,
                                  whiteSpace: "pre-line",
                                  textAlign: "center",
                                }}
                              >
                                Since you have selected the managed return
                                method, please provide{"\n"}
                                the tracking number through the tracking portal
                                after dropping off{"\n"}
                                the item(s) with the carrier.
                              </Typography>
                            </DialogContent>
                            <DialogActions
                              sx={{
                                justifyContent: "center",
                              }}
                            >
                              <LoadingButton
                                variant="secondary"
                                className="Okay-btn-customise"
                                onClick={handleOkayDialog}
                                sx={{
                                  backgroundColor: "#002F43",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#002F43",
                                  },
                                }}
                              >
                                Okay
                              </LoadingButton>
                            </DialogActions>
                          </div>
                        </Dialog>
                      </div>
                    </Stack>
                    {tenantInfo?.is_customer_manage_return == true &&
                      labelType === LABEL_PROCESS_TYPE.OWN_LABEL && (
                        <Stack paddingRight={1.5} gap={3.5}>
                          <FormControl
                            size="small"
                            fullWidth
                            sx={{ height: "42px" }}
                          >
                            <InputLabel size="small" id="select-label">
                              Select carrier
                            </InputLabel>
                            <Select
                              // disabled={!buyMyOwnLabelSelected}
                              size="small"
                              labelId="select-label"
                              id="select-label"
                              label="Select a return reason"
                              value={selectValue}
                              input={<OutlinedInput label="Select carrier" />}
                              onChange={(event) =>
                                setSelectValue(event.target.value)
                              }
                              sx={{
                                color: "common.black",
                              }}
                            >
                              {carriers?.map((el) => (
                                <MenuItem
                                  value={el}
                                  sx={{
                                    ":hover": { backgroundColor: "#E9E9E9" },
                                  }}
                                >
                                  {el}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                      )}
                    {isAcceptAction && (
                      <BoxConfigDialog
                        // handleLabelGenerateSubmit={handleSubmit}
                        returnDetails={{
                          shipping_address: customerOrderInfo?.shipping_details,
                          order_number: customerOrderInfo?.order_number,
                        }}
                        userInfo={updatedUserInfo}
                        isAcceptAction={isAcceptAction}
                        onLabelGenerated={handleLabelGenerated}
                        customerLineItem={customerOrderInfo?.line_items}
                        labelButton={"Create Label"}
                        customerOrderInfo={customerOrderInfo}
                        ShipmentData={ShipmentData}
                        labelInfo={labelInfo}
                      />
                    )}
                  </Stack>
                </Stack>
                {labelType != null && (
                  <Stack direction={"row"} justifyContent={"center"} gap={2}>
                    <LoadingButton
                      variant="outlined"
                      className="cancel-btn-customise"
                      sx={{
                        color: `${brandPortalConfig?.color_theme?.brand_color}`,
                        borderColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                        // width: "30%",
                        // padding: '12px 60px',
                        "&:hover": {
                          borderColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                        },
                      }}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      onClick={(e) => {
                        selectedRadioValue == "customer_managed_return"
                          ? handleOkayClick()
                          : ShowReturnButton
                          ? createReturnRequestSubmit()
                          : handleGenerateLabel();
                      }}
                      // onClick={createReturnRequestSubmit}
                      className="return-btn-customise"
                      sx={{
                        backgroundColor: "#002F43",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#002F43",
                        },
                      }}
                    >
                      {selectedRadioValue == "prepaid_free_label"
                        ? ShowReturnButton
                          ? "Create Return"
                          : "Generate label"
                        : "Create Return"}
                    </LoadingButton>
                  </Stack>
                )}
              </>
            )}
        </Stack>
      </Stack>
    </ReturnsLayout>
  );
};

export default ReturnsHome;
