import {Box, Typography, Divider} from "@mui/material";
import {getNotificationStatusText, unixToDateAndTime} from "src/utils/core";

const NotificationsItem = ({message, timeAgo, status}) => {
  const notificationStatus = {pending: '#FFC107', successful: '#4CAF50', unsuccessful: '#F44336', stop: '#9E9E9E'}
  return (
    <>
      <Box
        sx={{
          py: {xs: 1.5, md: 1.5},
          px: {xs: 1.6, md: 2.5},
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          gap: 0,
          ":hover": {
            backgroundColor: "primary.lighter",
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{color: "common.black", fontSize: "16px"}}
        >
          {message}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "common.black",
              fontSize: "14px",
              color: "rgb(20, 63, 103)",
            }}
            noWrap
          >
            {unixToDateAndTime(timeAgo)} PST
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "common.black",
              fontSize: "14px",
              color: notificationStatus[status] || "purple",
            }}
            noWrap
          >
            {getNotificationStatusText(status)}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{borderStyle: "line"}}/>
    </>
  );
};

export default NotificationsItem;
