import {
  Stack,
  Box,
  Modal,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { createUser } from "src/features/usersPage/usersApi";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";

import typography from "src/theme/typography";
import palette from "src/theme/palette";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const styleInput = {
  input: { color: palette.common.black },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "primary.dark",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.dark",
    },
  },
};

const popUpModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "auto", lg: "auto", md: "auto" },
  borderRadius: "20px",
  background: "#FFF",
  overflow: "auto",
  overflow: "auto-y",
  overflowX: "hidden",
  outline: "none",
};

const AddUserModal = ({
  openModal,
  setOpenModal,
  handleCloseModal,
  setSuccessfulAddUser,
}) => {
  const [role, setRole] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    handleCloseModal();

    dispatch(incrementAwaitingApiCounter());

    const headers = {
      tenant_id: userInfo.tenant_id,
      user_id: userInfo.user_id,
      tenant_name: userInfo.tenant_name,
      action: "createTenant",
    };

    try {
      const response = await createUser(headers, data, userInfo);
      setIsError(false);
      // if (response.statusCode === 200) {
      setOpenModal(false);
      setSuccessfulAddUser(true);
      // }
      dispatch(decrementAwaitingApiCounter());
    } catch (err) {
      setIsError(true);
      setStatusMessage(err.message);
      dispatch(decrementAwaitingApiCounter());
    }
  };

  const handleSelectChange = (event) => {
    setRole(event.target.value);
  };

  const validateEmail = (email) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
  };

  useEffect(() => {
    if (!errors?.user_name?.message) setInvalidEmailError(true);
    else setInvalidEmailError(false);
  }, [validateEmail]);

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box sx={popUpModalStyle}>
        <Stack
          gap={5}
          direction="column"
          sx={{
            color: "common.black",
            paddingY: 3,
            paddingX: 4,
            fontFamily: typography.fontFamilyRegular,
            backgroundColor: "white",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: { xs: 13, md: 20 },
              right: { md: 22, xs: 10 },
              zIndex: 2,
            }}
            onClick={handleCloseModal}
          >
            <img width="30px" src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>
          <FormControl
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              gap: 3,
            }}
          >
            <Stack gap={1.5} alignItems="center">
              <Box
                style={{
                  fontFamily: typography.fontFamilySemiBold,
                }}
                sx={{
                  fontSize: {
                    lg: 30,
                    md: 27,
                    xs: 27,
                    sm: 27,
                  },
                  textAlign: "center",
                  // color: palette.primary.main,
                }}
              >
                Add User
              </Box>
              <Box
                sx={{
                  fontSize: {
                    lg: 16,
                    md: 16,
                    xs: 14,
                    sm: 14,
                  },
                  textAlign: "center",
                }}
              >
                To grant access to a new user,<br></br> please provide the
                required information below.
              </Box>
            </Stack>

            <Stack spacing={2}>
              <Stack direction={"row"} spacing={2}>
                <TextField
                  // className="textField"
                  label="First name"
                  name="first_name"
                  {...register("first_name", {
                    required: "First Name is required.",
                    validate: (value) => !/[^A-Za-z0-9 _]+/.test(value),
                  })}
                  error={!!errors.first_name}
                  helperText={
                    errors.first_name && "Valid first name is required."
                  }
                  sx={styleInput}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
                <TextField
                  label="Last name"
                  name="last_name"
                  {...register("last_name", {
                    required: "Valid Last Name is required.",
                    validate: (value) => !/[^A-Za-z0-9 _]+/.test(value),
                    message: "Valid Last Name is required.",
                  })}
                  error={!!errors.last_name}
                  helperText={
                    errors.last_name && "Valid last name is required."
                  }
                  sx={styleInput}
                  inputProps={{ maxLength: 40 }}
                />
              </Stack>
              <FormControl fullWidth sx={styleInput}>
                <InputLabel id="select-label">Role</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={role}
                  label="Role"
                  name="role"
                  {...register("role", {
                    required: "Role is required.",
                  })}
                  error={!!errors.role}
                  helperText={errors.role && errors.role.message}
                  sx={{
                    color: "common.black",
                    ...styleInput,
                  }}
                  onChange={handleSelectChange}
                >
                  <MenuItem value={"basic"} sx={{ color: "common.black" }}>
                    Basic
                  </MenuItem>
                  <MenuItem
                    value={"administrator"}
                    sx={{ color: "common.black" }}
                  >
                    Administrator
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Email"
                name="user_name"
                {...register("user_name", {
                  required: "Email address is required.",
                  validate: (email) => validateEmail(email),
                })}
                error={!!errors.user_name || isError}
                helperText={
                  errors?.user_name
                    ? invalidEmailError
                      ? "Invalid email address."
                      : errors.user_name.message
                    : statusMessage
                }
                sx={styleInput}
                inputProps={{ maxLength: 40 }}
              />
            </Stack>

            <Stack gap={2} direction={"row"} dis alignItems="center">
              <Button
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  boxShadow: "none",
                  backgroundColor: "grey.600",
                  paddingY: 1.3,
                  fontSize: {
                    lg: 15,
                    md: 15,
                    xs: 13,
                    sm: 13,
                  },
                  "&:hover": {
                    backgroundColor: "primary.lightIcon",
                  },
                }}
                style={{
                  fontFamily: typography.fontFamilySemiBold,
                }}
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                disableElevation
                type="submit"
                sx={{
                  boxShadow: "none",
                  backgroundColor: palette.secondary.main,
                  paddingY: 1.3,
                  fontSize: {
                    lg: 15,
                    md: 15,
                    xs: 13,
                    sm: 13,
                  },
                  fontFamily: typography.fontFamilySemiBold,
                }}
              >
                Add User
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddUserModal;
