import { Box, Typography } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const ShippingInstructions = ({shippingInfo}) => {
    return <>
        <Box sx={{textAlign:'center', padding: '20px'}}>
            <TaskAltIcon sx={{height: '50px', width: '50px', color: 'lightblue'}}/>
            <Typography sx={{ color: '#0009', fontSize: '24px' }}>You're all set!</Typography>
            <Typography sx={{ color: '#0009', fontSize: '14px', mb:1 }}>We've recorded your return request.</Typography>
            <div style={{ color: '#0009', fontWeight: 'bold', fontSize: '14px', textAlign:'left'}} dangerouslySetInnerHTML={{ __html: shippingInfo }} />
        </Box>
    </>
}

export default ShippingInstructions