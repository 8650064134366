// usersState.js
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  getBrandPortalInfo, getBrandPortalProductInfo,
  getCustomerOrderInformation, getEstimateRefundSummary,
  getEstimateReturnSummary,
  getOrderEligibleLineItems, getReturnInformationByReturnID, getTenantInfo
} from "./customerBrandPortalApi";
import {getBrandedPortalInfo} from "../../brandedPortal/brandedPortalApi";

const initialState = {
  customerOrderInfo: {
    statusCode: 0,
    data: [],
  },
  tenantInfo: {
    // "tenant_id": "66698f0f7ac5acfa3bbd2ee7",
    // "tenant_name": "beyondcartsdemo",
    // "company_name": "beyondcartsdemo"
  },
  eligibleLineItems: [],
  returnRequestEstimate: {},
  returnEstimate: {},
  returnInfo: {},
  isLoading: false,
  error: null,
  brandPortalConfig: {},
  trackingPortalConfig: {},
  productImages: {}
};

export const getCustomerOrderInfo = createAsyncThunk(
  "returns/fetch_customer_order_items",
  async ({userInfo, params}) => {
    const response = await getCustomerOrderInformation(userInfo, params);
    return response.data;
  }
);
export const getEligibleOrderItems = createAsyncThunk(
  "returns/fetch_eligible_line_items",
  async ({userInfo, payload}) => {
    const response = await getOrderEligibleLineItems(userInfo, payload);
    const data = response.data;
    if (Object.keys(data).length > 0) {
      return data[Object.keys(data)[0]];
    } else {
      return []
    }
  }
);

export const getEstimateReturnRequestSummary = createAsyncThunk(
  "returns/fetch_return_request_estimate_summary",
  async ({userInfo, payload}) => {
    const response = await getEstimateReturnSummary(userInfo, payload);
    const data = response.data;
    return data;
  }
);

export const getEstimateReturnRefundSummary = createAsyncThunk(
  "returns/fetch_return_estimate_summary",
  async ({userInfo, payload}) => {
    const response = await getEstimateRefundSummary(userInfo, payload);
    const data = response.data;
    return data;
  }
);

export const getReturnInformationById = createAsyncThunk(
  "returns/fetch_return_info",
  async ({userInfo, payload}) => {
    const response = await getReturnInformationByReturnID(userInfo, payload);
    const data = response.data;
    return data;
  }
);

export const getBrandPortalConfig = createAsyncThunk(
  "returns/fetch_brand_portal_config",
  async ({userInfo, payload}) => {
    const response = await getBrandPortalInfo(userInfo, payload);
    const data = response.data;
    return data;
  }
);
export const getBrandProductImages = createAsyncThunk(
  "returns/fetch_product_images",
  async ({userInfo, payload}) => {

    const response = await getBrandPortalProductInfo(userInfo, payload);
    const data = response.data;
    return data;
  }
);
export const getTenantInfoData = createAsyncThunk(
  "returns/tenant_info",
  async ({userInfo, payload}) => {
    const response = await getTenantInfo(userInfo, payload);
    const data = response.data;
    return data;
  }
);
const customerBrandPortal = createSlice({
  name: "returns",
  initialState,
  reducers: {
    updateReturnInfo: (state, action) => {
      state['returnInfo'] = action.payload
    },
    updateBrandPortalConfig: (state, action) => {
      state['brandPortalConfig'] = action.payload
    },
    updateTrackingPortalConfig: (state, action) => {
      state['trackingPortalConfig'] = action.payload
    },
    updateTenantInfo: (state, action) => {
      state['tenantInfo'] = action.payload
    },
    resetBrandPortal: (state, action) => {
      state['customerOrderInfo'] = {
        statusCode: 0,
        data: [],
      };
      state['eligibleLineItems'] = [];
      state['returnRequestEstimate'] = {};
      state['returnEstimate'] = {};
      state['returnInfo'] = {};
      state['isLoading'] = false;
      state['error'] = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerOrderInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCustomerOrderInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerOrderInfo = action.payload;
      })
      .addCase(getCustomerOrderInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getEligibleOrderItems.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEligibleOrderItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.eligibleLineItems = action.payload;
      })
      .addCase(getEligibleOrderItems.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getEstimateReturnRequestSummary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEstimateReturnRequestSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.returnRequestEstimate = action.payload;
      })
      .addCase(getEstimateReturnRequestSummary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getEstimateReturnRefundSummary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEstimateReturnRefundSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.returnEstimate = action.payload;
      })
      .addCase(getEstimateReturnRefundSummary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getReturnInformationById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getReturnInformationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.returnInfo = action.payload;
      })
      .addCase(getReturnInformationById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getBrandPortalConfig.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBrandPortalConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.brandPortalConfig = action.payload;
      })
      .addCase(getBrandPortalConfig.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getBrandProductImages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBrandProductImages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productImages = action.payload;
      })
      .addCase(getBrandProductImages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getTenantInfoData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTenantInfoData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tenantInfo = action.payload;
      })
      .addCase(getTenantInfoData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  updateReturnInfo,
  updateTenantInfo,
  updateTrackingPortalConfig,
  resetBrandPortal,
  updateBrandPortalConfig
} = customerBrandPortal.actions;

export default customerBrandPortal.reducer;
