import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ResetPassword from "src/components/reset-password/ResetPassword";
import timer from "src/utils/timer";
import CircularLoader from "src/loader/circular/CircularLoader";

const ResetPasswordStep3Page = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    await timer(1000);
    setIsSubmited(true);
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title> Reset Password | BeyondCarts </title>
      </Helmet>

      {isLoading && <CircularLoader />}

      <ResetPassword
        title={"Your password has been reset!"}
        style={{
          title: {
            color: "$primary-color",
            textAlign: "center",
            paddingBottom: "3vh",
          },
        }}
        btnText={"Return to login"}
        handleSubmit={handleSubmit}
        setIsSubmited={setIsSubmited}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ResetPasswordStep3Page;
