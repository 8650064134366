import axios from "axios";
import { decryptData } from "./chiper";
import { encConfig } from "./config";
import { deleteToken } from "src/utils/extractUserToken";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    if (result?.data) return decryptData(result?.data);
  }
  return result;
};

export default async function getData(
  url,
  params = null,
  headers,
  token = null
) {
  // random number of 9 digits, not starting by zero
  const randomInt = Math.floor(1e7 + Math.random() * 9e7);
  const apiKey = process.env.REACT_APP_BACKEND_API_KEY;
  const config = {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Apikey: apiKey,
      request_id: randomInt,
    },
    params: params,
  };
  if (token) config.headers.Authorization = `Bearer ${token}`;

  try {
    const response = await axios.get(url, config);
    return JSON?.parse(extractData(response));
  } catch (error) {
    if (error?.status === 400 && error?.response){
      const parsedError = JSON.parse(extractData(error?.response));
      return parsedError;
    }
      // return await JSON?.parse(extractData(error?.response));
    if (error?.status == 401 || error?.status == 403) {
      deleteToken();
      return;
    }
    let statusMsg = JSON.parse(extractData(error?.response));
    if ([401, 403].includes(parseInt(error?.status))) {
      deleteToken();
      return;
    }
    // throw statusMsg.statusMessage;
  }
}
export async function getDataNoEnc(url, params = null, headers, token = null) {
  // random number of 9 digits, not starting by zero
  const randomInt = Math.floor(1e7 + Math.random() * 9e7);
  const apiKey = process.env.REACT_APP_BACKEND_API_KEY;
  const config = {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Apikey: apiKey,
      request_id: randomInt,
    },
    params: params,
  };

  if (token) config.headers.Authorization = `Bearer ${token}`;

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    if (error?.status == 401 || error?.status == 403) {
      deleteToken();
      return;
    }
    let statusMsg = JSON.parse(extractData(error?.response)); // Due to this line error not checking  the condition  and requiremnet is  when token is expired need to logout Id:#1467
    if ([401, 403].includes(parseInt(error?.status))) {
      deleteToken();
      return;
    }
    // throw statusMsg.statusMessage;
  }
}
