import Joi from "joi";
import {
  validationFieldMessages,
  validationTypes,
} from "../formValidationRules/validation";

const firstNameValidation = Joi.string()
  .trim()
  .min(validationTypes.firstName.minLength)
  .max(validationTypes.firstName.maxLength)
  .pattern(validationTypes.firstName.pattern)
  .required()
  .messages({
    "string.empty": validationFieldMessages.firstName.required,
    "string.min": validationFieldMessages.firstName.minLength,
    "string.max": validationFieldMessages.firstName.maxLength,
    "string.pattern.base": validationFieldMessages.firstName.pattern,
  })
  .label("First Name");

const lastNameValidation = Joi.string()
  .trim()
  .min(validationTypes.lastName.minLength)
  .max(validationTypes.lastName.maxLength)
  .pattern(validationTypes.lastName.pattern)
  .required()
  .messages({
    "string.empty": validationFieldMessages.lastName.required,
    "string.min": validationFieldMessages.lastName.minLength,
    "string.max": validationFieldMessages.lastName.maxLength,
    "string.pattern.base": validationFieldMessages.lastName.pattern,
  })
  .label("Last Name");

const addressLineValidation = Joi.string()
  .trim()
  .min(1)
  .min(validationTypes.address1.minLength)
  .max(validationTypes.address1.maxLength)
  .pattern(validationTypes.address1.pattern)
  .required()
  .messages({
    "string.empty": "Address is required",
    "string.min": validationFieldMessages.address1.minLength,
    "string.max": validationFieldMessages.address1.maxLength,
    "string.pattern.base": validationFieldMessages.address1.pattern,
  });

const cityValidation = Joi.string()
  .trim()
  .min(validationTypes.city.minLength)
  .max(validationTypes.city.maxLength)
  .pattern(validationTypes.city.pattern)
  .required()
  .messages({
    "string.empty": "City is required",
    "string.min": validationFieldMessages.city.minLength,
    "string.max": validationFieldMessages.city.maxLength,
    "string.pattern.base": validationFieldMessages.city.pattern,
  });

const stateValidation = Joi.string()
  .trim()
  .min(1)
  .min(validationTypes.city.minLength)
  .max(validationTypes.city.maxLength)
  .pattern(validationTypes.city.pattern)
  .required()
  .messages({
    "string.empty": "State is required",
    "string.min": validationFieldMessages.state.minLength,
    "string.max": validationFieldMessages.state.maxLength,
    "string.pattern.base": validationFieldMessages.state.pattern,
  });

const postalCodeValidation = Joi.string()
  .trim()
  .min(validationTypes.zip.minLength)
  .max(validationTypes.zip.maxLength)
  .pattern(validationTypes.zip.pattern)
  .required()
  .messages({
    "string.empty": "Zip code is required",
    "string.min": validationFieldMessages.zip.minLength,
    "string.max": validationFieldMessages.zip.maxLength,
    "string.pattern.base": validationFieldMessages.zip.pattern,
  });

const countryValidation = Joi.string()
  .trim()
  .min(validationTypes.city.minLength)
  .max(validationTypes.city.maxLength)
  .pattern(validationTypes.city.pattern)
  .required()
  .messages({
    "string.empty": "Country is required",
    "string.min": validationFieldMessages.country.minLength,
    "string.max": validationFieldMessages.country.maxLength,
    "string.pattern.base": validationFieldMessages.country.pattern,
  });

export const warehouseAddressSchema = Joi.object({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  addressLine: addressLineValidation,
  city: cityValidation,
  state: stateValidation,
  zipCode: postalCodeValidation,
  country: countryValidation,
});
