import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  step: 0,
  warehouse: {
    firstName: "",
    lastName: "",
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  },
  carrier: {
    carrier: "USPS",
    mailClass: [],
  },
  label: {
    labelSize: "",
    labelType: "",
  },
  boxes: [],
  activeBox: 0,
  emailConfig: {
    labelType: "",
  },
  paidLabelConfig: {
    reasons: [],
  },
};

const stepperFormSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    resetStepperForm: () => {
      return initialState;
    },
    updateStep: (state, action) => {
      state.step = action.payload;
    },
    updateForm: (state, action) => {
      state = _.merge({}, state, action.payload);
      return state;
    },
    addBox: (state, action) => {
      const newBox = action.payload;
      state.boxes = newBox;
    },
    updateWarehouse: (state, action) => {
      if (!state.warehouse) {
        state.warehouse = {};
      }
      state.warehouse = {
        ...state.warehouse,
        ...action.payload,
      };
    },
    updateActiveBox: (state, action) => {
      state.activeBox = action.payload;
    },
    updateCarrier: (state, action) => {
      const { carrier, mailClass } = action.payload;
      state.carrier = { ...action.payload };
    },
    updateBoxConfig: (state, action) => {
      state.boxConfig = action.payload;
    },
    updateEmailConfig: (state, action) => {
      state.emailConfig = action.payload;
    },
    updatePaidLabelConfig: (state, action) => {
      const { variantName } = action.payload;
      state.paidLabelConfig.reasons = [...variantName];
    },
    updateLabel: (state, action) => {
      // const { labelSize, labelType } = action.payload;
      state.label = { ...action.payload };
    },
  },
});
export const selectPaidLabelReasons = (state) =>
  state.form.paidLabelConfig.reasons;
export const selectLabelSizeOption = (state) => state?.form?.label?.labelSize;
export const {
  updateWarehouse,
  updateCarrier,
  updateBoxConfig,
  updateEmailConfig,
  updatePaidLabelConfig,
  addBox,
  updateLabel,
  updateActiveBox,
  resetStepperForm,
  updateForm,
  updateStep,
} = stepperFormSlice.actions;

export default stepperFormSlice.reducer;
